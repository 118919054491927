import React, { useState } from 'react'
import { add, endOfWeek, format, isWithinInterval, startOfWeek } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs'
import { InputDate } from 'components/Input'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import MapGallery from 'components/MapGallery'
import { useTheme } from 'styled-components'
import { EcmwfDescription } from '..'
import { Container, ControllerContainer } from './styles'

const Daily: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const theme = useTheme()
  const models = ['ecmwf', 'gefs', 'eta']
  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

  const returnLabelColor = (mapDate: Date): string => {
    const currentDayWithOneWeekAdded = add(date, { weeks: 1 })
    const currentDayWithTwoWeeksAdded = add(date, { weeks: 2 })
    const firstDayOfCurrentWeek = startOfWeek(date, { weekStartsOn: 6 })
    const lastDayOfCurrentWeek = endOfWeek(date, { weekStartsOn: 6 })
    const firstDayOfNextWeek = startOfWeek(currentDayWithOneWeekAdded, { weekStartsOn: 6 })
    const lastDayOfNextWeek = endOfWeek(currentDayWithOneWeekAdded, { weekStartsOn: 6 })
    const firstDayOfNextNextWeek = startOfWeek(currentDayWithTwoWeeksAdded, { weekStartsOn: 6 })
    const lastDayOfNextNextWeek = endOfWeek(currentDayWithTwoWeeksAdded, { weekStartsOn: 6 })

    if (isWithinInterval(mapDate, { start: firstDayOfCurrentWeek, end: lastDayOfCurrentWeek })) return theme.safiraColors.blue[4]
    if (isWithinInterval(mapDate, { start: firstDayOfNextWeek, end: lastDayOfNextWeek })) return theme.safiraColors.green[4]
    if (isWithinInterval(mapDate, { start: firstDayOfNextNextWeek, end: lastDayOfNextNextWeek })) return theme.safiraColors.orange[4]

    return 'red'
  }

  const images = days.map(day => {
    const targetDate = add(date, { days: day })
    const formattedDate = format(date, 'yyyyMMdd')
    const formattedTargetDate = format(targetDate, 'yyyyMMdd')
    const weekDay = targetDate.toLocaleString('pt-BR', { weekday: 'long' })
    return {
      title: `Previsão para ${weekDay}, ${targetDate.toLocaleDateString()}`,
      color: returnLabelColor(targetDate),
      ecmwf: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-ecmwf/ECMWF_r${formattedDate}_00Z_${
        day < 10 ? `0${day}` : day
      }_diario_${formattedTargetDate}.png`,
      gefs: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-gefs/GEFS_r${formattedDate}_00Z_${
        day < 10 ? `0${day}` : day
      }_diario_${formattedTargetDate}.png`,
      eta: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-eta/ETA_r${formattedDate}_00Z_${
        day < 10 ? `0${day}` : day
      }_diario_${formattedTargetDate}.png`,
    }
  })

  const descriptions = {
    ecmwf: (
      <EcmwfDescription initialText='Mapa de chuva diária prevista pelo modelo European Centre for Medium-Range Weather Forecasts (ECMWF), rodado e disponibilizado pela instituição de mesmo nome. O ECMWF é um modelo de previsão por conjunto (ensemble) global e possui 0.2º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão média do conjunto.' />
    ),
    gefs: (
      <p>
        Mapa de chuva diária prevista pelo modelo Global Ensemble Forecast System (GEFS), rodado e disponibilizado pelo National Centers for
        Environmental Prediction (NCEP) da National Oceanic and Atmospheric Administration (NOAA). O GEFS é um modelo de previsão por conjunto
        (ensemble) global e possui 0.5º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os
        primeiros 14 dias de previsão e é apresentada a previsão média do conjunto.
      </p>
    ),
    eta: (
      <p>
        Mapa de chuva diária prevista pelo modelo ETA, rodado e disponibilizado pelo Centro de Previsão de Tempo e Estudos Climáticos do Instituto
        Nacional de Pesquisas Espaciais (CPTEC/INPE). O ETA é um modelo de previsão regional e possui 40 km de resolução espacial e horizonte de
        previsão de 10 dias.
      </p>
    ),
  }

  return (
    <Container>
      <SectionTitle>Previsão diária</SectionTitle>
      <SectionText>
        <span>Mapas de previsão diária de chuva dos modelos que compõe o processo de chuva-vazão do ONS. Utilize as teclas</span>
        <BsArrowLeftShort style={{ marginLeft: '5px' }} />
        <BsArrowRightShort style={{ marginRight: '5px' }} />
        <span>para alternar a data da previsão. </span>
        <span>
          Como os dados de previsão de chuva desta seção são disponibilizados diretamente pelo ONS, eventuais atrasos na geração dos mapas podem
          ocorrer.
        </span>
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          value={format(date, 'yyyy-MM-dd')}
          max={format(new Date(), 'yyyy-MM-dd')}
          onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
        />
      </ControllerContainer>
      <MapGallery images={images} models={models} descriptions={descriptions} scale='forecast' />
    </Container>
  )
}

export default Daily
