import React from 'react'
import { ResponsiveContainer, CartesianGrid, ComposedChart, XAxis, YAxis, Bar, Tooltip, Label, ReferenceLine, Line, Legend, Area } from 'recharts'
import { utcDateFromDate } from 'utils'
import { EnaEarDTO } from '..'
import { ChartsLoadingPlaceholder } from './ChartsLoadingPlaceholder'
import { ChartContainer, ChartsContainer } from './styles'
import { CustomTooltip } from './CustomTooltip'
import { CustomLegend } from 'components/Chart'
import { lighten } from 'polished'
import { useTheme } from 'styled-components'

type ChartsProps = {
  data?: EnaEarDTO[]
  color?: string
  isLoading?: boolean
  chartType?: string
}

interface Chart {
  label: string
  break?: boolean
  yAxisMaxValue?: number
  YAxisTickFormatter: (tick: number) => string
  series: {
    type?: string
    name: string
    dataKey: string
    color?: string
    tooltipValueFormatter?: (value: number) => string
  }[]
}

export const Charts: React.FC<ChartsProps> = ({ data, color, isLoading, chartType }) => {
  if (isLoading) return <ChartsLoadingPlaceholder />

  const theme = useTheme()

  const charts: Chart[] = [
    {
      label: 'ENA (MWmed)',
      series: [
        {
          name: 'ENA MWmed',
          dataKey: 'enaMwmed',
          color,
        },
        {
          type: 'line',
          name: 'MLT',
          dataKey: 'mlt',
          color: color && lighten(0.3, color),
        },
      ],
      YAxisTickFormatter: (tick: number) => tick.toLocaleString('pt-br'),
    },
    {
      label: 'EAR (MWmês)',
      break: true,
      series: [
        {
          name: 'EAR (MWmês)',
          dataKey: 'earDayMwMonth',
          color,
        },
        {
          type: 'line',
          name: 'EAR Capacidade',
          dataKey: 'earCapacityMwMonth',
          color: color && lighten(0.3, color),
        },
      ],
      YAxisTickFormatter: (tick: number) => tick.toLocaleString('pt-br'),
    },
    {
      label: 'ENA (% MLT)',
      series: [
        {
          name: 'ENA (% MLT)',
          dataKey: 'enaPercent',
          color,
          tooltipValueFormatter(value) {
            return (value / 100).toLocaleString('pt-br', { style: 'percent', maximumFractionDigits: 1 })
          },
        },
      ],
      YAxisTickFormatter: (tick: number) => {
        if (chartType && chartType === 'line' && data) {
          const series = data.map(entry => entry.enaPercent)

          const [max, min] = [Math.max(...series), Math.min(...series)]

          if (max - min <= 2)
            return (tick / 100).toLocaleString('pt-br', {
              style: 'percent',
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            })
        }

        return (tick / 100).toLocaleString('pt-br', { style: 'percent' })
      },
    },
    {
      label: 'EAR (%)',
      yAxisMaxValue: 100,
      series: [
        {
          name: 'EAR (%)',
          dataKey: 'earDayPercent',
          tooltipValueFormatter(value) {
            return (value / 100).toLocaleString('pt-br', { style: 'percent', maximumFractionDigits: 1 })
          },
          color,
        },
      ],
      YAxisTickFormatter: (tick: number) => {
        if (chartType && chartType === 'line' && data) {
          const series = data.map(entry => entry.earDayPercent)

          const [max, min] = [Math.max(...series), Math.min(...series)]

          if (max - min <= 2)
            return (tick / 100).toLocaleString('pt-br', {
              style: 'percent',
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            })
        }

        return (tick / 100).toLocaleString('pt-br', { style: 'percent' })
      },
    },
  ]

  return (
    <ChartsContainer>
      {charts.map((chart, index) => {
        const delay = (index + 1) * 100
        return (
          <>
            <ChartContainer key={index} delay={delay}>
              <ResponsiveContainer>
                <ComposedChart data={data}>
                  <CartesianGrid strokeDasharray='3' vertical={false} />
                  <Legend
                    content={
                      <CustomLegend
                        legends={chart.series.map(serie => ({
                          text: serie.name,
                          color: serie.color,
                          isLine: serie.type === 'line' || chartType === 'line',
                        }))}
                      />
                    }
                  />
                  <XAxis
                    dataKey='date'
                    tick={{ fontSize: '12px' }}
                    tickFormatter={tick => utcDateFromDate(new Date(tick)).toLocaleDateString()}
                    scale={chartType && chartType === 'area' ? 'point' : undefined}
                  />
                  <YAxis
                    width={90}
                    tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                    domain={chartType && chartType === 'line' ? ['auto', 'auto'] : [0, chart.yAxisMaxValue ? chart.yAxisMaxValue : 'auto']}
                    tickFormatter={chart.YAxisTickFormatter}
                  >
                    <Label
                      angle={-90}
                      value={chart.label}
                      position='insideLeft'
                      style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                    />
                  </YAxis>
                  {data?.map((entry, dataIndex) => {
                    if (utcDateFromDate(new Date(entry.date)).getDate() !== 1 || dataIndex === 0) return null
                    return <ReferenceLine key={String(entry.date)} x={String(entry.date)} stroke='#ccc' strokeDasharray='3' />
                  })}
                  <Tooltip
                    content={<CustomTooltip lines={chart.series.map(serie => ({ ...serie, valueFormatter: serie.tooltipValueFormatter }))} />}
                  />
                  {chart.series.map(serie => {
                    if (!serie.type && chartType) {
                      if (chartType === 'bar') {
                        return (
                          <Bar
                            animationBegin={delay}
                            strokeWidth={10}
                            key={serie.dataKey}
                            dataKey={serie.dataKey}
                            fill={serie.color}
                            radius={[5, 5, 0, 0]}
                            name={serie.name}
                          />
                        )
                      }

                      if (chartType === 'area')
                        return (
                          <Area
                            animationBegin={delay}
                            stroke='transparent'
                            key={serie.dataKey}
                            dataKey={serie.dataKey}
                            fill={serie.color}
                            fillOpacity={1}
                            name={serie.name}
                          />
                        )

                      if (chartType === 'line')
                        return (
                          <Line
                            animationBegin={delay}
                            strokeWidth={3}
                            key={serie.dataKey}
                            dataKey={serie.dataKey}
                            stroke={serie.color}
                            dot={data && data?.length <= 30}
                            name={serie.name}
                          />
                        )
                    }

                    if (serie.type === 'bar') {
                      return (
                        <Bar
                          animationBegin={delay}
                          strokeWidth={10}
                          key={serie.dataKey}
                          dataKey={serie.dataKey}
                          fill={serie.color}
                          radius={[5, 5, 0, 0]}
                          name={serie.name}
                        />
                      )
                    }

                    if (serie.type === 'area')
                      return (
                        <Area
                          animationBegin={delay}
                          stroke='transparent'
                          key={serie.dataKey}
                          dataKey={serie.dataKey}
                          fill={serie.color}
                          fillOpacity={1}
                          name={serie.name}
                        />
                      )

                    if (serie.type === 'line')
                      return (
                        <Line
                          animationBegin={delay}
                          strokeWidth={3}
                          key={serie.dataKey}
                          dataKey={serie.dataKey}
                          stroke={serie.color}
                          dot={data && data?.length <= 30}
                          name={serie.name}
                        />
                      )

                    return null
                  })}
                </ComposedChart>
              </ResponsiveContainer>
            </ChartContainer>
            {chart.break ? <div className='break' /> : null}
          </>
        )
      })}
    </ChartsContainer>
  )
}
