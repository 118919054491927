import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  max-width: 1600px;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 8rem;
`

export const Divider = styled.div`
  height: 1px;
  margin: 1.5rem 0;
  background: ${props => props.theme.colors.black300};
`
