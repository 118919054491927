import React, { useState } from 'react'
import { InputDate } from 'components/Input'
import { SectionText, SectionTitle } from 'components/Section'
import { Container, ControllerContainer, ChartContainer } from './styles'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ResponsiveContainer, LineChart, YAxis, XAxis, Line, Legend, Tooltip, CartesianGrid, Label } from 'recharts'
import { subsystems } from 'utils/globals'
import { useTheme } from 'styled-components'
import { format, parse } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { CustomLegend } from 'components/Chart'
import { CustomTooltip } from './CustomTooltip'

type DessemCCEEPLD = {
  hour: number
  southeast: number
  south: number
  northeast: number
  north: number
}

export function Price({ lastCceeDessemDate }: { lastCceeDessemDate: Date }) {
  const [date, setDate] = useState(lastCceeDessemDate)

  const theme = useTheme()

  const { data, isLoading } = useQuery(
    ['dessem-ccee-pld', date],
    () => api.get<DessemCCEEPLD[]>(`dessem-ccee/prices/${date.toISOString()}`).then(({ data }) => data),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  return (
    <Container>
      <SectionTitle>Modelo DESSEM - PLD</SectionTitle>
      <SectionText>
        Resultado do modelo DESSEM no caso CCEE com os valores do Custo Marginal da Operação (CMO) após médias horárias de preço e antes da aplicação
        dos limites regulatórios do Preço de Liquidação das Diferenças (PLD)
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          value={format(utcDateFromDate(date), 'yyyy-MM-dd')}
          max={format(utcDateFromDate(lastCceeDessemDate), 'yyyy-MM-dd')}
          onChange={e => setDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
        />
      </ControllerContainer>
      <ChartContainer isLoading={isLoading}>
        <ResponsiveContainer>
          <LineChart data={data}>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={
                <CustomLegend
                  legends={subsystems.map(subsystem => ({
                    text: subsystem.name,
                    color: subsystem.color,
                    isLine: true,
                  }))}
                />
              }
            />
            <CartesianGrid vertical={false} stroke={theme.colors.black100} />
            <XAxis
              dataKey='hour'
              tick={{ fontSize: '0.875rem', fill: theme.colors.black300 }}
              tickLine={false}
              axisLine={false}
              interval={0}
              tickFormatter={tick => `${Number(tick) - 1}h`}
              padding={{ left: 15, right: 15 }}
            />
            <YAxis
              width={90}
              tick={{ fontSize: '0.875rem', fill: theme.colors.black300 }}
              domain={['auto', 'auto']}
              tickCount={8}
              allowDecimals={false}
              tickLine={false}
              axisLine={false}
              tickFormatter={tick => Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(tick))}
            >
              <Label
                angle={-90}
                value='PLD antes da aplicação dos limites (R$/MWh)'
                position='insideLeft'
                style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
              />
            </YAxis>
            {[...subsystems].reverse().map(subsystem => (
              <Line strokeWidth={5} dot={false} key={subsystem.key} dataKey={subsystem.key} stroke={subsystem.color} />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}
