import styled, { keyframes } from 'styled-components'

const ContainerAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Container = styled.div`
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border-bottom: 5px solid #ccc;
  animation: ${ContainerAnimation} 250ms 1;
  z-index: 1000;
`

interface TooltipGrid {
  hasOnlyOneValue?: boolean
  columns?: number
}

export const TooltipGrid = styled.div<TooltipGrid>`
  display: flex;
  gap: 10px;
`

export const TooltipContainer = styled.div``

export const ToolTipDivider = styled.div`
  width: 1px;
  background: rgba(0, 0, 0, 0.05);
`

export const TooltipTitleContainer = styled.div`
  padding-bottom: 5px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: ${props => props.theme.safiraColors.blue[3]};
`

export const TooltipInfoContainer = styled.div`
  padding: 5px 0;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  text-align: center;
`

interface TooltipInfoValue {
  color?: string
  bold?: boolean
  transparent?: boolean
  isTitle?: boolean
}

export const TooltipInfoValue = styled.span<TooltipInfoValue>`
  color: ${props => (props.color ? props.color : '')};
  filter: ${props => (props.transparent ? 'opacity(0)' : 'opacity(1)')};
  font-weight: ${props => (props.bold ? 'bold' : '')};
  font-size: ${props => (props.isTitle ? '13px' : '12px')};
`
