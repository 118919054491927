import styled, { css } from 'styled-components'
import loadingGif from 'assets/gifs/loading_fast.gif'

export const Container = styled.div``

export const ControllerContainer = styled.div`
  display: flex;
  padding: 2rem 0;
  gap: 1rem;
`

export const ChartContainer = styled.div<{ isLoading: boolean }>`
  height: 500px;
  position: relative;
  ${props =>
    props.isLoading &&
    css`
      ::after {
        content: '';
        background: rgba(0, 0, 0, 0.2);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(${loadingGif});
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 1rem;
      }
    `}
`
