import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { DessemStudy } from './DessemStudy'

export type StudyMetaData = {
  id: number
  title: string
  body: string
  published: boolean
  createdAt: string
  updatedAt: string
  author: string
  studies: {
    hash: string
    name: string
  }[]
}

export function Studies() {
  const { type, id }: { type: string; id: string } = useParams()

  if (type === 'dessem') return <DessemStudy id={id} />

  return <Redirect to='/studies/curves-and-matrixes' />
}
