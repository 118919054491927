import React from 'react'
import { parse, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { TooltipProps } from 'recharts'
import { returnBRNumber } from 'utils'

import { Container, TooltipTitleContainer, TooltipGrid, TooltipInfoContainer, TooltipInfoValue, TooltipContainer } from 'components/CustomTooltip'

interface CustomTooltip extends TooltipProps {
  dataKey: string
}

function CustomTooltip({ payload, active, dataKey }: CustomTooltip) {
  if (!active || !payload) return null

  const returnMonthStringFromNumber = (monthNumber: string): string => {
    const date = parse(monthNumber, 'M', new Date())

    const monthString = format(date, 'MMMM', { locale: ptBR })

    return monthString.charAt(0).toUpperCase() + monthString.slice(1)
  }

  return (
    <Container>
      <TooltipContainer>
        <TooltipTitleContainer>{returnMonthStringFromNumber(payload[0].payload.month)}</TooltipTitleContainer>
        <TooltipGrid columns={1}>
          <TooltipInfoContainer>
            <TooltipInfoValue>{`${returnBRNumber(payload[0].payload[dataKey])} MWmed`}</TooltipInfoValue>
          </TooltipInfoContainer>
        </TooltipGrid>
      </TooltipContainer>
    </Container>
  )
}

export default CustomTooltip
