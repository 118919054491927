import styled, { keyframes } from 'styled-components'
import { lighten, transparentize } from 'polished'

const downloadButtonAnimation = keyframes`
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: -25px;
    opacity: 1;
  }
`

const downloadLabel = keyframes`
  from {
    width: 0;
  }
  to {
    width: default;
  }
`

export const Container = styled.div``

export const TableContainer = styled.div`
  margin-top: 2rem;
  overflow-y: auto;
  max-height: 400px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid #ccc;
  border-radius: 5px;
  table {
    text-align: center;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    thead {
      tr {
        color: #fff;
        :first-child th {
          background: ${props => props.theme.safiraColors.blue[4]};
          z-index: 2;
          top: 0;
        }
        :not(:first-child) th {
          background: ${props => lighten(0.1, props.theme.safiraColors.blue[5])};
          z-index: 1;
          top: 38px;
        }
        th {
          position: sticky;
          padding: 15px;
          font-size: 14px;
          word-wrap:break-word :not(:last-child) {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }
`

export const TableRow = styled.tr<{ isToday: boolean }>`
  :nth-child(odd) {
    background: ${props =>
      props.isToday ? transparentize(0.3, props.theme.safiraColors.green[4]) : transparentize(0.95, props.theme.safiraColors.blue[4])};
  }
  :nth-child(even) {
    background: ${props => (props.isToday ? transparentize(0.5, props.theme.safiraColors.green[4]) : 'default')};
  }
  td {
    font-size: 12px;
    padding: 10px;
    color: ${props => (props.isToday ? '#fff' : 'default')};
  }
`

export const DownloadBar = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DownloadButton = styled.button<{ description?: string }>`
  border: none;
  border-bottom: 2px solid ${props => transparentize(0.7, props.theme.safiraColors.green[4])};
  padding: 5px;
  border-radius: 5px;
  font-size: 20px;
  background: ${props => transparentize(0.8, props.theme.safiraColors.green[4])};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 200ms;
  position: relative;
  width: auto;
  div {
    :first-child {
      /* background: red; */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :last-child {
      width: 0%;
      transition: 200ms;
      span {
        font-size: 10px;
        display: none;
      }
    }
  }
  :hover {
    background: ${props => props.theme.safiraColors.green[4]};
    border-bottom: 2px solid ${props => lighten(0.1, props.theme.safiraColors.green[4])};
    :hover div:last-child {
      width: 100%;
      span {
        display: block;
        white-space: nowrap;
        /* animation: ${downloadLabel} 1s 1; */
      }
    }
    /* :before {
      content: ${props => `'${props.description}'`};
      color: ${props => props.theme.safiraColors.blue[4]};
      width: auto;
      white-space: nowrap;
      height: 100%;
      position: absolute;
      font-size: 12px;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      animation: ${downloadButtonAnimation} 100ms 1;
    } */
  }
`
