import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Label = styled.label`
  color: #8c8c8c;
  font-size: 0.875rem;
`

export const InputGroup = styled.div`
  display: flex;
  gap: 15px;
  div {
    display: flex;
    align-items: center;
    gap: 3px;
  }
`

export const InputElement = styled.input`
  padding: 7px 5px;
  display: block;
  outline: none;
  font-size: 12px;
  border: 1px solid rgb(150, 150, 150);
  border-radius: 2px;
  transition: border 200ms;
  :focus {
    border: 1px solid rgb(100, 100, 100);
  }
`

export const InputLabel = styled.label`
  font-size: 0.8rem;
  cursor: pointer;
`
