import { transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 50px 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
`

export const PaginationItem = styled.button`
  height: 2rem;
  width: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background: none;
  border: none;
  transition: 200ms;
  color: ${props => transparentize(0.63, props.theme.safiraColors.blue[5])};
  :hover {
    color: ${props => transparentize(0.4, props.theme.safiraColors.blue[5])};
  }
  :disabled {
    cursor: default;
    color: ${props => transparentize(0.8, props.theme.safiraColors.blue[5])};
  }
`
