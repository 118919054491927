import styled, { keyframes } from 'styled-components'

const ImageAnimation = keyframes`
  0% {
    opacity: 0.3;
    height: 10rem;
  }
  25% {
    opacity: 0.8;
    height: 11rem;
  }
  100% {
    opacity: 0.3;
    height: 10rem;
  }
`

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    animation: ${ImageAnimation} 1s infinite;
    height: 10rem;
  }
`
