import React from 'react'

import { Container, TitleContainer, PageTitle } from './styles'

const bannerImages = {
  meteorology: `${process.env.REACT_APP_STATIC_ADDRESS}/images/client/header-meteorology.png`,
  hourlyPrices: `${process.env.REACT_APP_STATIC_ADDRESS}/images/client/header-hourly-prices.png`,
  operations: `${process.env.REACT_APP_STATIC_ADDRESS}/images/client/header-operations.png`,
}

interface Banner {
  title?: string
  hasNavigation?: boolean
  bannerName?: 'meteorology' | 'hourlyPrices' | 'operations'
}

const Banner: React.FC<Banner> = ({ title, hasNavigation, children }) => {
  return (
    <Container hasNavigation={hasNavigation} backgroundURL={bannerImages.meteorology}>
      <TitleContainer>
        <PageTitle>{title}</PageTitle>
        {children}
      </TitleContainer>
    </Container>
  )
}

export default Banner
