import React from 'react'
import { utcDateFromDate } from 'utils'
import { TooltipProps } from 'recharts'
import { Container } from 'components/CustomTooltip'
import { CustomProspectiveStudy } from '../..'
import { subsystems } from 'utils/globals'
import { capitalize } from 'lodash'
import { TableContainer, TableHeader } from './styles'

type CustomTooltipProps = {
  studies?: CustomProspectiveStudy['meta']['studies']
  withLimits?: boolean
  colors: string[]
  hiddenHashes: string[]
}

export const CustomTooltip = ({ payload, active, studies, withLimits, colors, hiddenHashes }: TooltipProps & CustomTooltipProps) => {
  if (!(payload && active) || !studies) return null

  const titleMonth = utcDateFromDate(new Date(payload[0].payload.date)).toLocaleDateString('pt-br', { month: 'long' })

  const title = `${capitalize(titleMonth)} - RV${payload[0].payload.revision}`

  const filteredStudies = studies
    .map((study, index) => ({ ...study, color: colors[index] }))
    .filter(study => !hiddenHashes.includes(study.hash))
    .filter(study => payload[0].payload.studies[study.hash])

  const forwardValue = payload[0].payload.forwardPrice

  const formattedForwardValue =
    forwardValue &&
    Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(forwardValue))

  return (
    <Container>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th colSpan={filteredStudies.length * 3 + 1}>{`${title} | Convencional pela curva forward: ${formattedForwardValue}`}</th>
            </tr>
            <tr>
              <th rowSpan={2}> </th>
              {filteredStudies.map(study => (
                <TableHeader key={study.hash} colSpan={3} color={study.color}>
                  {study.name}
                </TableHeader>
              ))}
            </tr>
            <tr>
              {filteredStudies.map(study => (
                <React.Fragment key={study.hash}>
                  <th>PLD</th>
                  <th>ENA</th>
                  <th>EAR%</th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {subsystems.map(subsystem => (
              <tr key={subsystem.key}>
                <td>{subsystem.initials.toUpperCase()}</td>
                {filteredStudies.map(study => {
                  const studyToPlot = payload[0].payload.studies[study.hash]?.[subsystem.key]

                  const { pld_average: pldAverage, cmo_average: cmoAverage, ear_start: earStart, monthly_ena_mlt: enaMlt } = studyToPlot

                  return (
                    <React.Fragment key={`${subsystem.key} - ${study.hash}`}>
                      <td>
                        {Intl.NumberFormat('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          maximumFractionDigits: 0,
                        }).format(withLimits ? pldAverage : cmoAverage)}
                      </td>
                      <td>
                        {Intl.NumberFormat('pt-br', {
                          style: 'percent',
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        }).format(enaMlt)}
                      </td>
                      <td>
                        {Intl.NumberFormat('pt-br', {
                          style: 'percent',
                          maximumFractionDigits: 1,
                          minimumFractionDigits: 1,
                        }).format(earStart / 100)}
                      </td>
                    </React.Fragment>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  )
}
