import styled from 'styled-components'

export const Container = styled.div``

export const ControllerAndMapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.image-container {
    display: flex;
    justify-content: center;
    img {
      height: 10rem;
    }
  }
`

export const ControllerContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const TitlesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 25px 0;
  padding-top: 50px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      font-size: 18px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.31);
    }
    svg {
      filter: grayscale(1);
    }
  }
`
