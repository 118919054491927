import React, { useState } from 'react'
import { format, startOfWeek, eachDayOfInterval } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { InputDate } from 'components/Input'
import { SectionText } from 'components/Section'
import SectionTitle from 'components/SectionTitle'
import MapGallery from 'components/MapGallery'
import { Container, ControllerContainer } from './styles'

const Observed: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const firstDay = startOfWeek(date, { weekStartsOn: 6 })

  const images = eachDayOfInterval({ end: date, start: firstDay }).map(observedDate => {
    const formattedDate = format(observedDate, 'yyyyMMdd')
    const filename = `merge_diario_${formattedDate}.png`
    const weekDay = observedDate.toLocaleString('pt-BR', { weekday: 'long' })
    return {
      title: `${weekDay.split('').shift()?.toUpperCase()}${weekDay.split('').splice(1)?.join('')} ${observedDate.toLocaleDateString()}`,
      defaultURL: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/merge-diario/${filename}`,
    }
  })

  return (
    <Container>
      <SectionTitle
        title='Precipitação Observada - MERGE'
        info='O produto MERGE foi desenvolvido e é disponibilizado pelo Centro de Previsão de Tempo e Estudos Climáticos do Instituto Nacional de Pesquisas Espaciais (CPTEC/INPE). É uma estimativa de chuva observada a partir de combinação de dados pluviométricos "in situ" (estações meteorológicas de superfície) e dados de sensoriamento remoto (satélites meteorológicos). '
      />
      <SectionText>
        Mapa de chuva diária verificada pelo produto MERGE (CPTEC/INPE). O acumulado do dia atual é considerado como o volume de chuva registrado
        entre as 12Z do dia anterior e as 12Z do dia corrente.
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          value={format(date, 'yyyy-MM-dd')}
          max={format(new Date(), 'yyyy-MM-dd')}
          onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
        />
      </ControllerContainer>
      <MapGallery imagesPerLine={images.length < 4 ? 3 : 4} images={images} scale='forecast' />
    </Container>
  )
}

export default Observed
