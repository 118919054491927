import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 3rem;
`

export const ControllerContainer = styled.div`
  display: flex;
  gap: 3rem;
  padding: 1rem 0;
  > div {
    padding: 2rem 0;
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  img {
    height: 9rem;
  }
`

export const Title = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.safiraColors.blue[3]};
`
