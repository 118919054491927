import React from 'react'
import html2canvas, { Options } from 'html2canvas'

function useScreenshot(
  ref: React.MutableRefObject<HTMLDivElement>,
  fileName: string,
  options: Partial<Options>,
  canvasFormatter: (canvas: HTMLCanvasElement) => HTMLCanvasElement = canvas => canvas,
) {
  function takeScreenshot() {
    html2canvas(ref.current, options).then(canvas => {
      const anchor = document.createElement('a')
      anchor.href = canvasFormatter(canvas).toDataURL('image/png')
      anchor.download = fileName
      anchor.click()
    })
  }

  return { takeScreenshot }
}

export default useScreenshot
