import React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { subsystems } from 'utils/globals'
import { MatrixStudyCase } from '../..'
import { SubtitleContainer, Container, TableContainer, TableData, InfoContainer, TableHeaderContent } from './styles'

export function BaseCase({ baseCase, limitedPrices }: { baseCase: MatrixStudyCase['caseData']['baseCase']; limitedPrices: boolean }) {
  return (
    <Container>
      <SubtitleContainer>Cenário base</SubtitleContainer>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th />
              <th>
                <TableHeaderContent>
                  MWmed
                  <InfoContainer>
                    <AiOutlineInfoCircle />
                    <div>
                      <AiOutlineInfoCircle />
                      <span>Megawatt médio - 1 MWmed-mês = 744 MWh/mês (energia média no intervalo de tempo considerado)</span>
                    </div>
                  </InfoContainer>
                </TableHeaderContent>
              </th>
              <th>
                <TableHeaderContent>
                  %MLT
                  <InfoContainer>
                    <AiOutlineInfoCircle />
                    <div>
                      <AiOutlineInfoCircle />
                      <span>Porcentagem sobre a média de longo termo - média de ENA (Energia Natural Afluente) desde 1931</span>
                    </div>
                  </InfoContainer>
                </TableHeaderContent>
              </th>
              <th>
                <TableHeaderContent>
                  EAR Inicial
                  <InfoContainer>
                    <AiOutlineInfoCircle />
                    <div>
                      <AiOutlineInfoCircle />
                      <span>Energia armazenada inicial - Energia armazenada por subsistema no início da simulação</span>
                    </div>
                  </InfoContainer>
                </TableHeaderContent>
              </th>
              <th>
                <TableHeaderContent>
                  PLD
                  <InfoContainer>
                    <AiOutlineInfoCircle />
                    <div>
                      <AiOutlineInfoCircle />
                      <span>Preço de liquidação das diferenças - Preço utilizado para liquidar as diferenças no mercado de curto prazo</span>
                    </div>
                  </InfoContainer>
                </TableHeaderContent>
              </th>
            </tr>
          </thead>
          <tbody>
            {subsystems.map(subsystem => (
              <tr key={subsystem.key}>
                <TableData color={subsystem.color}>{subsystem.name}</TableData>
                <TableData color={subsystem.color}>
                  {baseCase[subsystem.key].monthly_ena_mwmed.toLocaleString('pt-br', { maximumFractionDigits: 0 })}
                </TableData>
                <TableData color={subsystem.color}>
                  {baseCase[subsystem.key].monthly_ena_mlt.toLocaleString('pt-br', {
                    style: 'percent',
                  })}
                </TableData>
                <TableData color={subsystem.color}>
                  {(baseCase[subsystem.key].ear_start / 100).toLocaleString('pt-br', {
                    style: 'percent',
                    maximumFractionDigits: 1,
                  })}
                </TableData>
                <TableData color={subsystem.color}>
                  {baseCase[subsystem.key][limitedPrices ? 'pld_average' : 'cmo_average'].toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                    maximumFractionDigits: 0,
                  })}
                </TableData>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  )
}
