import { transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 3rem;
`

export const SubtitleContainer = styled.h2`
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  gap: 10px;
`

export const ControllerContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
`

export const FixedEnasContainer = styled.div`
  margin-top: 2rem;
  padding: 0 0.5rem;
  display: flex;
  gap: 1rem;
`

export const FixedEnaContainer = styled.div<{ color: string }>`
  display: inline-block;
  div {
    padding: 0.5rem;
    display: inline-block;
    border: 2px solid ${props => props.color};
    border-radius: 0.25rem;
  }
`

export const MainDataContainer = styled.div`
  margin-top: 1rem;
  table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
    border-spacing: 0.5rem 0.5rem;
    thead tr {
      th {
        padding: 0.5rem;
        border-radius: 0.25rem;
      }
      :not(:last-child) {
        background: #ccc;
      }
    }
    tbody tr {
      td {
        padding: 0.5rem;
        border-radius: 0.25rem;
        :first-child {
          font-weight: 700;
        }
      }
    }
  }
`

export const MainDataTableHeader = styled.th<{ color: string; highlighted: boolean }>`
  border: 2px solid ${props => transparentize(0.5, props.color)};
  color: rgba(0, 0, 0, 0.5);
  transition: 200ms;
  background: ${props => (props.highlighted ? transparentize(0.4, props.color) : null)};
`

export const MainEnaDataTableData = styled.td<{ color: string; highlighted: boolean }>`
  border: 2px solid ${props => transparentize(0.5, props.color)};
  color: rgba(0, 0, 0, 0.5);
  transition: 200ms;
  background: ${props => (props.highlighted ? transparentize(0.4, props.color) : null)};
`

export const MainDataTableData = styled.td<{ unstuck: boolean; closeToBase: boolean }>`
  border: ${props => (props.closeToBase ? `2px solid ${props.theme.safiraColors.red[0]}` : '2px solid rgba(0, 0, 0, 0.05)')};
  color: rgba(0, 0, 0, 0.87);
  transition: 200ms;
  background: ${props => (props.unstuck ? transparentize(0.8, props.theme.safiraColors.blue[0]) : 'transparent')};
  :hover {
    color: ${props => props.theme.safiraColors.blue[4]};
  }
`
