import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { StudiesList } from './StudiesList'
import { Studies } from './Studies'

export const CustomProspectiveStudies = () => (
  <Switch>
    <Route exact path='/estudos/medio-prazo' component={StudiesList} />
    <Route exact path='/estudos/medio-prazo/:type/:id' component={Studies} />
  </Switch>
)
