import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`

export const ChartContainer = styled.div`
  height: 600px;
  padding: 30px;
  overflow: hidden;
  background: #fff;
  border-radius: 0.5rem;
`
