import styled from 'styled-components'

export const Container = styled.div``

export const ControllerContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 2rem 0;
`

export const ChartAndMenuContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
  gap: 15px;
  padding-top: 30px;
`

export const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding-top: 30px;
  justify-content: center;
  max-width: 1024px;
  margin: 0 auto;
`

export const SummaryContainer = styled.div`
  border-radius: 8px;
  transition: 200ms;
  position: relative;
`

export const SummaryTitleContainer = styled.div<{ color: string }>`
  font-size: 16px;
  color: #999;
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 700;
  color: ${props => props.theme.safiraColors.blue[3]};
  justify-content: center;
  > div {
    width: 1.2rem;
    height: 1.2rem;
    background: ${props => props.color};
    border-radius: 0.25rem;
  }
`

export const SummaryValueContainer = styled.div`
  margin-top: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  font-weight: bold;
  border: 2px solid #eaeaea;
  border-radius: 0.25rem;
  padding: 2rem;
  text-align: center;
  color: ${props => props.theme.safiraColors.blue[3]};
`

export const ImportExportContainer = styled.div`
  grid-column: 3;
  span {
    :first-child {
      color: gray;
      font-weight: 500;
    }
    :last-child {
      font-weight: bold;
      color: ${props => props.theme.safiraColors.blue[3]};
    }
  }
`

// !SIDE MENU
export const SideMenuContainer = styled.div`
  height: 100%;
  border-radius: 5px;
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 10px;
  transition: 200ms;
  opacity: 0.8;
  max-height: 600px;
  :hover {
    opacity: 1;
  }
`

export const SideMenuGroupContainer = styled.div`
  border-bottom: 1px solid #ccc;
`

export const SideMenuTitle = styled.div`
  color: #333;
  font-size: 14px;
  padding: 10px;
  text-align: left;
  font-weight: bold;
  :first-child {
    border-radius: 5px 5px 0 0;
  }
`

export const SideMenuOptions = styled.ul`
  padding: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  li {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    input,
    label {
      cursor: pointer;
    }
  }
`

export const SideMenuFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 12px;
  padding: 10px;
  div:first-child {
    text-align: left;
    span:last-child {
      color: #808080;
    }
  }
  div:last-child {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    button {
      text-align: right;
      border: none;
      padding: none;
      background: none;
      color: ${props => props.theme.safiraColors.blue[2]};
      cursor: pointer;
      font-size: 12px;
      :last-child {
        color: ${props => props.theme.safiraColors.red[1]};
      }
    }
  }
`

export const SideMenuImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  img {
    width: 100%;
  }
`
