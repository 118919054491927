import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem 2rem;
  background-color: #0554d3;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 2rem;
  > div {
    display: flex;
    gap: 1rem;
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  font-size: 16px;
  font-weight: 700;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    filter: brightness(1.1);
  }
`

export const Text = styled.p`
  max-width: 700px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #fff;
  padding: 0;
  a {
    color: ${props => props.theme.colors.cyan};
    :hover {
      text-decoration: underline;
    }
  }
`

export const AcceptButton = styled(Button)`
  color: #ffffff;
  background: #032677;
`

export const DeclineButton = styled(Button)`
  color: #032677;
  background: #ffffff;
`
