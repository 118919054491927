import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import { usePrismicDocumentByUID, PrismicRichText, PrismicImage } from '@prismicio/react'
import { asImageSrc } from '@prismicio/helpers'
import 'react-medium-image-zoom/dist/styles.css'
import Zoom from 'react-medium-image-zoom'
import { Container, BannerContainer, ArticleTitle, AuthorInfo, ArticleBody, RichTextImgContainer } from './styles'

function LoadingPlaceholder() {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', display: 'grid', gap: '30px', marginTop: '50px' }}>
      <RectShape color='#ccc' style={{ height: '300px' }} />
      <RectShape color='#ccc' style={{ height: '2rem' }} />
      <TextBlock rows={30} color='#ccc' />
    </div>
  )
}

export function Article() {
  const { slug } = useParams<{ slug: string; page: string }>()
  const [document, { state }] = usePrismicDocumentByUID('middle_post', slug, {
    graphQuery: `{
      middle_post {
        banner
        title
        body
        author_link {
          name
          description
          picture
        }
      }
    }`,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (state === 'loading') return <LoadingPlaceholder />

  return (
    <Container>
      <AuthorInfo>
        {document?.data.author_link.data?.picture && <PrismicImage field={document?.data.author_link.data.picture} />}
        {document?.data.author_link.data?.name && <PrismicRichText field={document?.data.author_link.data.name} />}
        <span>
          {document?.last_publication_date &&
            new Date(document.last_publication_date).toLocaleDateString('pt-br', {
              day: 'numeric',
              month: 'long',
              year: '2-digit',
            })}
        </span>
      </AuthorInfo>
      <ArticleTitle>
        <PrismicRichText field={document?.data.title} />
      </ArticleTitle>
      <BannerContainer>
        <Zoom>
          <PrismicImage field={document?.data.banner} />
        </Zoom>
        <figcaption>{document?.data.banner.alt}</figcaption>
      </BannerContainer>
      <ArticleBody>
        <PrismicRichText
          field={document?.data.body}
          components={{
            image: children => (
              <RichTextImgContainer>
                <Zoom>
                  <img src={asImageSrc(children.node)} />
                </Zoom>
                <figcaption>{children.node.alt}</figcaption>
              </RichTextImgContainer>
            ),
          }}
        />
      </ArticleBody>
    </Container>
  )
}
