import React from 'react'

import { Container, ColorsContainer, Color, LabelsContainer } from './styles'

const ScaleClimate: React.FC = () => {
  return (
    <Container>
      <ColorsContainer>
        <Color style={{ background: '#612500' }} />
        <Color style={{ background: '#873800' }} />
        <Color style={{ background: '#ad4e00' }} />
        <Color style={{ background: '#d46b08' }} />
        <Color style={{ background: '#fa8c16' }} />
        <Color style={{ background: '#ffa940' }} />
        <Color style={{ background: '#ffc069' }} />
        <Color style={{ background: '#FFFFFF' }} />
        <Color style={{ background: '#95de64' }} />
        <Color style={{ background: '#73d13d' }} />
        <Color style={{ background: '#52c41a' }} />
        <Color style={{ background: '#389e0d' }} />
        <Color style={{ background: '#237804' }} />
        <Color style={{ background: '#135200' }} />
        <Color style={{ background: '#092b00' }} />
      </ColorsContainer>
      <LabelsContainer>
        <span>-200</span>
        <span>-150</span>
        <span>-100</span>
        <span>-75</span>
        <span>-50</span>
        <span>-25</span>
        <span>-10</span>
        <span>10</span>
        <span>25</span>
        <span>50</span>
        <span>75</span>
        <span>100</span>
        <span>150</span>
        <span>200</span>
      </LabelsContainer>
      <span>Anomalia de Precipitação (mm)</span>
    </Container>
  )
}

export default ScaleClimate
