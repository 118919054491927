import styled, { css } from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
  margin-top: 2rem;
`

export const ChartContainer = styled.div`
  margin-top: 1rem;
  height: 600px;
  position: relative;
`

export const ControllerContainer = styled.div`
  display: flex;
  gap: 3rem;
  padding: 1rem 0;
  > div {
    padding: 2rem 0;
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  img {
    height: 9rem;
  }
`

export const ChartControllers = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    border-radius: 3px;
    ${props =>
      props.active &&
      css`
        background: ${props.theme.colors.green};
      `}
  }
`
