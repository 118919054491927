import styled from 'styled-components'

export const Container = styled.div``

export const ChartsContainer = styled.div`
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  > div:last-child {
    width: 100%;
  }
`

export const ChartContainer = styled.div`
  height: 250px;
  width: calc(50% - 30px);
`
