import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Banner from 'components/Banner'
import Navigation from 'components/Navigation'
import { RealTime } from './RealTime'
import ThermalDispatch from './ThermalDispatch'
import { EnaEar } from './EnaEar'
import EnergyBalance from './EnergyBalance'
import HourlyDemand from './HourlyDemand'
import { RDH } from './RDH'
import { ENA } from './ENA'
import { BalancoDeEnergiaNosSubsistemas } from './DadosAbertosOns/BalancoDeEnergiaNosSubsistemas'
import { EarDiarioPorBacia } from './DadosAbertosOns/EAR/EarDiarioPorBacia'
import { EarDiarioPorRee } from 'pages/Operation/DadosAbertosOns/EAR/EarDiarioPorRee'

import { Container, Divider } from './styles'
import { EarDiarioPorReservatorio } from 'pages/Operation/DadosAbertosOns/EAR/EarDiarioPorReservatorio'
import { EarDiarioPorSubsistema } from 'pages/Operation/DadosAbertosOns/EAR/EarDiarioPorSubsistema'
import { EnaDiariaPorBacia } from 'pages/Operation/DadosAbertosOns/ENA/EnaDiariaPorBacia'
import { EnaDiariaPorRee } from 'pages/Operation/DadosAbertosOns/ENA/EnaDiariaPorRee'
import { EnaDiariaPorReservatorio } from 'pages/Operation/DadosAbertosOns/ENA/EnaDiariaPorReservatorio'
import { EnaDiariaPorSubsistema } from 'pages/Operation/DadosAbertosOns/ENA/EnaDiariaPorSubsistema'
import { GeracaoTermicaPorMotivoDeDespacho } from 'pages/Operation/DadosAbertosOns/GeracaoTermicaPorMotivoDeDespacho'
import { Carga } from 'pages/Operation/DadosAbertosOns/Carga'

export function Operation() {
  return (
    <>
      <Banner title='Operação' bannerName='operations' />
      <Container>
        <Navigation
          options={[
            {
              label: 'Tempo real',
              value: '/operation/real-time/acomplished',
              base: '/operation/real-time/acomplished',
            },
            {
              label: 'Despacho térmico',
              value: '/operation/thermal-dispatch',
              base: '/operation/thermal-dispatch',
            },
            {
              label: 'Hidrologia e Reservatório',
              value: '/operation/hydrology/bdo',
              base: '/operation/hydrology',
              suboptions: [
                {
                  label: 'BDO',
                  value: '/operation/hydrology/bdo',
                },
                {
                  label: 'RDH',
                  value: '/operation/hydrology/rdh',
                },
                {
                  label: 'MLT',
                  value: '/operation/hydrology/mlt',
                },
              ],
            },
            {
              label: 'Balanço energético',
              value: '/operation/energy-balance',
              base: '/operation/energy-balance',
            },
            {
              label: 'Carga Horária',
              value: '/operation/hourly-demand',
              base: '/operation/hourly-demand',
            },
            // {
            //   label: 'Dados abertos ONS',
            //   value: '/operation/dados-abertos-ons/balanco-de-energia-nos-subsistemas',
            //   base: '/operation/dados-abertos-ons',
            //   suboptions: [
            //     {
            //       label: 'Balanço de energia nos subsistemas',
            //       value: '/operation/dados-abertos-ons/balanco-de-energia-nos-subsistemas',
            //     },
            //     {
            //       label: 'Energia armazenada por bacia',
            //       value: '/operation/dados-abertos-ons/energia-armazenada-por-bacia',
            //     },
            //     {
            //       label: 'Energia armazenada por REE',
            //       value: '/operation/dados-abertos-ons/energia-armazenada-por-ree',
            //     },
            //     {
            //       label: 'Energia armazenada por reservatorio',
            //       value: '/operation/dados-abertos-ons/energia-armazenada-por-reservatorio',
            //     },
            //     {
            //       label: 'Energia armazenada por subsistema',
            //       value: '/operation/dados-abertos-ons/energia-armazenada-por-subsistema',
            //     },
            //     {
            //       label: 'ENA por bacia',
            //       value: '/operation/dados-abertos-ons/ena-por-bacia',
            //     },
            //     {
            //       label: 'ENA por ree',
            //       value: '/operation/dados-abertos-ons/ena-por-ree',
            //     },
            //     {
            //       label: 'ENA por reservatório',
            //       value: '/operation/dados-abertos-ons/ena-por-reservatorio',
            //     },
            //     {
            //       label: 'ENA por subsistema',
            //       value: '/operation/dados-abertos-ons/ena-por-subsistema',
            //     },
            //     {
            //       label: 'Motivo do despacho',
            //       value: '/operation/dados-abertos-ons/geracao-termica-por-motivo-de-despacho',
            //     },
            //     {
            //       label: 'Carga',
            //       value: '/operation/dados-abertos-ons/carga',
            //     },
            //   ],
            // },
          ]}
        />
        <Divider />
        <Switch>
          <Route path='/operation/real-time' component={RealTime} />
          <Route path='/operation/energy-balance' component={EnergyBalance} />
          <Route path='/operation/hourly-demand' component={HourlyDemand} />
          <Route path='/operation/thermal-dispatch' component={ThermalDispatch} />
          <Route path='/operation/hydrology/bdo' component={EnaEar} />
          <Route path='/operation/hydrology/rdh' component={RDH} />
          <Route path='/operation/hydrology/mlt' component={ENA} />

          <Route path='/operation/dados-abertos-ons/balanco-de-energia-nos-subsistemas' component={BalancoDeEnergiaNosSubsistemas} />

          <Route path='/operation/dados-abertos-ons/energia-armazenada-por-bacia' component={EarDiarioPorBacia} />
          <Route path='/operation/dados-abertos-ons/energia-armazenada-por-ree' component={EarDiarioPorRee} />
          <Route path='/operation/dados-abertos-ons/energia-armazenada-por-reservatorio' component={EarDiarioPorReservatorio} />
          <Route path='/operation/dados-abertos-ons/energia-armazenada-por-subsistema' component={EarDiarioPorSubsistema} />

          <Route path='/operation/dados-abertos-ons/ena-por-bacia' component={EnaDiariaPorBacia} />
          <Route path='/operation/dados-abertos-ons/ena-por-ree' component={EnaDiariaPorRee} />
          <Route path='/operation/dados-abertos-ons/ena-por-reservatorio' component={EnaDiariaPorReservatorio} />
          <Route path='/operation/dados-abertos-ons/ena-por-subsistema' component={EnaDiariaPorSubsistema} />

          <Route path='/operation/dados-abertos-ons/geracao-termica-por-motivo-de-despacho' component={GeracaoTermicaPorMotivoDeDespacho} />
          <Route path='/operation/dados-abertos-ons/carga' component={Carga} />
        </Switch>
      </Container>
    </>
  )
}
