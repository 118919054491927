import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { StudiesList } from './StudiesList'
import { Studies } from './Studies'

export const CustomProspectiveStudiesShort = () => (
  <Switch>
    <Route exact path='/estudos/curtissimo-prazo' component={StudiesList} />
    <Route exact path='/estudos/curtissimo-prazo/:type/:id' component={Studies} />
  </Switch>
)
