import React from 'react'
import errorSvg from 'assets/svg/sheets-error.svg'
import { Container } from './styles'

interface NoDataWarning {
  text?: string
}

export const ErrorWarning = ({ text }: NoDataWarning) => (
  <Container>
    <img alt='No data' src={errorSvg} />
    <span>{text || 'Um erro inesperado aconteceu, por favor, tente novamente.'}</span>
  </Container>
)
