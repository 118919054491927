import styled, { keyframes } from 'styled-components'
import { transparentize } from 'polished'

const tooltipAppear = keyframes`
  from{
    transform: translateX(-20px);
    opacity: 0;
  }
  to{
    transform: translateX(0);
    opacity: 1;
  }
`

export const Container = styled.div`
  position: relative;
`

export const SectionTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${props => props.theme.safiraColors.blue[4]};
  border-bottom: 2px solid ${props => transparentize(0.8, props.theme.safiraColors.blue[4])};
  span {
    position: relative;
  }
`

export const InfoContainer = styled.div`
  position: absolute;
  padding-top: 30px;
  z-index: 1;
  left: 0;
  top: 0;
  div {
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    animation: ${tooltipAppear} 200ms 1;
    border-bottom: 5px solid ${props => props.theme.safiraColors.blue[5]};
  }
  table {
    border-collapse: collapse;
    font-size: 12px;
    color: #000;
    font-weight: normal;
    thead tr th {
      border: 1px solid rgba(0, 0, 0, 0.5);
      padding: 5px;
    }
    tbody tr td {
      border: 1px solid rgba(0, 0, 0, 0.5);
      padding: 5px;
    }
    tbody tr td ul {
      list-style: none;
      li span {
        line-height: 20px;
      }
    }
  }
  p {
    font-weight: normal;
    text-align: justify;
    font-size: 12px;
    line-height: 20px;
    color: #000;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.safiraColors.blue[3]};
    font-weight: bold;
  }
`
