import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Banner from 'components/Banner'
import Navigation from 'components/Navigation'
import { Article } from './Article'
import { RegulatorioList } from './RegulatorioList'

export const Regulatorio = () => (
  <>
    {window.location.pathname === '/regulatorio' && <Banner title='Regulatório' />}
    {window.location.pathname === '/regulatorio' && <Navigation />}
    <Switch>
      <Route exact path='/regulatorio' component={RegulatorioList} />
      <Route exact path='/regulatorio/:slug' component={Article} />
    </Switch>
  </>
)
