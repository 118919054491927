import { useEffect } from 'react'
import axios from 'axios'
import { useAuth } from 'oidc-react'

export function SaveModule() {
  const auth = useAuth()

  useEffect(() => {
    if (!auth.userData?.profile.email) return

    axios.post(process.env.REACT_APP_SAVE_MODULE_URL as string, {
      module: 'inteligenciaMercado',
      email: auth.userData?.profile.email,
    })
  }, [auth.userData?.profile.email])

  return null
}
