import React from 'react'
import { subsystems } from 'utils/globals'
import { CustomProspectiveStudy } from '../..'
import { CaseTableData, Container, TableData } from './styles'

type ChartGroupProps = {
  data: CustomProspectiveStudy['data'][0]
  selectedRev: 'rv0' | 'rv1' | 'rv2' | 'rv3'
}

export function Table({ data, selectedRev }: ChartGroupProps) {
  const numberOfStudies = data.southeast.studies[selectedRev].length

  const tableHeaders: { text: string; rowSpan: number; colSpan: number }[][] = [
    [
      { text: '#', rowSpan: 2, colSpan: 1 },
      { text: 'Submercado', rowSpan: 2, colSpan: 1 },
      { text: 'EAR Inicial (%)	', rowSpan: 2, colSpan: 1 },
      { text: 'ENA Semanal (MWmed)	', rowSpan: 1, colSpan: 6 },
      { text: 'ENA Mensal	', rowSpan: 1, colSpan: 3 },
      { text: 'PLD Médio (R$/MWh)', rowSpan: 1, colSpan: 2 },
    ],
    [
      { text: 'Sem. 1', rowSpan: 1, colSpan: 1 },
      { text: 'Sem. 2', rowSpan: 1, colSpan: 1 },
      { text: 'Sem. 3', rowSpan: 1, colSpan: 1 },
      { text: 'Sem. 4', rowSpan: 1, colSpan: 1 },
      { text: 'Sem. 5', rowSpan: 1, colSpan: 1 },
      { text: 'Sem. 6', rowSpan: 1, colSpan: 1 },
      { text: 'MWmed', rowSpan: 1, colSpan: 1 },
      { text: 'MLT', rowSpan: 1, colSpan: 1 },
      { text: '% MLT', rowSpan: 1, colSpan: 1 },
      { text: 'Sem limites', rowSpan: 1, colSpan: 1 },
      { text: 'Com limites', rowSpan: 1, colSpan: 1 },
    ],
  ]

  const tableDatas: {
    key: keyof CustomProspectiveStudy['data'][0]['southeast']['studies']['rv0'][0]
    formatter: (value: number) => string
  }[] = [
    {
      key: 'ear_start',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          style: 'percent',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(value / 100),
    },
    {
      key: 'week1',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week2',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week3',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week4',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week5',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week6',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'monthly_ena_mwmed',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'mlt',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'monthly_ena_mlt',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          style: 'percent',
        }).format(value),
    },
    {
      key: 'cmo_average',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }).format(value),
    },
    {
      key: 'pld_average',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }).format(value),
    },
  ]

  return (
    <Container>
      <table>
        <thead>
          {tableHeaders.map((entry1, index) => (
            <tr key={index}>
              {entry1.map(entry => (
                <th key={entry.text} rowSpan={entry.rowSpan} colSpan={entry.colSpan}>
                  {entry.text}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {Array.from({ length: numberOfStudies }, (...[, index]) => index).map(index => (
            <React.Fragment key={index}>
              <tr>
                <CaseTableData rowSpan={5}>{index + 1}</CaseTableData>
              </tr>
              {subsystems.map((subsystem, subsystemIndex, subsystemArray) => {
                const isLastFromCase = subsystemIndex + 1 === subsystemArray.length

                return (
                  <tr key={`${index} - ${subsystem.key}`}>
                    <TableData isLastFromCase={isLastFromCase}>{subsystem.initials.toUpperCase()}</TableData>
                    {tableDatas.map(tableData => (
                      <TableData key={tableData.key} isLastFromCase={isLastFromCase}>
                        {tableData.formatter(Number(data[subsystem.key].studies[selectedRev][index][tableData.key]))}
                      </TableData>
                    ))}
                  </tr>
                )
              })}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Container>
  )
}
