import styled, { keyframes, css } from 'styled-components'
import { darken } from 'polished'

const Spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const Label = styled.label`
  color: transparent;
  font-size: 0.875rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  padding-top: 3px;
`

const animationCss = css`
  animation: ${Spin} 1s infinite;
`

export const Button = styled.button<{ loading?: boolean }>`
  padding: 10px 15px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: ${props => props.theme.colors.blue700};
  display: flex;
  align-items: center;
  background: ${props => (props.loading ? props.theme.colors.yellow : props.theme.colors.blue100)};
  svg {
    font-size: 1.3rem;
    color: ${props => props.theme.colors.black500};
    ${props => (props.loading ? animationCss : undefined)}
  }
  :hover {
    background: ${props => darken(0.1, props.theme.colors.blue100)};
  }
  transition: background-color 250ms;
`

export const InputElement = styled.input``
