import React, { useState } from 'react'
import { FaCookieBite } from 'react-icons/fa'
import { add } from 'date-fns'
import { Container, AcceptButton, DeclineButton, Text } from './styles'

const cookieUrl =
  'https://firebasestorage.googleapis.com/v0/b/safira-group.appspot.com/o/Politica%20de%20Cookies%20-%20Grupo%20Safira.pdf?alt=media&token=42be48bd-b9ec-4fba-a7b3-85d4ba1913d0'

const privacyPolicy = `${process.env.REACT_APP_STATIC_ADDRESS}/files/Politica de Privacidade - Safira Energia.pdf`

export function CookieConsent() {
  const [show, setShow] = useState(() => {
    const cookie = document.cookie.split(';').find(cookie => cookie.includes('cookieConsent'))

    return !cookie
  })

  function handleAccept() {
    const date = add(new Date(), { days: 7 })

    const expires = 'expires=' + date.toUTCString()

    document.cookie = `cookieConsent=true;${expires}`

    setShow(false)
  }

  function handleDecline() {
    const date = add(new Date(), { days: 7 })

    const expires = 'expires=' + date.toUTCString()

    document.cookie = `cookieConsent=false;${expires}`

    setShow(false)
  }

  if (!show) return null

  return (
    <Container>
      <FaCookieBite size={40} color='white' />
      <Text>
        <strong>
          Utilizamos &nbsp;
          <a href={cookieUrl} target='_blank' rel='noreferrer'>
            cookies
          </a>
          &nbsp;para melhorar sua experiência em nosso site
        </strong>
        &nbsp;e direcionar conteúdos relevantes para você. Ao continuar, você concorda e aceita nossa &nbsp;
        <strong>
          <a href={privacyPolicy} target='_blank' rel='noreferrer'>
            política de privacidade
          </a>
        </strong>
        .
      </Text>
      <div>
        <DeclineButton onClick={handleDecline}>Negar</DeclineButton>
        <AcceptButton onClick={handleAccept}>Aceitar todos</AcceptButton>
      </div>
    </Container>
  )
}
