import React, { useMemo, useState, useRef } from 'react'
import { add, format, parse, startOfYear } from 'date-fns'
import { ComposedChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend, Label, Tooltip } from 'recharts'
import { useTheme } from 'styled-components'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ChartContainer, CustomLegend, LoadingContainer } from 'components/Chart'
import { SectionTitle, SectionText } from 'components/Section'
import { InputDate, PrismaSelect } from 'components/Input'
import { Container, ControllerContainer, ControllerDivider, ScreenshotButton } from './styles'
import { CustomTooltip } from './CustomTooltip'
import useScreenshot from 'utils/useScreenshot'
import { AiFillPicture } from 'react-icons/ai'

interface DataDTO {
  date: string
  station: number
  subBasin: string
  usable: string
  naturalFlow: number
  level: number
  storedVolume: number
  waitVolume: number
  turbinedFlow: number
  pouredFlow: number
  otrFlow: number
  dflFlow: number
  traFlow: number
  aflFlow: number
  incFlow: number
  conUse: number
  evaporation: number
  art: number
}

export type Variable = { label: string; axisLabel: string; unit: string; key: keyof DataDTO }

export type Station = { station: number; subBasin: string; usable: string }

export function RDH() {
  const variables = useMemo<Variable[]>(
    () => [
      {
        label: 'Vazão Natural Afluente',
        axisLabel: 'Vazão Natural Afluente (m³/s)',
        key: 'naturalFlow',
        unit: 'm³/s',
      },
      {
        label: 'Vazão Defluente',
        axisLabel: 'Vazão Defluente (m³/s)',
        key: 'dflFlow',
        unit: 'm³/s',
      },
      {
        label: 'Vazão Incremental',
        axisLabel: 'Vazão Incremental (m³/s)',
        key: 'incFlow',
        unit: 'm³/s',
      },
      {
        label: 'Vazão Turbinada',
        axisLabel: 'Vazão Turbinada (m³/s)',
        key: 'turbinedFlow',
        unit: 'm³/s',
      },
      {
        label: 'Vazão Vertida',
        axisLabel: 'Vazão Vertida (m³/s)',
        key: 'pouredFlow',
        unit: 'm³/s',
      },
      {
        label: 'Cota - Nível do Reservatório',
        axisLabel: 'Cota - Nível do Reservatório (m)',
        key: 'level',
        unit: 'm',
      },
      {
        label: 'Volume Armazenado',
        axisLabel: 'Volume Armazenado (% v.u.)',
        key: 'storedVolume',
        unit: '% v.u.',
      },
    ],
    [],
  )
  const [initialDate, setInitialDate] = useState(add(startOfYear(new Date()), { years: -3 }))
  const [endDate, setEndDate] = useState(new Date())
  const [station1, setStation1] = useState<Station>()
  const [station2, setStation2] = useState<Station>()
  const [variable1, setVariable1] = useState<Variable>(variables[0])
  const [variable2, setVariable2] = useState<Variable>(variables[0])
  const ref = useRef(document.createElement('div'))
  const { takeScreenshot } = useScreenshot(ref, 'rdh', {})

  const theme = useTheme()

  const { data: stations } = useQuery(
    ['rdh-stations'],
    () =>
      api.get<Station[]>('rdh/stations').then(response => {
        setStation1(response.data[0])
        return response.data
      }),
    { refetchOnWindowFocus: false },
  )

  const { data: rdhData, isLoading: isLoading1 } = useQuery(
    ['rdh', station1, initialDate, endDate],
    () => api.get<DataDTO[]>('rdh', { params: { station: station1?.station, initialDate, endDate } }).then(response => response.data),
    {
      staleTime: 60 * 1000, // 1 minute
      enabled: !!station1,
    },
  )

  const { data: rdhData2, isLoading: isLoading2 } = useQuery(
    ['rdh', station2, initialDate, endDate],
    () => api.get<DataDTO[]>('rdh', { params: { station: station2?.station, initialDate, endDate } }).then(response => response.data),
    {
      staleTime: 60 * 1000, // 1 minute
      enabled: !!station1 && !!station2,
    },
  )

  return (
    <Container>
      <SectionTitle>RDH - Relatório Diário da Situação Hidroenergética nos Subsistemas</SectionTitle>
      <SectionText>
        Histórico recente de vazão (natural afluente, defluente, afluente, turbinada e vertida), volume armazenado e cota de usinas hidrelétricas
        (UHEs) do Sistema Interligado Nacional (SIN), de acordo com os dados do Relatório Diário da Situação Hidroenergética nos Subsistemas (RDH) do
        Operador Nacional do Sistema Elétrico (ONS).
      </SectionText>
      <ControllerContainer>
        <InputDate
          disabled={isLoading1 || isLoading2}
          value={format(initialDate, 'yyyy-MM-dd')}
          label='Data inicial'
          onChange={e => setInitialDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
        />
        <InputDate
          disabled={isLoading1 || isLoading2}
          value={format(endDate, 'yyyy-MM-dd')}
          label='Data final'
          onChange={e => setEndDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
        />
        <ControllerDivider />
        <PrismaSelect
          disabled={isLoading1 || isLoading2}
          label='Usina'
          value={station1?.station.toString()}
          options={stations && stations.map(entry => ({ label: entry.usable, value: entry.station.toString() }))}
          onChange={ev => {
            if (!stations) return

            const selectedVariable = stations.find(entry => entry.station === Number(ev.target.value))

            if (selectedVariable) setStation1(selectedVariable)
          }}
        />
        <PrismaSelect
          label='Variável'
          value={variable1.key}
          options={variables.map(entry => ({ label: entry.label, value: entry.key }))}
          disabled={isLoading1 || isLoading2}
          onChange={ev => {
            const selectedVariable = variables.find(entry => entry.key === ev.target.value)

            if (selectedVariable) setVariable1(selectedVariable)
          }}
        />
        <ControllerDivider />
        <PrismaSelect
          label='Usina'
          disabled={isLoading1 || isLoading2}
          value={station2?.station.toString()}
          options={stations && [{ label: '', value: '' }, ...stations.map(entry => ({ label: entry.usable, value: entry.station.toString() }))]}
          onChange={ev => {
            if (!stations) return

            setStation2(stations.find(entry => entry.station === Number(ev.target.value)))
          }}
        />
        <PrismaSelect
          label='Variável'
          value={variable2.key}
          disabled={isLoading1 || isLoading2 || !station2}
          options={variables.map(entry => ({ label: entry.label, value: entry.key }))}
          onChange={ev => {
            const selectedVariable = variables.find(entry => entry.key === ev.target.value)

            if (selectedVariable) setVariable2(selectedVariable)
          }}
        />
        <ScreenshotButton onClick={takeScreenshot}>
          <AiFillPicture />
        </ScreenshotButton>
      </ControllerContainer>
      <ChartContainer height='600px' ref={ref}>
        {(() => {
          if (isLoading1 || isLoading2) return <LoadingContainer />
          return (
            <ResponsiveContainer>
              <ComposedChart>
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                <Tooltip content={<CustomTooltip station1={station1} station2={station2} variable1={variable1} variable2={variable2} />} />
                <Legend
                  verticalAlign='bottom'
                  content={
                    <CustomLegend
                      legends={[
                        {
                          color: theme.colors.blue400,
                          text: `${station1?.usable ?? ''} - ${variable1.label}`,
                          isLine: true,
                        },
                        ...(station2
                          ? [
                              {
                                color: theme.colors.red300,
                                text: `${station2?.usable ?? ''} - ${variable2.label}`,
                                isLine: true,
                              },
                            ]
                          : []),
                      ].filter(Boolean)}
                    />
                  }
                />
                <YAxis
                  width={100}
                  type='number'
                  yAxisId={1}
                  tickCount={10}
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                  tickFormatter={tick => Number(tick).toLocaleString('pt-br')}
                >
                  <Label
                    angle={-90}
                    value={variable1.axisLabel}
                    fontSize={12}
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                  />
                </YAxis>
                <YAxis
                  hide={variable1.key === variable2.key || !station2}
                  width={100}
                  type='number'
                  yAxisId={2}
                  tickCount={10}
                  orientation='right'
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                  tickFormatter={tick => Number(tick).toLocaleString('pt-br')}
                >
                  <Label
                    angle={-90}
                    value={variable2.axisLabel}
                    fontSize={12}
                    position='insideRight'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                  />
                </YAxis>
                <XAxis
                  dataKey='date'
                  type='category'
                  allowDuplicatedCategory={false}
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                  tickFormatter={tick =>
                    new Intl.DateTimeFormat('pt-br', {
                      day: 'numeric',
                      month: 'numeric',
                      year: '2-digit',
                      timeZone: 'UTC',
                    }).format(new Date(tick))
                  }
                />
                <Line
                  data={rdhData}
                  dataKey={variable1.key}
                  fill={theme.colors.blue100}
                  stroke={theme.colors.blue400}
                  dot={false}
                  strokeWidth={5}
                  yAxisId={1}
                />
                <Line
                  data={rdhData2}
                  dataKey={variable2.key}
                  stroke={theme.colors.red300}
                  dot={false}
                  strokeWidth={5}
                  yAxisId={variable1.key === variable2.key ? 1 : 2}
                />
              </ComposedChart>
            </ResponsiveContainer>
          )
        })()}
      </ChartContainer>
    </Container>
  )
}
