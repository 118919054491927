import styled, { keyframes } from 'styled-components'
import { lighten } from 'polished'

const appear = keyframes`
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
`

export const Container = styled.div<{ animationDelay: number }>`
  animation: ${appear} 500ms 1;
  animation-delay: ${props => props.animationDelay};
  animation-fill-mode: backwards;
`

export const DataTableContainer = styled.div`
  overflow-y: auto;
  max-height: 400px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  table {
    text-align: center;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    thead {
      tr {
        color: #fff;
        :first-child th {
          background: ${props => props.theme.safiraColors.blue[4]};
          z-index: 2;
          top: 0;
        }
        :not(:first-child) th {
          background: ${props => lighten(0.1, props.theme.safiraColors.blue[5])};
          z-index: 1;
          top: 38px;
        }
        th {
          position: sticky;
          padding: 10px;
          font-size: 14px;
          word-wrap: break-word;
          :not(:last-child) {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }
`

export const DataTableTd = styled.td`
  padding: 5px;
  font-size: 0.875rem;
  color: ${props => props.theme.colors.blue700};
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

export const DataTableMapTd = styled.td`
  color: ${props => props.theme.colors.blue700};
  font-weight: bold;
  font-size: 1rem;
  border-bottom: 1px solid rgb(70, 70, 70);
  border-right: 1px solid rgb(70, 70, 70);
`

export const DataTableSubsystemTd = styled.td<{ subsystemColor: string }>`
  font-weight: bold;
  font-size: 0.875rem;
  color: ${props => props.theme.colors.blue700};
  border-bottom: 1px solid rgb(70, 70, 70);
`

export const DataTableRow = styled.tr`
  :not(:nth-child(5n)) ${DataTableSubsystemTd} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  :nth-child(5n) ${DataTableTd} {
    border-bottom-color: rgba(0, 0, 0, 0.5);
  }
  :last-child ${DataTableSubsystemTd} && ${DataTableTd} {
    border-bottom: none;
  }
  ${DataTableTd} {
    :nth-child(2) {
      border-left: 1px solid rgba(0, 0, 0, 0.5);
      border-right: 1px solid rgba(0, 0, 0, 0.5);
    }
    :nth-child(9),
    :nth-child(12) {
      border-left: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
`
