import React from 'react'
import { utcDateFromDate } from 'utils'
import { TooltipProps } from 'recharts'
import {
  Container,
  TooltipGrid,
  TooltipTitleContainer,
  TooltipInfoContainer,
  TooltipInfoValue,
  TooltipContainer,
  ToolTipDivider,
} from 'components/CustomTooltip'

interface CustomTooltipProps {
  lines: {
    name: string
    dataKey: string
    valueFormatter?: (value: number) => string
  }[]
}

export const CustomTooltip: React.FC<TooltipProps & CustomTooltipProps> = ({ payload, active, lines }) => {
  if (!(payload && active) || !payload[0]) return null

  return (
    <Container>
      <TooltipTitleContainer>{utcDateFromDate(new Date(payload[0].payload.date)).toLocaleDateString('pt-br')}</TooltipTitleContainer>
      <TooltipContainer>
        <TooltipGrid columns={3}>
          <TooltipInfoContainer>
            {lines.map(line => (
              <TooltipInfoValue key={line.dataKey}>{line.name}</TooltipInfoValue>
            ))}
          </TooltipInfoContainer>
          <ToolTipDivider />
          <TooltipInfoContainer>
            {lines.map(line => (
              <TooltipInfoValue key={line.dataKey}>
                {line.valueFormatter
                  ? line.valueFormatter(payload[0].payload[line.dataKey])
                  : payload[0].payload[line.dataKey].toLocaleString('pt-br')}
              </TooltipInfoValue>
            ))}
          </TooltipInfoContainer>
        </TooltipGrid>
      </TooltipContainer>
    </Container>
  )
}
