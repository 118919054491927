import React from 'react'
import { format } from 'date-fns'
import { Section } from 'components/Section'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import SectionSubTitle from 'components/SectionSubtitle'

import { Container, ImagesContainer } from './styles'

const MJO: React.FC = () => {
  const date = new Date()
  const formattedDate = format(date, 'yyyyMMdd')

  const phaseDiagramInfo = (
    <div>
      <p>
        Cada quadrante do diagrama apresenta a fase do ciclo e a localização da convecção intensificada. A propagação para leste é descrita pelo
        traçado de dias sequenciais formando um círculo anti-horário em torno da origem, a intensidade é representada pela amplitude, sendo que,
        quanto maior a amplitude, mais intenso o ciclo, e baixas atividades são descritas por movimentos aleatórios em torno da origem.
      </p>
      <p>
        <span>Fonte: </span>
        <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
          NOAA
        </a>
      </p>
    </div>
  )

  const potencialSpeedInfo = (
    <div>
      <p>
        Através da velocidade potencial em 200hPa, é possível acompanhar a previsão da propagação da MJO a cada 5 dias, sendo possível observar as
        regiões com convecção intensificada (vermelho) ou inibida (verde) e suas intensidades.
      </p>
      <p>
        O ciclo é dividido em oito fases, cada uma representa a região de intensificação da convecção. As fases 1 e 8 são as mais favoráveis para a
        formação de precipitação no Brasil, correspondendo a convecção intensificada no oeste do Oceano Índico, e o deslocamento da convecção para
        leste sobre o oceano Pacífico, respectivamente. As demais fases indicam inibição para a formação ou menor intensidade de precipitação.
      </p>
      <p>
        <span>Fonte: </span>
        <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
          NOAA
        </a>
      </p>
    </div>
  )

  return (
    <Container>
      <Section>
        <SectionTitle>Oscilação de Madden-Julian</SectionTitle>
        <SectionText>
          É um fenômeno intra-sazonal, com duração entre 30 e 60 dias, caracterizado pela propagação para leste de nebulosidade e precipitação na
          região equatorial, provocando intensificação ou inibição de chuvas convectivas em diversas regiões do planeta, incluindo a América do Sul. O
          acompanhamento e previsão da MJO pode ser feita através do Diagrama de Fase e da Anomalia da Velocidade Potencial (chi) em 200hPa.
        </SectionText>
      </Section>
      <Section>
        <SectionSubTitle title='Diagrama de fase' info={phaseDiagramInfo} />
        <ImagesContainer>
          <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/noaa-oscillations/combphase_noCFSfull.png`} alt='' />
          <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/noaa-oscillations/ECMF_phase_51m_small.png`} alt='' />
        </ImagesContainer>
      </Section>
      <Section>
        <SectionSubTitle title='Velocidade potencial' info={potencialSpeedInfo} />
        <ImagesContainer>
          <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/noaa-oscillations/ewp.png`} alt='' />
        </ImagesContainer>
      </Section>
    </Container>
  )
}

export default MJO
