import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { Container, MenuContainer, MenuItem, ItemDropdownContainer, DropdownItem, Menu } from './styles'

interface Navigation {
  options?: {
    label: string
    value: string
    base: string
    isPaid?: boolean
    show?: boolean
    suboptions?: {
      label: string
      value: string
    }[]
  }[]
}

const Navigation: React.FC<Navigation> = ({ options }) => {
  const location = useLocation()
  return (
    <Container>
      <MenuContainer>
        <Menu>
          {options
            ?.filter(entry => entry.show === true || entry.show === undefined)
            .map((entry, index) => (
              <MenuItem hasSuboptions={!!entry.suboptions} position={index} selected={location.pathname.includes(entry.base)} key={entry.value}>
                {entry.suboptions && <IoIosArrowDropdownCircle />}
                <Link to={entry.value}>{entry.label}</Link>
                {(() => {
                  if (!entry.suboptions) return null
                  return (
                    <ItemDropdownContainer key={entry.value}>
                      <ul>
                        {entry.suboptions.map((suboption, index2) => (
                          <DropdownItem key={index2} position={index2} selected={location.pathname.includes(suboption.value)}>
                            <Link to={suboption.value}>{suboption.label}</Link>
                          </DropdownItem>
                        ))}
                      </ul>
                    </ItemDropdownContainer>
                  )
                })()}
              </MenuItem>
            ))}
        </Menu>
      </MenuContainer>
    </Container>
  )
}

export default Navigation
