import React from 'react'
import { subsystems } from 'utils/globals'

import { Container, InfoContainer, InfoValueContainer, InfoTitle, InfoIndex, InfoValue } from './styles'

interface Prices {
  date: Date
  time: number
  southeast: number
  south: number
  northeast: number
  north: number
}

const Summary: React.FC<{ prices: Prices[] }> = ({ prices }) => {
  if (prices.length === 0) return <></>
  const currentTime = new Date().getHours()

  const summaries = [
    {
      title: 'Preço atual',
      entries: subsystems.map(subsystem => ({
        label: subsystem.name,
        value: prices[currentTime][subsystem.key].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      })),
    },
    {
      title: 'Preço mínimo',
      entries: subsystems.map(subsystem => ({
        label: subsystem.name,
        value: Math.min(...prices.map(entry => entry[subsystem.key])).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      })),
    },
    {
      title: 'Preço médio',
      entries: subsystems.map(subsystem => ({
        label: subsystem.name,
        value: (prices.map(entry => entry[subsystem.key]).reduce((prev, next) => prev + next) / prices.length).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      })),
    },
    {
      title: 'Preço máximo',
      entries: subsystems.map(subsystem => ({
        label: subsystem.name,
        value: Math.max(...prices.map(entry => entry[subsystem.key])).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      })),
    },
  ]

  return (
    <Container>
      {summaries.map(summary => (
        <InfoContainer key={summary.title}>
          <InfoTitle>{summary.title}</InfoTitle>
          {summary.entries.map(entry => (
            <InfoValueContainer key={entry.label}>
              <InfoIndex>{entry.label}</InfoIndex>
              <div>{Array(100).fill('.')}</div>
              <InfoValue>{entry.value}</InfoValue>
            </InfoValueContainer>
          ))}
        </InfoContainer>
      ))}
    </Container>
  )
}

export default Summary
