import styled from 'styled-components'

export const Container = styled.div`
  h1 {
    margin-top: 2rem;
    color: ${props => props.theme.safiraColors.orange[3]};
  }
  div {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    p {
      color: #ccc;
    }
  }
  > p {
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 2rem;
  }
`

export const Banner = styled.div`
  height: 200px;
  border-radius: 0.25rem;
  opacity: 0.1;
  background-image: url(${process.env.REACT_APP_STATIC_ADDRESS}/images/client/transmissao-linha.png);
  background-attachment: fixed;
`

export const ReturnButton = styled.button`
  height: 2rem;
  width: 2rem;
  background: transparent;
  border: none;
  svg {
    font-size: 1.5rem;
    color: #ccc;
    transition: 200ms;
  }
  :hover svg {
    color: #121212;
  }
`

export const UnpublishedWarning = styled.h4`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #fff;
  background: ${props => props.theme.colors.red400};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
