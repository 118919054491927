import { capitalize } from 'lodash'
import React from 'react'
import { utcDateFromDate } from 'utils'
import { subsystems } from 'utils/globals'
import { CustomProspectiveStudy, ProspectiveStudyData } from '..'
import { Title } from '../styles'
import { Container, DateRevisionTd, StudyTd, ValueTd, TableContainer } from './styles'

type SummedTablesProps = {
  response: CustomProspectiveStudy
  arePricesLimited: boolean
}

export function Tables({ response }: SummedTablesProps) {
  const {
    meta: { studies },
  } = response

  const keys: { key: keyof ProspectiveStudyData; name: string; formatter: (value: number) => string }[] = [
    {
      key: 'ear_start',
      name: 'EAR Inicial (%)',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(value / 100),
    },
    {
      key: 'week1',
      name: 'Sem. 1',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week2',
      name: 'Sem. 2	',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week3',
      name: 'Sem. 3	',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week4',
      name: 'Sem. 4	',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week5',
      name: 'Sem. 5	',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'week6',
      name: 'Sem. 6	',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'monthly_ena_mwmed',
      name: 'MWmed',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'mlt',
      name: 'MLT',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'monthly_ena_mlt',
      name: '% MLT',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value),
    },
    {
      key: 'cmo_average',
      name: 'Sem limites',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }).format(value),
    },
    {
      key: 'pld_average',
      name: 'Com limites',
      formatter: value =>
        Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }).format(value),
    },
  ]

  return (
    <Container>
      <Title>Dados expandidos</Title>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th rowSpan={2}>Data/Revisão</th>
              <th rowSpan={2} colSpan={2}>
                Estudo
              </th>
              <th colSpan={7}>ENA Semanal (MWmed)</th>
              <th colSpan={3}>ENA Mensal</th>
              <th colSpan={3}>PLD Médio (R$/MWh)</th>
            </tr>
            <tr>
              {keys.map(index => (
                <th key={index.key}>{index.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {response.data.data.map(entry => (
              <React.Fragment key={`${entry.date} ${entry.revision}`}>
                <tr>
                  <DateRevisionTd rowSpan={studies.length * 5 + 1}>
                    <p>
                      {capitalize(
                        Intl.DateTimeFormat('pt-br', {
                          month: 'long',
                          year: 'numeric',
                        }).format(utcDateFromDate(new Date(entry.date))),
                      )}
                    </p>
                    <p>{`REV ${entry.revision}`}</p>
                  </DateRevisionTd>
                </tr>
                {studies.map(study => (
                  <>
                    <tr>
                      <StudyTd rowSpan={5}>{study.name}</StudyTd>
                    </tr>
                    {subsystems.map((subsystem, index, array) => (
                      <tr key={`${study.hash} - ${subsystem.key} - ${entry.revision}`}>
                        <ValueTd isLastFromStudy={index + 1 === array.length} color={subsystem.color}>
                          {subsystem.initials.toUpperCase()}
                        </ValueTd>
                        {keys.map(key => (
                          <ValueTd color={subsystem.color} isLastFromStudy={index + 1 === array.length} key={key.key}>
                            {(() => {
                              const valueToPlot = entry.studies[study.hash]?.[subsystem.key][key.key]

                              if (typeof valueToPlot !== 'number') return '-'

                              return key.formatter(valueToPlot)
                            })()}
                          </ValueTd>
                        ))}
                      </tr>
                    ))}
                  </>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  )
}
