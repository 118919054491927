import React, { useState } from 'react'
import { format, parse } from 'date-fns'
import { ComposedChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Area, Tooltip, Legend, Label } from 'recharts'
import { useTheme } from 'styled-components'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ChartContainer, CustomLegend, LoadingContainer } from 'components/Chart'
import { SectionTitle, SectionText } from 'components/Section'
import { InputDate, PrismaSelect } from 'components/Input'
import { Container, ControllerContainer } from './styles'
import CustomTooltip from './CustomTooltip'
import { subsystems } from 'utils/globals'
import { utcDateFromDate } from 'utils'

interface DataDTO {
  time: string
  demand: number
  windGeneration: number
  hydraulicGeneration: number
  nuclearGeneration: number
  solarGeneration: number
  thermalGeneration: number
  dessemDemand: number
}

export function RealTime() {
  const [date, setDate] = useState(new Date())
  const [subsystem, setSubsystem] = useState<string>()

  const theme = useTheme()

  const { data: operationData, isLoading } = useQuery(
    ['system-operation-stack', date, subsystem],
    () => api.get<DataDTO[]>('system-operation', { params: { date, subsystem } }).then(response => response.data),
    {
      staleTime: 60 * 1000, // 1 minute
    },
  )

  const chart = {
    lines: [
      {
        name: 'Carga prevista',
        dataKey: 'dessemDemand',
        stroke: theme.safiraColors.red[0],
        hashed: true,
      },
      {
        name: 'Carga realizada',
        dataKey: 'demand',
        stroke: theme.safiraColors.blue[5],
      },
    ],
    areas: [
      {
        name: 'Geração hidráulica',
        dataKey: 'hydraulicGeneration',
        fill: theme.safiraColors.blue[2],
        stackId: 1,
      },
      {
        name: 'Geração térmica',
        dataKey: 'thermalGeneration',
        fill: theme.safiraColors.orange[3],
        stackId: 1,
      },
      {
        name: 'Geração nuclear',
        dataKey: 'nuclearGeneration',
        fill: theme.safiraColors.orange[5],
        stackId: 1,
      },
      {
        name: 'Geração solar',
        dataKey: 'solarGeneration',
        fill: theme.safiraColors.yellow[3],
        stackId: 1,
      },
      {
        name: 'Geração eólica',
        dataKey: 'windGeneration',
        fill: theme.safiraColors.green[2],
        stackId: 1,
      },
    ],
  }

  return (
    <Container>
      <SectionTitle>Operação em tempo real</SectionTitle>
      <SectionText>
        Dados de geração de energia por fonte e carga realizada disponibilizados em tempo real pelo Operador Nacional do Sistema Elétrico (ONS), para
        o Sistema Interligado Nacional (SIN) e para os subsistemas. Os dados de carga prevista são obtidos através da saída do modelo de programação
        diária da operação DESSEM.
      </SectionText>
      <ControllerContainer>
        <InputDate
          disabled={isLoading}
          value={format(date, 'yyyy-MM-dd')}
          label='Data'
          onChange={e => setDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
          max={format(new Date(), 'yyyy-MM-dd')}
        />
        <PrismaSelect
          label='Submercado'
          disabled={isLoading}
          onChange={({ target }) => setSubsystem(target.value)}
          options={[{ label: 'Sistema Interligado', value: '' }, ...subsystems.map(subsystem => ({ label: subsystem.name, value: subsystem.key }))]}
        />
      </ControllerContainer>
      <ChartContainer height='600px'>
        {(() => {
          if (isLoading) return <LoadingContainer />
          return (
            <ResponsiveContainer>
              <ComposedChart data={operationData}>
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                <Tooltip
                  content={
                    <CustomTooltip
                      subsystem={subsystem}
                      tooltips={[...chart.lines.map(line => ({ ...line })), ...chart.areas.map(area => ({ ...area }))]}
                    />
                  }
                />
                <Legend
                  verticalAlign='bottom'
                  content={
                    <CustomLegend
                      legends={[
                        ...chart.lines.map(area => ({ color: area.stroke, text: area.name })),
                        ...chart.areas.map(area => ({ color: area.fill, text: area.name })),
                      ]}
                    />
                  }
                />
                <YAxis
                  width={80}
                  tickLine={false}
                  type='number'
                  tickCount={10}
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                  tickFormatter={tick => new Intl.NumberFormat('pt-br').format(tick)}
                >
                  <Label
                    angle={-90}
                    value='Geração / Carga ( MW )'
                    fontSize={12}
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
                  />
                </YAxis>
                <XAxis
                  dataKey='time'
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                  interval={59}
                  tickFormatter={tick =>
                    `${new Intl.DateTimeFormat('pt-br', {
                      hour: 'numeric',
                    }).format(utcDateFromDate(new Date(tick)))}h`
                  }
                />
                {chart.areas.map(chart => (
                  <Area
                    key={chart.name}
                    name={chart.name}
                    dataKey={chart.dataKey}
                    stroke={chart.fill}
                    stackId={chart.stackId}
                    fill={chart.fill}
                    dot={false}
                    strokeWidth={0}
                  />
                ))}
                {chart.lines.map(chart => (
                  <Line
                    key={chart.name}
                    name={chart.name}
                    dataKey={chart.dataKey}
                    stroke={chart.stroke}
                    dot={false}
                    strokeWidth={3}
                    strokeDasharray={chart.hashed ? '10 3' : undefined}
                  />
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          )
        })()}
      </ChartContainer>
    </Container>
  )
}
