import React, { useState, useRef, useEffect } from 'react'
import api from 'services/api'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import prismaIcon from 'assets/svg/prisma-icon.svg'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { SectionTitle, SectionText } from 'components/Section'
import { Pagination } from 'components/Pagination'
import NoDataWarning from 'components/NoDataWarning'
import {
  Container,
  StudyContainer,
  StudyIconContainer,
  StudiesContainer,
  StudyTopContainer,
  StudyTitleAndAuthorContainer,
  StudyBodyContainer,
  StudyTitleContainer,
  StudyAuthorContainer,
  StudyPillsContainer,
  StudyBottomContainer,
} from './styles'
import { utcDateFromDate } from 'utils'

type Response = {
  meta: {
    total: number
    per_page: number
    current_page: number
    last_page: number
    first_page: number
    first_page_url: string
    last_page_url: string
    next_page_url: string
    previous_page_url: string
  }
  data: {
    hash: string
    title: string
    body: string
    author: string
    typeName: string
    typeKey: string
    casesCount: number
    published: boolean
    createdAt: string
  }[]
}

const LoadingPlaceholder = () => (
  <StudiesContainer>
    {new Array(6).fill('').map(index => (
      <RectShape key={index} color='lightgray' style={{ width: '100%', height: '320px', borderRadius: '5px' }} className='text-block' />
    ))}
  </StudiesContainer>
)

export function StudiesList() {
  const [page, setPage] = useState(1)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const studiesListRef = useRef(document.createElement('div'))

  const { data, isLoading, isFetching } = useQuery(
    ['curves-and-matrixes-medio-prazo', page],
    () =>
      api
        .get<Response>('custom-prospective-studies', {
          params: { page, studyTypes: ['curve', 'matrix', 'dispersion'] },
        })
        .then(async ({ data }) => {
          await new Promise(resolve => setTimeout(() => resolve(true), 1000))

          return data
        }),
    {
      staleTime: 1000 * 60, // 1 minute
      keepPreviousData: true,
    },
  )

  function scrollTop() {
    if (isFirstRender) return setIsFirstRender(false)
    window.scrollTo({ top: studiesListRef.current.getBoundingClientRect().top, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollTop()
  }, [page])

  return (
    <Container>
      <SectionTitle title='Projeções de médio prazo' />
      <SectionText>
        <p>
          Nesta aba estão disponíveis projeções de <strong>Preço da Liquidação das Diferenças (PLD)</strong> realizadas através de rodadas dos modelos
          Newave e Decomp, para as próximas semanas e meses, divididas em dois seguimentos: curvas e matrizes.
        </p>
        <p style={{ marginTop: '1rem' }}>
          As <strong>curvas de médio prazo</strong> são projeções de PLD semanas e meses à frente, utilizando cenários de energia natural afluente
          (ENA) e/ou de operação do Sistema Interligado Nacional (SIN), como: informações de custos e entradas/saídas de usinas térmicas, níveis dos
          reservatórios das usinas hidrelétricas, limites de importação/exportação de energia entre submercados, projeção de carga e geração de usinas
          não-simuladas (eólicas, fotovoltaicas, pequenas centrais hidrelétricas e pequenas centrais térmicas) entre outros.
        </p>
        <p style={{ marginTop: '1rem' }}>
          As <strong>matrizes</strong> também são simulações de preço meses à frente, projetando a média semanal ou mensal do PLD, mas utilizando X
          cenários possíveis de variações de ENA para dois submercados, mantendo as ENAs dos submercados restantes fixas.
        </p>
        <p style={{ marginTop: '1rem' }}>
          <strong>Observação</strong>: O PLD é disponibilizado de forma horária desde 1º de janeiro de 2021 pela CCEE, como resultado de rodadas
          oficiais do modelo de programação diária DESSEM. Como explicado no início, as projeções expostas aqui são rodadas dos modelos de médio e
          curto prazo, NEWAVE e DECOMP, respectivamente, cujos resultados são utilizados como {'"entrada"'} para o DESSEM. Portanto, as projeções
          nesta seção são aproximações do PLD médio para as próximas semanas e meses.
        </p>
      </SectionText>
      <div ref={studiesListRef} />
      {(() => {
        if (isLoading) return <LoadingPlaceholder />

        if (!data || data.data.length === 0) return <NoDataWarning text='Nenhum estudo encontrado.' />

        return (
          <StudiesContainer>
            {data.data.map((study, index) => (
              <StudyContainer position={index} total={data.data.length} key={study.hash} isLoading={isLoading || isFetching}>
                <StudyTopContainer>
                  <StudyIconContainer>
                    <img src={prismaIcon} alt='Prisma icon' />
                  </StudyIconContainer>
                  <StudyTitleAndAuthorContainer>
                    <StudyTitleContainer title={study.title}>{study.title ?? '-'}</StudyTitleContainer>
                    <StudyAuthorContainer>{study.author ?? '-'}</StudyAuthorContainer>
                  </StudyTitleAndAuthorContainer>
                </StudyTopContainer>
                <StudyBodyContainer>{study.body}</StudyBodyContainer>
                <StudyPillsContainer>
                  <div>{study.typeName ?? '-'}</div>
                  <div>
                    {(() => {
                      if (!study.casesCount) return

                      const { casesCount } = study

                      const casesString = casesCount > 1 ? 'cenários' : 'cenário'

                      return <span>{`${casesCount} ${casesString}`}</span>
                    })()}
                  </div>
                </StudyPillsContainer>
                <StudyBottomContainer>
                  <span>{utcDateFromDate(new Date(study.createdAt)).toLocaleDateString('pt-br')}</span>
                  <Link to={`/estudos/medio-prazo/${study.typeKey}/${study.hash}`}>
                    <button type='button'>Ver estudo</button>
                  </Link>
                </StudyBottomContainer>
              </StudyContainer>
            ))}
          </StudiesContainer>
        )
      })()}
      <Pagination
        paginationProps={data?.meta}
        onChange={newPage => {
          scrollTop()
          setPage(newPage)
        }}
      />
    </Container>
  )
}
