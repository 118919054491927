import React, { useEffect, useState } from 'react'
import { Section, SectionText } from 'components/Section'
import { LastPosts } from './LastPosts'
import HourlyPLD from './HourlyPLD'
import { Container, Content, HomeSectionTitle } from './styles'

const Home: React.FC = () => {
  const [hourlyPLDKey, setHourlyPLDKey] = useState(0)
  const [currentTime, setCurrentTime] = useState(new Date().getHours())

  useEffect(() => {
    const interval = setInterval(() => {
      const time = new Date().getHours()
      if (time !== currentTime) {
        setHourlyPLDKey(hourlyPLDKey + 1)
        setCurrentTime(time)
      }
    }, 10000)
    return () => clearInterval(interval)
  }, [currentTime, hourlyPLDKey])

  return (
    <Container>
      <Content>
        <Section>
          <HomeSectionTitle>As últimas notícias do Mercado de Energia</HomeSectionTitle>
          <SectionText>Fique por dentro dos assuntos regulatórios e garanta que sua empresa esteja em conformidade com as leis.</SectionText>
          <LastPosts />
        </Section>
        <Section>
          <HomeSectionTitle>{`PLD horário - ${new Date().toLocaleDateString('pt-BR')} - R$/MWh`}</HomeSectionTitle>
          <HourlyPLD key={hourlyPLDKey} />
        </Section>
      </Content>
    </Container>
  )
}

export default Home
