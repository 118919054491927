import React from 'react'
import { CustomProspectiveStudy } from '..'
import { BaseCase } from './BaseCase'
import { MatrixTable } from './MatrixTable'
import { Container, StudyContainer, StudyTitle } from './styles'

export function StudiesRenderer({ data, limitedPrices }: { data: CustomProspectiveStudy['data']; limitedPrices: boolean }) {
  return (
    <Container>
      {data.map((entry, index) => (
        <React.Fragment key={`${entry.caseName} - ${index}`}>
          <StudyTitle style={{ marginTop: index === 0 ? undefined : '100px' }}>{entry.caseName}</StudyTitle>
          <StudyContainer>
            <MatrixTable baseCase={entry.caseData.baseCase} data={entry} limitedPrices={limitedPrices} />
            <BaseCase baseCase={entry.caseData.baseCase} limitedPrices={limitedPrices} />
          </StudyContainer>
        </React.Fragment>
      ))}
    </Container>
  )
}
