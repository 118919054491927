import styled from 'styled-components'

export const TableContainer = styled.div`
  table {
    border-collapse: collapse;
    text-align: center;
    border: 1px solid #eaeaea;
    thead tr {
      th {
        border: 1px solid #eaeaea;
        border: 1px solid #eaeaea;
        font-size: 0.875rem;
        padding: 1rem 0.5rem;
      }
      :first-child th {
        color: ${props => props.theme.colors.blue700};
      }
      :last-child th {
        padding: 0.5rem;
        color: #999;
      }
    }
    td {
      border-right: 1px solid #eaeaea;
      border-left: 1px solid #eaeaea;
      padding: 0.3rem;
      font-size: 0.75rem;
      :first-child {
        font-weight: bold;
      }
    }
  }
`

export const TableHeader = styled.th<{ color: string }>`
  color: ${props => props.color};
`
