import React, { ReactEventHandler } from 'react'

interface Image {
  src: string
}

const Image: React.FC<Image> = ({ src }) => {
  const errorImageAddress = 'https://via.placeholder.com/1500x1650/fff?text=Mapa%20não%20disponível'

  const handleImageError: ReactEventHandler<HTMLImageElement> = ev => {
    const element = ev.target as HTMLImageElement
    element.onerror = null
    element.src = errorImageAddress
  }

  return <img src={src} alt='' onError={handleImageError} />
}

export default Image
