import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Price } from './Price'
import { Demand } from './Demand'
import { EnergyBalance } from './EnergyBalance'
import { Exchange } from './Exchange'
import { useQuery } from 'react-query'
import api from 'services/api'
import { utcDateFromDate } from 'utils'
import { LoadingWarning } from './styles'

export function DessemCCEEMiddle() {
  const { data: lastCceeDessemDate } = useQuery(
    'last-dessem-day',
    () => api.get<string>('dessem-ccee', { timeout: 3000 }).then(({ data }) => utcDateFromDate(new Date(data))),
    { refetchOnWindowFocus: false },
  )

  if (!lastCceeDessemDate) return <LoadingWarning>Buscando último dia disponível</LoadingWarning>

  return (
    <Switch>
      <Route exact path='/dessem/ccee-middle/price' component={() => <Price lastCceeDessemDate={lastCceeDessemDate} />} />
      <Route exact path='/dessem/ccee-middle/demand' component={() => <Demand lastCceeDessemDate={lastCceeDessemDate} />} />
      <Route exact path='/dessem/ccee-middle/energy-balance' component={() => <EnergyBalance lastCceeDessemDate={lastCceeDessemDate} />} />
      <Route exact path='/dessem/ccee-middle/exchange' component={() => <Exchange lastCceeDessemDate={lastCceeDessemDate} />} />
    </Switch>
  )
}
