import styled from 'styled-components'

export const Container = styled.div``

export const StudyContainer = styled.div`
  display: grid;
  gap: 3rem;
`

export const StudyTitle = styled.h1`
  color: ${props => props.theme.safiraColors.blue[3]};
`
