import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Banner from 'components/Banner'
import Navigation from 'components/Navigation'
import Acomplished from './Acomplished'
import Current from './Current'
import { ForecastOns } from './ForecastOns'
import { ForecastWeather } from './ForecastWeather'
import { Indices } from './Indices'
import { Report } from './Report'
import { Climate } from './Climate'

import { Container, Divider } from './styles'
import { CompanyContext } from 'CompanyProvider'

const Meteorology: React.FC = () => {
  const companyLevel = useContext(CompanyContext)

  return (
    <>
      <Banner title='Meteorologia' />
      <Container>
        <Navigation
          options={[
            {
              label: 'Análises',
              value: '/meteorology/reports',
              base: '/meteorology/reports',
              show: companyLevel >= 1,
            },
            {
              label: 'Realizado',
              value: '/meteorology/acomplished',
              base: '/meteorology/acomplished',
              show: companyLevel >= 0,
            },
            {
              label: 'Atual',
              value: '/meteorology/current',
              base: '/meteorology/current',
              show: companyLevel >= 0,
            },
            {
              label: 'Previsão - ONS',
              value: '/meteorology/forecast-ons/daily',
              base: '/meteorology/forecast',
              show: companyLevel >= 0,
              suboptions: [
                { label: 'Diário', value: '/meteorology/forecast-ons/daily' },
                { label: 'Pêntadas', value: '/meteorology/forecast-ons/pentad' },
                { label: 'Semanal', value: '/meteorology/forecast-ons/weekly' },
              ],
            },
            {
              label: 'Previsão - Tempo',
              value: '/meteorology/weather-forecast/daily',
              base: '/meteorology/weather-forecast',
              show: companyLevel >= 1,
              suboptions: [
                { label: 'Diário', value: '/meteorology/weather-forecast/daily' },
                { label: 'Pêntadas', value: '/meteorology/weather-forecast/pentad' },
                { label: 'Semanal', value: '/meteorology/weather-forecast/weekly' },
              ],
            },
            {
              label: 'Previsão - Clima',
              value: '/meteorology/climate/precipitation',
              base: '/meteorology/climate/precipitation',
              show: companyLevel >= 1,
            },
            {
              label: 'Índices',
              value: '/meteorology/indices/antarctic-oscillation',
              base: '/meteorology/indices',
              show: companyLevel >= 0,
              suboptions: [
                { label: 'AAO', value: '/meteorology/indices/antarctic-oscillation' },
                { label: 'MJO', value: '/meteorology/indices/madden-julian-oscillation' },
                { label: 'ENSO', value: '/meteorology/indices/el-nino–southern-oscillation' },
                { label: 'IOD', value: '/meteorology/indices/indian-ocean-dipole' },
                { label: 'PDO', value: '/meteorology/indices/pacific-decadal-oscillation' },
                { label: 'AMO', value: '/meteorology/indices/atlantic-multidecadal-oscillation' },
              ],
            },
          ]}
        />
        <Divider />
        <Switch>
          {[
            {
              show: companyLevel >= 1,
              path: '/meteorology/reports',
              component: Report,
            },
            {
              show: companyLevel >= 0,
              path: '/meteorology/acomplished',
              component: Acomplished,
            },
            {
              show: companyLevel >= 0,
              path: '/meteorology/current',
              component: Current,
            },
            {
              show: companyLevel >= 0,
              path: '/meteorology/indices',
              component: Indices,
            },
            {
              show: companyLevel >= 0,
              path: '/meteorology/forecast-ons',
              component: ForecastOns,
            },
            {
              show: companyLevel >= 1,
              path: '/meteorology/weather-forecast',
              component: ForecastWeather,
            },
            {
              show: companyLevel >= 1,
              path: '/meteorology/climate',
              component: Climate,
            },
          ]
            .filter(entry => entry.show)
            .map(entry => (
              <Route key={entry.path} path={entry.path} component={entry.component} />
            ))}
          <Route path='/meteorology/*' component={() => <Redirect to='/meteorology/acomplished' />} />
        </Switch>
      </Container>
    </>
  )
}

export default Meteorology
