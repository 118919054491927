import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 660px;
  column-gap: 30px;
  row-gap: 10px;
  padding-top: 50px;
`
