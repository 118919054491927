import React, { useState } from 'react'
import { InputDate, PrismaSelect } from 'components/Input'
import { SectionText, SectionTitle } from 'components/Section'
import { Container, ControllerContainer, ChartContainer } from './styles'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ResponsiveContainer, YAxis, XAxis, Line, Legend, Tooltip, CartesianGrid, Label, ComposedChart, Bar } from 'recharts'
import { subsystemsWithSin } from 'utils/globals'
import { useTheme } from 'styled-components'
import { format, parse } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { CustomLegend } from 'components/Chart'
import { CustomTooltip } from './CustomTooltip'

type DessemCCEEEnergyBalance = { semiHour: number } & {
  [key in typeof subsystemsWithSin[number]['key']]: {
    demand: number
    renewable: number
    hydraulic: number
    thermal: number
  }
}[]

export function EnergyBalance({ lastCceeDessemDate }: { lastCceeDessemDate: Date }) {
  const [date, setDate] = useState(lastCceeDessemDate)
  const [selectedSubsystem, setSelectedSubsystem] = useState<typeof subsystemsWithSin[number]>(subsystemsWithSin[0])

  const theme = useTheme()

  const { data, isLoading } = useQuery(
    ['dessem-ccee-middle-demand', date],
    () => api.get<DessemCCEEEnergyBalance[]>(`middle-dessem-ccee/energy-balance/${date.toISOString()}`).then(({ data }) => data),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  const axises = [
    {
      name: 'Geração hidráulica',
      key: 'hydraulic',
      color: theme.colors.blue400,
    },
    {
      name: 'Geração térmica',
      key: 'thermal',
      color: theme.colors.yellow,
    },
    {
      name: 'Geração de usinas não simuladas',
      key: 'renewable',
      color: theme.colors.green,
    },
  ]

  return (
    <Container>
      <SectionTitle>Modelo DESSEM - Balanço Energético</SectionTitle>
      <SectionText>
        Resultado do modelo DESSEM no caso CCEE com os dados de geração e carga para visualização e cálculo do balanço energético.
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          max={format(utcDateFromDate(lastCceeDessemDate), 'yyyy-MM-dd')}
          value={format(utcDateFromDate(date), 'yyyy-MM-dd')}
          onChange={e => setDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
        />
        <PrismaSelect
          label='Submercado'
          options={subsystemsWithSin.map((subsystem, index) => ({ label: subsystem.name, value: String(index) }))}
          onChange={event => setSelectedSubsystem(subsystemsWithSin[Number(event.target.value)])}
        />
      </ControllerContainer>
      <ChartContainer isLoading={isLoading}>
        <ResponsiveContainer>
          <ComposedChart data={data}>
            <Tooltip content={<CustomTooltip subsystem={selectedSubsystem} />} />
            <Legend
              content={
                <CustomLegend
                  legends={[
                    ...axises.map(axis => ({ text: axis.name, color: axis.color })),
                    { text: 'Carga', color: theme.colors.black500, isLine: true },
                  ]}
                />
              }
            />
            <CartesianGrid vertical={false} stroke={theme.colors.black100} />
            <XAxis
              ticks={Array.from({ length: 48 })
                .map((_entry, index) => index + 1)
                .filter(entry => entry % 2 !== 0)}
              axisLine={false}
              tickLine={false}
              dataKey='semiHour'
              tick={{ fontSize: '0.875rem', fill: theme.colors.black300 }}
              interval={0}
              tickFormatter={tick => `${(Number(tick) - 1) / 2}h`}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tick={{ fontSize: '0.875rem', fill: theme.colors.black300 }}
              domain={['auto', 'auto']}
              tickCount={8}
              allowDecimals={false}
              width={80}
              tickFormatter={tick => Intl.NumberFormat('pt-br').format(Number(tick))}
            >
              <Label
                angle={-90}
                value='Geração e Carga (MW)'
                fontSize={12}
                position='insideLeft'
                style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
              />
            </YAxis>
            {axises.map(axis => (
              <Bar stackId={1} key={axis.key} strokeWidth={5} dataKey={`${selectedSubsystem.key}.${axis.key}`} fill={axis.color} />
            ))}
            <Line strokeWidth={5} dot={false} dataKey={`${selectedSubsystem.key}.demand`} stroke={theme.colors.black500} strokeDasharray='5 3' />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}
