import React from 'react'
import { Container } from './styles'

export function Loading() {
  return (
    <Container>
      <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/images/client/safira-on-on-only.png`} alt='' />
    </Container>
  )
}
