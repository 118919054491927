import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const Label = styled.label`
  color: #8c8c8c;
  font-size: 0.875rem;
`

export const InputElement = styled.select`
  padding: 10px 20px 10px 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
`
