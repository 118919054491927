import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { CurveStudy } from './CurveStudy'
import { MatrixStudy } from './MatrixStudy'
import { ScatterStudy } from './ScatterStudy'

export type StudyMetaData = {
  id: number
  title: string
  body: string
  published: boolean
  createdAt: string
  updatedAt: string
  author: string
  studies: {
    hash: string
    name: string
  }[]
}

export function Studies() {
  const { type, id }: { type: string; id: string } = useParams()

  if (type === 'curve') return <CurveStudy id={id} />

  if (type === 'matrix') return <MatrixStudy hash={id} />

  if (type === 'dispersion') return <ScatterStudy id={id} />

  return <Redirect to='/studies/curves-and-matrixes' />
}
