/* eslint-disable camelcase */
import React, { useState } from 'react'
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Container, PaginationItem } from './styles'

type PaginationProps = {
  onChange?: (newPage: number) => void
  paginationProps?: {
    total: number
    per_page: number
    current_page: number
    last_page: number
    first_page: number
    first_page_url: string
    last_page_url: string
    next_page_url: string
    previous_page_url: string
  }
}

export function Pagination({ paginationProps, onChange }: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(paginationProps?.first_page ?? 1)

  function incrementPage() {
    if (paginationProps && currentPage < paginationProps.last_page) {
      setCurrentPage(state => {
        if (onChange) onChange(state + 1)
        return state + 1
      })
    }
  }

  function decrementPage() {
    if (paginationProps && currentPage > paginationProps.first_page) {
      setCurrentPage(state => {
        if (onChange) onChange(state - 1)
        return state - 1
      })
    }
  }

  function goToFirstPage() {
    if (paginationProps) {
      setCurrentPage(() => {
        if (onChange) onChange(paginationProps.first_page)
        return paginationProps.first_page
      })
    }
  }

  function goToLastPage() {
    if (paginationProps) {
      setCurrentPage(() => {
        if (onChange) onChange(paginationProps.last_page)
        return paginationProps.last_page
      })
    }
  }

  return (
    <Container>
      <PaginationItem type='button' onClick={goToFirstPage} disabled={paginationProps && currentPage === paginationProps.first_page}>
        <BsChevronDoubleLeft />
      </PaginationItem>
      <PaginationItem type='button' onClick={decrementPage} disabled={paginationProps && currentPage === paginationProps.first_page}>
        <BsChevronLeft />
      </PaginationItem>
      <PaginationItem type='button'>{currentPage}</PaginationItem>
      <PaginationItem type='button'>{paginationProps?.last_page}</PaginationItem>
      <PaginationItem type='button' onClick={incrementPage} disabled={paginationProps && currentPage === paginationProps.last_page}>
        <BsChevronRight />
      </PaginationItem>
      <PaginationItem type='button' onClick={goToLastPage} disabled={paginationProps && currentPage === paginationProps.last_page}>
        <BsChevronDoubleRight />
      </PaginationItem>
    </Container>
  )
}
