import styled from 'styled-components'

export const Container = styled.div``

export const ControllerContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 2rem 0;
`

export const ChartContainer = styled.div`
  height: 500px;
`
