import React, { useState } from 'react'
import { InputDate } from 'components/Input'
import { SectionText, SectionTitle } from 'components/Section'
import { Container, ControllerContainer, ChartContainer, ChartsContainer } from './styles'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ResponsiveContainer, LineChart, YAxis, XAxis, Line, Legend, Tooltip, CartesianGrid, Label } from 'recharts'
import { subsystemsWithSin } from 'utils/globals'
import { useTheme } from 'styled-components'
import { format, parse } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { CustomLegend } from 'components/Chart'
import { CustomTooltip } from './CustomTooltip'
import { transparentize } from 'polished'

type DessemCCEEDemand = {
  semiHour: number
  southeast: number
  south: number
  northeast: number
  north: number
}

export function Demand({ lastCceeDessemDate }: { lastCceeDessemDate: Date }) {
  const [date, setDate] = useState(lastCceeDessemDate)

  const theme = useTheme()

  const { data, isLoading } = useQuery(
    ['dessem-ccee-middle-demand', date],
    () => api.get<DessemCCEEDemand[]>(`middle-dessem-ccee/demand/${date.toISOString()}`).then(({ data }) => data),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  return (
    <Container>
      <SectionTitle>Modelo DESSEM - Carga</SectionTitle>
      <SectionText>Resultado do modelo DESSEM no caso CCEE com os valores de Carga (MW).</SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          max={format(utcDateFromDate(lastCceeDessemDate), 'yyyy-MM-dd')}
          value={format(utcDateFromDate(date), 'yyyy-MM-dd')}
          onChange={e => setDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
        />
      </ControllerContainer>
      <ChartsContainer>
        {[...subsystemsWithSin].map(subsystem => (
          <ChartContainer isLoading={isLoading} key={subsystem.key}>
            <ResponsiveContainer>
              <LineChart data={data} syncId={1}>
                <Tooltip content={<CustomTooltip subsystem={subsystem} />} />
                <Legend content={<CustomLegend legends={[{ text: subsystem.name, color: subsystem.color, isLine: true }]} />} />
                <CartesianGrid vertical={false} stroke={theme.colors.black100} />
                <XAxis
                  ticks={Array.from({ length: 48 })
                    .map((_entry, index) => index)
                    .filter(entry => entry % 2 !== 0)}
                  padding={{ left: 15, right: 15 }}
                  dataKey='semiHour'
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black300 }}
                  interval={1}
                  tickFormatter={tick => `${(Number(tick) - 1) / 2}h`}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  tickLine={false}
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black300 }}
                  domain={['auto', 'auto']}
                  tickCount={8}
                  allowDecimals={false}
                  width={80}
                  axisLine={false}
                  stroke={transparentize(0.5, subsystem.color)}
                  tickFormatter={tick => Intl.NumberFormat('pt-br').format(Number(tick))}
                >
                  <Label
                    angle={-90}
                    value='Carga (MW)'
                    fontSize={12}
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
                  />
                </YAxis>
                <Line strokeWidth={5} dot={false} dataKey={subsystem.key} stroke={subsystem.color} />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        ))}
      </ChartsContainer>
    </Container>
  )
}
