import React, { useState } from 'react'
import { format, startOfDay } from 'date-fns'
import { capitalize } from 'lodash'
import { useQuery } from 'react-query'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { Section, SectionTitle, SectionText } from 'components/Section'
import { ControllerContainer } from 'components/Chart'
import { InputDate } from 'components/Input'
import InputRadio from 'components/InputRadio'
import { utcDateFromDate } from 'utils'
import { months } from 'utils/globals'
import api from 'services/api'
import DataTableAndChart from './DataTable'
import { Container, DataContainers, DataContainer, DataInfoContainer } from './styles'
import { ErrorWarning } from 'components/ErrorWarning'
import NoDataWarning from 'components/NoDataWarning'

interface ApiResponseDto {
  year: number
  month: number
  revision: string
  studies: {
    id: number
    date: string
    map: string
    member: string
    sensibility: string
    rainModel: string
    forecast: string
    model: string
    subsystems: {
      [index: string]: {
        [index: string]: number
      }
    }
  }[]
}

const LoadingPlaceholder = () => (
  <>
    <RectShape color='#ccc' style={{ height: '20px', width: '25%' }} />
    <RectShape color='#ccc' style={{ height: '20px', width: '25%' }} />
    <RectShape color='#ccc' style={{ height: '400px' }} />
  </>
)

export const ProspectiveStudies: React.FC = () => {
  const [date, setDate] = useState(startOfDay(new Date()))
  const [studyType, setStudyType] = useState('PRELIMINAR')

  const {
    data: studies,
    isLoading,
    isError,
  } = useQuery(
    ['studies-short-term', date, studyType],
    () =>
      api
        .get<ApiResponseDto[]>('prospective-studies', {
          timeout: 3000,
          params: {
            date,
            studyType: studyType === 'ESTENDIDO' ? 'DEFINITIVO' : studyType,
            extended: studyType === 'ESTENDIDO',
          },
        })
        .then(({ data }) => data),

    {
      staleTime: 1000 * 60, // 1 minute
      refetchOnWindowFocus: false,
    },
  )

  return (
    <Container>
      <Section>
        <SectionTitle title='Projeções de curto prazo' />
        <SectionText>
          <p>
            Nesta aba estão disponíveis projeções de <strong>Preço da Liquidação das Diferenças (PLD)</strong> realizadas através de rodadas dos
            modelos Newave e Decomp, para a(s) próxima(s) semana(s) operativa(s), divididas em três seguimentos: rodadas preliminares, definitivas e
            estendidas.
          </p>
          <p style={{ marginTop: '1rem' }}>
            As <strong>rodadas preliminares</strong> são programadas uma vez ao dia (todos os dias, incluindo os finais de semana) e, geralmente,
            disponibilizadas no período da manhã. Por padrão, são apresentadas simulações de preço utilizando a Energia Natural Afluente (ENA)
            prevista a partir da expectativa de chuva dos modelos meteorológicos GEFS, ECMWF e CFSv2. Além disso, disponibilizamos um cenário com a
            metodologia de previsão de chuva do Operador Nacional do Sistema Elétrico (ONS), o mesmo utilizado na formação do PLD pela Câmara de
            Comercialização de Energia Elétrica (CCEE).
          </p>
          <p style={{ marginTop: '1rem' }}>
            Já as <strong>rodadas definitivas</strong> são programadas e disponibilizadas uma vez ao dia (dias úteis), geralmente, no período da
            tarde, após a divulgação dos produtos de chuva e ENA verificada pelo ONS (PSAT e ACOMPH, respectivamente). Neste caso, as simulações de
            preço utilizam a ENA prevista a partir da expectativa de chuva dos modelos GEFS e ECMWF e do cenário ONS.
          </p>
          <p style={{ marginTop: '1rem' }}>
            Por fim, as <strong>rodadas estendidas</strong> são realizadas, geralmente, às segundas e quintas-feiras durante o período noturno, após a
            divulgação dos dados do modelo ECMWF estendido, cujo horizonte de previsão é de 45 dias. Eventualmente, disponibilizamos nesta seção,
            algumas simulações de membros isolados e percentis do ECMWF estendido, além da previsão de chuva do GEFS estendido do dia anterior, cujo
            horizonte de previsão é de 35 dias.
          </p>
          <p style={{ marginTop: '1rem' }}>
            <strong>Observação</strong>: O PLD é disponibilizado de forma horária desde 1º de janeiro de 2021 pela CCEE, como resultado de rodadas
            oficiais do modelo de programação diária DESSEM. Como explicado no início, as projeções expostas aqui são rodadas dos modelos de médio e
            curto prazo, NEWAVE e DECOMP, respectivamente, cujos resultados são utilizados como {'"entrada"'} para o DESSEM. Portanto, as projeções
            nesta seção são aproximações do PLD médio para a(s) próxima(s) semana(s).
          </p>
        </SectionText>
        <ControllerContainer style={{ gap: '30px' }}>
          <InputDate label='Data' value={format(date, 'yyyy-MM-dd')} onChange={({ target }) => setDate(utcDateFromDate(new Date(target.value)))} />
          <InputRadio
            name='dasd'
            label='Tipo de estudo'
            onChange={({ target }) => setStudyType(target.value)}
            options={[
              { label: 'Preliminar', value: 'PRELIMINAR', checked: true },
              { label: 'Definitivo', value: 'DEFINITIVO', checked: false },
              { label: 'Estendido', value: 'ESTENDIDO', checked: false },
            ]}
          />
        </ControllerContainer>
        <DataContainers>
          {(() => {
            if (isLoading) return <LoadingPlaceholder />
            if (isError) return <ErrorWarning text='Não conseguimos buscar os estudos, por favor, tente novamente.' />
            if (!studies || studies.length === 0) return <NoDataWarning text='Nenhum estudo encontrado.' />

            return studies.map((study, index) => {
              const { studies: subStudies } = study
              return (
                <DataContainer key={`${study.month} ${study.year} ${study.revision}`}>
                  <DataInfoContainer>
                    <h1>{`${capitalize(months?.[study.month - 1] ?? '')} ${study.year}`}</h1>
                    <h2>{study.revision}</h2>
                  </DataInfoContainer>
                  <DataTableAndChart studies={subStudies} index={index} />
                </DataContainer>
              )
            })
          })()}
        </DataContainers>
      </Section>
    </Container>
  )
}
