import { capitalize } from 'lodash'
import React from 'react'
import { utcDateFromDate } from 'utils'
import { subsystems } from 'utils/globals'
import { CustomProspectiveStudy } from '..'
import { Container, ValueTableDate, StudyTableDate, TableContainer } from './styles'
import { Title } from '../styles'

type SummedTablesProps = {
  response: CustomProspectiveStudy
  arePricesLimited: boolean
}

export function SummedTables({ response, arePricesLimited }: SummedTablesProps) {
  const keys = [
    {
      key: arePricesLimited ? 'pld_average' : 'cmo_average',
      formatter: (value: number) => Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value),
    },
    {
      key: 'ear_start',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(value / 100),
    },
    {
      key: 'monthly_ena_mlt',
      formatter: (value: number) =>
        Intl.NumberFormat('pt-br', { style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value),
    },
  ] as const

  return (
    <Container>
      <Title>Dados resumidos</Title>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th rowSpan={2} colSpan={2}>
                Estudo
              </th>
              <th colSpan={response.data.data.length}>PLD ({arePricesLimited ? 'com limites' : 'sem limites'})</th>
              <th colSpan={response.data.data.length}>EAR</th>
              <th colSpan={response.data.data.length}>ENA</th>
            </tr>
            <tr>
              {keys.map(key =>
                response.data.data.map(entry => (
                  <th key={`${entry.date} - ${entry.revision} - ${key.key}`}>
                    {(() => {
                      const entryDate = utcDateFromDate(new Date(entry.date))

                      const stringMonth = capitalize(Intl.DateTimeFormat('pt-br', { month: 'short' }).format(entryDate))

                      const stringYear = capitalize(Intl.DateTimeFormat('pt-br', { year: '2-digit' }).format(entryDate))

                      const stringRevision = `Rev ${entry.revision}`

                      return `${stringMonth.replace('.', '')}/${stringYear}\n${stringRevision}`
                    })()}
                  </th>
                )),
              )}
            </tr>
          </thead>
          <tbody>
            {response.meta.studies.map(study => (
              <React.Fragment key={study.hash}>
                <>
                  <tr>
                    <StudyTableDate rowSpan={5}>{study.name}</StudyTableDate>
                  </tr>
                  {subsystems.map((subsystem, subsystemIndex, subsystemsArray) => (
                    <tr key={subsystem.key}>
                      <ValueTableDate isLastFromData={true} isLastFromStudy={subsystemIndex + 1 === subsystemsArray.length} color={subsystem.color}>
                        {subsystem.initials.toUpperCase()}
                      </ValueTableDate>
                      {keys.map(key =>
                        response.data.data.map((entry, index, array) => {
                          return (
                            <ValueTableDate
                              isLastFromData={index + 1 === array.length}
                              isLastFromStudy={subsystemIndex + 1 === subsystemsArray.length}
                              color={subsystem.color}
                              key={`${entry.date} - ${entry.revision} - ${study.hash} - ${subsystem.key}`}
                            >
                              {(() => {
                                const value = entry.studies[study.hash]?.[subsystem.key][key.key]

                                if (typeof value !== 'number') return '-'

                                return key.formatter(value)
                              })()}
                            </ValueTableDate>
                          )
                        }),
                      )}
                    </tr>
                  ))}
                </>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  )
}
