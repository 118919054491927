import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ChartsRenderer } from './ChartsRenderer'
import { Container, ControllerContainer } from './styles'
import NoDataWarning from 'components/NoDataWarning'
import { StudyMetaData } from '..'
import { Header } from '../Header'
import { LoadingPlaceholder } from '../LoadingAlert'
import { Switch } from 'components/Input'
import { Redirect } from 'react-router-dom'

export type ProspectiveStudyData = {
  date: string
  map: string
  member: string
  sensibility: string
  rain_model: string
  forecast: string
  year: 2022
  month: 4
  model: string
  revision: string
  week: string
  subsystem: string
  ear_start: number
  ear_end: number
  week1: number
  week2: number
  week3: number
  week4: number
  week5: number
  week6: number
  monthly_ena_mwmed: number
  mlt: number
  monthly_ena_mlt: number
  cmo_light: number
  cmo_medium: number
  cmo_heavy: number
  cmo_average: number
  pld_light: number
  pld_mediun: number
  pld_heavy: number
  pld_average: number
  hydraulic_generation: number
  thermal_generation: number
}

type StudyIndex = 'rv0' | 'rv1' | 'rv2' | 'rv3'

export type CustomProspectiveStudy = {
  meta: StudyMetaData
  data: Record<
    string,
    {
      ecmwfMainMembers?: { base: number; control: number; average: number }
      southeast: {
        studies: {
          [key in StudyIndex]: ProspectiveStudyData[]
        }
        thresholds: {
          rv0: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv1: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv2: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv3: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
        }
      }
      south: {
        studies: {
          [key in StudyIndex]: ProspectiveStudyData[]
        }
        thresholds: {
          rv0: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv1: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv2: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv3: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
        }
      }
      northeast: {
        studies: {
          [key in StudyIndex]: ProspectiveStudyData[]
        }
        thresholds: {
          rv0: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv1: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv2: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv3: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
        }
      }
      north: {
        studies: {
          [key in StudyIndex]: ProspectiveStudyData[]
        }
        thresholds: {
          rv0: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv1: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv2: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
          rv3: { threshold: { bottom: number; top: number }; cmo: number; pld: number }[]
        }
      }
    }
  >
}

type CurveStudyProps = {
  id: string
}

export function ScatterStudy({ id }: CurveStudyProps) {
  const [limitedPrices, setLimitedPrices] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(['custom-prospective-study', id], () => api.get<CustomProspectiveStudy>(`custom-prospective-studies/${id}`).then(({ data }) => data), {
    staleTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false,
  })

  if (isLoading) return <LoadingPlaceholder />

  if (isError) return <Redirect to='/studies/curves-and-matrixes' />

  if (!response || Object.values(response.data).length === 0)
    return (
      <div style={{ paddingTop: '3rem' }}>
        <NoDataWarning text='Dados não encontrados.' />
      </div>
    )

  return (
    <Container>
      <Header meta={response?.meta} />
      <ControllerContainer>
        <Switch text='Limites de PLD' onChange={value => setLimitedPrices(value)} />
      </ControllerContainer>
      <ChartsRenderer response={response} arePricesLimited={limitedPrices} />
    </Container>
  )
}
