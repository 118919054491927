import React, { useState, useEffect, useRef } from 'react'
import { BiChevronRight } from 'react-icons/bi'
import { FaInfoCircle } from 'react-icons/fa'

import { Container, SectionTitleText, InfoContainer } from './styles'

interface SectionTitle {
  title?: string
  info?: JSX.Element | string
}

const SectionSubTitle: React.FC<SectionTitle> = ({ title, info }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const tooltipRef = useRef(document.createElement('div'))

  useEffect(() => {
    function handleOutsideOfUserPoupClick(this: Document, ev: globalThis.MouseEvent) {
      if (tooltipRef.current && !tooltipRef.current.contains(ev.target as Node)) setShowTooltip(false)
    }

    document.addEventListener('mouseover', handleOutsideOfUserPoupClick)

    return () => document.removeEventListener('mouseover', handleOutsideOfUserPoupClick)
  }, [])

  return (
    <Container>
      <SectionTitleText>
        <BiChevronRight />
        <span>{title}</span>
        {info ? <FaInfoCircle onMouseMove={() => setShowTooltip(!showTooltip)} style={{ marginLeft: '5px', cursor: 'pointer' }} /> : null}
      </SectionTitleText>
      {info && showTooltip && typeof info === 'string' ? (
        <InfoContainer ref={tooltipRef}>
          <div>
            <p>{info}</p>
          </div>
        </InfoContainer>
      ) : null}
      {info && showTooltip && React.isValidElement(info) ? <InfoContainer ref={tooltipRef}>{info}</InfoContainer> : null}
    </Container>
  )
}

export default SectionSubTitle
