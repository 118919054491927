import styled from 'styled-components'

export const Container = styled.div``

export const ImageContainer = styled.div`
  display: grid;
  justify-content: center;
  img {
    height: calc(100vh - 100px);
    object-fit: contain;
  }
`
