import styled, { keyframes } from 'styled-components'

const chartContainerAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const ChartContainer = styled.div<{ delay: number }>`
  width: calc(50% - 15px);
  height: 300px;
  animation: ${chartContainerAnimation} 1s 1;
  animation-delay: ${props => props.delay}ms;
  animation-fill-mode: backwards;
`

export const ChartsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 10px;
  div.break {
    flex-basis: 100%;
    height: 0;
  }
`
