export function getZitadelToken() {
  const storageKey = process.env.REACT_APP_ZITADEL_SESSION_STORAGE_KEY

  if (!storageKey) return 'Token found.'

  const item = localStorage.getItem(storageKey) || sessionStorage.getItem(storageKey)

  if (!item) return ''

  return JSON.parse(item).access_token
}
