import { useEffect } from 'react'
import ReactGA from 'react-ga4'

export function Analytics() {
  if (process.env.NODE_ENV !== 'production') return null

  const gtmTags = String(process.env.REACT_APP_GTM_TAGS).split(';')

  useEffect(() => {
    ReactGA.initialize(gtmTags.map(entry => ({ trackingId: entry })))
  }, [])

  return null
}
