import React, { useState } from 'react'
import { format, add, startOfWeek, endOfWeek, startOfDay } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { InputDate } from 'components/Input'
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import MapGallery from 'components/MapGallery'
import { Container, ControllerContainer } from './styles'
import { EcmwfDescription } from '..'

const Daily: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const models = ['ecmwf', 'gefs', 'eta']
  const weeks = ['semana_atual', 'proxima_semana', 'proxima_semana2']

  const images = weeks.flatMap((week, index) => {
    const formattedDate = format(date, 'yyyyMMdd')
    const daysToSubtract = [2, 1, 0]
    const lastDayOfForecast = add(date, { days: 14 })
    const firstDate = index === 0 ? date : startOfWeek(add(lastDayOfForecast, { days: daysToSubtract[index] * -7 }), { weekStartsOn: 6 })
    const secondDate = index === 2 ? lastDayOfForecast : endOfWeek(add(lastDayOfForecast, { days: daysToSubtract[index] * -7 }), { weekStartsOn: 6 })
    if (startOfDay(firstDate).toISOString() === startOfDay(secondDate).toISOString()) return []
    const firstWeekDay = firstDate.toLocaleString('pt-BR', { weekday: 'long' })
    const secondWeekDay = secondDate.toLocaleString('pt-BR', { weekday: 'long' })
    return {
      title: `Previsão de ${firstWeekDay}, ${firstDate.toLocaleDateString()} até ${secondWeekDay}, ${secondDate.toLocaleDateString()}`,
      ecmwf: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-ecmwf/ECMWF_r${formattedDate}_00Z_${week}.png`,
      gefs: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-gefs/GEFS_r${formattedDate}_00Z_${week}.png`,
      eta: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-eta/ETA_r${formattedDate}_00Z_${week}.png`,
    }
  })

  const descriptions = {
    ecmwf: (
      <EcmwfDescription initialText='Mapa de chuva prevista por semana operativa pelo modelo European Centre for Medium-Range Weather Forecasts (ECMWF), rodado e disponibilizado pela instituição de mesmo nome. O ECMWF é um modelo de previsão numérica por conjunto (ensemble) global e possui 0.2º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão média do conjunto.' />
    ),
    gefs: (
      <p>
        Mapa de chuva prevista por semana operativa pelo modelo Global Ensemble Forecast System (GEFS), rodado e disponibilizado pelo National Centers
        for Environmental Prediction (NCEP) da National Oceanic and Atmospheric Administration (NOAA), dos Estados Unidos da América. O GEFS é um
        modelo de previsão numérica por conjunto (ensemble) global e possui 0.5º de resolução espacial e horizonte de previsão de 16 dias. No produto
        abaixo estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão média do conjunto.
      </p>
    ),
    eta: (
      <p>
        Mapa de chuva prevista por semana operativa pelo modelo ETA, rodado e disponibilizado pelo Centro de Previsão de Tempo e Estudos Climáticos do
        Instituto Nacional de Pesquisas Espaciais (CPTEC/INPE). O ETA é um modelo de previsão numérica regional e possui 40 km de resolução espacial e
        horizonte de previsão de 10 dias.
      </p>
    ),
  }

  return (
    <Container>
      <SectionTitle>Previsão semanal</SectionTitle>
      <SectionText>
        <span>Mapas de previsão semanal de chuva de chuva dos modelos que compõe o processo de chuva-vazão do ONS. Utilize as teclas</span>
        <BsArrowLeftShort style={{ marginLeft: '5px' }} />
        <BsArrowRightShort style={{ marginRight: '5px' }} />
        <span>para alternar a data da previsão. </span>
        <span>
          Como os dados de previsão de chuva desta seção são disponibilizados diretamente pelo ONS, eventuais atrasos na geração dos mapas podem
          ocorrer.
        </span>
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          value={format(date, 'yyyy-MM-dd')}
          max={format(new Date(), 'yyyy-MM-dd')}
          onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
        />
      </ControllerContainer>
      <MapGallery images={images} models={models} descriptions={descriptions} scale='forecast' />
    </Container>
  )
}

export default Daily
