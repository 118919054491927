import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const Label = styled.label`
  color: #8c8c8c;
  font-size: 0.875rem;
`

export const InputElement = styled.select`
  padding: 10px 5px 10px 10px;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: ${props => props.theme.colors.blue700};
`
