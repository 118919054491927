import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2rem;
`

export const ControllerContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
`
