import React from 'react'
import { Container, ButtonsContainer, Button, Label } from './styles'
import { IoReload } from 'react-icons/io5'

export function InputSubmit({
  onClick,
  loading,
  disabled,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  loading?: boolean
  disabled?: boolean
}) {
  return (
    <Container>
      <Label>&nbsp;</Label>
      <ButtonsContainer>
        <Button onClick={onClick} loading={loading} disabled={disabled}>
          <IoReload />
        </Button>
      </ButtonsContainer>
    </Container>
  )
}
