import React from 'react'
import { TooltipProps } from 'recharts'
import { subsystems } from 'utils/globals'
import { numberToBrl, utcDateFromDate } from 'utils'

import { Container, TooltipGrid, TooltipInfoContainer, TooltipInfoValue, TooltipContainer, ToolTipDivider } from 'components/CustomTooltip'

const CustomTooltip: React.FC<TooltipProps> = ({ payload, active }) => {
  if (payload && active) {
    return (
      <Container>
        <TooltipContainer>
          <TooltipGrid columns={3}>
            <TooltipInfoContainer>
              <TooltipInfoValue>{utcDateFromDate(new Date(payload[0].payload.time)).toLocaleDateString('pt-br')}</TooltipInfoValue>
              {subsystems.map(subsystem => (
                <TooltipInfoValue key={subsystem.name} bold>
                  {subsystem.name}
                </TooltipInfoValue>
              ))}
            </TooltipInfoContainer>
            <ToolTipDivider />
            <TooltipInfoContainer>
              <TooltipInfoValue bold>
                <span>{utcDateFromDate(new Date(payload[0].payload.time)).toLocaleString('pt-br', { hour: '2-digit' })}</span>
                <span>:00h</span>
              </TooltipInfoValue>
              {subsystems.map(subsystem => (
                <TooltipInfoValue key={subsystem.name}>{numberToBrl(payload[0].payload[subsystem.key])}</TooltipInfoValue>
              ))}
            </TooltipInfoContainer>
          </TooltipGrid>
        </TooltipContainer>
      </Container>
    )
  }
  return null
}

export default CustomTooltip
