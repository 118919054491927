import React, { useState } from 'react'
import { PrismaSelect } from 'components/Input'
import { CustomProspectiveStudy } from '..'
import { Title } from '../styles'
import { Container, ControllerContainer } from './styles'
import { ChartGroup } from './ChartGroup'
import { Table } from './Table'

type ChartProps = {
  response: CustomProspectiveStudy
  arePricesLimited: boolean
}

export function ChartsRenderer({ response, arePricesLimited }: ChartProps) {
  const [selectedStudy, setSelectedStudy] = useState(0)
  const [selectedRev, setSelectedRev] = useState<'rv0' | 'rv1' | 'rv2' | 'rv3'>('rv0')

  return (
    <Container>
      <Title>Preço de liquidação das diferenças (PLD)</Title>
      <ControllerContainer>
        <PrismaSelect
          label='Estudo'
          value={String(selectedStudy)}
          options={response.meta.studies.map((study, index) => ({ label: study.name, value: String(index) }))}
          onChange={ev => setSelectedStudy(Number(ev.target.value))}
        />
        <PrismaSelect
          label='Revisão'
          value={selectedRev}
          options={[
            { value: 'rv0', label: 'RV0' },
            { value: 'rv1', label: 'RV1' },
            { value: 'rv2', label: 'RV2' },
            { value: 'rv3', label: 'RV3' },
          ]}
          onChange={({ target: { value } }) => {
            if (value === 'rv0') setSelectedRev('rv0')
            if (value === 'rv1') setSelectedRev('rv1')
            if (value === 'rv2') setSelectedRev('rv2')
            if (value === 'rv3') setSelectedRev('rv3')
          }}
        />
      </ControllerContainer>
      <ChartGroup
        study={response.meta.studies[selectedStudy]}
        data={response.data[response.meta.studies[selectedStudy].hash]}
        arePricesLimited={arePricesLimited}
        selectedRev={selectedRev}
      />
      <Table data={response.data[response.meta.studies[selectedStudy].hash]} selectedRev={selectedRev} />
    </Container>
  )
}
