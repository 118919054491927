import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 50px;
`

export const ControllerContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 2rem 0;
`
