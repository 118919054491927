import React from 'react'

import { Container, ColorsContainer, LabelsContainer } from './styles'

const Scale: React.FC = () => {
  return (
    <Container>
      <ColorsContainer />
      <LabelsContainer>
        <span>-100</span>
        <span>-75</span>
        <span>-50</span>
        <span>-25</span>
        <span>0</span>
        <span>25</span>
        <span>50</span>
        <span>75</span>
      </LabelsContainer>
      <span>Temperatura de Brilho (ºC)</span>
    </Container>
  )
}

export default Scale
