import { transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 30px auto;
  padding-top: 1rem;
  > span {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.875rem;
    color: ${props => transparentize(0.5, props.theme.colors.blue700)};
    font-weight: bold;
    text-orientation: upright;
  }
`

export const ColorsContainer = styled.div`
  height: 15px;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255) 0%,
    rgba(255, 255, 255) 0.390625%,
    rgba(255, 255, 255) 0.78125%,
    rgba(255, 255, 255) 1.171875%,
    rgba(255, 255, 255) 1.5625%,
    rgba(255, 255, 255) 1.953125%,
    rgba(255, 255, 255) 2.34375%,
    rgba(255, 255, 255) 2.734375%,
    rgba(255, 255, 255) 3.125%,
    rgba(255, 255, 255) 3.515625%,
    rgba(255, 255, 255) 3.90625%,
    rgba(255, 255, 255) 4.296875%,
    rgba(255, 255, 255) 4.6875%,
    rgba(255, 255, 255) 5.078125%,
    rgba(255, 255, 255) 5.46875%,
    rgba(255, 255, 255) 5.859375%,
    rgba(255, 255, 255) 6.25%,
    rgba(255, 255, 255) 6.640625%,
    rgba(255, 255, 255) 7.03125%,
    rgba(255, 255, 255) 7.421875%,
    rgba(127, 0, 127) 7.8125%,
    rgba(140, 13, 135) 8.203125%,
    rgba(153, 25, 142) 8.59375%,
    rgba(165, 38, 150) 8.984375%,
    rgba(178, 51, 157) 9.375%,
    rgba(191, 64, 165) 9.765625%,
    rgba(204, 76, 173) 10.15625%,
    rgba(217, 89, 180) 10.546875%,
    rgba(229, 102, 188) 10.9375%,
    rgba(242, 114, 195) 11.328125%,
    rgba(255, 127, 203) 11.71875%,
    rgba(230, 230, 230) 12.109375%,
    rgba(205, 205, 205) 12.5%,
    rgba(179, 179, 179) 12.890625%,
    rgba(154, 154, 154) 13.28125%,
    rgba(128, 128, 128) 13.671875%,
    rgba(103, 103, 103) 14.0625%,
    rgba(77, 77, 77) 14.453125%,
    rgba(52, 52, 52) 14.84375%,
    rgba(26, 26, 26) 15.234375%,
    rgba(0, 0, 0) 15.625%,
    rgba(26, 0, 0) 16.015625%,
    rgba(51, 0, 0) 16.40625%,
    rgba(77, 0, 0) 16.796875%,
    rgba(102, 0, 0) 17.1875%,
    rgba(128, 0, 0) 17.578125%,
    rgba(153, 0, 0) 17.96875%,
    rgba(179, 0, 0) 18.359375%,
    rgba(204, 0, 0) 18.75%,
    rgba(230, 0, 0) 19.140625%,
    rgba(255, 0, 0) 19.53125%,
    rgba(255, 26, 0) 19.921875%,
    rgba(255, 51, 0) 20.3125%,
    rgba(255, 77, 0) 20.703125%,
    rgba(255, 102, 0) 21.09375%,
    rgba(255, 128, 0) 21.484375%,
    rgba(255, 153, 0) 21.875%,
    rgba(255, 179, 0) 22.265625%,
    rgba(255, 204, 0) 22.65625%,
    rgba(255, 230, 0) 23.046875%,
    rgba(255, 255, 0) 23.4375%,
    rgba(230, 255, 0) 23.828125%,
    rgba(204, 255, 0) 24.21875%,
    rgba(179, 255, 0) 24.609375%,
    rgba(153, 255, 0) 25%,
    rgba(128, 255, 0) 25.390625%,
    rgba(102, 255, 0) 25.78125%,
    rgba(77, 255, 0) 26.171875%,
    rgba(51, 255, 0) 26.5625%,
    rgba(26, 255, 0) 26.953125%,
    rgba(0, 255, 0) 27.34375%,
    rgba(0, 234, 10) 27.734375%,
    rgba(0, 213, 19) 28.125%,
    rgba(0, 191, 29) 28.515625%,
    rgba(0, 170, 38) 28.90625%,
    rgba(0, 149, 48) 29.296875%,
    rgba(0, 128, 58) 29.6875%,
    rgba(0, 106, 67) 30.078125%,
    rgba(0, 85, 77) 30.46875%,
    rgba(0, 64, 86) 30.859375%,
    rgba(0, 43, 96) 31.25%,
    rgba(0, 21, 105) 31.640625%,
    rgba(0, 0, 115) 32.03125%,
    rgba(0, 0, 115) 32.421875%,
    rgba(0, 13, 122) 32.8125%,
    rgba(0, 26, 129) 33.203125%,
    rgba(0, 38, 136) 33.59375%,
    rgba(0, 51, 143) 33.984375%,
    rgba(0, 64, 150) 34.375%,
    rgba(0, 77, 157) 34.765625%,
    rgba(0, 89, 164) 35.15625%,
    rgba(0, 102, 171) 35.546875%,
    rgba(0, 115, 178) 35.9375%,
    rgba(0, 128, 185) 36.328125%,
    rgba(0, 140, 192) 36.71875%,
    rgba(0, 153, 199) 37.109375%,
    rgba(0, 166, 206) 37.5%,
    rgba(0, 179, 213) 37.890625%,
    rgba(0, 191, 220) 38.28125%,
    rgba(0, 204, 227) 38.671875%,
    rgba(0, 217, 234) 39.0625%,
    rgba(0, 230, 241) 39.453125%,
    rgba(0, 242, 248) 39.84375%,
    rgba(0, 255, 255) 40.234375%,
    rgba(255, 255, 255) 40.625%,
    rgba(255, 255, 255) 41.015625%,
    rgba(255, 255, 255) 41.40625%,
    rgba(255, 255, 255) 41.796875%,
    rgba(255, 255, 255) 42.1875%,
    rgba(255, 255, 255) 42.578125%,
    rgba(255, 255, 255) 42.96875%,
    rgba(254, 254, 254) 43.359375%,
    rgba(252, 252, 252) 43.75%,
    rgba(249, 249, 249) 44.140625%,
    rgba(247, 247, 247) 44.53125%,
    rgba(244, 244, 244) 44.921875%,
    rgba(242, 242, 242) 45.3125%,
    rgba(239, 239, 239) 45.703125%,
    rgba(237, 237, 237) 46.09375%,
    rgba(234, 234, 234) 46.484375%,
    rgba(232, 232, 232) 46.875%,
    rgba(229, 229, 229) 47.265625%,
    rgba(226, 226, 226) 47.65625%,
    rgba(224, 224, 224) 48.046875%,
    rgba(221, 221, 221) 48.4375%,
    rgba(219, 219, 219) 48.828125%,
    rgba(216, 216, 216) 49.21875%,
    rgba(214, 214, 214) 49.609375%,
    rgba(211, 211, 211) 50%,
    rgba(209, 209, 209) 50.390625%,
    rgba(206, 206, 206) 50.78125%,
    rgba(203, 203, 203) 51.171875%,
    rgba(201, 201, 201) 51.5625%,
    rgba(198, 198, 198) 51.953125%,
    rgba(196, 196, 196) 52.34375%,
    rgba(193, 193, 193) 52.734375%,
    rgba(191, 191, 191) 53.125%,
    rgba(188, 188, 188) 53.515625%,
    rgba(186, 186, 186) 53.90625%,
    rgba(183, 183, 183) 54.296875%,
    rgba(181, 181, 181) 54.6875%,
    rgba(178, 178, 178) 55.078125%,
    rgba(175, 175, 175) 55.46875%,
    rgba(173, 173, 173) 55.859375%,
    rgba(170, 170, 170) 56.25%,
    rgba(168, 168, 168) 56.640625%,
    rgba(165, 165, 165) 57.03125%,
    rgba(163, 163, 163) 57.421875%,
    rgba(160, 160, 160) 57.8125%,
    rgba(158, 158, 158) 58.203125%,
    rgba(155, 155, 155) 58.59375%,
    rgba(152, 152, 152) 58.984375%,
    rgba(150, 150, 150) 59.375%,
    rgba(147, 147, 147) 59.765625%,
    rgba(145, 145, 145) 60.15625%,
    rgba(142, 142, 142) 60.546875%,
    rgba(140, 140, 140) 60.9375%,
    rgba(137, 137, 137) 61.328125%,
    rgba(135, 135, 135) 61.71875%,
    rgba(132, 132, 132) 62.109375%,
    rgba(130, 130, 130) 62.5%,
    rgba(127, 127, 127) 62.890625%,
    rgba(124, 124, 124) 63.28125%,
    rgba(122, 122, 122) 63.671875%,
    rgba(119, 119, 119) 64.0625%,
    rgba(117, 117, 117) 64.453125%,
    rgba(114, 114, 114) 64.84375%,
    rgba(112, 112, 112) 65.234375%,
    rgba(109, 109, 109) 65.625%,
    rgba(107, 107, 107) 66.015625%,
    rgba(104, 104, 104) 66.40625%,
    rgba(101, 101, 101) 66.796875%,
    rgba(99, 99, 99) 67.1875%,
    rgba(96, 96, 96) 67.578125%,
    rgba(94, 94, 94) 67.96875%,
    rgba(91, 91, 91) 68.359375%,
    rgba(89, 89, 89) 68.75%,
    rgba(86, 86, 86) 69.140625%,
    rgba(84, 84, 84) 69.53125%,
    rgba(81, 81, 81) 69.921875%,
    rgba(79, 79, 79) 70.3125%,
    rgba(76, 76, 76) 70.703125%,
    rgba(73, 73, 73) 71.09375%,
    rgba(71, 71, 71) 71.484375%,
    rgba(68, 68, 68) 71.875%,
    rgba(66, 66, 66) 72.265625%,
    rgba(63, 63, 63) 72.65625%,
    rgba(61, 61, 61) 73.046875%,
    rgba(58, 58, 58) 73.4375%,
    rgba(56, 56, 56) 73.828125%,
    rgba(53, 53, 53) 74.21875%,
    rgba(50, 50, 50) 74.609375%,
    rgba(48, 48, 48) 75%,
    rgba(45, 45, 45) 75.390625%,
    rgba(43, 43, 43) 75.78125%,
    rgba(40, 40, 40) 76.171875%,
    rgba(38, 38, 38) 76.5625%,
    rgba(35, 35, 35) 76.953125%,
    rgba(33, 33, 33) 77.34375%,
    rgba(30, 30, 30) 77.734375%,
    rgba(28, 28, 28) 78.125%,
    rgba(25, 25, 25) 78.515625%,
    rgba(22, 22, 22) 78.90625%,
    rgba(20, 20, 20) 79.296875%,
    rgba(17, 17, 17) 79.6875%,
    rgba(15, 15, 15) 80.078125%,
    rgba(12, 12, 12) 80.46875%,
    rgba(10, 10, 10) 80.859375%,
    rgba(07, 07, 07) 81.25%,
    rgba(05, 05, 05) 81.640625%,
    rgba(02, 02, 02) 82.03125%,
    rgba(0, 0, 0) 82.421875%,
    rgba(0, 0, 0) 82.8125%,
    rgba(0, 0, 0) 83.203125%,
    rgba(0, 0, 0) 83.59375%,
    rgba(0, 0, 0) 83.984375%,
    rgba(0, 0, 0) 84.375%,
    rgba(0, 0, 0) 84.765625%,
    rgba(0, 0, 0) 85.15625%,
    rgba(0, 0, 0) 85.546875%,
    rgba(0, 0, 0) 85.9375%,
    rgba(0, 0, 0) 86.328125%,
    rgba(0, 0, 0) 86.71875%,
    rgba(0, 0, 0) 87.109375%,
    rgba(0, 0, 0) 87.5%,
    rgba(0, 0, 0) 87.890625%,
    rgba(0, 0, 0) 88.28125%,
    rgba(0, 0, 0) 88.671875%,
    rgba(0, 0, 0) 89.0625%,
    rgba(0, 0, 0) 89.453125%,
    rgba(0, 0, 0) 89.84375%,
    rgba(0, 0, 0) 90.234375%,
    rgba(0, 0, 0) 90.625%,
    rgba(0, 0, 0) 91.015625%,
    rgba(0, 0, 0) 91.40625%,
    rgba(0, 0, 0) 91.796875%,
    rgba(0, 0, 0) 92.1875%,
    rgba(0, 0, 0) 92.578125%,
    rgba(0, 0, 0) 92.96875%,
    rgba(0, 0, 0) 93.359375%,
    rgba(0, 0, 0) 93.75%,
    rgba(0, 0, 0) 94.140625%,
    rgba(0, 0, 0) 94.53125%,
    rgba(0, 0, 0) 94.921875%,
    rgba(0, 0, 0) 95.3125%,
    rgba(0, 0, 0) 95.703125%,
    rgba(0, 0, 0) 96.09375%,
    rgba(0, 0, 0) 96.484375%,
    rgba(0, 0, 0) 96.875%,
    rgba(0, 0, 0) 97.265625%,
    rgba(0, 0, 0) 97.65625%,
    rgba(0, 0, 0) 98.046875%,
    rgba(0, 0, 0) 98.4375%,
    rgba(0, 0, 0) 98.828125%,
    rgba(0, 0, 0) 99.21875%,
    rgba(0, 0, 0) 99.609375%,
    rgba(0, 0, 0) 100%
  );
`

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
`
