import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { parse, format, add } from 'date-fns'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, Legend, Tooltip, Area, Line, CartesianGrid, Label, ReferenceLine } from 'recharts'
import { BsFillLightningChargeFill } from 'react-icons/bs'
import api from 'services/api'
import { getNumberColor, utcDateFromDate } from 'utils'
import { CustomLegend } from 'components/Chart'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { InputDate } from 'components/Input'
import { subsystems } from 'utils/globals'
import {
  Container,
  ControllerContainer,
  ChartsContainer,
  ChartContainer,
  SummaryWrapper,
  SummaryContainer,
  SummaryTitleContainer,
  SummaryInfoContainer,
  SummaryValuesContainer,
} from './styles'

import CustomTooltip from './CustomTooltip'

type ApiHourlyDemandDTO = {
  date: string
  time: string
  southeastForecast: number
  southForecast: number
  northeastForecast: number
  northForecast: number
  southeastVerified: number
  southVerified: number
  northeastVerified: number
  northVerified: number
  sinForecast: number
  sinVerified: number
}

type ApiResponse = {
  summary: {
    totalAverage: Omit<ApiHourlyDemandDTO, 'date' | 'time'>
    hourlyAverage: Omit<ApiHourlyDemandDTO, 'date' | 'time'>[]
    dailyAverage: Omit<ApiHourlyDemandDTO, 'time'>[]
  }
  data: ApiHourlyDemandDTO[]
}

type DateLimits = {
  upperLimit: string
  lowerLimit: string
}

const HourlyDemand: React.FC = () => {
  const theme = useTheme()

  // * Controls
  const [dateLimits, setDateLimits] = useState<DateLimits>()
  const [start, setStart] = useState(new Date())
  const [end, setEnd] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  // * Data
  const [data, setData] = useState<ApiResponse['data']>([])
  const [summaryData, setSummaryData] = useState<ApiResponse['summary']>()

  // * Chart
  const [referenceLines, setReferenceLines] = useState<JSX.Element[]>([])

  // * Date limits api call
  useEffect(() => {
    api
      .get<DateLimits>('hourly-demand/interval')
      .then(response => {
        setStart(add(utcDateFromDate(new Date(response.data.upperLimit)), { days: -6 }))
        setEnd(utcDateFromDate(new Date(response.data.upperLimit)))
        return response
      })
      .then(response => setDateLimits(response.data))
  }, [])

  // * Data api call
  useEffect(() => {
    if (!dateLimits) return
    if (isFirstLoad) setLoading(true)
    api
      .get<ApiResponse>('hourly-demand', { params: { start, end } })
      .then(response => {
        setData(response.data.data)
        setSummaryData(response.data.summary)
        setIsFirstLoad(false)
        setReferenceLines(
          response.data.data.length > 168
            ? []
            : response.data.data
                .filter(entry => utcDateFromDate(new Date(entry.time)).getHours() === 0)
                .map(entry => <ReferenceLine key={entry.time} alwaysShow stroke='#ccc' strokeDasharray='3' strokeWidth={1} x={entry.time} />),
        )
      })
      .then(() => setLoading(false))
  }, [start, end, dateLimits])

  const charts = [
    ...subsystems.map(subsystem => ({
      area: { name: `Carga verificada ${subsystem.name}`, dataKey: `${subsystem.key}Verified`, color: subsystem.color },
      line: { name: `Carga prevista ${subsystem.name}`, dataKey: `${subsystem.key}Forecast`, color: subsystem.color },
      legends: [
        { text: `Carga verificada ${subsystem.name}`, color: subsystem.color },
        { text: `Carga prevista ${subsystem.name}`, color: subsystem.color },
      ],
    })),
    {
      area: { name: `Carga verificada SIN`, dataKey: 'sinVerified', color: '#333' },
      line: { name: `Carga prevista SIN`, dataKey: 'sinForecast', color: '#333' },
      legends: [
        { text: `Carga verificada SIN`, color: '#333' },
        { text: `Carga prevista SIN`, color: '#333' },
      ],
    },
  ]

  const summaries = [
    {
      forecastDataKey: 'sinForecast',
      verifiedDataKey: 'sinVerified',
      name: 'Carga média SIN',
      color: '#333',
      dark: true,
      icon: BsFillLightningChargeFill,
      gridArea: 'sin',
    },
    {
      forecastDataKey: 'southeastForecast',
      verifiedDataKey: 'southeastVerified',
      name: 'Carga média Sudeste',
      color: theme.safiraColors.blue[3],
      dark: false,
      icon: BsFillLightningChargeFill,
      gridArea: 'southeast',
    },
    {
      forecastDataKey: 'southForecast',
      verifiedDataKey: 'southVerified',
      name: 'Carga média Sul',
      color: theme.safiraColors.yellow[3],
      dark: false,
      icon: BsFillLightningChargeFill,
      gridArea: 'south',
    },
    {
      forecastDataKey: 'northeastForecast',
      verifiedDataKey: 'northeastVerified',
      name: 'Carga média Nordeste',
      color: theme.safiraColors.orange[3],
      dark: false,
      icon: BsFillLightningChargeFill,
      gridArea: 'northeast',
    },
    {
      forecastDataKey: 'northForecast',
      verifiedDataKey: 'northVerified',
      name: 'Carga média Norte',
      color: theme.safiraColors.green[3],
      dark: false,
      icon: BsFillLightningChargeFill,
      gridArea: 'north',
    },
  ] as const

  return (
    <Container>
      <SectionTitle>Carga Horária</SectionTitle>
      <SectionText>
        Dados de carga horária verificada de acordo com o Boletim Diário da Operação (BDO) e carga prevista de acordo com o arquivo de entrada de
        dados do Modelo de Despacho de Curtíssimo Prazo (DESSEM), disponibilizados pelo Organizador Nacional do Sistema Elétrico (ONS). Dados
        apresentados por submercados e total do Sistema Interligado Nacional (SIN).
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Início'
          value={format(start, 'yyyy-MM-dd')}
          max={dateLimits && format(utcDateFromDate(new Date(dateLimits.upperLimit)), 'yyyy-MM-dd')}
          min={dateLimits && format(utcDateFromDate(new Date(dateLimits.lowerLimit)), 'yyyy-MM-dd')}
          onChange={ev => setStart(parse(ev.target.value, 'yyyy-MM-dd', new Date()))}
        />
        <InputDate
          label='Fim'
          value={format(end, 'yyyy-MM-dd')}
          max={dateLimits && format(utcDateFromDate(new Date(dateLimits.upperLimit)), 'yyyy-MM-dd')}
          min={dateLimits && format(utcDateFromDate(new Date(dateLimits.lowerLimit)), 'yyyy-MM-dd')}
          onChange={ev => setEnd(parse(ev.target.value, 'yyyy-MM-dd', new Date()))}
        />
      </ControllerContainer>
      <SummaryWrapper>
        {summaries.map(summary => (
          <SummaryContainer key={summary.name} style={{ gridArea: summary.gridArea }}>
            <SummaryTitleContainer color={summary.color}>
              <div />
              <span>{summary.name}</span>
            </SummaryTitleContainer>
            <SummaryValuesContainer>
              <SummaryInfoContainer>
                <span>Verificado</span>
                <div style={{ overflow: 'hidden' }}>{Array(window.innerWidth).fill('.').join('')}</div>
                <span>
                  {(() => {
                    if (!summaryData) return '- MWmed'
                    const value = summaryData.totalAverage[summary.verifiedDataKey].toLocaleString('pt-br', {
                      maximumFractionDigits: 0,
                    })
                    return `${value} MWmed`
                  })()}
                </span>
              </SummaryInfoContainer>
              <SummaryInfoContainer>
                <span>Previsto</span>
                <div style={{ overflow: 'hidden' }}>{Array(window.innerWidth).fill('.').join('')}</div>
                <span>
                  {(() => {
                    if (!summaryData) return '- MWmed'
                    const value = summaryData.totalAverage[summary.forecastDataKey].toLocaleString('pt-br', {
                      maximumFractionDigits: 0,
                    })
                    return `${value} MWmed`
                  })()}
                </span>
              </SummaryInfoContainer>
              <SummaryInfoContainer>
                <span>Diferença</span>
                <div style={{ overflow: 'hidden' }}>{Array(window.innerWidth).fill('.').join('')}</div>
                {(() => {
                  if (!summaryData) return <span>-</span>
                  const difference = summaryData.totalAverage[summary.verifiedDataKey] - summaryData.totalAverage[summary.forecastDataKey]
                  const color = getNumberColor(difference)
                  return (
                    <span style={{ color }}>
                      {difference.toLocaleString('pt-br', { maximumFractionDigits: 0 })}
                      &nbsp; MWmed
                    </span>
                  )
                })()}
              </SummaryInfoContainer>
            </SummaryValuesContainer>
          </SummaryContainer>
        ))}
      </SummaryWrapper>
      <ChartsContainer>
        {charts.map(chart => (
          <ChartContainer key={chart.area.name}>
            <ResponsiveContainer>
              {(() => {
                if (loading) return <RectShape style={{ height: '100%', width: '100%', backgroundColor: '#ccc', borderRadius: '5px' }} />
                return (
                  <ComposedChart data={data}>
                    <CartesianGrid strokeDasharray='3 3' vertical={data.length <= 168 ? false : undefined} />
                    <Tooltip content={CustomTooltip} />
                    <Legend content={<CustomLegend legends={chart.legends} />} />
                    <XAxis
                      type='category'
                      dataKey='time'
                      interval={data.length <= 168 ? 0 : 'preserveEnd'}
                      tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                      tickLine={false}
                      scale='point'
                      tickFormatter={tick => {
                        const tickDate = utcDateFromDate(new Date(tick))
                        const formattedDate = tickDate.toLocaleDateString()
                        if (data.length <= 168) {
                          if (tickDate.getHours() !== 12) return ''
                          return formattedDate
                        }
                        return formattedDate
                      }}
                    />
                    <YAxis
                      type='number'
                      tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                      domain={[0, 'auto']}
                      interval={0}
                      tickCount={6}
                      tickFormatter={tick => new Intl.NumberFormat('pt-br').format(Number(tick))}
                      width={90}
                    >
                      <Label
                        angle={-90}
                        value='Carga Horária (MWmed)'
                        position='insideLeft'
                        style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                      />
                    </YAxis>
                    {referenceLines}
                    <Area name={chart.area.name} dataKey={chart.area.dataKey} dot={false} fill={chart.area.color} style={{ strokeWidth: 0 }} />
                    <Line name={chart.line.name} dataKey={chart.line.dataKey} stroke={chart.line.color} strokeWidth={2} dot={false} />
                  </ComposedChart>
                )
              })()}
            </ResponsiveContainer>
          </ChartContainer>
        ))}
      </ChartsContainer>
    </Container>
  )
}

export default HourlyDemand
