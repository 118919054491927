import styled, { keyframes } from 'styled-components'
import { transparentize } from 'polished'

const appear = keyframes`
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
`

export const Container = styled.div``

export const DataContainers = styled.div`
  display: grid;
  padding-top: 50px;
  gap: 30px;
`

export const DataContainer = styled.div``

export const DataInfoContainer = styled.div`
  padding-bottom: 15px;
  animation: ${appear} 200ms 1;
  h1 {
    font-size: 1.8rem;
    color: ${props => props.theme.safiraColors.blue[4]};
  }
  h2 {
    font-size: 1.3rem;
    color: ${props => transparentize(0.5, props.theme.safiraColors.blue[4])};
  }
`
