import React from 'react'
import { TooltipProps } from 'recharts'
import { Container, TooltipInfoContainer, TooltipInfoValue, TooltipGrid, TooltipTitleContainer, ToolTipDivider } from 'components/CustomTooltip'
import { returnBRTimeFormatFromUTCString, returnBRNumberWithoutDecimalPlaces } from 'utils'

interface CustomTooltipProps extends TooltipProps {
  title?: string
  subsystem?: string
  tooltips: {
    name: string
    dataKey: string
  }[]
}

const CustomTooltip = ({ active, payload, tooltips, subsystem }: CustomTooltipProps) => {
  if (!active || !payload || !payload[0] || !payload[0].payload) return null

  const totalGeneration = Number(
    (payload[0].payload.hydraulicGeneration ?? 0) +
      (payload[0].payload.thermalGeneration ?? 0) +
      (payload[0].payload.nuclearGeneration ?? 0) +
      (payload[0].payload.solarGeneration ?? 0) +
      (payload[0].payload.windGeneration ?? 0),
  )

  const exchange = totalGeneration - (payload[0].payload.demand ?? 0)

  const differenceForecastAcomplished = payload[0].payload.demand - payload[0].payload.dessemDemand

  return (
    <Container>
      <TooltipTitleContainer>{returnBRTimeFormatFromUTCString(payload[0].payload.time ?? new Date())}</TooltipTitleContainer>
      <TooltipGrid columns={3}>
        <TooltipInfoContainer>
          {tooltips.map(tooltip => (
            <TooltipInfoValue key={tooltip.dataKey}>{tooltip.name}</TooltipInfoValue>
          ))}
          <TooltipInfoValue>Geração total</TooltipInfoValue>
          {!subsystem && <TooltipInfoValue>Intercâmbio</TooltipInfoValue>}
          <TooltipInfoValue>Dif Carga R-P</TooltipInfoValue>
        </TooltipInfoContainer>
        <ToolTipDivider />
        <TooltipInfoContainer>
          {tooltips.map(tooltip => (
            <TooltipInfoValue key={tooltip.dataKey}>{`${returnBRNumberWithoutDecimalPlaces(
              payload[0].payload[tooltip.dataKey] ?? 0,
            )} MW`}</TooltipInfoValue>
          ))}
          <TooltipInfoValue>
            <span>{totalGeneration.toLocaleString('pt-br', { maximumFractionDigits: 0 })}</span>
            <span> MW</span>
          </TooltipInfoValue>
          {!subsystem && (
            <TooltipInfoValue>
              <span>{exchange.toLocaleString('pt-br', { maximumFractionDigits: 0 })}</span>
              <span> MW</span>
            </TooltipInfoValue>
          )}
          <TooltipInfoValue>
            <span>
              {Intl.NumberFormat('pt-br', { maximumFractionDigits: 0 }).format(
                Number.isNaN(differenceForecastAcomplished) ? 0 : differenceForecastAcomplished,
              )}
            </span>
            <span> MW</span>
          </TooltipInfoValue>
        </TooltipInfoContainer>
      </TooltipGrid>
    </Container>
  )
}

export default CustomTooltip
