import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  background: #fff;
  padding: 30px;
  border-radius: 0.5rem;
  margin-top: 1rem;
`

export const PostBannerContainer = styled.div<{ src?: string }>`
  height: 200px;
  background-image: ${props =>
    props.src ? `url(${props.src})` : `url(${process.env.REACT_APP_STATIC_ADDRESS}/images/client/transmissao-linha-azul.png)`};
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem;
  opacity: 0.9;
  transition: opacity 200ms;
`

const postContainerAnimation = keyframes`
  from {
    left: -20px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
`

export const PostContainer = styled.div`
  transition: transform ease-in-out 200ms, opacity ease-in-out 200ms;
  opacity: 0.9;
  position: relative;
  animation: ${postContainerAnimation} 1s 1;
  :hover {
    transform: translateY(-3px);
    opacity: 1;
    ${PostBannerContainer} {
      opacity: 1;
    }
  }
`

export const PostInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.blue700};
`

export const PostTitleContainer = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
  color: ${props => props.theme.colors.blue700};
`
