import React, { useState, useEffect } from 'react'
import { ResponsiveContainer, LineChart, BarChart, Bar, XAxis, YAxis, Line, Tooltip, CartesianGrid, ReferenceLine, Legend, Label } from 'recharts'
import { BsCalendarRange } from 'react-icons/bs'
import { format, startOfMonth, endOfWeek } from 'date-fns'
import { Section, SectionTitle, SectionText } from 'components/Section'
import { InputDate } from 'components/Input'
import { ControllerContainer } from 'components/Layout'
import { ChartContainer, CustomLegend } from 'components/Chart'
import api from 'services/api'
import { subsystems } from 'utils/globals'
import { Summary } from 'components/Summary'
import { capitalize, PrismaChartUtils, numberToBrl, utcDateFromDate } from 'utils'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import { Container, ChartsContainer, RevisionContainer, RevisionChartAndInfoContainer } from './styles'
import CustomTooltip from './CustomTooltip'
import { useTheme } from 'styled-components'

interface apiResponse {
  intervalSummary: {
    min: {
      southeast: {
        time: Date
        value: number
      }
      south: {
        time: Date
        value: number
      }
      northeast: {
        time: Date
        value: number
      }
      north: {
        time: Date
        value: number
      }
    }
    max: {
      southeast: {
        time: Date
        value: number
      }
      south: {
        time: Date
        value: number
      }
      northeast: {
        time: Date
        value: number
      }
      north: {
        time: Date
        value: number
      }
    }
  }
  monthSummary: {
    min: {
      southeast: {
        time: Date
        value: number
      }
      south: {
        time: Date
        value: number
      }
      northeast: {
        time: Date
        value: number
      }
      north: {
        time: Date
        value: number
      }
    }
    max: {
      southeast: {
        time: Date
        value: number
      }
      south: {
        time: Date
        value: number
      }
      northeast: {
        time: Date
        value: number
      }
      north: {
        time: Date
        value: number
      }
    }
    avg: {
      southeast: number
      south: number
      northeast: number
      north: number
    }
  }
  revisionsData: {
    revision: number
    firstDateOfRevision: Date
    revisionAverage: {
      southeast: number
      south: number
      northeast: number
      north: number
    }
    revisionDailyAverages: {
      date: Date
      southeast: number
      south: number
      northeast: number
      north: number
    }[]
    prices: {
      date: Date
      time: Date
      revision: number
      southeast: number
      south: number
      northeast: number
      north: number
    }[]
  }[]
}

const History: React.FC = () => {
  const [date, setDate] = useState(startOfMonth(new Date()))
  const [data, setData] = useState<apiResponse>()
  const [loading, setLoading] = useState(true)
  const dailyAverageTicks = PrismaChartUtils.getChartTicks({ payload: [0, 600], numberOfTicks: 3 })

  const theme = useTheme()

  useEffect(() => {
    setLoading(true)
    setData(undefined)
    api.get<apiResponse>('ccee-dessem-prices/show-by-week', { params: { date } }).then(response => {
      setData(response.data)
      setLoading(false)
    })
  }, [date])

  const customLegend = () => (
    <CustomLegend
      legends={subsystems.map(subsystem => ({
        text: capitalize(subsystem.name),
        color: subsystem.color,
      }))}
    />
  )

  const summaries = [
    { title: 'Preços mínimos', data: data?.intervalSummary.min },
    { title: 'Preços médios', data: data?.monthSummary.avg },
    { title: 'Preços máximo', data: data?.intervalSummary.max },
  ]

  return (
    <Container>
      <Section>
        <SectionTitle
          title='Histórico do PLD horário'
          info='A partir de 1° de janeiro de 2021, o Preço de Liquidação das Diferenças - PLD passou a ser calculado oficialmente para cada submercado em base horária, conforme proposto pela Comissão Permanente para Análise de Metodologias e programas Computacionais do Setor Elétrico – CPAMP, com cronograma de implantação definido pela Portaria MME 301/2019. No período de abril de 2018 até a efetiva implementação do preço horário, paralelamente ao cálculo oficial do PLD em base semanal, a CCEE também realizou o cálculo e divulgação do Preço horário “Sombra”, também disponível para consulta na ferramenta abaixo. Os valores divulgados até 31 de dezembro de 2020, consistem na operação sombra e não estão vigentes comercialmente.'
        />
        <SectionText text='Histórico do Preço de Liquidação das Diferenças (PLD) horário para cada submercado, disponibilizado pela Câmara de Comercialização de Energia Elétrica (CCEE).' />
        <ControllerContainer>
          <InputDate
            label='Mês'
            type='month'
            value={format(date, 'yyyy-MM')}
            onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
            max={format(startOfMonth(new Date()), 'yyyy-MM')}
          />
        </ControllerContainer>
        <Summary columns={3}>
          {summaries.map(summary => (
            <div key={summary.title}>
              <h2>{summary.title}</h2>
              <div>
                {subsystems.map(subsystem => (
                  <div key={subsystem.name}>
                    <span>{subsystem.name}</span>
                    <Summary.Dots />
                    <span style={{ textAlign: 'center' }}>
                      {(() => {
                        const value = summary.data?.[subsystem.key]
                        if (typeof value === 'number') {
                          return (
                            summary.data?.[subsystem.key].toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            }) ?? '-'
                          )
                        }
                        if (!value) return <TextBlock rows={1} color='#ccc' />
                        const valueDate = utcDateFromDate(new Date(value.time)).toLocaleDateString('pt-br')
                        const valueHour = utcDateFromDate(new Date(value.time)).toLocaleTimeString('pt-br', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                        return (
                          <span>
                            <span>
                              {numberToBrl(value.value)}
                              &nbsp;
                            </span>
                            <span style={{ color: '#838080' }}>{`(${valueDate} ${valueHour})`}</span>
                          </span>
                        )
                      })()}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Summary>
        <ChartsContainer>
          {(() => {
            if (!loading) return null
            return (
              <RevisionContainer index={0}>
                <h1>
                  <BsCalendarRange />
                  &nbsp;
                  <TextBlock rows={1} color='#ccc' />
                </h1>
                <RevisionChartAndInfoContainer>
                  <div>
                    <h3>Média diária</h3>
                    <ChartContainer height='150px'>
                      <ResponsiveContainer>
                        <RectShape color='#ccc' />
                      </ResponsiveContainer>
                    </ChartContainer>
                    <h3>Valores horários</h3>
                    <ChartContainer height='300px'>
                      <ResponsiveContainer>
                        <RectShape color='#ccc' />
                      </ResponsiveContainer>
                    </ChartContainer>
                  </div>
                  <Summary columns={3}>
                    <div>
                      <h2>Média da semana</h2>
                      <div>
                        {subsystems.map(subsystem => (
                          <div key={subsystem.name}>
                            <span>{subsystem.name}</span>
                            <Summary.Dots />
                            <span>{'-'}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Summary>
                </RevisionChartAndInfoContainer>
              </RevisionContainer>
            )
          })()}
          {data?.revisionsData.map((revisionData, index) => {
            const { revisionAverage, revisionDailyAverages } = revisionData
            const pricesToPlot = revisionData.prices
            const yAxisTicks = PrismaChartUtils.getChartTicks({
              payload: pricesToPlot.flatMap(entry => [entry.southeast, entry.south, entry.northeast, entry.north]),
            })
            return (
              <RevisionContainer index={index} key={index}>
                <h1>
                  <BsCalendarRange />
                  &nbsp;
                  {(() => {
                    const revisionString = `RV${revisionData.revision}`
                    const monthString = Intl.DateTimeFormat('pt-br', { month: 'long' }).format(
                      utcDateFromDate(endOfWeek(new Date(revisionData.firstDateOfRevision), { weekStartsOn: 5 })),
                    )

                    return `${revisionString} ${capitalize(monthString)}`
                  })()}
                </h1>
                <RevisionChartAndInfoContainer>
                  <div>
                    <h3>Média diária</h3>
                    <ChartContainer height='150px'>
                      <ResponsiveContainer>
                        <BarChart data={revisionDailyAverages}>
                          <CartesianGrid strokeDasharray='3' />
                          <Tooltip content={<CustomTooltip isDaily />} />
                          <Tooltip />
                          <YAxis
                            interval={0}
                            ticks={dailyAverageTicks}
                            type='number'
                            domain={['dataMin', 'dataMax']}
                            tick={{ fontSize: '14px' }}
                            width={100}
                            tickFormatter={tick => Number(tick).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                          >
                            <Label
                              angle={-90}
                              value='PLD (R$/MWh)'
                              position='insideLeft'
                              style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                            />
                          </YAxis>
                          <XAxis tickLine={false} tick={false} />
                          {[...subsystems].reverse().map((subsystem, subsystemIndex) => (
                            <Bar
                              key={subsystem.name}
                              animationBegin={subsystemIndex * 150}
                              dataKey={subsystem.key}
                              fill={subsystem.color}
                              background={{ fill: '#eee' }}
                            />
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    </ChartContainer>
                    <h3>Valores horários</h3>
                    <ChartContainer height='300px'>
                      <ResponsiveContainer>
                        <LineChart data={pricesToPlot}>
                          <CartesianGrid strokeDasharray='3' />
                          <Legend content={customLegend()} />
                          <Tooltip content={<CustomTooltip isDaily={false} />} />
                          <YAxis
                            ticks={yAxisTicks}
                            type='number'
                            domain={['dataMin', 'dataMax']}
                            tick={{ fontSize: '0.875rem' }}
                            width={100}
                            tickFormatter={tick => Number(tick).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                          >
                            <Label
                              angle={-90}
                              value='PLD (R$/MWh)'
                              position='insideLeft'
                              style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                            />
                          </YAxis>
                          <XAxis
                            type='category'
                            dataKey='time'
                            interval={5}
                            tick={{ fontSize: '0.875rem' }}
                            tickLine={false}
                            tickFormatter={tick => {
                              const tickDate = utcDateFromDate(new Date(tick))
                              if (tickDate.getHours() !== 12) return ''
                              const formattedDate = tickDate.toLocaleDateString()
                              const formattedWeekday = tickDate.toLocaleDateString('pt-br', { weekday: 'short' })
                              return `${formattedDate} ${capitalize(formattedWeekday)}`
                            }}
                          />
                          {pricesToPlot.map((priceToPlot, pricesIndex) => {
                            if (utcDateFromDate(new Date(priceToPlot.time)).getHours() !== 0 || pricesIndex === 0) return null
                            return <ReferenceLine key={String(priceToPlot.time)} x={String(priceToPlot.time)} stroke='#333' />
                          })}
                          {[...subsystems].map(subsystem => (
                            <Line
                              key={subsystem.name}
                              name='-'
                              isAnimationActive={false}
                              dataKey={subsystem.key}
                              stroke='#ccc'
                              strokeWidth={3}
                              dot={false}
                            />
                          ))}
                          {[...subsystems].reverse().map((subsystem, pricesIndex) => (
                            <Line
                              key={subsystem.name}
                              animationBegin={pricesIndex * 150}
                              dataKey={subsystem.key}
                              stroke={subsystem.color}
                              strokeWidth={3}
                              dot={false}
                            />
                          ))}
                        </LineChart>
                      </ResponsiveContainer>
                    </ChartContainer>
                  </div>
                  <Summary columns={3}>
                    <div>
                      <h2>Média da semana</h2>
                      <div>
                        {subsystems.map(subsystem => (
                          <div key={subsystem.name}>
                            <span>{subsystem.name}</span>
                            <Summary.Dots />
                            <span>
                              {revisionAverage?.[subsystem.key].toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              }) ?? '-'}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Summary>
                </RevisionChartAndInfoContainer>
              </RevisionContainer>
            )
          })}
        </ChartsContainer>
      </Section>
    </Container>
  )
}

export default History
