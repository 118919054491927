import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import { Container, ControllerContainer } from './styles'
import { StudiesRenderer } from './StudiesRenderer'
import { ProspectiveStudy } from 'types'
import { StudyMetaData } from '..'
import { LoadingPlaceholder } from '../LoadingAlert'
import NoDataWarning from 'components/NoDataWarning'
import { Header } from '../Header'
import { Switch } from 'components/Input'
import { Redirect } from 'react-router-dom'

type StudyDTO = Pick<
  ProspectiveStudy,
  'sensibility' | 'subsystem' | 'cmo_average' | 'pld_average' | 'monthly_ena_mwmed' | 'ear_start' | 'monthly_ena_mlt'
>

export type MatrixStudyCase = {
  caseName: string
  caseData: {
    fixedSubsystems: {
      subsystem: string
      subsystemIndex: number
      sensibility: number
    }[]
    varyingSubsystems: {
      subsystem: string
      subsystemIndex: number
      sensibilities: number[]
    }[]
    baseCase: {
      southeast: StudyDTO
      south: StudyDTO
      northeast: StudyDTO
      north: StudyDTO
    }
    studies: {
      sensibility: string
      southeast: StudyDTO
      south: StudyDTO
      northeast: StudyDTO
      north: StudyDTO
    }[]
  }
}

export type CustomProspectiveStudy = {
  meta: StudyMetaData
  data: MatrixStudyCase[]
}

export function MatrixStudy({ hash }: { hash: string }) {
  const [limitedPrices, setLimitedPrices] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(
    ['custom-prospective-study', hash],
    () => api.get<CustomProspectiveStudy>(`custom-prospective-studies/${hash}`).then(({ data }) => data),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      refetchOnWindowFocus: false,
    },
  )

  return (
    <Container>
      {(() => {
        if (isLoading) return <LoadingPlaceholder />
        if (isError) return <Redirect to='/studies/curves-and-matrixes' />
        if (!response || response.data.length === 0) {
          return (
            <div style={{ paddingTop: '3rem' }}>
              <NoDataWarning text='Dados não encontrados.' />
            </div>
          )
        }
        return (
          <div style={{ display: 'grid', gap: '3rem' }}>
            <Header meta={response?.meta} />
            <ControllerContainer>
              <Switch text='Limites de PLD' onChange={value => setLimitedPrices(value)} />
            </ControllerContainer>
            <StudiesRenderer data={response.data} limitedPrices={limitedPrices} />
          </div>
        )
      })()}
    </Container>
  )
}
