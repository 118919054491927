import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { add } from 'date-fns'
import axios from 'axios'
import { Container } from './styles'
import { SectionTitle } from 'components/Section'
import { Chart } from './Chart'
import { Controller } from './Controller'
import { formatQueryDate } from 'utils'

export function Carga() {
  const [params, setParams] = useState({ start: add(new Date(), { days: -10 }), end: new Date(), code: 'SECO' })

  const {
    data: cargaVerificada,
    isLoading: isLoading1,
    isFetching: isFetching1,
  } = useQuery(
    ['carga-verificada', params],
    async () => {
      const response = await axios.get<Record<string, unknown>[]>('https://apicarga.ons.org.br/prd/cargaverificada', {
        params: { dat_inicio: formatQueryDate(params.start), dat_fim: formatQueryDate(params.end), cod_areacarga: params.code },
      })

      return response.data.map(entry => ({
        ...entry,
        val_cargaglobal: entry.val_cargaglobal === 0 ? null : entry.val_cargaglobal,
        val_cargaglobalcons: entry.val_cargaglobalcons === 0 ? null : entry.val_cargaglobalcons,
        val_cargaglobalsmmgd: entry.val_cargaglobal === 0 ? null : entry.val_cargaglobalsmmgd,
        val_cargasupervisionada: entry.val_cargaglobal === 0 ? null : entry.val_cargasupervisionada,
      }))
    },
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  const {
    data: cargaProgramada,
    isLoading: isLoading2,
    isFetching: isFetching2,
  } = useQuery(
    ['carga-programada', params],
    async () => {
      const response = await axios.get<Record<string, unknown>[]>('https://apicarga.ons.org.br/prd/cargaprogramada', {
        params: { dat_inicio: formatQueryDate(params.start), dat_fim: formatQueryDate(params.end), cod_areacarga: params.code },
      })

      return response.data
    },
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  return (
    <Container>
      <SectionTitle>Carga verificada e programada</SectionTitle>
      <Controller setParams={setParams} start={params.start} end={params.end} />
      <Chart
        isFetching={isFetching1 || isFetching2}
        isLoading={isLoading1 || isLoading2}
        cargaProgramada={cargaProgramada}
        cargaVerificada={cargaVerificada}
      />
    </Container>
  )
}
