import React, { useState, useEffect } from 'react'
import { format, startOfMonth, parse } from 'date-fns'
import { transparentize } from 'polished'
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Line, Bar, Legend, Tooltip, Label, Area } from 'recharts'
import { useTheme } from 'styled-components'
import { ChartContainer, CustomLegend } from 'components/Chart'
import { returnBRDateFormatFromUTCString, utcDateFromDate } from 'utils'
import api from 'services/api'
import { InputDate, PrismaSelect, InputChartType } from 'components/Input'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { subsystems } from 'utils/globals'
import { useDebounce } from 'usehooks-ts'
import CustomTooltip from './CustomTooltip'
import {
  Container,
  ControllerContainer,
  ChartAndMenuContainer,
  SummaryWrapper,
  SummaryContainer,
  SummaryTitleContainer,
  SummaryValueContainer,
  SideMenuContainer,
  SideMenuOptions,
  SideMenuFooter,
  SideMenuTitle,
  SideMenuGroupContainer,
  SideMenuImageContainer,
  ImportExportContainer,
} from './styles'

type Summary = {
  hydraulic: number
  thermal: number
  wind: number
  solar: number
  total: number
  exchange: number
  demand: number
}

type ApiResponse = {
  summary: {
    avg: Summary
    min: Summary
    max: Summary
  }
  data: {
    date: Date
    hydraulic: number
    thermal: number
    wind: number
    solar: number
    total: number
    exchange: number
    demand: number
    deficit: number
  }[]
}

const Daily: React.FC = () => {
  const theme = useTheme()

  // * Resize key
  const [key, setKey] = useState(1)
  const debouncedValue = useDebounce<number>(key, 1000)

  // * Controls
  const [dateLimits, setDateLimits] = useState<{ upper: Date; lower: Date }>()
  const [dateInterval, setDateInterval] = useState<{ start: Date; end: Date }>()
  const [subsystemId, setSubsystemId] = useState(5)
  const [loading, setLoading] = useState(true)
  const [chartType, setChartType] = useState<'bar' | 'line' | 'area'>('bar')
  const [axises, setAxises] = useState([
    {
      dataKey: 'hydraulic',
      name: 'Geração hidráulica',
      color: theme.safiraColors.blue[3],
      show: true,
      yAxisId: 1,
      stackId: 2,
    },
    {
      dataKey: 'thermal',
      name: 'Geração térmica',
      color: theme.safiraColors.orange[4],
      show: true,
      yAxisId: 1,
      stackId: 2,
    },
    { dataKey: 'wind', name: 'Geração Eólica', color: theme.safiraColors.green[4], show: true, yAxisId: 1, stackId: 2 },
    {
      dataKey: 'solar',
      name: 'Geração Solar',
      color: theme.safiraColors.yellow[3],
      show: true,
      yAxisId: 1,
      stackId: 2,
    },
    { dataKey: 'demand', name: 'Carga', color: '#333', show: true, isLine: true, yAxisId: 1, stackId: 2 },
  ])

  // * Data
  const [data, setData] = useState<ApiResponse['data']>([])
  const [summaryData, setSummaryData] = useState<ApiResponse['summary']>()

  useEffect(() => {
    api.get<{ date: Date }[]>('energetic-balance/dates', { params: { limit: 30 } }).then(response => {
      const upperDateLimit = new Date([...response.data].shift()?.date ?? new Date())
      const lowerDateLimit = new Date([...response.data].pop()?.date ?? startOfMonth(new Date()))
      setDateInterval({ start: lowerDateLimit, end: upperDateLimit })
      setDateLimits({ lower: lowerDateLimit, upper: upperDateLimit })
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    if (!dateInterval) return
    api
      .get<ApiResponse>('energetic-balance', {
        params: { start: dateInterval.start, end: dateInterval.end, subsystemId },
      })
      .then(response => {
        setSummaryData(response.data.summary)
        setData(response.data.data)
        setLoading(false)
      })
  }, [subsystemId, dateInterval])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setKey(key + 1)
    })
    return () => window.removeEventListener('resize', () => setKey(key + 1))
  }, [debouncedValue])

  const summaries = [
    {
      dataKey: 'demand',
      name: 'Carga média',
      color: transparentize(0.8, theme.safiraColors.blue[5]),
    },
    {
      dataKey: 'hydraulic',
      name: 'Geração hidráulica média',
      color: theme.safiraColors.blue[2],
    },
    {
      dataKey: 'thermal',
      name: 'Geração térmica média',
      color: theme.safiraColors.orange[3],
    },
    {
      dataKey: 'wind',
      name: 'Geração eólica média',
      color: theme.safiraColors.green[3],
    },
    {
      dataKey: 'solar',
      name: 'Geração solar média',
      color: theme.safiraColors.yellow[3],
    },
    {
      dataKey: 'total',
      name: 'Geração total média',
      color: transparentize(0.5, theme.safiraColors.blue[0]),
    },
  ] as const

  return (
    <Container>
      <SectionTitle>Balanço energético</SectionTitle>
      <SectionText>
        Dados de geração por fonte e carga realizados provenientes do Boletim Diário da Operação (BDO) do Operador Nacional do Sistema Elétrico (ONS),
        para o Sistema Interligado Nacional (SIN) e para os subsistemas.
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Início'
          max={format(dateLimits ? utcDateFromDate(dateLimits.upper) : new Date(), 'yyyy-MM-dd')}
          value={dateInterval && format(utcDateFromDate(dateInterval.start), 'yyyy-MM-dd')}
          disabled={loading}
          onChange={e => dateInterval && setDateInterval({ ...dateInterval, start: parse(e.target.value, 'yyyy-MM-dd', new Date()) })}
        />
        <InputDate
          label='Fim'
          max={format(dateLimits ? utcDateFromDate(dateLimits.upper) : new Date(), 'yyyy-MM-dd')}
          disabled={loading}
          value={dateInterval && format(utcDateFromDate(dateInterval.end), 'yyyy-MM-dd')}
          onChange={e => dateInterval && setDateInterval({ ...dateInterval, end: parse(e.target.value, 'yyyy-MM-dd', new Date()) })}
        />
        <PrismaSelect
          label='Submercado'
          disabled={loading}
          onChange={e => setSubsystemId(Number(e.target.value))}
          options={[
            { label: 'Sistema Interligado', value: '5' },
            ...subsystems.map((subsystem, index) => ({ label: subsystem.name, value: String(index + 1) })),
          ]}
        />
        <InputChartType defaultType='bar' onChange={newType => setChartType(newType)} />
      </ControllerContainer>
      <SummaryWrapper>
        {summaries.map(summary => (
          <SummaryContainer key={summary.name}>
            <SummaryTitleContainer color={summary.color}>
              <div />
              <span>{summary.name}</span>
            </SummaryTitleContainer>
            <SummaryValueContainer>
              {(() => {
                if (!summaryData?.avg?.[summary.dataKey]) return '-'
                const value = summaryData?.avg?.[summary.dataKey]
                return value.toLocaleString('pt-br', { maximumFractionDigits: value > 1000 ? 0 : 2 })
              })()}
              &nbsp;
              <span>MWmed</span>
            </SummaryValueContainer>
          </SummaryContainer>
        ))}
        <ImportExportContainer>
          {(() => {
            const difference = Math.round(summaryData?.avg?.total ?? 0) - Math.round(summaryData?.avg?.demand ?? 0)

            if (difference === 0) return null

            const fluxName = difference > 0 ? 'Exportação' : 'Importação'

            return (
              <>
                <span>{`${fluxName}: `}</span>
                <span>{`${Math.abs(difference).toLocaleString('pt-br', { maximumFractionDigits: 0 })} MWmed`}</span>
              </>
            )
          })()}
        </ImportExportContainer>
      </SummaryWrapper>
      <ChartAndMenuContainer>
        <ChartContainer height='600px'>
          <ResponsiveContainer key={debouncedValue}>
            <ComposedChart data={data}>
              <CartesianGrid strokeDasharray='3 3' vertical={false} />
              <Legend
                content={
                  <CustomLegend
                    legends={axises.map(axis => ({
                      text: axis.name,
                      color: axis.color,
                    }))}
                  />
                }
              />
              <Tooltip content={<CustomTooltip axises={axises} />} cursor={false} />
              <XAxis
                tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                type='category'
                dataKey='date'
                tickFormatter={tick => returnBRDateFormatFromUTCString(tick)}
                scale={chartType === 'bar' ? undefined : 'point'}
              />
              <YAxis
                yAxisId={1}
                tickCount={10}
                tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                tickFormatter={tick => new Intl.NumberFormat('pt-br').format(Number(tick))}
                width={90}
              >
                <Label
                  angle={-90}
                  value='Geração / Carga ( MWmed )'
                  fontSize={12}
                  position='insideLeft'
                  style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                />
              </YAxis>
              {axises.map((axis, index, array) => {
                if (axis.isLine) {
                  return (
                    <Line
                      hide={!axis.show}
                      stroke={axis.color}
                      dataKey={axis.dataKey}
                      fill={axis.color}
                      strokeDasharray='10 5'
                      strokeWidth={3}
                      dot={false}
                      yAxisId={axis.yAxisId}
                    />
                  )
                }

                if (chartType === 'bar') {
                  return (
                    <Bar
                      hide={!axis.show}
                      dataKey={axis.dataKey}
                      fill={axis.color}
                      maxBarSize={50}
                      stackId={axis.stackId}
                      stroke='transparent'
                      style={{ stroke: axis.color, strokeWidth: 1 }}
                      radius={index === array.filter(entry => !entry.isLine).length - 1 ? [5, 5, 0, 0] : undefined}
                      yAxisId={axis.yAxisId}
                    />
                  )
                }

                if (chartType === 'line') {
                  return (
                    <Line
                      hide={!axis.show}
                      stroke={axis.color}
                      dataKey={axis.dataKey}
                      fill={axis.color}
                      // strokeDasharray='10 5'
                      strokeWidth={3}
                      dot={false}
                      yAxisId={axis.yAxisId}
                    />
                  )
                }

                if (chartType === 'area') {
                  return (
                    <Area
                      dot={false}
                      hide={!axis.show}
                      dataKey={axis.dataKey}
                      fill={axis.color}
                      stroke={axis.color}
                      stackId={axis.stackId}
                      style={{ strokeWidth: 0 }}
                      yAxisId={axis.yAxisId}
                    />
                  )
                }

                return null
              })}
            </ComposedChart>
          </ResponsiveContainer>
        </ChartContainer>
        <SideMenuContainer>
          <SideMenuContainer>
            <SideMenuGroupContainer>
              <SideMenuTitle>Fontes</SideMenuTitle>
              <SideMenuOptions>
                {axises.map((axis, _index, array) => (
                  <li key={axis.name}>
                    <input
                      checked={axis.show}
                      type='checkbox'
                      id={`checkbox-${axis.dataKey}`}
                      value={axis.dataKey}
                      onChange={({ target }) => {
                        const barIndex = array.findIndex(entry => entry.dataKey === axis.dataKey)
                        if (barIndex === -1) return
                        const newBarsArray = Array.from(array)
                        newBarsArray[barIndex].show = target.checked
                        setAxises(newBarsArray)
                      }}
                    />
                    <label htmlFor={`checkbox-${axis.dataKey}`} style={{ color: axis.show ? '' : '#ccc' }}>
                      {axis.name}
                    </label>
                  </li>
                ))}
              </SideMenuOptions>
              <SideMenuFooter>
                <div>
                  <span>{axises.filter(bar => bar.show).length}</span>
                  <span>
                    &nbsp; de &nbsp;
                    {axises.length}
                  </span>
                </div>
                <div>
                  <button type='button' onClick={() => setAxises(axises.map(axis => ({ ...axis, show: true })))}>
                    Todos
                  </button>
                  <button type='button' onClick={() => setAxises(axises.map(axis => ({ ...axis, show: false })))}>
                    Nenhum
                  </button>
                </div>
              </SideMenuFooter>
            </SideMenuGroupContainer>
            <SideMenuImageContainer>
              <img
                src={subsystems[subsystemId - 1]?.mapUrl ?? `${process.env.REACT_APP_STATIC_ADDRESS}/images/subsystems/mapa_brasil_colorido.png`}
                alt='subsystem'
              />
            </SideMenuImageContainer>
          </SideMenuContainer>
        </SideMenuContainer>
      </ChartAndMenuContainer>
    </Container>
  )
}

export default Daily
