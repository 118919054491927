import React, { ChangeEventHandler } from 'react'

import { Container, Label, InputElement } from './styles'

interface Select {
  label?: string
  onChange?: ChangeEventHandler<HTMLSelectElement>
  options?: {
    value: string
    label: string
  }[]
}

const Select: React.FC<Select> = ({ options, label, onChange }) => {
  return (
    <Container>
      <Label>{label ?? 'Selecione uma opção'}</Label>
      <InputElement onChange={onChange}>
        {options?.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </InputElement>
    </Container>
  )
}

export default Select
