import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Daily from './Daily'
import Pentad from './Pentad'
import Weekly from './Weekly'

export const EcmwfDescription = ({ initialText }: { initialText: string }) => (
  <>
    <p>{initialText}</p>
    <p style={{ fontStyle: 'italic' }}>
      <a href='https://apps.ecmwf.int/datasets/licences/general/' target='_blank' rel='noreferrer'>
        Creative Commons CC-4.0-BY licence and the ECMWF Terms of Use:
      </a>
    </p>
    <p style={{ fontStyle: 'italic' }}>The following wording should be attached to the use of this ECMWF dataset:</p>
    <ol
      style={{
        color: 'black',
        textAlign: 'start',
        fontWeight: 'normal',
        listStylePosition: 'inside',
        fontStyle: 'italic',
      }}
    >
      <li>Copyright statement: Copyright {'"© [year] European Centre for Medium-Range Weather Forecasts (ECMWF)"'}.</li>
      <li>
        Source{' '}
        <a href='www.ecmwf.int' target='_blank' rel='noreferrer'>
          www.ecmwf.int
        </a>
      </li>
      <li>
        Licence Statement: This data is published under a Creative Commons Attribution 4.0 International (CC BY 4.0).
        <a href='https://creativecommons.org/licenses/by/4.0/' target='_blank' rel='noreferrer'>
          https://creativecommons.org/licenses/by/4.0/
        </a>
      </li>
      <li>
        Disclaimer: ECMWF does not accept any liability whatsoever for any error or omission in the data, their availability, or for any loss or
        damage arising from their use.
      </li>
      <li>Where applicable, an indication if the material has been modified and an indication of previous modifications.</li>
    </ol>
    <p style={{ fontStyle: 'italic' }}>The following wording shall be attached to services created with this ECMWF dataset:</p>
    <ol
      style={{
        color: 'black',
        textAlign: 'start',
        fontWeight: 'normal',
        listStylePosition: 'inside',
        fontStyle: 'italic',
      }}
    >
      <li>
        Copyright statement: Copyright
        {'"This service is based on data and products of the European Centre for Medium-Range Weather Forecasts (ECMWF)"'}.
      </li>
      <li>
        Source{' '}
        <a href='www.ecmwf.int' target='_blank' rel='noreferrer'>
          www.ecmwf.int
        </a>
      </li>
      <li>
        Licence Statement: This ECMWF data is published under a Creative Commons Attribution 4.0 International (CC BY 4.0).
        <a href='https://creativecommons.org/licenses/by/4.0/' target='_blank' rel='noreferrer'>
          https://creativecommons.org/licenses/by/4.0/
        </a>
      </li>
      <li>
        Disclaimer: ECMWF does not accept any liability whatsoever for any error or omission in the data, their availability, or for any loss or
        damage arising from their use.
      </li>
      <li>Where applicable, an indication if the material has been modified and an indication of previous modifications</li>
    </ol>
  </>
)

export const ForecastWeather = () => (
  <Switch>
    <Route path='/meteorology/weather-forecast/daily' component={Daily} />
    <Route path='/meteorology/weather-forecast/pentad' component={Pentad} />
    <Route path='/meteorology/weather-forecast/weekly' component={Weekly} />
  </Switch>
)
