import { transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px;
  > span {
    margin-top: 1rem;
    text-align: center;
    display: block;
    font-size: 0.875rem;
    color: ${props => transparentize(0.5, props.theme.colors.blue700)};
    font-weight: bold;
  }
`

export const LabelsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  span {
    font-size: 12px;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);
  }
`

export const ColorsContainer = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
`

export const Color = styled.div`
  filter: opacity(0.7);
  height: 10px;
  width: 100%;
  :first-child {
    border-radius: 15px 0px 0 15px;
  }
  :last-child {
    border-radius: 0 15px 15px 0;
  }
`
