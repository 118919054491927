import styled, { keyframes } from 'styled-components'

const eppear = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

interface Container {
  hasNavigation?: boolean
  backgroundURL?: string
}

export const Container = styled.div<Container>`
  padding: 2rem 0;
`

export const TitleContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`

export const PageTitle = styled.div`
  font-size: 3.5rem;
  color: ${props => props.theme.colors.blue700};
  animation: ${eppear} 500ms 1;
`
