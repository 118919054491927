import { CustomLegend } from 'components/Chart'
import { transparentize } from 'polished'
import React, { useState } from 'react'
import { CartesianGrid, Cell, Label, ResponsiveContainer, ReferenceLine, Scatter, ScatterChart, Tooltip, XAxis, YAxis, Legend } from 'recharts'
import { useTheme } from 'styled-components'
import { subsystems } from 'utils/globals'
import { CustomProspectiveStudy } from '../..'
import { CustomTooltip } from './CustomTooltip'
import { Container, ChartContainer, ThresholdsContainer } from './styles'

type ChartGroupProps = {
  study: CustomProspectiveStudy['meta']['studies'][0]
  data: CustomProspectiveStudy['data'][0]
  arePricesLimited: boolean
  selectedRev: 'rv0' | 'rv1' | 'rv2' | 'rv3'
}

export function ChartGroup({ study, data, arePricesLimited, selectedRev }: ChartGroupProps) {
  const [mouseOveredIndex, setMouseOveredIndex] = useState<number>()
  const [clickedIndex, setClickedIndex] = useState<number>()
  const theme = useTheme()

  function getCellOpacity(index: number) {
    if (typeof clickedIndex === 'number' && index === clickedIndex) return 1
    if (typeof clickedIndex === 'number' && index !== clickedIndex) return 0.1

    if (typeof mouseOveredIndex === 'number' && index === mouseOveredIndex) return 1
    if (typeof mouseOveredIndex === 'number' && index !== mouseOveredIndex) return 0.5

    return 1
  }

  return (
    <Container>
      {subsystems.map(subsystem => {
        const { ecmwfMainMembers } = data

        const subsystemData = data[subsystem.key].studies[selectedRev]

        return (
          <React.Fragment key={`${study.hash} - ${subsystem.key}`}>
            <ChartContainer>
              <ResponsiveContainer width='100%'>
                <ScatterChart syncId={study.hash} data={subsystemData} margin={{ top: 30 }}>
                  <CartesianGrid strokeDasharray='10 5' stroke='#dfdfdf' />
                  {ecmwfMainMembers && (
                    <Legend
                      wrapperStyle={{
                        marginTop: '-25px',
                        left: '50px',
                      }}
                      content={
                        <CustomLegend
                          legends={[
                            { text: 'Base', color: theme.colors.cyan, isLine: true },
                            { text: 'Controle', color: theme.colors.green, isLine: true },
                            { text: 'Média', color: theme.colors.orange, isLine: true },
                          ]}
                        />
                      }
                    />
                  )}
                  <ReferenceLine strokeWidth={2} stroke='#ccc' strokeDasharray='5 3' y={1}>
                    <Label value='100% ENA' fontSize='0.75rem' position='insideRight' stroke='#ccc' />
                  </ReferenceLine>
                  <Tooltip content={<CustomTooltip arePricesLimited={arePricesLimited} index={mouseOveredIndex && mouseOveredIndex + 1} />} />
                  <YAxis
                    stroke='#ccc'
                    tickCount={8}
                    tick={{ fontSize: '12px' }}
                    dataKey='monthly_ena_mlt'
                    interval={0}
                    tickFormatter={tick => Intl.NumberFormat('pt-br', { style: 'percent' }).format(Number(tick))}
                  >
                    <Label angle={-90} value={'ENA (% MLT)'} position='insideLeft' style={{ fontSize: '12px', fill: '#ccc', textAnchor: 'middle' }} />
                  </YAxis>
                  <XAxis
                    stroke='#ccc'
                    dataKey={arePricesLimited ? 'pld_average' : 'cmo_average'}
                    tick={{ fontSize: '12px' }}
                    tickCount={10}
                    padding={{ left: 30, right: 30 }}
                    height={70}
                    type='number'
                    tickFormatter={tick => Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(tick)}
                  >
                    <Label value={'PLD (R$/MWh)'} style={{ fontSize: '12px', fill: '#ccc', textAnchor: 'middle' }} />
                  </XAxis>
                  <Scatter key={`${study.hash} - ${subsystem.key}`} fill='#fff' name={subsystem.name} cursor='pointer'>
                    {subsystemData.map((...[, index]) => (
                      <Cell
                        key={`cell-${study.hash}-${subsystem.key}-${index}`}
                        onMouseEnter={() => setMouseOveredIndex(index)}
                        onMouseLeave={() => setMouseOveredIndex(undefined)}
                        stroke={subsystem.color}
                        strokeWidth={2}
                        fill={transparentize(0.8, subsystem.color)}
                        onClick={() => {
                          if (typeof clickedIndex === 'number' && index === clickedIndex) return setClickedIndex(undefined)
                          setClickedIndex(index)
                        }}
                        opacity={getCellOpacity(index)}
                      />
                    ))}
                  </Scatter>
                  {[
                    ecmwfMainMembers && subsystemData[ecmwfMainMembers.base] && (
                      <ReferenceLine
                        strokeWidth={2}
                        stroke={theme.colors.blue400}
                        strokeDasharray='5 3'
                        x={subsystemData[ecmwfMainMembers.base][arePricesLimited ? 'pld_average' : 'cmo_average']}
                      >
                        <Label value='Base' fontSize='0.75rem' position='top' stroke={theme.colors.blue400} />
                      </ReferenceLine>
                    ),
                    ecmwfMainMembers && subsystemData[ecmwfMainMembers.control] && (
                      <ReferenceLine
                        strokeWidth={2}
                        stroke={theme.colors.green}
                        strokeDasharray='5 3'
                        x={subsystemData[ecmwfMainMembers.control][arePricesLimited ? 'pld_average' : 'cmo_average']}
                      >
                        <Label offset={20} value='Controle' fontSize='0.75rem' position='top' stroke={theme.colors.green} />
                      </ReferenceLine>
                    ),
                    ecmwfMainMembers && subsystemData[ecmwfMainMembers.average] && (
                      <ReferenceLine
                        strokeWidth={2}
                        stroke={theme.colors.orange}
                        strokeDasharray='5 3'
                        x={subsystemData[ecmwfMainMembers.average][arePricesLimited ? 'pld_average' : 'cmo_average']}
                      >
                        <Label value='Média' fontSize='0.75rem' position='top' stroke={theme.colors.orange} />
                      </ReferenceLine>
                    ),
                  ].filter(Boolean)}
                </ScatterChart>
              </ResponsiveContainer>
            </ChartContainer>
            <ThresholdsContainer>
              <table>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <span style={{ color: subsystem.color }}>{subsystem.name}</span>
                    </th>
                  </tr>
                  <tr>
                    <th>Intervalos</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {data[subsystem.key].thresholds[selectedRev].map(threshold => (
                    <tr key={`${threshold.threshold.bottom} - ${threshold.threshold.top}`}>
                      <td>
                        <span>{Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(threshold.threshold.bottom)}</span>
                        <span> - </span>
                        <span>{Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(threshold.threshold.top)}</span>
                      </td>
                      <td>{threshold.cmo}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ThresholdsContainer>
          </React.Fragment>
        )
      })}
    </Container>
  )
}
