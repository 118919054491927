import React, { useMemo, useState } from 'react'
import { CartesianGrid, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, Label, Legend, ComposedChart, Bar } from 'recharts'
import { AiOutlineTable } from 'react-icons/ai'
import { capitalize } from 'lodash'
import { subsystems } from 'utils/globals'
import { utcDateFromDate } from 'utils'
import { Switch, PrismaSelect } from 'components/Input'
import { CustomProspectiveStudy } from '..'
import { CustomTooltip } from './CustomTooltip'
import { CustomLegend } from 'components/Chart'
import { Container, ChartContainer, ControllerContainer, ChartControllers } from './styles'
import { useTheme } from 'styled-components'
import randomColor from 'randomcolor'
import { Title } from '../styles'
import { isEqual } from 'date-fns'

const fixedColors = [
  '#83d123',
  '#600000',
  '#e56910',
  '#008000',
  '#ff1a75',
  '#9933ff',
  '#4dffb8',
  '#ff1ac6',
  '#ffff00',
  '#f6ad79',
  '#009900',
  '#aa0000',
  '#cc00cc',
  '#666699',
  '#de3400',
  '#1afffb',
  '#ff00c8',
  '#032677',
  '#ff0000',
  '#1f961d',
]

type CustomizedAxisTickProps = {
  x: number
  y: number
  index: number
  stroke: string
  payload: { coordinate: number; index: number; offset: number; value: string }
}

export function Chart({ response, arePricesLimited }: { response?: CustomProspectiveStudy; arePricesLimited: boolean }) {
  if (!response) return null
  const theme = useTheme()
  const [selectedSubsystem, setSelectedSubsystem] = useState(0)
  const [highlightedLine, setHighlighted] = useState<string>()
  const [hiddenLines, setHiddenLines] = useState<string[]>([])
  const [showStorage, setShowStorage] = useState(true)
  const [base0, setBase0] = useState(true)
  const [showTooltip, setShowTooltip] = useState(true)
  const colors = useMemo(
    () =>
      ['#333', theme.safiraColors.red[0], theme.safiraColors.blue[0]]
        .concat(fixedColors)
        .concat(response?.meta.studies.map(() => randomColor({ luminosity: 'bright' }))),
    [''],
  )

  function handleLineToggle(hash?: string) {
    if (!hash) return
    if (!hiddenLines.includes(hash)) return setHiddenLines(state => state.concat(hash))
    setHiddenLines(state => state.filter(entry => entry !== hash))
  }

  return (
    <Container>
      <Title>Preço de liquidação das diferenças (PLD)</Title>
      <ControllerContainer>
        <img src={subsystems[selectedSubsystem].mapUrl} alt='' />
        <div>
          <PrismaSelect
            label='Submercado'
            value={String(selectedSubsystem)}
            onChange={ev => setSelectedSubsystem(Number(ev.target.value))}
            options={subsystems.map((subsystem, index) => ({ label: subsystem.name, value: String(index) }))}
          />
          <Switch text='Base 0' value={base0} onChange={value => setBase0(value)} />
          <Switch text='Reservatório' value={showStorage} onChange={value => setShowStorage(value)} />
        </div>
      </ControllerContainer>
      <ChartControllers active={showTooltip}>
        <button type='button' title={showTooltip ? 'Esconder tooltip' : 'Mostrar tooltip'} onClick={() => setShowTooltip(!showTooltip)}>
          <AiOutlineTable />
        </button>
      </ChartControllers>
      <ChartContainer>
        <ResponsiveContainer>
          <ComposedChart
            data={response.data.data.map(entry => ({
              ...entry,
              forwardPrice:
                response.data.forwardCurve.data.find(forward => isEqual(new Date(forward.product), new Date(entry.date)))?.convencional ?? 200,
            }))}
            height={600}
            barGap={1}
          >
            <CartesianGrid strokeDasharray='10 5' stroke='#dfdfdf' />
            <Tooltip
              cursor={{ stroke: '#dfdfdf', strokeWidth: 3 }}
              content={
                showTooltip ? (
                  <CustomTooltip hiddenHashes={hiddenLines} colors={colors} studies={response.meta.studies} withLimits={arePricesLimited} />
                ) : (
                  <></>
                )
              }
            />
            <Legend
              content={
                <CustomLegend
                  wrapperStyle={{ marginTop: '2rem' }}
                  onLegendMouseOver={legend => setHighlighted(legend.meta?.hash)}
                  onLegendMouseLeave={() => setHighlighted('')}
                  onLegendClick={legend => handleLineToggle(legend.meta?.hash)}
                  legends={[
                    {
                      text: `Curva forward - ${Intl.DateTimeFormat('pt-br', {}).format(utcDateFromDate(new Date(response.data.forwardCurve.date)))}`,
                      isLine: true,
                      color: theme.colors.blue400,
                    },
                    ...response.meta.studies.map((study, index) => ({
                      text: study.name,
                      color: colors[index],
                      isDashed: hiddenLines.includes(study.hash),
                      meta: { hash: study.hash },
                    })),
                  ]}
                />
              }
            />
            <YAxis
              stroke='#ccc'
              tickCount={8}
              yAxisId={1}
              domain={[base0 ? 0 : 'auto', 'auto']}
              tick={{ fontSize: '12px', fill: 'rgba(0, 0, 0, 0.87)' }}
              width={100}
              tickFormatter={tick => Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(tick)}
            >
              <Label angle={-90} value={'PLD (R$/MWh)'} position='insideLeft' style={{ fontSize: '12px', fill: '#ccc', textAnchor: 'middle' }} />
            </YAxis>
            {showStorage && (
              <YAxis
                orientation='right'
                yAxisId={2}
                interval={0}
                stroke='#ccc'
                tickCount={11}
                domain={[0, 100]}
                tick={{ fontSize: '12px', fill: 'rgba(0, 0, 0, 0.87)' }}
                width={70}
                tickFormatter={tick => Intl.NumberFormat('pt-br', { style: 'percent' }).format(tick / 100)}
              >
                <Label angle={-90} value='EAR%' position='insideRight' style={{ fontSize: '12px', fill: '#ccc', textAnchor: 'middle' }} />
              </YAxis>
            )}
            <XAxis
              stroke='#ccc'
              interval={0}
              padding={{ left: 30, right: 30 }}
              tick={(props: CustomizedAxisTickProps) => {
                const { x, y, index } = props

                const payload = response?.data.data[index]

                if (!payload) return null

                const { revision, date } = payload

                const formattedDateMonth = new Intl.DateTimeFormat('pt-br', { month: 'short' })
                  .format(utcDateFromDate(new Date(date)))
                  .replace('.', '')

                const formattedDateYear = new Intl.DateTimeFormat('pt-br', { year: '2-digit' }).format(utcDateFromDate(new Date(date)))

                const formattedRevision = `Rev ${revision}`

                return (
                  <g transform={`translate(${x - 5},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor='end' fill='rgba(0, 0, 0, 0.87)' fontSize={12} transform='rotate(-15)'>
                      {`${capitalize(formattedDateMonth)}/${formattedDateYear} ${formattedRevision}`}
                    </text>
                  </g>
                )
              }}
            />
            {showStorage &&
              response.meta.studies.map((study, index) => {
                if (hiddenLines.includes(study.hash)) return null

                return (
                  <Bar
                    background={{ fill: '#eee' }}
                    opacity={0.2}
                    radius={[5, 5, 0, 0]}
                    key={`bar - ${study.hash} - ${index}`}
                    fill={colors[index]}
                    yAxisId={2}
                    dataKey={`studies.${study.hash}.${subsystems[selectedSubsystem].key}.ear_start`}
                  />
                )
              })}
            {response.meta.studies.map((study, index) => {
              if (hiddenLines.includes(study.hash)) return null

              let lineOpacity = 1
              let lineWidth = 5
              if (highlightedLine && !(study.hash === highlightedLine)) {
                lineOpacity = 0.2
                lineWidth = 3
              }

              return (
                <Line
                  yAxisId={1}
                  type='monotone'
                  key={`line - ${study.hash} - ${index}`}
                  stroke={colors[index]}
                  fill={colors[index]}
                  opacity={lineOpacity}
                  strokeWidth={lineWidth}
                  dataKey={`studies.${study.hash}.${subsystems[selectedSubsystem].key}.${arePricesLimited ? 'pld_average' : 'cmo_average'}`}
                />
              )
            })}
            <Line yAxisId={1} opacity={0.5} dataKey='forwardPrice' stroke={theme.colors.blue400} strokeWidth={5} strokeDasharray='10 5' dot={false} />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}
