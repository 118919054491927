import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AAO from './AAO'
import MJO from './MJO'
import ENSO from './ENSO'
import IOD from './IOD'
import PDO from './PDO'
import AMO from './AMO'

export const Indices = () => (
  <Switch>
    <Route path='/meteorology/indices/antarctic-oscillation' component={AAO} />
    <Route path='/meteorology/indices/madden-julian-oscillation' component={MJO} />
    <Route path='/meteorology/indices/el-nino–southern-oscillation' component={ENSO} />
    <Route path='/meteorology/indices/indian-ocean-dipole' component={IOD} />
    <Route path='/meteorology/indices/pacific-decadal-oscillation' component={PDO} />
    <Route path='/meteorology/indices/atlantic-multidecadal-oscillation' component={AMO} />
  </Switch>
)
