import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import Banner from 'components/Banner'
import Navigation from 'components/Navigation'

import { ProspectiveStudies } from './ProspectiveStudies'
import { CustomProspectiveStudies } from './CustomProspectiveStudies'
import { CustomProspectiveStudiesShort } from './CustomProspectiveStudiesShort'

import { Container, Divider } from './styles'
import { CompanyContext } from 'CompanyProvider'

export const Studies = () => {
  const companyLevel = useContext(CompanyContext)

  return (
    <>
      <Banner title='Estudos' />
      <Container>
        <Navigation
          options={[
            {
              label: 'Projeções de curtíssimo prazo',
              value: '/estudos/curtissimo-prazo',
              base: 'curtissimo-prazo',
              show: companyLevel >= 2,
            },
            {
              label: 'Projeções de curto prazo',
              value: '/estudos/curto-prazo',
              base: 'curto-prazo',
              show: companyLevel >= 2,
            },
            {
              label: 'Projeções de médio prazo',
              value: '/estudos/medio-prazo',
              base: 'medio-prazo',
              show: companyLevel >= 2,
            },
          ]}
        />
        <Divider />
        <Switch>
          {[
            {
              show: companyLevel >= 2,
              path: '/estudos/curtissimo-prazo',
              component: CustomProspectiveStudiesShort,
            },
            {
              show: companyLevel >= 2,
              path: '/estudos/curto-prazo',
              component: ProspectiveStudies,
            },
            {
              show: companyLevel >= 2,
              path: '/estudos/medio-prazo',
              component: CustomProspectiveStudies,
            },
          ]
            .filter(entry => entry.show)
            .map(entry => (
              <Route key={entry.path} path={entry.path} component={entry.component} />
            ))}
        </Switch>
      </Container>
    </>
  )
}
