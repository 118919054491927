import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { transparentize } from 'polished'

export const Container = styled.nav`
  height: 5rem;
  background: ${props => props.theme.colors.blue700};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
`

const logoContainerAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const LogoContainer = styled.div`
  padding-left: 2rem;
  display: flex;
  align-items: center;
  animation: ${logoContainerAnimation} 1s 1;
  a img {
    width: 120px;
    transition: 250ms;
  }
`

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ul {
    list-style: none;
    display: flex;
    gap: 20px;
    li {
      position: relative;
    }
  }
`

type MenuItemProps = {
  active: boolean
  position: number
}

const menuItemAnimation = keyframes`
  from {
    left: -20px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
`

export const MenuItem = styled(Link)<MenuItemProps>`
  color: ${props => (props.active ? props.theme.colors.cyan : props.theme.colors.black300)};
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.7rem;
  border-radius: 0.25rem;
  transition: color 250ms;
  position: relative;
  text-shadow: 0 0 1px ${props => (props.active ? props.theme.colors.cyan : 'transparent')};
  animation: ${menuItemAnimation} 250ms 1;
  animation-delay: ${props => props.position * 100}ms;
  animation-fill-mode: backwards;
  :hover {
    color: ${props => (props.active ? props.theme.colors.cyan : props.theme.colors.black100)};
    text-shadow: 0px 0px 1px black;
  }
`

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  gap: 10px;
  padding: 1rem 3rem 1rem 0;
  span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
  }
  button {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 14px;
      cursor: pointer;
      color: #fff;
      transition: 200ms;
      opacity: 0.5;
      :hover {
        opacity: 1;
      }
    }
  }
`

export const DynamicMapLink = styled.a`
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.7rem;
  animation: ${menuItemAnimation} 250ms 1;
  animation-delay: 800ms;
  animation-fill-mode: backwards;
  border-radius: 5px;
  color: ${props => props.theme.colors.black300};
  background: ${() => transparentize(0.85, '#bee6ff')};
  :hover {
    color: ${props => props.theme.colors.blue700};
    background: #bee6ff;
    transition: 250ms;
  }
`
