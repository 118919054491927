import styled from 'styled-components'

export const Container = styled.div`
  table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
    th {
      border: 1px solid black;
      padding: 0.7rem;
    }
    td {
      border: 1px solid #ccc;
      padding: 0.5rem;
    }
    tbody tr:nth-child(2) td {
      border-top: none;
    }
  }
`
