import React from 'react'
import { TooltipProps } from 'recharts'
import { utcDateFromDate } from 'utils'
import {
  Container,
  TooltipGrid,
  TooltipTitleContainer,
  TooltipInfoContainer,
  TooltipInfoValue,
  TooltipContainer,
  ToolTipDivider,
} from 'components/CustomTooltip'

const tooltipKeys = [
  { key: 'convencional', label: 'Convencional' },
  { key: 'incentivated50', label: 'Incentivada 50%' },
  { key: 'incentivated100', label: 'Incentivada 100%' },
  { key: 'cq5', label: 'CQ5' },
]

const CustomTooltip: React.FC<TooltipProps & { keys: { key: string; label: string }[]; type: string }> = ({ payload, active, type }) => {
  if (payload && active) {
    return (
      <Container>
        <TooltipTitleContainer>
          {payload[0].payload.register && utcDateFromDate(new Date(payload[0].payload.register)).toLocaleDateString()}
        </TooltipTitleContainer>
        <TooltipContainer>
          <TooltipGrid columns={3}>
            <TooltipInfoContainer>
              {tooltipKeys.map(key => (
                <TooltipInfoValue key={key.key}>{key.label}</TooltipInfoValue>
              ))}
            </TooltipInfoContainer>
            <ToolTipDivider />
            <TooltipInfoContainer>
              {tooltipKeys.map(key => {
                const value = payload[0].payload[`${key.key}${type}`]
                if (!value) return <TooltipInfoValue>-</TooltipInfoValue>
                const formattedValue = Number(value).toLocaleString('default', { style: 'currency', currency: 'BRL' })
                return <TooltipInfoValue key={key.key}>{formattedValue ?? '-'}</TooltipInfoValue>
              })}
            </TooltipInfoContainer>
          </TooltipGrid>
        </TooltipContainer>
      </Container>
    )
  }
  return null
}

export default CustomTooltip
