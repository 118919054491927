import React, { useState } from 'react'
import { add, endOfWeek, format, isWithinInterval, startOfWeek } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { InputDate } from 'components/Input'
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import MapGallery from 'components/MapGallery'
import { useTheme } from 'styled-components'
import { Container, ControllerContainer } from './styles'
import { EcmwfDescription } from '..'

const Daily: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const theme = useTheme()
  const models = ['ecmwf', 'ec00', 'gfs', 'gefs']
  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

  const returnLabelColor = (mapDate: Date): string => {
    const currentDayWithOneWeekAdded = add(date, { weeks: 1 })
    const currentDayWithTwoWeeksAdded = add(date, { weeks: 2 })
    const firstDayOfCurrentWeek = startOfWeek(date, { weekStartsOn: 6 })
    const lastDayOfCurrentWeek = endOfWeek(date, { weekStartsOn: 6 })
    const firstDayOfNextWeek = startOfWeek(currentDayWithOneWeekAdded, { weekStartsOn: 6 })
    const lastDayOfNextWeek = endOfWeek(currentDayWithOneWeekAdded, { weekStartsOn: 6 })
    const firstDayOfNextNextWeek = startOfWeek(currentDayWithTwoWeeksAdded, { weekStartsOn: 6 })
    const lastDayOfNextNextWeek = endOfWeek(currentDayWithTwoWeeksAdded, { weekStartsOn: 6 })

    if (isWithinInterval(mapDate, { start: firstDayOfCurrentWeek, end: lastDayOfCurrentWeek })) return theme.safiraColors.blue[4]
    if (isWithinInterval(mapDate, { start: firstDayOfNextWeek, end: lastDayOfNextWeek })) return theme.safiraColors.green[4]
    if (isWithinInterval(mapDate, { start: firstDayOfNextNextWeek, end: lastDayOfNextNextWeek })) return theme.safiraColors.orange[4]

    return 'red'
  }

  const images = days.map(day => {
    const targetDate = add(date, { days: day })
    const formattedDate = format(date, 'yyyyMMdd')
    const formattedTargetDate = format(targetDate, 'yyyyMMdd')
    const weekDay = targetDate.toLocaleString('pt-BR', { weekday: 'long' })
    return {
      title: `Previsão para ${weekDay}, ${targetDate.toLocaleDateString()}`,
      color: returnLabelColor(targetDate),

      ecmwf: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-ecmwf-backup/ECMWF_r${formattedDate}_00Z_${
        day < 10 ? `0${day}` : day
      }_diario_${formattedTargetDate}.png`,

      gefs: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-gefs-backup/GEFS_r${formattedDate}_00Z_${
        day < 10 ? `0${day}` : day
      }_diario_${formattedTargetDate}.png`,

      gfs: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-gfs-backup/GFS_r${formattedDate}_00Z_${
        day < 10 ? `0${day}` : day
      }_diario_${formattedTargetDate}.png`,

      ec00: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-ec00-backup/EC00_r${formattedDate}_00Z_${
        day < 10 ? `0${day}` : day
      }_diario_${formattedTargetDate}.png`,
    }
  })

  const descriptions = {
    ecmwf: (
      <EcmwfDescription initialText='Mapa de chuva diária prevista pelo modelo European Centre for Medium-Range Weather Forecasts (ECMWF), rodado e disponibilizado pela instituição de mesmo nome. O ECMWF é um modelo de previsão numérica por conjunto (ensemble) global e possui 0.2º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão média do conjunto.' />
    ),
    ec00: (
      <EcmwfDescription initialText='Mapa de chuva diária prevista pelo modelo European Centre for Medium-Range Weather Forecasts (ECMWF), rodado e disponibilizado pela instituição de mesmo nome. O ECMWF é um modelo de previsão numérica por conjunto (ensemble) global e possui 0.2º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão do membro controle.' />
    ),
    gfs: (
      <p>
        Mapa de chuva diária prevista pelo modelo Global Forecast System (GFS), rodado e disponibilizado pelo National Centers for Environmental
        Prediction (NCEP) da National Oceanic and Atmospheric Administration (NOAA), dos Estados Unidos da América. O GFS é um modelo de previsão
        numérica determinístico possui 0.25º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os
        primeiros 14 dias de previsão.
      </p>
    ),
    gefs: (
      <p>
        Mapa de chuva diária prevista pelo modelo Global Ensemble Forecast System (GEFS), rodado e disponibilizado pelo National Centers for
        Environmental Prediction (NCEP) da National Oceanic and Atmospheric Administration (NOAA), dos Estados Unidos da América. O GEFS é um modelo
        de previsão numérica por conjunto (ensemble) global e possui 0.5º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo
        estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão média do conjunto.
      </p>
    ),
  }

  const aliases = {
    ecmwf: 'ECMWF - ENS',
    ec00: 'ECMWF - Controle',
    gfs: 'GFS',
    gefs: 'GEFS - ENS',
  }

  return (
    <Container>
      <SectionTitle>Previsão diária</SectionTitle>
      <SectionText>
        <span>
          Mapas de previsão diária de chuva dos modelos de previsão de tempo ECMWF (média do conjunto e controle), GEFS (média do conjunto) e GFS.
          Utilize as teclas
        </span>
        <BsArrowLeftShort style={{ marginLeft: '5px' }} />
        <BsArrowRightShort style={{ marginRight: '5px' }} />
        <span>para alternar a data da previsão.</span>
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          value={format(date, 'yyyy-MM-dd')}
          max={format(new Date(), 'yyyy-MM-dd')}
          onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
        />
      </ControllerContainer>
      <MapGallery images={images} models={models} descriptions={descriptions} aliases={aliases} scale='forecast' />
    </Container>
  )
}

export default Daily
