import React from 'react'
import { Container, TypeSelectorContainer } from './styles'
import { useQuery } from 'react-query'
import { client } from 'services/prismic'
import { asText } from '@prismicio/helpers'

type FilterProps = {
  typeId?: string
  updateTypeId: (newTypeId?: string) => void
}

export function Filter({ typeId, updateTypeId }: FilterProps) {
  const { data: articleTypes } = useQuery(
    ['middle_post_type'],
    () =>
      client
        .getByType('middle_post_type', {
          orderings: {
            field: 'my.middle_post_type.title',
            direction: 'asc',
          },
          graphQuery: `{
            middle_post_type {
            title
            color
          }
        }`,
        })
        .then(response =>
          response.results.map(({ uid, last_publication_date: lastPublicationDate, data, id }) => ({
            slug: uid,
            title: asText(data.title),
            color: data.color,
            id,
            createdAt: new Intl.DateTimeFormat('pt-br', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            }).format(new Date(lastPublicationDate)),
          })),
        ),
    {
      staleTime: 60 * 60 * 1000, // 1 minute
      keepPreviousData: true,
    },
  )

  return (
    <Container>
      <TypeSelectorContainer selectedIndex={articleTypes ? articleTypes.findIndex(entry => entry.id === typeId) + 2 : 0}>
        <button onClick={() => updateTypeId(undefined)}>
          <div style={{ background: 'black' }} />
          <span>Todos</span>
        </button>
        {articleTypes?.map(articleType => (
          <button key={articleType.slug} onClick={() => updateTypeId(articleType.id)}>
            <div style={{ background: articleType.color }} />
            <span>{articleType.title}</span>
          </button>
        ))}
      </TypeSelectorContainer>
    </Container>
  )
}
