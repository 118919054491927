import React from 'react'
import { TooltipProps } from 'recharts'
import { utcDateFromDate } from 'utils'
import {
  Container,
  TooltipGrid,
  TooltipTitleContainer,
  TooltipInfoContainer,
  TooltipInfoValue,
  TooltipContainer,
  ToolTipDivider,
} from 'components/CustomTooltip'

const CustomTooltip: React.FC<TooltipProps & { curveIndex?: number; type: string }> = ({ payload, active, curveIndex, type }) => {
  if (payload && active) {
    const tooltipKeys = [
      { key: 'convencional', label: 'Convencional' },
      { key: 'incentivated50', label: 'Incentivada 50%' },
      { key: 'incentivated100', label: 'Incentivada 100%' },
      { key: 'cq5', label: 'CQ5' },
    ]

    return (
      <Container>
        <TooltipTitleContainer>
          {(() => {
            if (!payload[0].payload.product) return '-'
            const product = utcDateFromDate(new Date(payload[0].payload.product))
            const month = product.toLocaleString('default', { month: 'long' })
            const year = product.toLocaleString('default', { year: '2-digit' })
            return `${month}/${year}`
          })()}
        </TooltipTitleContainer>
        <TooltipContainer>
          <TooltipGrid columns={3}>
            <TooltipInfoContainer>
              {tooltipKeys.map(key => (
                <TooltipInfoValue key={key.key}>{key.label}</TooltipInfoValue>
              ))}
            </TooltipInfoContainer>
            <ToolTipDivider />
            <TooltipInfoContainer>
              {tooltipKeys.map(key => {
                if (!curveIndex) return <TooltipInfoValue>-</TooltipInfoValue>
                const value = payload[0].payload[curveIndex][`${key.key}${type}`]
                if (!value) return <TooltipInfoValue>-</TooltipInfoValue>
                const formattedValue = Number(value).toLocaleString('default', { style: 'currency', currency: 'BRL' })
                return <TooltipInfoValue key={key.key}>{formattedValue ?? '-'}</TooltipInfoValue>
              })}
            </TooltipInfoContainer>
          </TooltipGrid>
        </TooltipContainer>
      </Container>
    )
  }
  return null
}

export default CustomTooltip
