import React from 'react'
import { TooltipProps } from 'recharts'
import { returnBRDateFormatFromUTCString, returnBRNumber, getNumberColor } from 'utils'
import { Container, TooltipGrid, TooltipInfoContainer, TooltipInfoValue, TooltipTitleContainer } from 'components/CustomTooltip'

type CustomTooltipProps = {
  dataKey: string
  name: string
}

const CustomTooltip: React.FC<TooltipProps & { axises: CustomTooltipProps[] }> = ({ active, payload, label, axises }) => {
  if (active && payload && label) {
    if (payload[0]) {
      return (
        <Container>
          <TooltipTitleContainer>
            {`${returnBRDateFormatFromUTCString(payload[0].payload.date)} - ${new Date(payload[0].payload.date).toLocaleString('default', {
              weekday: 'long',
              timeZone: 'UTC',
            })}`}
          </TooltipTitleContainer>
          <TooltipGrid>
            <TooltipInfoContainer>
              {axises.map(axis => (
                <TooltipInfoValue key={axis.name} style={{ fontWeight: 'bold' }}>
                  {axis.name}
                </TooltipInfoValue>
              ))}
              <TooltipInfoValue style={{ fontWeight: 'bold' }}>Geração total</TooltipInfoValue>
              <TooltipInfoValue style={{ fontWeight: 'bold' }}>Carga - Geração total</TooltipInfoValue>
            </TooltipInfoContainer>
            <TooltipInfoContainer>
              {axises.map(axis => (
                <TooltipInfoValue key={axis.name}>{`${returnBRNumber(Math.round(payload[0].payload[axis.dataKey]))} MWmed`}</TooltipInfoValue>
              ))}
              <TooltipInfoValue>{`${returnBRNumber(Math.round(payload[0].payload.total))} MWmed`}</TooltipInfoValue>
              {(() => {
                const deficit = Math.round(payload[0].payload.deficit)
                const color = getNumberColor(deficit)
                return <TooltipInfoValue color={color}>{`${returnBRNumber(Math.round(payload[0].payload.deficit))} MWmed`}</TooltipInfoValue>
              })()}
            </TooltipInfoContainer>
          </TooltipGrid>
        </Container>
      )
    }
  }
  return null
}

export default CustomTooltip
