import { readableColor, transparentize } from 'polished'
import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi'
import { PrismaSelect, Switch } from 'components/Input'
import { CustomLegend } from 'components/Chart'
import { subsystems } from 'utils/globals'
import { MatrixStudyCase } from '../..'
import {
  SubtitleContainer,
  Container,
  FixedEnasContainer,
  FixedEnaContainer,
  MainDataContainer,
  MainDataTableHeader,
  MainEnaDataTableData,
  MainDataTableData,
  ControllerContainer,
} from './styles'
import { returnColorFromNumber } from 'utils'

export function MatrixTable({
  data,
  limitedPrices,
  baseCase,
}: {
  data: MatrixStudyCase
  limitedPrices: boolean
  baseCase: MatrixStudyCase['caseData']['baseCase']
}) {
  const { fixedSubsystems, varyingSubsystems, studies } = data.caseData

  const firstVaryingSubsystem = subsystems[varyingSubsystems[0].subsystemIndex]
  const secondVaryingSubsystem = subsystems[varyingSubsystems[1].subsystemIndex]
  const firstFixedSubsystem = subsystems[fixedSubsystems[0].subsystemIndex]
  const secondFixedSubsystem = subsystems[fixedSubsystems[1].subsystemIndex]

  const [highlighted, setHighlighted] = useState<{ column: number; row: number }>({ column: 0, row: 0 })
  const [selectedSubsystem, setSelectedSubsystem] = useState(0)
  const [showUnstuckValues, setShowUnstuckValues] = useState(false)

  const numberOfSensibilites = varyingSubsystems[0].sensibilities.length

  const theme = useTheme()

  function formatPrice(price: number) {
    return new Intl.NumberFormat('default', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 0,
    }).format(price)
  }

  function fomatPriceDifference(price: number) {
    const Icon = price < 0 ? BiDownArrowAlt : BiUpArrowAlt

    const formatedValue = new Intl.NumberFormat('default', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 2,
    }).format(Math.abs(price))

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: returnColorFromNumber(price) }}>
        {price !== 0 ? <Icon /> : null}
        {formatedValue}
      </div>
    )
  }

  return (
    <Container>
      <SubtitleContainer>Matriz</SubtitleContainer>
      <ControllerContainer>
        <PrismaSelect
          label='Submercado'
          value={String(selectedSubsystem)}
          onChange={ev => setSelectedSubsystem(Number(ev.target.value))}
          options={subsystems.map((subsystem, index) => ({ label: subsystem.name, value: String(index) }))}
        />
        <Switch text='Diferenças' onChange={value => setShowUnstuckValues(value)} />
      </ControllerContainer>
      <FixedEnasContainer>
        <FixedEnaContainer color={firstFixedSubsystem.color}>
          <div>
            <span>{`ENA ${firstFixedSubsystem.name}: `}</span>
            {new Intl.NumberFormat('default', { style: 'percent' }).format(fixedSubsystems[0].sensibility)}
          </div>
        </FixedEnaContainer>
        <FixedEnaContainer color={secondFixedSubsystem.color}>
          <div>
            <span>{`ENA ${secondFixedSubsystem.name}: `}</span>
            <span>{new Intl.NumberFormat('default', { style: 'percent' }).format(fixedSubsystems[1].sensibility)}</span>
          </div>
        </FixedEnaContainer>
      </FixedEnasContainer>
      <MainDataContainer>
        <table>
          <thead>
            <tr>
              <th
                rowSpan={2}
                style={{ background: firstVaryingSubsystem.color, color: readableColor(firstVaryingSubsystem.color) }}
              >{`ENA ${firstVaryingSubsystem.name}`}</th>
              <th
                colSpan={varyingSubsystems[0].sensibilities.length}
                style={{ background: secondVaryingSubsystem.color, color: readableColor(secondVaryingSubsystem.color) }}
              >{`ENA ${secondVaryingSubsystem.name}`}</th>
            </tr>
            <tr>
              {varyingSubsystems[1].sensibilities.map((value, index) => (
                <MainDataTableHeader key={value} color={secondVaryingSubsystem.color} highlighted={index + 1 === highlighted.column}>
                  {Intl.NumberFormat('default', { style: 'percent' }).format(value / 100)}
                </MainDataTableHeader>
              ))}
            </tr>
          </thead>
          <tbody>
            {varyingSubsystems[0].sensibilities.map((value, index) => (
              <tr key={value}>
                <MainEnaDataTableData color={firstVaryingSubsystem.color} highlighted={index + 1 === highlighted.row}>
                  {Intl.NumberFormat('default', { style: 'percent' }).format(value / 100)}
                </MainEnaDataTableData>
                {studies.slice(index * numberOfSensibilites, (index + 1) * numberOfSensibilites).map((study, index2) => {
                  const differenceToSoutheast =
                    study[subsystems[selectedSubsystem].key]?.[limitedPrices ? 'pld_average' : 'cmo_average'] -
                    study[subsystems[0].key]?.[limitedPrices ? 'pld_average' : 'cmo_average']

                  const firstPreviousMlt =
                    studies[index * numberOfSensibilites + index2 - numberOfSensibilites]?.[firstVaryingSubsystem.key].monthly_ena_mlt
                  const secondPreviousMlt = studies[index2 - 1]?.[secondVaryingSubsystem.key].monthly_ena_mlt

                  const firstFollowingMlt =
                    studies[index * numberOfSensibilites + index2 + numberOfSensibilites]?.[firstVaryingSubsystem.key].monthly_ena_mlt
                  const secondFollowingMlt = studies[index2 + 1]?.[secondVaryingSubsystem.key].monthly_ena_mlt

                  const firstBaseMlt = baseCase[firstVaryingSubsystem.key].monthly_ena_mlt
                  const secondBaseMlt = baseCase[secondVaryingSubsystem.key].monthly_ena_mlt

                  const firstCondition = firstBaseMlt > firstPreviousMlt && firstBaseMlt < firstFollowingMlt
                  const secondCondition = secondBaseMlt > secondPreviousMlt && secondBaseMlt < secondFollowingMlt

                  const condition = secondCondition && firstCondition

                  return (
                    <MainDataTableData
                      key={study.sensibility}
                      onMouseOver={() => setHighlighted(state => ({ ...state, column: index2 + 1, row: index + 1 }))}
                      onMouseLeave={() => setHighlighted({ column: 0, row: 0 })}
                      unstuck={Math.abs(differenceToSoutheast) > 0.1}
                      closeToBase={condition}
                    >
                      {showUnstuckValues
                        ? fomatPriceDifference(differenceToSoutheast)
                        : formatPrice(study[subsystems[selectedSubsystem].key]?.[limitedPrices ? 'pld_average' : 'cmo_average'])}
                    </MainDataTableData>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </MainDataContainer>
      <CustomLegend
        legends={[
          {
            color: transparentize(0.8, theme.safiraColors.blue[0]),
            text: 'Cenários com descolamento acima de R$0,10 em relação ao Sudeste',
          },
          {
            color: theme.safiraColors.red[0],
            text: 'Cenários próximos ao base (%MLT)',
          },
        ]}
      />
    </Container>
  )
}
