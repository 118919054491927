import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import { Container, ControllerContainer, Title } from './styles'
import NoDataWarning from 'components/NoDataWarning'
import { StudyMetaData } from '..'
import { Header } from '../Header'
import { LoadingPlaceholder } from '../LoadingAlert'
import { Switch, PrismaSelect } from 'components/Input'
import { Redirect } from 'react-router-dom'
import { subsystems } from 'utils/globals'
import { SemiHourlyChart } from './SemiHourlyChart'
import { HourlyChart } from './HourlyChart'
import { DailyChart } from './DailyChart'
import { DailyTable } from './DailyTable'

export type CustomProspectiveStudy = {
  meta: StudyMetaData
  data: {
    daily: {
      date: string
      studies: {
        [index: string]: {
          southeast: { cmo: number; pld: number }
          south: { cmo: number; pld: number }
          northeast: { cmo: number; pld: number }
          north: { cmo: number; pld: number }
        }
      }
    }[]
    semiHourly: {
      date: string
      semiHour: number
      studies: {
        [index: string]: {
          southeast: { cmo: number }
          south: { cmo: number }
          northeast: { cmo: number }
          north: { cmo: number }
        }
      }
    }[]
    hourly: {
      date: string
      hour: number
      studies: {
        [index: string]: {
          southeast: { cmo: number; pld: number }
          south: { cmo: number; pld: number }
          northeast: { cmo: number; pld: number }
          north: { cmo: number; pld: number }
        }
      }
    }[]
  }
}

type CurveStudyProps = { id: string }

export const fixedColors = [
  '#83d123',
  '#600000',
  '#e56910',
  '#2b7af9',
  '#d11aff',
  '#043baa',
  '#b8ed5d',
  '#ff1a75',
  '#ff1a1a',
  '#f6ad79',
  '#079235',
  '#aa0000',
  '#e6e600',
  '#3973ac',
  '#de3400',
  '#0554d3',
  '#a1e244',
  '#032677',
  '#820000',
  '#83d123',
]

export function DessemStudy({ id }: CurveStudyProps) {
  const [limitedPrices, setLimitedPrices] = useState(false)
  const [selectedSubsystem, setSelectedSubsystem] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(['custom-prospective-study', id], () => api.get<CustomProspectiveStudy>(`custom-prospective-studies/${id}`).then(({ data }) => data), {
    staleTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false,
  })

  if (isLoading) return <LoadingPlaceholder />

  if (isError) return <Redirect to='/studies/curves-and-matrixes' />

  if (!response) {
    return (
      <div style={{ paddingTop: '3rem' }}>
        <NoDataWarning text='Dados não encontrados.' />
      </div>
    )
  }

  return (
    <Container>
      <Header meta={response?.meta} />
      <ControllerContainer>
        <img src={subsystems[selectedSubsystem].mapUrl} alt='' />
        <div>
          <PrismaSelect
            label='Submercado'
            value={String(selectedSubsystem)}
            onChange={ev => setSelectedSubsystem(Number(ev.target.value))}
            options={subsystems.map((subsystem, index) => ({ label: subsystem.name, value: String(index) }))}
          />
          <Switch text='Limites de PLD' onChange={value => setLimitedPrices(value)} />
        </div>
      </ControllerContainer>
      <Title>Média diária</Title>
      <DailyChart data={response.data.daily} selectedSubsystem={selectedSubsystem} studies={response.meta.studies} limitedPrices={limitedPrices} />
      <Title>{`PLD (${limitedPrices ? 'com limites' : 'sem limites'})`}</Title>
      <HourlyChart data={response.data.hourly} selectedSubsystem={selectedSubsystem} studies={response.meta.studies} limitedPrices={limitedPrices} />
      <Title>Média diária tabelas</Title>
      <DailyTable data={response.data.daily} selectedSubsystem={selectedSubsystem} studies={response.meta.studies} limitedPrices={limitedPrices} />
      <Title>CMO (CASO CCEE)</Title>
      <SemiHourlyChart data={response.data.semiHourly} selectedSubsystem={selectedSubsystem} studies={response.meta.studies} />
    </Container>
  )
}
