import styled from 'styled-components'
import { transparentize } from 'polished'

export const Container = styled.div``

export const StudiesContainer = styled.div`
  margin-top: 50px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: min-content;
`

export const StudyContainer = styled.div<{ position: number; total: number; isLoading: boolean }>`
  transition: 200ms;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
  border-radius: 0.5rem;
  padding: 1rem;
  opacity: ${props => (props.isLoading ? '0.5' : '1')};
`

export const StudyTopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const StudyTitleAndAuthorContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`

export const StudyIconContainer = styled.div`
  border-radius: 5px;
  height: 3rem;
  width: 3rem;
  background: red;
  padding: 0.5rem;
  background: #f2f2f2;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    opacity: 0.5;
  }
`

export const StudyTitleContainer = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: #333;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const StudyAuthorContainer = styled.div`
  color: ${transparentize(0.5, '#333')};
`

export const StudyBodyContainer = styled.div`
  margin-top: 1rem;
  color: ${transparentize(0.5, '#333')};
  line-height: 2rem;
  max-height: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StudyPillsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  div {
    padding: 1rem;
    border-radius: 0.5rem;
    background: #f2f2f2;
    font-size: 0.8rem;
    font-weight: 700;
    color: ${transparentize(0.5, '#333')};
  }
`

export const StudyBottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  span {
    font-size: 0.8rem;
    color: ${transparentize(0.5, '#333')};
  }
  button {
    padding: 1rem 1.5rem;
    border: none;
    font-weight: 700;
    color: #fff;
    border-radius: 0.5rem;
    background: ${props => props.theme.safiraColors.green[3]};
    transition: filter 200ms;
    :hover {
      filter: brightness(0.9);
    }
  }
`
