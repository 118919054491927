import React, { useState } from 'react'
import { InputDate, PrismaSelect } from 'components/Input'
import { SectionText, SectionTitle } from 'components/Section'
import { Container, ControllerContainer, ChartContainer } from './styles'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ResponsiveContainer, LineChart, YAxis, XAxis, Line, Legend, Tooltip, CartesianGrid, Label } from 'recharts'
import { useTheme } from 'styled-components'
import { format, parse } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { CustomLegend } from 'components/Chart'
import { CustomTooltip } from './CustomTooltip'

type DessemCCEEPLD = {
  semiHour: number
  southeast: number
  south: number
  northeast: number
  north: number
}

export function Exchange({ lastCceeDessemDate }: { lastCceeDessemDate: Date }) {
  const [date, setDate] = useState(lastCceeDessemDate)
  const [flow, setFlow] = useState('FXGET  e FXGTR')

  const theme = useTheme()

  const { data, isLoading } = useQuery(
    ['dessem-ccee-middle-pld', date, flow],
    () => api.get<DessemCCEEPLD[]>('middle-dessem-ccee/exchange', { params: { date, flow } }).then(({ data }) => data),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  return (
    <Container>
      <SectionTitle>Modelo DESSEM - Intercâmbio</SectionTitle>
      <SectionText>Intercâmbio do modelo DESSEM sem rede elétrica</SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          value={format(utcDateFromDate(date), 'yyyy-MM-dd')}
          max={format(utcDateFromDate(lastCceeDessemDate), 'yyyy-MM-dd')}
          onChange={e => setDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
        />
        <PrismaSelect
          label='Fluxo'
          value={flow}
          options={[
            { label: 'FXGET  e FXGTR', value: 'FXGET  e FXGTR' },
            { label: 'EXPN', value: 'EXPN' },
            { label: 'FNE', value: 'FNE' },
            { label: 'FNESE', value: 'FNESE' },
            { label: 'FNS', value: 'FNS' },
            { label: 'FNS + FNESE', value: 'FNS + FNESE' },
            { label: 'FTUXG', value: 'FTUXG' },
          ]}
          onChange={ev => setFlow(ev.target.value)}
        />
      </ControllerContainer>
      <ChartContainer isLoading={isLoading}>
        <ResponsiveContainer>
          <LineChart data={data}>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={
                <CustomLegend
                  legends={[
                    { text: flow, color: theme.colors.cyan, isLine: true },
                    { text: 'Limite superior', color: theme.colors.black500, isLine: true },
                  ]}
                />
              }
            />
            <CartesianGrid vertical={false} stroke={theme.colors.black100} />
            <XAxis
              ticks={Array.from({ length: 48 })
                .map((_entry, index) => index + 1)
                .filter(entry => entry % 2 !== 0)}
              dataKey='semiHour'
              tick={{ fontSize: '0.875rem', fill: theme.colors.black300 }}
              tickFormatter={tick => `${(Number(tick) - 1) / 2}h`}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              tick={{ fontSize: '0.875rem', fill: theme.colors.black300 }}
              width={80}
              domain={['auto', 'auto']}
              tickCount={8}
              allowDecimals={false}
              tickLine={false}
              axisLine={false}
              tickFormatter={tick => Intl.NumberFormat('pt-br', { maximumFractionDigits: 0 }).format(Number(tick))}
            >
              <Label
                angle={-90}
                value='MW'
                fontSize={12}
                position='insideLeft'
                style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
              />
            </YAxis>
            <Line strokeWidth={5} dot={false} dataKey='value' stroke={theme.colors.cyan} />
            <Line strokeWidth={5} dot={false} dataKey='upperLimit' stroke={theme.colors.black500} strokeDasharray='10 3' />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}
