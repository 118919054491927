import React from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { Loading } from 'pages/Loading'
import { useAuth } from 'oidc-react'

type Response = {
  data: [
    {
      groupId: string
      name: string
      telephone: string
      owner: string
      companies: [
        {
          agentId: string
          name: string
          cnpj: string
          fantasyName: string
          thundersId: string
        },
      ]
      responsible: [string]
      accessLevel: number
    },
  ]
  status: number
}

export const CompanyContext = React.createContext<number>(0)

export function CompanyProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth()

  const accessToken = auth.userData?.access_token

  if (!accessToken) return <Loading />

  const { data, isLoading } = useQuery(
    'user-company',
    () =>
      axios
        .get<Response>('https://safira-gestao-producao.uc.r.appspot.com/proposal/listUserCompanies', {
          headers: { Authorization: accessToken?.replace('Bearer', '').trim() ?? '' },
        })
        .then(({ data }) => data.data[0].accessLevel)
        .catch(error => {
          const status = error?.response?.status

          if (status === 404) return 0

          auth.signOutRedirect()
        }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: 3600000,
    },
  )

  if (isLoading || data === undefined) return <Loading />

  return <CompanyContext.Provider value={data}>{children}</CompanyContext.Provider>
}
