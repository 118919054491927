import { lighten, transparentize } from 'polished'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
`

export const TableContainer = styled.div`
  margin-top: 2rem;
  overflow: auto;
  max-width: 100%;
  max-height: 700px;
  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${props => transparentize(0.3, props.theme.colors.blue100)};
  }
  ::-webkit-scrollbar-track {
    background-color: ${props => transparentize(0.3, props.theme.colors.black100)};
  }
  table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
    thead tr {
      th {
        padding: 1rem;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        color: ${props => props.theme.colors.blue700};
        background: ${props => props.theme.safiraColors.blue[4]};
      }
      :first-child {
        th {
          color: #fff;
          :first-child {
            border-top-left-radius: 1rem;
            border-left: none;
          }
          :last-child {
            border-top-right-radius: 1rem;
            border-right: none;
          }
        }
      }
      :last-child th {
        color: #fff;
        background: ${props => lighten(0.1, props.theme.safiraColors.blue[5])};
        :last-child {
          border-right: none;
        }
      }
    }
    tbody tr {
      td {
        padding: 0.5rem;
        font-size: 0.875rem;
      }
    }
  }
`

export const StudyTableDate = styled.td`
  border: 1px solid #ccc;
  font-weight: bold;
  color: ${props => props.theme.colors.blue700};
  box-shadow: inset 0 1px 0 #eaeaea, inset 0 -1px 0 #eaeaea, inset 1px 0 0 #eaeaea, inset -1px 0 0 #eaeaea;
  position: sticky;
  background: #fff;
  left: 0px;
  border: none;
  min-width: 150px;
  max-width: 150px;
`

type ValueTableDateProps = {
  color: string
  isLastFromData: boolean
  isLastFromStudy: boolean
}

export const ValueTableDate = styled.td<ValueTableDateProps>`
  :first-child {
    position: sticky;
    left: 150px;
    box-shadow: inset 0 1px 0 #eaeaea, inset 0 -1px 0 #eaeaea, inset 1px 0 0 #eaeaea, inset -1px 0 0 #eaeaea;
    border: none;
    background: #fff;
    font-weight: bold;
    color: ${props => transparentize(0.3, props.color)};
  }
  ${props =>
    props.isLastFromData &&
    css`
      border-right: 1px solid #ccc;
    `}
  ${props =>
    props.isLastFromStudy &&
    css`
      border-bottom: 1px solid #ccc;
    `}
`
