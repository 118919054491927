import React from 'react'
import noDataSVG from 'assets/svg/sheets-empty.svg'
import { Container } from './styles'

interface NoDataWarning {
  text?: string
}

const NoDataWarning: React.FC<NoDataWarning> = ({ text }) => {
  return (
    <Container>
      <img alt='No data' src={noDataSVG} />
      <span>{text || 'Nada encontrado.'}</span>
    </Container>
  )
}

export default NoDataWarning
