import React from 'react'
import api from 'services/api'
import { startOfDay } from 'date-fns'
import { useAuth } from 'oidc-react'
import { queryClient } from 'services/queryClient'

export function Prefetcher() {
  if (useAuth().isLoading) return null

  const studyType = 'PRELIMINAR'
  const date = startOfDay(new Date())

  queryClient.prefetchQuery(
    ['studies-short-term', date, studyType],
    () => api.get('prospective-studies', { timeout: 3000, params: { date, studyType, extended: false } }).then(({ data }) => data),
    {
      retry: 3,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  )

  return <></>
}
