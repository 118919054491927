import React, { useEffect, useState } from 'react'
import { ResponsiveContainer, LineChart, XAxis, YAxis, Line, Tooltip, Legend, CartesianGrid, Label } from 'recharts'
import { useTheme } from 'styled-components'
import { utcDateFromDate, numberToBrl } from 'utils'
import { CustomLegend } from 'components/Chart'

import api from 'services/api'

import { subsystems } from 'utils/globals'
import { Container, ChartContainer } from './styles'
import CustomTooltip from './CustomTooltip'
import Summary from './Summary'

interface Response {
  summary: {
    max: {
      southeast: number
      south: number
      northeast: number
      north: number
    }
    min: {
      southeast: number
      south: number
      northeast: number
      north: number
    }
    average: {
      southeast: number
      south: number
      northeast: number
      north: number
    }
  }
  prices: {
    date: Date
    time: number
    southeast: number
    south: number
    northeast: number
    north: number
  }[]
}

const HourlyPLD: React.FC = () => {
  const [data, setData] = useState<Response['prices']>([])
  const theme = useTheme()

  useEffect(() => {
    api.get<Response>('ccee-dessem-prices', { params: { start: new Date() } }).then(response => setData(response.data.prices))
  }, [])

  return (
    <Container>
      <Summary prices={data} />
      <ChartContainer>
        <ResponsiveContainer>
          <LineChart data={data}>
            <XAxis
              dataKey='time'
              interval={0}
              padding={{ left: 30, right: 30 }}
              tickFormatter={tick => `${utcDateFromDate(new Date(tick)).toLocaleTimeString('pt-br', { hour: '2-digit' })}h`}
              tick={{ fontSize: '0.875rem' }}
            />
            <YAxis
              tick={{ fontSize: '0.875rem' }}
              height={50}
              interval={0}
              tickCount={8}
              width={90}
              tickFormatter={tick => numberToBrl(Number(tick))}
              domain={['auto', 'auto']}
            >
              <Label
                angle={-90}
                value={'PLD (R$/MWh)'}
                position='insideLeft'
                style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
              />
            </YAxis>
            <CartesianGrid strokeDasharray='3 3' />
            <Tooltip content={CustomTooltip} />
            <Legend
              verticalAlign='bottom'
              content={<CustomLegend legends={subsystems.map(subsystem => ({ text: subsystem.name, color: subsystem.color }))} />}
            />
            <Line name='Norte' dataKey='north' strokeWidth={5} stroke={theme.subSystemColors.north} fill={theme.subSystemColors.north} />
            <Line
              name='Nordeste'
              dataKey='northeast'
              strokeWidth={5}
              stroke={theme.subSystemColors.northEast}
              fill={theme.subSystemColors.northEast}
            />
            <Line name='Sul' dataKey='south' strokeWidth={5} stroke={theme.subSystemColors.south} fill={theme.subSystemColors.south} />
            <Line
              name='Sudeste'
              dataKey='southeast'
              strokeWidth={5}
              stroke={theme.subSystemColors.southEast}
              fill={theme.subSystemColors.southEast}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}

export default HourlyPLD
