import styled from 'styled-components'

export const Container = styled.div`
  gap: 1rem;
  display: grid;
  margin-bottom: 2rem;
`

export const TypeSelectorContainer = styled.div<{ selectedIndex: number }>`
  display: flex;
  gap: 1rem;
  button {
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    transition: filter 150ms;
    animation-direction: alternate;
    :nth-child(${props => props.selectedIndex}) {
      background: rgba(0, 0, 0, 0.2);
    }
    div {
      padding: 0.5rem;
      background: black;
      border-radius: 0.25rem;
    }
    :hover {
      filter: brightness(0.95);
    }
  }
`
