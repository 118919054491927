import React from 'react'
import { format } from 'date-fns'
import { BiChevronRight } from 'react-icons/bi'
import { Section } from 'components/Section'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import SectionSubTitle from 'components/SectionSubtitle'

import { Container, ImageContainer } from './styles'

const AAO: React.FC = () => {
  const date = new Date()
  const formattedDate = format(date, 'yyyyMMdd')

  const aaoInfo = (
    <div>
      <p>
        É caracterizada por anomalias na altura geopotencial, temperatura, vento zonal e precipitação com sinais opostos entre a Antártida e latitudes
        médias. O índice AAO é obtido a partir da anomalia da altura geopotencial em 700mb na latitude 20ºS, e apresenta a variação entre as fases
        negativa e positiva, com a duração e intensidade de cada fase, além da tendência prevista pelos modelos.
      </p>
      <table>
        <thead>
          <tr>
            <th>Fase negativa</th>
            <th>Fase positiva</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                <li>
                  <BiChevronRight />
                  <span>Ciclones em latitudes médias;</span>
                </li>
                <li>
                  <BiChevronRight />
                  <span>Maior precipitação no Sul;</span>
                </li>
                <li>
                  <BiChevronRight />
                  <span>Menor precipitação no Norte e Nordeste;</span>
                </li>
                <li>
                  <BiChevronRight />
                  <span>ZCAS enfraquecidas (verão);</span>
                </li>
                <li>
                  <BiChevronRight />
                  <span>Frentes frias mais intensas (outono).</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <BiChevronRight />
                  <span>Ciclones mais próximos da Antártida;</span>
                </li>
                <li>
                  <BiChevronRight />
                  <span>Menor precipitação no Sul;</span>
                </li>
                <li>
                  <BiChevronRight />
                  <span>ZCAS mais intensas (verão).</span>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <span>Adaptado de: </span>
        <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
          NOAA
        </a>
      </p>
    </div>
  )

  return (
    <Container>
      <Section>
        <SectionTitle>Oscilação Antártica</SectionTitle>
        <SectionText>
          É uma oscilação de pressão que ocorre ao longo de todo o ano em latitudes médias e altas. As fases positiva e negativa influenciam o volume
          e local da precipitação, a região com maior concentração de atividades ciclônicas e na intensidade das ZCAS (Zona de Convergência do
          Atlântico Sul). O acompanhamento da oscilação nos últimos meses (linha escura) e a previsão para a próxima quinzena (linhas coloridas) é
          realizado pelo índice AAO.
        </SectionText>
      </Section>
      <Section>
        <SectionSubTitle title='Histórico e previsão' info={aaoInfo} />
        <ImageContainer>
          <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/noaa-oscillations/wkxzteq_anm.png`} alt='' />
        </ImageContainer>
      </Section>
    </Container>
  )
}

export default AAO
