import React from 'react'
import { Link } from 'react-router-dom'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { Container, PostContainer, PostBannerContainer, PostInfoContainer, PostTitleContainer } from './styles'
import { useQuery } from 'react-query'
import { client } from 'services/prismic'
import { asText, asImageSrc } from '@prismicio/helpers'

const LoadingPlaceholder = () => (
  <>{Array(4).fill(<RectShape color='#ccc' style={{ width: '100%', height: 250, borderRadius: '0.25rem' }} className='text-block' />)}</>
)

export function LastPosts() {
  const { data, isLoading } = useQuery(
    'regulatory-posts-list',
    () =>
      client
        .getByType('gim_post', {
          page: 1,
          pageSize: 4,
          orderings: { field: 'document.first_publication_date', direction: 'desc' },
          graphQuery: `
          {
            gim_post {
              title
              banner
              author_link {
                name
              }
            }
          }`,
        })
        .then(response => ({
          ...response,
          formatedResults: response.results.map(post => {
            return {
              slug: post.uid,
              title: asText(post.data.title),
              author: post?.data?.author_link?.data?.name ? asText(post.data.author_link.data.name) : 'Regulatório Safira',
              banner: post.data.banner,
              createdAt: new Intl.DateTimeFormat('pt-br').format(new Date(post.last_publication_date)),
            }
          }),
        })),
    {
      staleTime: 60 * 60 * 1000000, // 1 minute
    },
  )

  if (isLoading) {
    return (
      <Container>
        <LoadingPlaceholder />
      </Container>
    )
  }

  return (
    <Container>
      {data?.formatedResults.map(post => (
        <Link key={post.slug} to={`/regulatorio/${post.slug}?page=1&search=`}>
          <PostContainer>
            <PostBannerContainer src={asImageSrc(post.banner) ?? undefined} />
            <PostInfoContainer>
              <span>{post.author}</span>
              <time>{post.createdAt}</time>
            </PostInfoContainer>
            <PostTitleContainer>{post.title}</PostTitleContainer>
          </PostContainer>
        </Link>
      ))}
    </Container>
  )
}
