import React from 'react'
import styled from 'styled-components'
import { LoadingContainer } from 'components/Chart'

const Container = styled.div<{ isFetching?: boolean }>`
  height: 500px;
  position: relative;
  filter: ${props => (props.isFetching ? 'opacity(0.6)' : '')};
`

type ChartContainerProps = { children: React.ReactNode; isLoading?: boolean; isFetching?: boolean }

export function ChartContainer({ children, isLoading, isFetching }: ChartContainerProps) {
  if (isLoading)
    return (
      <Container>
        <LoadingContainer />
      </Container>
    )

  return <Container isFetching={isFetching}>{children}</Container>
}
