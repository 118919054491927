import React from 'react'
import { TooltipProps } from 'recharts'
import { Container, TooltipInfoContainer, TooltipInfoValue, TooltipGrid, TooltipTitleContainer, ToolTipDivider } from 'components/CustomTooltip'
import { subsystemsWithSin } from 'utils/globals'
import { useTheme } from 'styled-components'

interface CustomTooltipProps extends TooltipProps {
  subsystem: typeof subsystemsWithSin[number]
}

export function CustomTooltip({ active, payload, subsystem }: CustomTooltipProps) {
  if (!active || !payload || !payload[0] || !payload[0].payload) return null

  const theme = useTheme()

  return (
    <Container>
      <TooltipTitleContainer>{`${Number(payload[0].payload.hour) - 1}h`}</TooltipTitleContainer>
      <TooltipGrid columns={3}>
        <TooltipInfoContainer>
          <TooltipInfoValue>
            <span style={{ fontWeight: 'bold', color: theme.colors.blue400 }}>Geração hidráulica</span>
          </TooltipInfoValue>
          <TooltipInfoValue>
            <span style={{ fontWeight: 'bold', color: theme.colors.yellow }}>Geração térmica</span>
          </TooltipInfoValue>
          <TooltipInfoValue>
            <span style={{ fontWeight: 'bold', color: theme.colors.green }}>Geração de usinas não simuladas</span>
          </TooltipInfoValue>
          <TooltipInfoValue>
            <span style={{ fontWeight: 'bold', color: theme.colors.black500 }}>Carga</span>
          </TooltipInfoValue>
        </TooltipInfoContainer>
        <ToolTipDivider />
        <TooltipInfoContainer>
          <TooltipInfoValue>{Intl.NumberFormat('pt-br').format(Number(payload[0].payload[subsystem.key].hydraulic))}</TooltipInfoValue>
          <TooltipInfoValue>{Intl.NumberFormat('pt-br').format(Number(payload[0].payload[subsystem.key].thermal))}</TooltipInfoValue>
          <TooltipInfoValue>{Intl.NumberFormat('pt-br').format(Number(payload[0].payload[subsystem.key].renewable))}</TooltipInfoValue>
          <TooltipInfoValue>{Intl.NumberFormat('pt-br').format(Number(payload[0].payload[subsystem.key].demand))}</TooltipInfoValue>
        </TooltipInfoContainer>
      </TooltipGrid>
    </Container>
  )
}
