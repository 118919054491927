import React from 'react'
import { TooltipProps } from 'recharts'
import { Container, TooltipInfoContainer, TooltipInfoValue, TooltipGrid, TooltipTitleContainer, ToolTipDivider } from 'components/CustomTooltip'
import { subsystems } from 'utils/globals'

export function CustomTooltip({ active, payload }: TooltipProps) {
  if (!active || !payload || !payload[0] || !payload[0].payload) return null

  return (
    <Container>
      <TooltipTitleContainer>
        {(() => {
          const semiHour = Number(payload[0].payload.semiHour) - 1

          if (semiHour % 2 === 0) return `${semiHour / 2}:00h`

          return `${Math.floor(semiHour / 2)}:30h`
        })()}
      </TooltipTitleContainer>
      <TooltipGrid columns={3}>
        <TooltipInfoContainer>
          {subsystems.map(subsystem => (
            <TooltipInfoValue key={subsystem.name}>
              <span style={{ fontWeight: 'bold', color: subsystem.color }}>{subsystem.name}</span>
            </TooltipInfoValue>
          ))}
        </TooltipInfoContainer>
        <ToolTipDivider />
        <TooltipInfoContainer>
          {subsystems.map(subsystem => (
            <TooltipInfoValue key={subsystem.name}>
              {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(payload[0].payload[subsystem.key]))}
            </TooltipInfoValue>
          ))}
        </TooltipInfoContainer>
      </TooltipGrid>
    </Container>
  )
}
