import styled, { keyframes } from 'styled-components'

const containerAnimation = keyframes`
  from {
    opacity: 0;
    left: -100px;
  }
  to {
    opacity: 1;
    left: 0px;
  }
`

const textAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Container = styled.div`
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: ${containerAnimation} 200ms 1;
  img {
    height: 150px;
    animation: ${containerAnimation} 200ms 1;
  }
  span {
    margin-left: 20px;
    color: ${props => props.theme.safiraColors.blue[3]};
    font-weight: bold;
    animation: ${textAnimation} 200ms 1;
    animation-delay: 200ms;
    animation-fill-mode: backwards;
  }
`
