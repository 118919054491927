import React from 'react'
import { TooltipProps } from 'recharts'
import { Container, TooltipInfoContainer, TooltipInfoValue, TooltipGrid } from 'components/CustomTooltip'
import { subsystemsWithSin } from 'utils/globals'
import { useTheme } from 'styled-components'

interface CustomTooltipProps extends TooltipProps {
  subsystem: typeof subsystemsWithSin[number]
}

export function CustomTooltip({ active, payload, subsystem }: CustomTooltipProps) {
  if (!active || !payload || !payload[0] || !payload[0].payload) return null

  const theme = useTheme()

  return (
    <Container>
      <TooltipGrid columns={3}>
        <TooltipInfoContainer>
          <TooltipInfoValue>
            <span style={{ fontWeight: 'bold', color: theme.colors.black300 }}>{`${Number(payload[0].payload.hour) - 1}h`}</span>
            <span>{`: ${Intl.NumberFormat('pt-br', {
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            }).format(Number(payload[0].payload[subsystem.key]))} MWmed`}</span>
          </TooltipInfoValue>
        </TooltipInfoContainer>
      </TooltipGrid>
    </Container>
  )
}
