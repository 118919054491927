/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo } from 'react'
import { ResponsiveContainer, LineChart, XAxis, YAxis, Line, Tooltip, CartesianGrid, Legend, Label } from 'recharts'
import { add, startOfDay, format } from 'date-fns'
import { SectionTitle, SectionText } from 'components/Section'
import { ChartContainer, CustomLegend, LoadingContainer } from 'components/Chart'
import { ControllerContainer } from 'components/Layout'
import { InputDate } from 'components/Input'
import { transparentize } from 'polished'
import { useTheme } from 'styled-components'
import api from 'services/api'
import { subsystems } from 'utils/globals'
import { getUtcIsoStringFromDate, utcDateFromDate } from 'utils'
import CustomTooltip from './CustomTooltip'
import { Container, SummaryContainer, ChartsContainer, TableContainer } from './styles'
import { Summary } from 'components/Summary'

type ApiResponse = {
  summary: {
    max: {
      [index: string]: {
        southeast: number
        south: number
        northeast: number
        north: number
      }
    }
    min: {
      [index: string]: {
        southeast: number
        south: number
        northeast: number
        north: number
      }
    }
    average: {
      [index: string]: {
        southeast: number
        south: number
        northeast: number
        north: number
      }
    }
  }
  data: {
    time: number
    prices: {
      [index: string]: DessemPrice
    }
  }[]
}

const formattMoneyConfig = { style: 'currency', currency: 'BRL' }

const TwoDayComparison: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(startOfDay(new Date()))
  const [previousDate, setPreviousDate] = useState(add(currentDate, { days: -1 }))
  const currentDateString = useMemo(() => getUtcIsoStringFromDate(currentDate), [currentDate])
  const previousDateString = useMemo(() => getUtcIsoStringFromDate(previousDate), [previousDate])
  const [apiResponse, setApiResponse] = useState<ApiResponse>({ summary: { max: {}, min: {}, average: {} }, data: [] })
  const [loading, setLoading] = useState(true)
  const theme = useTheme()

  useEffect(() => {
    setLoading(true)
    api.get<ApiResponse>('ccee-dessem-prices/compare', { params: { start: previousDate, end: currentDate } }).then(response => {
      setApiResponse(response.data)
      setLoading(false)
    })
  }, [currentDate, previousDate])

  const charts: {
    title: string
    dataKeySubsistemName: 'southeast' | 'south' | 'northeast' | 'north'
    color: string
  }[] = [
    { title: 'Sudeste', dataKeySubsistemName: 'southeast', color: theme.safiraColors.blue[4] },
    { title: 'Sul', dataKeySubsistemName: 'south', color: theme.safiraColors.yellow[3] },
    { title: 'Nordeste', dataKeySubsistemName: 'northeast', color: theme.safiraColors.orange[3] },
    { title: 'Norte', dataKeySubsistemName: 'north', color: theme.safiraColors.green[3] },
  ]

  const summaries = [
    { title: 'Preços mínimos', data: apiResponse.summary.min },
    { title: 'Preços médios', data: apiResponse.summary.average },
    { title: 'Preços máximos', data: apiResponse.summary.max },
  ]

  return (
    <Container>
      <SectionTitle title='Comparação do PLD horário' />
      <SectionText text='Comparação do Preço de Liquidação das Diferenças (PLD) horário para cada submercado, disponibilizado pela Câmara de Comercialização de Energia Elétrica (CCEE), entre duas datas, anterior e posterior.' />
      <ControllerContainer>
        <InputDate
          label='Data anterior'
          value={format(previousDate, 'yyyy-MM-dd')}
          onChange={ev => {
            setPreviousDate(utcDateFromDate(new Date(ev.target.value)))
          }}
        />
        <InputDate
          label='Data posterior'
          value={format(currentDate, 'yyyy-MM-dd')}
          onChange={ev => setCurrentDate(utcDateFromDate(new Date(ev.target.value)))}
        />
      </ControllerContainer>
      <Summary columns={7}>
        {summaries.map(summary => (
          <div key={summary.title}>
            <h2>{summary.title}</h2>
            <SummaryContainer>
              <div>
                <span style={{ opacity: 0 }}>prisma</span>
                <span>{previousDate.toLocaleDateString('pt-br')}</span>
                <span>{currentDate.toLocaleDateString('pt-br')}</span>
                <span>Diferença</span>
              </div>
              {subsystems.map(subsystem => (
                <div key={subsystem.key}>
                  <span>{subsystem.initials.toUpperCase()}</span>
                  <span>{summary.data[currentDateString]?.[subsystem.key]?.toLocaleString('pt-br', formattMoneyConfig) || '-'}</span>
                  <span>{summary.data[previousDateString]?.[subsystem.key]?.toLocaleString('pt-br', formattMoneyConfig) || '-'}</span>
                  <span>
                    {(summary.data[currentDateString]?.[subsystem.key] - summary.data[previousDateString]?.[subsystem.key])?.toLocaleString(
                      'pt-br',
                      formattMoneyConfig,
                    ) || '-'}
                  </span>
                </div>
              ))}
            </SummaryContainer>
          </div>
        ))}
      </Summary>
      <ChartsContainer>
        {charts.map(chart => {
          const legends = [
            { color: transparentize(0.5, chart.color), text: previousDate.toLocaleDateString() },
            { color: chart.color, text: currentDate.toLocaleDateString() },
          ]
          return (
            <ChartContainer height='350px' key={chart.title}>
              {(() => {
                if (loading) return <LoadingContainer />
                return (
                  <ResponsiveContainer>
                    <LineChart data={apiResponse.data} syncId='hourly-prices-comparison'>
                      <CartesianGrid strokeDasharray='3' strokeOpacity={0.5} />
                      <Legend content={<CustomLegend legends={legends} />} />
                      <Tooltip
                        content={
                          <CustomTooltip
                            currentDate={currentDate}
                            currentDateString={currentDateString}
                            previousDateString={previousDateString}
                            previousDate={previousDate}
                            subsystemKey={chart.dataKeySubsistemName}
                          />
                        }
                      />
                      <YAxis
                        tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                        type='number'
                        width={90}
                        tickCount={6}
                        domain={['auto', 'auto']}
                        tickFormatter={tick => Number(tick).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      >
                        <Label
                          angle={-90}
                          value='PLD (R$/MWh)'
                          position='insideLeft'
                          style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                        />
                      </YAxis>
                      <XAxis
                        tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                        interval={1}
                        dataKey='time'
                        padding={{ left: 10, right: 10 }}
                        tickFormatter={tick => `${tick}h`}
                      />
                      <Line
                        name={currentDate.toLocaleDateString('pt-br')}
                        dataKey={(data: any): string => data.prices[currentDateString]?.[chart.dataKeySubsistemName]}
                        strokeWidth={5}
                        stroke={chart.color}
                        fill={chart.color}
                      />
                      <Line
                        name={previousDate.toLocaleDateString('pt-br')}
                        dataKey={(data: any): string => data.prices[previousDateString]?.[chart.dataKeySubsistemName]}
                        strokeWidth={5}
                        stroke={transparentize(0.5, chart.color)}
                        dot={false}
                        strokeDasharray='15 5'
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )
              })()}
            </ChartContainer>
          )
        })}
      </ChartsContainer>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th />
              <th colSpan={2}>Sudeste</th>
              <th colSpan={2}>Sul</th>
              <th colSpan={2}>Nordeste</th>
              <th colSpan={2}>Norte</th>
            </tr>
            <tr>
              <th>Horário</th>
              <th>{previousDate.toLocaleDateString()}</th>
              <th>{currentDate.toLocaleDateString()}</th>
              <th>{previousDate.toLocaleDateString()}</th>
              <th>{currentDate.toLocaleDateString()}</th>
              <th>{previousDate.toLocaleDateString()}</th>
              <th>{currentDate.toLocaleDateString()}</th>
              <th>{previousDate.toLocaleDateString()}</th>
              <th>{currentDate.toLocaleDateString()}</th>
            </tr>
          </thead>
          <tbody>
            {apiResponse.data.map(price => {
              const time = price.time < 10 ? `0${price.time}h` : `${price.time}h`
              return (
                <tr key={price.time}>
                  <td>{time}</td>
                  <td style={{ background: transparentize(0.95, theme.safiraColors.blue[4]) }}>
                    {price.prices[previousDateString]?.southeast?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }) || '-'}
                  </td>
                  <td style={{ background: transparentize(0.95, theme.safiraColors.blue[4]) }}>
                    {price.prices[currentDateString]?.southeast?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }) || '-'}
                  </td>
                  <td style={{ background: transparentize(0.95, theme.safiraColors.yellow[3]) }}>
                    {price.prices[previousDateString]?.south?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }) || '-'}
                  </td>
                  <td style={{ background: transparentize(0.95, theme.safiraColors.yellow[3]) }}>
                    {price.prices[currentDateString]?.south?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }) || '-'}
                  </td>
                  <td style={{ background: transparentize(0.95, theme.safiraColors.orange[3]) }}>
                    {price.prices[previousDateString]?.northeast?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }) || '-'}
                  </td>
                  <td style={{ background: transparentize(0.95, theme.safiraColors.orange[3]) }}>
                    {price.prices[currentDateString]?.northeast?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }) || '-'}
                  </td>
                  <td style={{ background: transparentize(0.95, theme.safiraColors.green[3]) }}>
                    {price.prices[previousDateString]?.north?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }) || '-'}
                  </td>
                  <td style={{ background: transparentize(0.95, theme.safiraColors.green[3]) }}>
                    {price.prices[currentDateString]?.north?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }) || '-'}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  )
}

export default TwoDayComparison
