import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ResponsiveContainer, ComposedChart, Area, CartesianGrid, YAxis, XAxis, Tooltip, Legend, Label, Line } from 'recharts'
import { PrismaSelect, InputDate } from 'components/Input'
import { Container, ControllerContainer } from './styles'
import { SectionTitle } from 'components/Section'
import { useTheme } from 'styled-components'
import { subsystemsWithSin } from 'utils/globals'
import { add, format, isAfter, isBefore, parse } from 'date-fns'
import { formatQueryDate, utcDateFromDate } from 'utils'
import { toast } from 'react-toastify'
import { ChartContainer } from '../..'

const yTickFormatter = (tick: string) => Intl.NumberFormat('pt-br').format(Number(tick))

const y2TickFormatter = (tick: string) => Intl.NumberFormat('pt-br', { style: 'percent' }).format(Number(tick) / 100)

const xTickFormatter = (tick: string) => {
  return Intl.DateTimeFormat('pt-br', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    timeZone: 'UTC',
  }).format(new Date(tick))
}

export function EarDiarioPorSubsistema() {
  const [end, setEnd] = useState(new Date())
  const [start, setStart] = useState(add(end, { days: -10 }))
  const [subsistema, setSubsistema] = useState(0)
  const [unit, setUnit] = useState<'mwmes' | 'percent'>('mwmes')

  const theme = useTheme()

  const datasets = useMemo(
    () =>
      [
        {
          key: 'earMaxSubsistema',
          name: 'EAR max subsistema',
          color: theme.colors.black300,
          component: 'line',
          show: unit === 'mwmes',
          dashed: true,
        },
        {
          key: 'earVerifSubsistemaMwmes',
          name: 'EAR verif subsistema',
          color: theme.colors.blue400,
          component: 'line',
          show: unit === 'mwmes',
        },
        {
          key: 'earVerifSubsistemaPercentual',
          name: 'EAR verif subsistema percentual',
          color: theme.colors.blue400,
          component: 'line',
          show: unit === 'percent',
        },
      ].filter(dataset => dataset.show),
    [unit],
  )

  const { data, isLoading, isFetching } = useQuery(
    ['energia-armazenada-diaria-por-subsistema-data', start, end, subsistema],
    async () => {
      const response = await api.get<Record<string, unknown>[]>(
        `dados-abertos-ons/energia-armazenada-diaria-por-subsistema/${subsystemsWithSin[subsistema].keyPt}`,
        { params: { start: formatQueryDate(start), end: formatQueryDate(end) } },
      )

      return response.data
    },
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  return (
    <Container>
      <SectionTitle>Energia armazenada por subsistema</SectionTitle>
      <ControllerContainer>
        <InputDate
          label='Início'
          value={format(utcDateFromDate(start), 'yyyy-MM-dd')}
          onChange={e => {
            const newStart = parse(e.target.value, 'yyyy-MM-dd', new Date())
            if (isBefore(newStart, end)) return setStart(newStart)
            toast.error('Data inicial deve ser anterior a final.', { position: 'top-right' })
          }}
        />
        <InputDate
          label='Fim'
          value={format(utcDateFromDate(end), 'yyyy-MM-dd')}
          onChange={e => {
            const newEnd = parse(e.target.value, 'yyyy-MM-dd', new Date())
            if (isAfter(newEnd, start)) return setEnd(newEnd)
            toast.error('Data final deve ser posterior a inicial.', { position: 'top-right' })
          }}
        />
        <PrismaSelect
          label='Subsistema'
          onChange={event => setSubsistema(Number(event.target.value))}
          options={subsystemsWithSin.map((subsistema, index) => ({ label: subsistema.name, value: String(index) }))}
        />
        <PrismaSelect
          label='Unidade'
          value={unit}
          onChange={event => setUnit(event.target.value as 'mwmes' | 'percent')}
          options={[
            { label: 'MWmês', value: 'mwmes' },
            { label: 'Porcentagem', value: 'percent' },
          ]}
        />
      </ControllerContainer>
      <ChartContainer isLoading={isLoading} isFetching={isFetching}>
        <ResponsiveContainer>
          <ComposedChart data={data}>
            <CartesianGrid strokeDasharray='3 5' />
            <XAxis dataKey='earData' tick={{ fontSize: '14px' }} tickFormatter={xTickFormatter} />
            <YAxis
              width={70}
              tick={{ fontSize: '14px' }}
              domain={unit === 'percent' ? [0, 100] : undefined}
              tickCount={unit === 'mwmes' ? 8 : 12}
              tickFormatter={unit === 'mwmes' ? yTickFormatter : y2TickFormatter}
            >
              <Label
                angle={-90}
                value={unit === 'mwmes' ? 'MWmês' : '%'}
                position='insideLeft'
                style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
              />
            </YAxis>
            <Legend iconType='square' wrapperStyle={{ fontSize: '14px' }} />
            <Tooltip labelFormatter={label => xTickFormatter(String(label))} formatter={value => `${Number(value).toLocaleString('pt-br')} MWmês`} />
            {datasets.map(dataset => {
              if (dataset.component === 'area') {
                return <Area key={dataset.key} dataKey={dataset.key} fill={dataset.color} name={dataset.name} stroke={dataset.color} />
              }

              if (dataset.component === 'line') {
                return (
                  <Line
                    dot={dataset.dashed ? false : undefined}
                    strokeDasharray={dataset.dashed ? '3 5' : undefined}
                    key={dataset.key}
                    dataKey={dataset.key}
                    stroke={dataset.color}
                    fill={dataset.color}
                    name={dataset.name}
                    strokeWidth={3}
                  />
                )
              }

              return null
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}
