import React, { useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ResponsiveContainer, ComposedChart, Area, CartesianGrid, YAxis, XAxis, Tooltip, Legend, Label, Line } from 'recharts'
import { PrismaSelect, InputDate } from 'components/Input'
import { Container, ControllerContainer } from './styles'
import { SectionTitle } from 'components/Section'
import { useTheme } from 'styled-components'
import { add, format, isAfter, isBefore, parse } from 'date-fns'
import { formatQueryDate, utcDateFromDate } from 'utils'
import { toast } from 'react-toastify'
import { ChartContainer } from '../..'

const yTickFormatter = (tick: string) => Intl.NumberFormat('pt-br').format(Number(tick))

const y2TickFormatter = (tick: string) => Intl.NumberFormat('pt-br', { style: 'percent' }).format(Number(tick) / 100)

const xTickFormatter = (tick: string) => {
  return Intl.DateTimeFormat('pt-br', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    timeZone: 'UTC',
  }).format(new Date(tick))
}

const datasets = [
  {
    key: 'enaBrutaResMwmed',
    name: 'ENA bruta reservatório',
    color: getRandomColor(),
    component: 'line',
    yAxisId: 1,
  },
  {
    key: 'enaBrutaResPercentualmlt',
    name: 'ENA bruta reservatório percentual mlt',
    color: getRandomColor(),
    component: 'line',
    yAxisId: 1,
  },
  {
    key: 'enaArmazenavelResMwmed',
    name: 'ENA armazenavel reservatório',
    color: getRandomColor(),
    component: 'line',
    yAxisId: 1,
  },
  {
    key: 'enaArmazenavelResPercentualmlt',
    name: 'ENA armazenavel reservatório percentual mlt',
    color: getRandomColor(),
    component: 'line',
    yAxisId: 1,
  },
  {
    key: 'enaQuedaBruta',
    name: 'ENA queda bruta',
    color: getRandomColor(),
    component: 'line',
    yAxisId: 1,
  },
  {
    key: 'mltEna',
    name: 'MLT ENA',
    color: getRandomColor(),
    component: 'line',
    yAxisId: 1,
  },
]

function getRandomColor() {
  const hue = Math.floor(Math.random() * 360)
  const saturation = Math.floor(Math.random() * 50) + 50 // Random saturation between 50 and 100
  const lightness = Math.floor(Math.random() * 20) + 50 // Random lightness between 50 and 70
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

export function EnaDiariaPorReservatorio() {
  const [end, setEnd] = useState(new Date())
  const [start, setStart] = useState(add(end, { days: -10 }))
  const [reservatorio, setReservatorio] = useState(0)

  const theme = useTheme()

  const {
    data: reservatorios,
    isLoading: isLoading1,
    isFetching: isFetching1,
  } = useQuery(
    ['ena-diaria-por-reservatoiro-reservatorios', start, end],
    async () => {
      const response = await api.get<string[]>('dados-abertos-ons/ena-diaria-por-reservatorio', {
        params: { start: formatQueryDate(start), end: formatQueryDate(end) },
      })

      return response.data
    },
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  const {
    data,
    isLoading: isLoading2,
    isFetching: isFetching2,
  } = useQuery(
    ['ena-diaria-por-reservatorio-data', start, end, reservatorio],
    async () => {
      const response = await api.get<Record<string, unknown>[]>(`dados-abertos-ons/ena-diaria-por-reservatorio/${reservatorios?.[reservatorio]}`, {
        params: { start: formatQueryDate(start), end: formatQueryDate(end) },
      })

      return response.data
    },
    { keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!reservatorios },
  )

  return (
    <Container>
      <SectionTitle>ENA por reservatório</SectionTitle>
      <ControllerContainer>
        <InputDate
          label='Início'
          value={format(utcDateFromDate(start), 'yyyy-MM-dd')}
          onChange={e => {
            const newStart = parse(e.target.value, 'yyyy-MM-dd', new Date())
            if (isBefore(newStart, end)) return setStart(newStart)
            toast.error('Data inicial deve ser anterior a final.', { position: 'top-right' })
          }}
        />
        <InputDate
          label='Fim'
          value={format(utcDateFromDate(end), 'yyyy-MM-dd')}
          onChange={e => {
            const newEnd = parse(e.target.value, 'yyyy-MM-dd', new Date())
            if (isAfter(newEnd, start)) return setEnd(newEnd)
            toast.error('Data final deve ser posterior a inicial.', { position: 'top-right' })
          }}
        />
        <PrismaSelect
          label='Reservatório'
          onChange={event => setReservatorio(Number(event.target.value))}
          options={reservatorios?.map((reservatorio, index) => ({ label: reservatorio, value: String(index) }))}
        />
      </ControllerContainer>
      <ChartContainer isFetching={isFetching1 || isFetching2} isLoading={isLoading1 || isLoading2}>
        <ResponsiveContainer>
          <ComposedChart data={data}>
            <CartesianGrid strokeDasharray='3 5' />
            <XAxis dataKey='enaData' tick={{ fontSize: '14px' }} tickFormatter={xTickFormatter} />
            <YAxis tick={{ fontSize: '14px' }} tickCount={8} yAxisId={1} tickFormatter={yTickFormatter}>
              <Label
                angle={-90}
                value={'MWmês'}
                position='insideLeft'
                style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
              />
            </YAxis>
            <YAxis tick={{ fontSize: '14px' }} tickCount={12} yAxisId={2} orientation='right' domain={[0, 100]} tickFormatter={y2TickFormatter} />
            <Legend iconType='square' wrapperStyle={{ fontSize: '14px' }} />
            <Tooltip labelFormatter={label => xTickFormatter(String(label))} />
            {datasets.map(dataset => {
              if (dataset.component === 'area')
                return (
                  <Area
                    key={dataset.key}
                    dataKey={dataset.key}
                    fill={dataset.color}
                    name={dataset.name}
                    yAxisId={dataset.yAxisId}
                    stroke={dataset.color}
                  />
                )

              if (dataset.component === 'line')
                return (
                  <Line
                    key={dataset.key}
                    dataKey={dataset.key}
                    stroke={dataset.color}
                    fill={dataset.color}
                    name={dataset.name}
                    yAxisId={dataset.yAxisId}
                  />
                )

              return null
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}
