import React from 'react'
import { TooltipProps } from 'recharts'

import { returnBRNumber, returnColorFromNumber, utcDateFromDate } from 'utils'
import { Container, TooltipGrid, TooltipInfoContainer, TooltipInfoValue, TooltipTitleContainer, ToolTipDivider } from 'components/CustomTooltip'

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
  if (!active || !payload || !label) return null
  if (!payload[0]) return null

  const sinDifference = payload[0].payload.sinForecast - payload[0].payload.sinVerified
  const seDifference = payload[0].payload.southeastForecast - payload[0].payload.southeastVerified
  const sDifference = payload[0].payload.southForecast - payload[0].payload.southVerified
  const neDifference = payload[0].payload.northeastForecast - payload[0].payload.northeastVerified
  const nDifference = payload[0].payload.northForecast - payload[0].payload.northVerified

  return (
    <Container>
      <TooltipTitleContainer>
        {(() => {
          const date = utcDateFromDate(new Date(payload[0].payload.time))
          const dateString = date.toLocaleString()
          return dateString
        })()}
      </TooltipTitleContainer>
      <TooltipGrid columns={7}>
        <TooltipInfoContainer>
          <TooltipInfoValue transparent>-</TooltipInfoValue>
          <TooltipInfoValue>SIN</TooltipInfoValue>
          <TooltipInfoValue>Sudeste</TooltipInfoValue>
          <TooltipInfoValue>Sul</TooltipInfoValue>
          <TooltipInfoValue>Nordeste</TooltipInfoValue>
          <TooltipInfoValue>Norte</TooltipInfoValue>
        </TooltipInfoContainer>
        <ToolTipDivider />
        <TooltipInfoContainer>
          <TooltipInfoValue>Previsão</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.sinForecast)}</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.southeastForecast)}</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.southForecast)}</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.northeastForecast)}</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.northForecast)}</TooltipInfoValue>
        </TooltipInfoContainer>
        <ToolTipDivider />
        <TooltipInfoContainer>
          <TooltipInfoValue>Verificado</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.sinVerified)}</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.southeastVerified)}</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.southVerified)}</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.northeastVerified)}</TooltipInfoValue>
          <TooltipInfoValue>{returnBRNumber(payload[0].payload.northVerified)}</TooltipInfoValue>
        </TooltipInfoContainer>
        <ToolTipDivider />
        <TooltipInfoContainer>
          <TooltipInfoValue>Diferença</TooltipInfoValue>
          <TooltipInfoValue color={returnColorFromNumber(sinDifference)}>{returnBRNumber(sinDifference)}</TooltipInfoValue>
          <TooltipInfoValue color={returnColorFromNumber(seDifference)}>{returnBRNumber(seDifference)}</TooltipInfoValue>
          <TooltipInfoValue color={returnColorFromNumber(sDifference)}>{returnBRNumber(sDifference)}</TooltipInfoValue>
          <TooltipInfoValue color={returnColorFromNumber(neDifference)}>{returnBRNumber(neDifference)}</TooltipInfoValue>
          <TooltipInfoValue color={returnColorFromNumber(nDifference)}>{returnBRNumber(nDifference)}</TooltipInfoValue>
        </TooltipInfoContainer>
      </TooltipGrid>
    </Container>
  )
}

export default CustomTooltip
