import React from 'react'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { Container } from './styles'

export const ChartsLoadingPlaceholder: React.FC = () => (
  <Container>
    <RectShape color='#ccc' style={{ height: '300px' }} />
    <RectShape color='#ccc' style={{ height: '300px' }} />
    <RectShape color='#ccc' style={{ height: '300px' }} />
    <RectShape color='#ccc' style={{ height: '300px' }} />
  </Container>
)
