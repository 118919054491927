import { transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2rem;
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr auto 1fr auto;
  gap: 1rem;
`

export const ChartContainer = styled.div`
  margin-top: 1rem;
  height: 310px;
  position: relative;
`

export const ThresholdsContainer = styled.div`
  table {
    white-space: nowrap;
    text-align: center;
    border-spacing: 0 0.4rem;
    min-width: 200px;
    thead tr {
      th {
        padding: 0rem;
        color: ${props => transparentize(0.5, props.theme.colors.blue700)};
        :last-child {
          padding-right: 1rem;
        }
      }
      :first-child th {
        padding: 0;
      }
    }
    td {
      padding: 0.1rem;
      font-size: 0.75rem;
      border-bottom: 1px solid #eaeaea;
      color: ${props => props.theme.colors.blue700};
      :first-child {
        border-radius: 0.3rem 0 0 0.3rem;
      }
      :last-child {
        border-radius: 0 0.3rem 0.3rem 0;
        padding-right: 1rem;
      }
    }
  }
`
