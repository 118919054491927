import React, { ChangeEventHandler } from 'react'
import { isValid } from 'date-fns'

import { Container, InputElement, Label } from './styles'

interface Select {
  label?: string
  max?: string
  min?: string
  value?: string
  disabled?: boolean
  type?: 'month'
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const InputDate: React.FC<Select> = ({ max, min, label, value, disabled, type, onChange }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <InputElement
        required
        disabled={disabled}
        type={type || 'date'}
        max={max}
        min={min}
        value={value}
        onChange={event => {
          if (Number.isNaN(Date.parse(event.target.value))) return

          const newDate = new Date(event.target.value)

          if (newDate.getFullYear().toString().length > 4) return

          if (!isValid(newDate)) return

          onChange?.(event)
        }}
      />
    </Container>
  )
}

export default InputDate
