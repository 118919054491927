import React from 'react'
import { TooltipProps } from 'recharts'
import { returnBRNumber, capitalize } from 'utils'

import { Container, TooltipTitleContainer, TooltipGrid, TooltipInfoContainer, TooltipInfoValue, TooltipContainer } from 'components/CustomTooltip'

const CustomTooltip: React.FC<TooltipProps> = ({ payload, label, active }) => {
  const titleFormatter = (date: string): string => {
    const month = new Date(date).toLocaleString('pt-BR', { month: 'short' })
    const year = new Date(date).toLocaleString('pt-BR', { year: 'numeric' })
    return `${capitalize(month)}/${year}`
  }

  if (active && payload && label) {
    return (
      <Container>
        <TooltipContainer>
          <TooltipTitleContainer>{titleFormatter(payload[0].payload.date)}</TooltipTitleContainer>
          <TooltipGrid columns={2}>
            <TooltipInfoContainer>
              <TooltipInfoValue bold>Índice</TooltipInfoValue>
            </TooltipInfoContainer>
            <TooltipInfoContainer>
              <TooltipInfoValue>{returnBRNumber(payload[0].payload.value)}</TooltipInfoValue>
            </TooltipInfoContainer>
          </TooltipGrid>
        </TooltipContainer>
      </Container>
    )
  }
  return null
}

export default CustomTooltip
