import React from 'react'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { Container } from './styles'

export const LoadingBlocks = () => (
  <Container>
    {Array.from({ length: 10 }).map((...[, index]) => (
      <RectShape key={index} color='#ccc' style={{ height: '15rem', borderRadius: '1rem' }} />
    ))}
  </Container>
)
