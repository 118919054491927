import styled, { keyframes } from 'styled-components'
import { transparentize } from 'polished'

export const Container = styled.div`
  background: #fff;
  max-width: 1600px;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 8rem;
`

export const ControllerContainer = styled.div`
  max-width: 900px;
  margin: 50px auto 0 auto;
  span {
    height: 1rem;
    display: flex;
    justify-content: flex-end;
    font-size: 0.9rem;
    padding-top: 0.25rem;
    color: #ccc;
    :first-child {
      color: ${props => transparentize(0.5, props.theme.colors.blue400)};
      font-weight: bold;
    }
    span {
      height: 1rem;
    }
  }
`

export const PostsContainer = styled.div<{ isFetching: boolean }>`
  padding-top: 50px;
  padding-top: 50px;
  max-width: 900px;
  margin: 0 auto;
  min-height: calc(100vh - 621px);
  ${props =>
    props.isFetching &&
    `
    filter: opacity(0.1);
  `}
`

const postContainerAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const PostContainer = styled.div<{ index: number }>`
  position: relative;
  transition: transform 200ms;
  animation: ${postContainerAnimation} 300ms 1;
  animation-fill-mode: backwards;
  animation-delay: ${props => (props.index + 1) * 50}ms;
  time {
    color: rgba(0, 0, 0, 0.3);
    font-weight: 600;
  }
  strong {
    margin-top: 0.25rem;
    display: block;
    font-size: 2rem;
    transition: 0.2s;
    color: ${props => props.theme.safiraColors.blue[3]};
    :hover {
      color: ${props => props.theme.safiraColors.green[3]};
    }
  }
  p {
    color: rgba(0, 0, 0, 0.5);
    display: block;
    margin-top: 0.5rem;
    line-height: 2rem;
    transition: 0.2s;
  }
  & + div {
    margin-top: 3rem;
    border-top: 1px solid #eaeaea;
    padding-top: 3rem;
  }
  :hover {
    transform: scale(1.01);
    p {
      color: ${props => props.theme.safiraColors.blue[4]};
    }
  }
`

export const PaginatorContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  span {
    color: rgba(0, 0, 0, 0.6);
  }
  button {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    :focus {
      border: 2px solid #eaeaea;
    }
    :disabled {
      filter: opacity(0.3);
    }
  }
`
