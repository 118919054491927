import React, { useState } from 'react'
import { add, format } from 'date-fns'
import { capitalize } from 'utils'
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import MapGallery from 'components/MapGallery'
import { Container } from './styles'

export function Climate() {
  const [date] = useState(new Date())
  const models = ['cfsv2']
  // const models = ['cfsv2', 'ecmwf']
  const months = [0, 1, 2, 3, 4, 5]

  const images = months.map(month => {
    const formattedCFSDate = format(date, 'yyyyMMdd')
    // const formattedECMWFDate = format(date, 'yyyyMM')
    const targetDate = add(date, { months: month })
    const formattedTargetDate = format(targetDate, 'yyyyMM')
    const monthInFull = capitalize(targetDate.toLocaleString('pt-BR', { month: 'long' }))
    const year = targetDate.getFullYear()
    return {
      title: `Previsão para ${monthInFull} de ${year}`,
      // ecmwf: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedECMWFDate}/modelo-seas-ecmwf/SEAS-ECMWF_r${formattedECMWFDate}_p${formattedTargetDate}.png`,
      cfsv2: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedCFSDate}/modelo-cfs-clima/CFSv2_r${formattedCFSDate}_p${formattedTargetDate}.png`,
    }
  })

  const descriptions = {
    // ecmwf: (
    //   <>
    //     <p>
    //       Mapa de anomalia mensal de precipitação prevista pelo modelo SEAS do European Centre for Medium-Range Weather Forecasts (ECMWF), rodado e
    //       disponibilizado pela instituição de mesmo nome e pela plataforma Copernicus. O SEAS (ECMWF) é um modelo de previsão por conjunto (ensemble)
    //       global e horizonte de previsão de 6 meses. No produto abaixo estão disponibilizados os produtos referentes à média dos 51 membros do
    //       conjunto.
    //     </p>
    //     <p style={{ fontStyle: 'italic' }}>
    //       <a href='https://cds.climate.copernicus.eu/api/v2/terms/static/licence-to-use-copernicus-products.pdf' target='_blank' rel='noreferrer'>
    //         Licence to use Copernicus Products
    //       </a>
    //     </p>
    //     <p style={{ fontStyle: 'italic' }}>
    //       All the data published in the Climate Data Store as part of the C3S seasonal forecast multi-system may be used and redistributed without
    //       restrictions.
    //     </p>
    //     <p style={{ fontStyle: 'italic' }}>
    //       For data originating from non-European modelling centres (whose contributions to C3S are in-kind), the following additional terms and
    //       conditions apply:
    //     </p>
    //     <ul
    //       style={{
    //         color: 'black',
    //         textAlign: 'start',
    //         fontWeight: 'normal',
    //         listStylePosition: 'inside',
    //         fontStyle: 'italic',
    //       }}
    //     >
    //       <li>
    //         The original source of the dataset should be duly acknowledged when it is used in scientific or technical papers, publications, press
    //         releases or other communications regarding the data.
    //       </li>
    //       <li>The originating centres do not guarantee the continuity of service, or the overall quality of service and content of information.</li>
    //       <li>The originating centres assume no liability or responsibility for any problem arising from the access to information or its use.</li>
    //     </ul>
    //   </>
    // ),
    cfsv2: (
      <p>
        Mapa de anomalia mensal de precipitação prevista pelo modelo CFSv2 (Climate Forecast System version 2), rodado e disponibilizado pelo National
        Centers for Environmental Prediction (NCEP) da National Oceanic and Atmospheric Administration (NOAA). O SEAS (ECMWF) é um modelo de previsão
        por conjunto (ensemble) global nos primeiros 4 meses de previsão (mês corrente + 3), com a utilização da rodada controle até os 10 meses de
        horizonte (mês corrente + 9). No produto abaixo, para os primeiros 4 meses de previsão estão disponibilizados os produtos referentes à média
        dos 4 membros do conjunto (controle + 3 outros membros) dos últimos 3 dias (dia corrente e os 2 dias anteriores). Para o resto do horizonte os
        produtos disponibilizados são uma média da rodada controle dos últimos 4 dias de previsão (dia corrente e os 3 dias anteriores). A
        climatologia de referência englobra o período de 1982 a 2010.
      </p>
    ),
  }

  return (
    <Container>
      <SectionTitle>Previsão de clima</SectionTitle>
      <SectionText>
        <span>
          Mapas de previsão mensal de anomalia de precipitação dos modelos ECMWF (SEAS) e CFSv2: anomalias positivas (verde) e negativas (laranja)
          indicam chuva acima e abaixo da média, respectivamente. Utilize as teclas
        </span>
        <BsArrowLeftShort style={{ marginLeft: '5px' }} />
        <BsArrowRightShort style={{ marginRight: '5px' }} />
        <span>para alternar a data da previsão.</span>
      </SectionText>
      <MapGallery images={images} models={models} scale='climate' descriptions={descriptions} />
    </Container>
  )
}
