import React from 'react'

import { Container } from './styles'

interface SectionText {
  text?: string
}

const SectionText: React.FC<SectionText> = ({ text, children }) => {
  return (
    <Container>
      <span>{text}</span>
      <span>{children}</span>
    </Container>
  )
}

export default SectionText
