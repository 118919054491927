import React from 'react'
import { add, format, eachHourOfInterval, startOfDay } from 'date-fns'
import SectionText from 'components/SectionText'
import SectionTitle from 'components/SectionTitle'
import { utcDateFromDate } from 'utils'
import { Container, ImageContainer } from './styles'
import ScaleGoes from 'components/MapGallery/ScaleGoes'

const Forecast2: React.FC = () => {
  const date = utcDateFromDate(add(new Date(), { minutes: -10 }))
  const formattedDate = format(date, 'yyyyMMdd')

  const mapMinutes = eachHourOfInterval({ start: startOfDay(date), end: date }).flatMap(hour => {
    const hourToWrite = hour.getHours() < 10 ? `0${hour.getHours()}` : hour.getHours()
    const lastCurrentMinuteMultipleOf10 = Math.floor(date.getMinutes() / 10) * 10
    const currentHour = date.getHours()
    let minutes = ['00', '10', '20', '30', '40', '50']
    if (hour.getHours() === currentHour) minutes = minutes.filter(minute => Number(minute) < lastCurrentMinuteMultipleOf10)
    return minutes.map(minute => `${hourToWrite}${minute}`)
  })

  const images = mapMinutes.map(mapMinute => ({
    title: `${mapMinute.split('').splice(0, 2).join('')}:${mapMinute.split('').splice(2, 2).join('')}`,
    'Imagem de Satélite': `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/goes-satellite-13/G16_BAND13_SEC_${formattedDate}${mapMinute}.png`,
  }))

  return (
    <Container>
      <SectionTitle
        title='Imagem de Satélite'
        info='Imagem do canal 13 (infravermelho) do satélite geoestacionário GOES-16 (NOAA/NASA). A imagem mostra a temperatura de brilho (Tb), que pode ser aproximada como a temperatura do topo das nuvens. Nesse sentido, quanto mais baixa a temperatura, maior a altura em que se encontra o topo da nuvem e, portanto, maior o seu desenvolvimento vertical. Consequentemente, temperaturas de brilho mais baixas indicam áreas onde tempestades estão se desenvolvendo e temperaturas de brilho não tão baixas podem indicar áreas onde chuva fraca a moderada pode estar ocorrendo.'
      />
      <SectionText>Imagens de satélite GOES-16 (canal 13 - infravermelho), disponibilizadas a cada 10 min.</SectionText>
      <ImageContainer>
        <img src={images.pop()?.['Imagem de Satélite']} alt='' />
        <ScaleGoes />
      </ImageContainer>
    </Container>
  )
}

export default Forecast2
