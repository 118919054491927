import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { BsFillDoorOpenFill } from 'react-icons/bs'
import { useAuth } from 'oidc-react'
import { CompanyContext } from 'CompanyProvider'

import { Container, LogoContainer, MenuContainer, MenuItem, UserContainer, DynamicMapLink } from './styles'

const NavBar: React.FC = () => {
  const location = useLocation()
  const companyLevel = useContext(CompanyContext)

  const auth = useAuth()

  const navItems = [
    {
      text: 'Home',
      link: '/home',
      activeTestString: '/home',
      minCompanyLevel: 0,
    },
    {
      text: 'Operação',
      link: '/operation/real-time/acomplished',
      activeTestString: '/operation',
      minCompanyLevel: 0,
    },
    {
      text: 'Regulatório',
      link: '/regulatorio',
      activeTestString: '/regulatorio',
      minCompanyLevel: 1,
    },
    {
      text: 'PLD horário',
      link: '/dessem/current-day',
      activeTestString: '/dessem',
      minCompanyLevel: 0,
    },
    {
      text: 'Meteorologia',
      link: companyLevel >= 1 ? '/meteorology/reports' : '/meteorology/acomplished',
      activeTestString: '/meteorology',
      minCompanyLevel: 0,
    },
    {
      text: 'Estudos',
      link: '/estudos/curtissimo-prazo',
      activeTestString: '/estudos',
      minCompanyLevel: 1,
    },
    {
      text: 'Mercado',
      link: '/market/forward-curve/by-day',
      activeTestString: '/market',
      minCompanyLevel: 1,
    },
  ] as const

  return (
    <Container>
      <LogoContainer>
        <a href={process.env.REACT_APP_SAFIRA_ON_LOGIN_URL}>
          <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/images/client/safira-on-white.png`} alt='Safira On Logo' />
        </a>
      </LogoContainer>
      <MenuContainer>
        <ul>
          {navItems
            .filter(navItem => companyLevel >= navItem.minCompanyLevel)
            .map((navItem, index) => (
              <li key={navItem.link}>
                <MenuItem position={index + 1} to={navItem.link} active={location.pathname.includes(navItem.activeTestString)}>
                  {navItem.text}
                </MenuItem>
              </li>
            ))}
          <li>
            <DynamicMapLink
              target='_blank'
              rel='noreferer noopener'
              href={`https://mapa.inteligencia.safiraenergia.com.br?user=${auth.userData?.profile.email}`}
            >
              Mapa dinâmico
            </DynamicMapLink>
          </li>
        </ul>
      </MenuContainer>
      <UserContainer>
        <span>{auth.userData?.profile?.name}</span>
        <button type='button' onClick={() => auth.signOutRedirect()}>
          <BsFillDoorOpenFill title='Sair' />
        </button>
      </UserContainer>
    </Container>
  )
}

export default NavBar
