import React, { useState } from 'react'
import { format, add } from 'date-fns'
import { utcDateFromDate } from 'utils'
import { InputDate } from 'components/Input'
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import MapGallery from 'components/MapGallery'
import { Container } from './styles'
import { ControllerContainer } from 'components/Layout'
import { EcmwfDescription } from '..'

const Daily: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const models = ['ecmwf', 'ec00', 'gfs', 'gefs']
  const pentads = [1, 2, 3]

  const images = pentads.map(pentad => {
    const formattedDate = format(date, 'yyyyMMdd')
    const firstDate = add(date, { days: (pentad - 1) * 5 })
    const secondDate = add(date, { days: pentad === 3 ? pentad * 5 - 1 : pentad * 5 })
    const firstWeekday = firstDate.toLocaleString('pt-BR', { weekday: 'long' })
    const secondWeekday = secondDate.toLocaleString('pt-BR', { weekday: 'long' })
    return {
      title: `Previsão de ${firstWeekday}, ${firstDate.toLocaleDateString()} até ${secondWeekday}, ${secondDate.toLocaleDateString()}`,
      ecmwf: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-ecmwf-backup/ECMWF_r${formattedDate}_00Z_pentada${pentad}.png`,
      ec00: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-ec00-backup/EC00_r${formattedDate}_00Z_pentada${pentad}.png`,
      gfs: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-gfs-backup/GFS_r${formattedDate}_00Z_pentada${pentad}.png`,
      gefs: `${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/modelo-gefs-backup/GEFS_r${formattedDate}_00Z_pentada${pentad}.png`,
    }
  })

  const descriptions = {
    ecmwf: (
      <EcmwfDescription initialText='Mapa de chuva prevista em pêntadas pelo modelo European Centre for Medium-Range Weather Forecasts (ECMWF), rodado e disponibilizado pela instituição de mesmo nome. O ECMWF é um modelo de previsão numérica por conjunto (ensemble) global e possui 0.2º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão média do conjunto.' />
    ),
    ec00: (
      <EcmwfDescription initialText='Mapa de chuva prevista em pêntadas pelo modelo European Centre for Medium-Range Weather Forecasts (ECMWF), rodado e disponibilizado pela instituição de mesmo nome. O ECMWF é um modelo de previsão numérica por conjunto (ensemble) global e possui 0.2º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão do membro controle.' />
    ),
    gfs: (
      <p>
        Mapa de chuva prevista em pêntadas pelo modelo Global Forecast System (GFS), rodado e disponibilizado pelo National Centers for Environmental
        Prediction (NCEP) da National Oceanic and Atmospheric Administration (NOAA), dos Estados Unidos da América. O GFS é um modelo de previsão
        numérica determinístico possui 0.25º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo estão disponibilizados os
        primeiros 14 dias de previsão.
      </p>
    ),
    gefs: (
      <p>
        Mapa de chuva prevista em pêntadas pelo modelo Global Ensemble Forecast System (GEFS), rodado e disponibilizado pelo National Centers for
        Environmental Prediction (NCEP) da National Oceanic and Atmospheric Administration (NOAA), dos Estados Unidos da América. O GEFS é um modelo
        de previsão numérica por conjunto (ensemble) global e possui 0.5º de resolução espacial e horizonte de previsão de 16 dias. No produto abaixo
        estão disponibilizados os primeiros 14 dias de previsão e é apresentada a previsão média do conjunto.
      </p>
    ),
  }

  const aliases = {
    ecmwf: 'ECMWF - ENS',
    ec00: 'ECMWF - Controle',
    gfs: 'GFS',
    gefs: 'GEFS - ENS',
  }

  return (
    <Container>
      <SectionTitle>Previsão de 5 dias</SectionTitle>
      <SectionText>
        <span>
          Mapas de previsão de 5 dias de chuva dos modelos de previsão de tempo ECMWF (média do conjunto e controle), GEFS (média do conjunto) e GFS.
          Utilize as teclas
        </span>
        <BsArrowLeftShort style={{ marginLeft: '5px' }} />
        <BsArrowRightShort style={{ marginRight: '5px' }} />
        <span>para alternar a data da previsão.</span>
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Data'
          value={format(date, 'yyyy-MM-dd')}
          max={format(new Date(), 'yyyy-MM-dd')}
          onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
        />
      </ControllerContainer>
      <MapGallery images={images} models={models} descriptions={descriptions} aliases={aliases} scale='forecast' />
    </Container>
  )
}

export default Daily
