import React from 'react'
import { Section } from 'components/Section'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import api from 'services/api'
import { ResponsiveContainer, LineChart, XAxis, YAxis, Line, Legend, Tooltip, CartesianGrid, Label } from 'recharts'
import { useTheme } from 'styled-components'
import { CustomLegend } from 'components/Chart'
import { capitalize } from 'lodash'
import { Container, ChartsContainer, ChartContainer } from './styles'
import CustomTooltip from './CustomTooltip'
import { useQuery } from 'react-query'

interface LongTermAverageDTO {
  month: number
  southeast: number
  south: number
  northeast: number
  north: number
}

export function ENA() {
  const theme = useTheme()

  const { data: longTermAverages } = useQuery(
    'long-term-averages',
    () => api.get<LongTermAverageDTO[]>('long-term-averages').then(({ data }) => data),
    {
      staleTime: 60 * 1000, // 1 minute
    },
  )

  const charts = [
    {
      name: 'Sudeste',
      dataKey: 'southeast',
      color: theme.safiraColors.blue[3],
    },
    {
      name: 'Sul',
      dataKey: 'south',
      color: theme.safiraColors.yellow[3],
    },
    {
      name: 'Nordeste',
      dataKey: 'northeast',
      color: theme.safiraColors.orange[3],
    },
    {
      name: 'Norte',
      dataKey: 'north',
      color: theme.safiraColors.green[3],
    },
    {
      name: 'SIN',
      dataKey: 'sin',
      color: theme.safiraColors.blue[4],
    },
  ]

  return (
    <Container>
      <Section>
        <SectionTitle title='Média de longo termo (MLT)' />
        <SectionText>Média da Energia Natural Afluente (ENA) de 1931 a 2022, por submercado e para o SIN.</SectionText>
        <ChartsContainer>
          {charts.map(chart => (
            <ChartContainer key={chart.name}>
              <ResponsiveContainer width='100%'>
                <LineChart data={longTermAverages} syncId='mlt'>
                  <XAxis
                    dataKey='month'
                    tick={{ fontSize: '12px' }}
                    tickFormatter={tick => capitalize(new Date(1, tick - 1, 1).toLocaleDateString('pt-BR', { month: 'short' }))}
                  />
                  <YAxis width={70} dataKey={chart.dataKey} tick={{ fontSize: '12px' }} tickFormatter={tick => Number(tick).toLocaleString('pt-BR')}>
                    <Label
                      angle={-90}
                      value='MLT ( MWmed )'
                      fontSize={12}
                      position='insideLeft'
                      style={{ fontSize: '12px', fill: '#ccc', textAnchor: 'middle' }}
                    />
                  </YAxis>
                  <CartesianGrid strokeDasharray='3 3' />
                  <Legend content={<CustomLegend legends={[{ text: chart.name, color: chart.color }]} />} />
                  <Tooltip content={<CustomTooltip dataKey={chart.dataKey} />} />
                  <Line
                    type='monotone'
                    name={chart.name}
                    legendType='plainline'
                    dataKey={chart.dataKey}
                    strokeWidth={3}
                    fill={chart.color}
                    stroke={chart.color}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartContainer>
          ))}
        </ChartsContainer>
      </Section>
    </Container>
  )
}

export default ENA
