import React, { useState, useEffect, useRef, MouseEventHandler } from 'react'
import api from 'services/api'
import { format } from 'date-fns'
import ptBr from 'date-fns/locale/pt-BR'
import { useTheme } from 'styled-components'
import { capitalize } from 'lodash'
import { BiCloudDownload } from 'react-icons/bi'
import { ResponsiveContainer, LineChart, XAxis, YAxis, Legend, Tooltip, Line, CartesianGrid, Label } from 'recharts'
import { SectionTitle, SectionText } from 'components/Section'
import { InputDate, InputNumber, Select } from 'components/Input'
import InputRadio from 'components/InputRadio'
import { ControllerContainer } from 'components/Layout'
import { utcDateFromDate, numberToBrl } from 'utils'
import { ChartContainer, CustomLegend, LoadingContainer } from 'components/Chart'
import { Container, TableContainer, DownloadBar, DownloadButton } from './styles'
import CustomTooltip from './CustomTooltip'
import { useDebounce } from 'usehooks-ts'

interface ApiResponse {
  curves: {
    index: number
    createdAt: Date
  }[]
  series: {
    product: string
    [index: number]: {
      id: number
      control: number
      register: Date
      product: Date
      convencional: number
      convencionalBuy: number
      convencionalSell: number
      incentivated50: number
      incentivated50Buy: number
      incentivated50Sell: number
      incentivated100: number
      incentivated100Buy: number
      incentivated100Sell: number
      created_at: Date
      updated_at: Date
      cq5: number
      cq5Buy: number
      cq5Sell: number
      spreadSouth: number
      spreadNortheast: number
      spreadNorth: number
    }
  }[]
}

export const ForwardCurveByDay: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const [type, setType] = useState('')
  const [registers, setRegisters] = useState<{ register: string }[]>([])
  const [curveIndex, setCurveIndex] = useState<number>()
  const [downloadUrl, setDownloadUrl] = useState<string>()
  const [data, setData] = useState<ApiResponse>({ curves: [], series: [] })
  const [loading, setLoading] = useState(true)
  const [config, setConfig] = useState<{ dateMin: Date; dateMax: Date }>()
  const [yAxisMaxValue, setYAxisMaxValue] = useState<number>(0)
  const debouncedYAxisMaxValue = useDebounce(yAxisMaxValue, 1000)
  const downloadRef = useRef<HTMLAnchorElement>(document.createElement('a'))
  const theme = useTheme()
  const chartLines = [
    { name: 'Convencional', key: 'convencional', color: theme.safiraColors.orange[3] },
    { name: 'CQ5', key: 'cq5', color: theme.safiraColors.blue[3] },
    { name: 'Incentivada 50%', key: 'incentivated50', color: theme.safiraColors.yellow[3] },
    { name: 'Incentivada 100%', key: 'incentivated100', color: theme.safiraColors.green[3] },
  ]
  const keys = [
    { key: 'product', label: 'Produto' },
    { key: 'convencional', label: 'Convencional' },
    { key: 'incentivated50', label: 'Incentivada 50%' },
    { key: 'incentivated100', label: 'Incentivada 100%' },
    { key: 'cq5', label: 'CQ5' },
    { key: 'spreadSouth', label: 'Swap SE/S' },
    { key: 'spreadNortheast', label: 'Swap SE/NE' },
    { key: 'spreadNorth', label: 'Swap SE/N' },
  ]

  useEffect(() => {
    if (downloadUrl) downloadRef.current.click()
  }, [downloadUrl])

  useEffect(() => {
    api.get<{ register: string }[]>('forward-curve/list-curve-registers').then(response => {
      const rawFirstAvailableRegister = [...response.data].pop()
      const rawLastAvailableRegister = [...response.data].shift()
      if (response.data.length === 0 || !rawLastAvailableRegister || !rawFirstAvailableRegister) return
      setDate(utcDateFromDate(new Date(rawLastAvailableRegister.register)))
      setRegisters(response.data)
      setConfig({
        dateMin: utcDateFromDate(new Date(rawFirstAvailableRegister.register)),
        dateMax: utcDateFromDate(new Date(rawLastAvailableRegister.register)),
      })
    })
  }, [])

  useEffect(() => {
    if (registers.length === 0) return
    setLoading(true)
    api.get<ApiResponse>('forward-curve', { params: { date: date.toISOString() } }).then(response => {
      setData(response.data)
      setCurveIndex([...response.data.curves.map(curve => curve.index)].pop())
      setTimeout(() => setLoading(false), 500)
    })
  }, [date, registers])

  const downloadData: MouseEventHandler<HTMLButtonElement> = ev => {
    ev.preventDefault()
    if (!data || !curveIndex) return
    const csvTitle = [
      'Produto',
      'Registro',
      ['Convencional', 'Incentivada 50%', 'Incentivada 100%', 'CQ5'].flatMap(entry =>
        ['', 'Venda', 'Compra'].flatMap(entry2 => `${entry} ${entry2}`),
      ),
      'Swap SE/S',
      'Swap SE/NE',
      'Swap SE/N',
    ].join(',')
    const csvContent = data.series
      .map(serie => {
        const entryData = serie[curveIndex]
        return [
          format(utcDateFromDate(new Date(entryData.product)), 'yyyy-MM-dd'),
          format(utcDateFromDate(new Date(entryData.register)), 'yyyy-MM-dd'),
          entryData.convencional ?? '-',
          entryData.convencionalSell ?? '-',
          entryData.convencionalBuy ?? '-',

          entryData.incentivated50 ?? '-',
          entryData.incentivated50Sell ?? '-',
          entryData.incentivated50Buy ?? '-',

          entryData.incentivated100 ?? '-',
          entryData.incentivated100Sell ?? '-',
          entryData.incentivated100Buy ?? '-',

          entryData.cq5 ?? '-',
          entryData.cq5Sell ?? '-',
          entryData.cq5Buy ?? '-',

          entryData.spreadSouth ?? '-',
          entryData.spreadNortheast ?? '-',
          entryData.spreadNorth ?? '-',
        ].join(',')
      })
      .join('\n')
    const blob = new Blob([csvTitle.concat('\n', csvContent)])
    const fileDownloadUrl = URL.createObjectURL(blob)
    setDownloadUrl(fileDownloadUrl)
  }

  return (
    <Container>
      <SectionTitle title='Curva forward - Sudeste' />
      <SectionText>
        Nesta aba estão expostos, por data e hora selecionados, os dados da curva forward disponibilizados e atualizados pelas mesas de negócio da
        Safira Energia, por meio de gráfico e tabela. Os produtos mensais disponíveis para consulta estão divididos em tipos de energia: Convencional,
        Incentivada 50% (i5), incentivada 100% (i1) e CQ5 (cogeração qualificada 50%). Também estão disponíveis informações de Swap SE/S, Swap SE/NE e
        Swap SE/N.
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Data da curva'
          max={config?.dateMax && format(config.dateMax, 'yyyy-MM-dd')}
          min={config?.dateMin && format(config.dateMin, 'yyyy-MM-dd')}
          value={format(date, 'yyyy-MM-dd')}
          onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
          disabled={loading}
        />
        <Select
          onChange={ev => setCurveIndex(Number(ev.target.value))}
          label='Hora da curva'
          options={
            data &&
            [...data?.curves].reverse().map(curve => ({
              label: format(new Date(curve.createdAt), "HH'h':mm'min'"),
              value: curve.index.toString(),
            }))
          }
        />
        <InputNumber max={5000} min={0} label='Preço máximo (R$/MWh)' value={yAxisMaxValue} customOnChange={value => setYAxisMaxValue(value)} />
        <InputRadio
          label='Tipo de preço'
          name='forward-curve-'
          onChange={ev => setType(ev.target.value)}
          options={[
            { label: 'Médio', value: '', checked: true },
            { label: 'Venda', value: 'Sell', checked: false },
            { label: 'Compra', value: 'Buy', checked: false },
          ]}
        />
      </ControllerContainer>
      <DownloadBar>
        <a
          style={{ display: 'none' }}
          ref={downloadRef}
          href={downloadUrl}
          download={`curva_forward_${format(date, 'dd_MMM_yyyy', { locale: ptBr })}.csv`}
        >
          &nbsp;
        </a>
        <DownloadButton title='Download em csv' type='button' description='Download dos dados em CSV' onClick={downloadData}>
          <div>
            <BiCloudDownload />
          </div>
          <div>
            <span>Download dos dados em csv </span>
          </div>
        </DownloadButton>
      </DownloadBar>
      <ChartContainer height='500px'>
        {(() => {
          if (loading) return <LoadingContainer />
          return (
            <ResponsiveContainer>
              <LineChart key={type} data={data?.series}>
                <XAxis
                  padding={{ left: 15, right: 15 }}
                  interval={3}
                  dataKey='product'
                  tick={{ fontSize: '0.875rem', color: theme.colors.black500 }}
                  height={30}
                  tickFormatter={tick => {
                    const tickDate = utcDateFromDate(new Date(tick))
                    const tickYear = tickDate.getFullYear().toString().substr(2, 2)
                    const tickMonth = tickDate.toLocaleDateString('default', { month: 'short' })
                    return `${tickMonth}/${tickYear}`
                  }}
                />
                <YAxis
                  tick={{ fontSize: '0.875rem', color: theme.colors.black500 }}
                  tickCount={11}
                  width={90}
                  tickFormatter={tick => Number(tick).toLocaleString('default', { style: 'currency', currency: 'BRL' })}
                  domain={[0, debouncedYAxisMaxValue || 'auto']}
                >
                  <Label
                    angle={-90}
                    value='Preço (R$/MWh)'
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                  />
                </YAxis>
                <Tooltip content={<CustomTooltip curveIndex={curveIndex} type={type} />} />
                <CartesianGrid strokeDasharray='3' />
                <Legend content={<CustomLegend legends={chartLines.map(line => ({ text: line.name, color: line.color }))} />} />
                {chartLines.map((line, index2) => (
                  <Line
                    key={index2}
                    strokeWidth={5}
                    stroke={line.color}
                    fill={line.color}
                    name={line.name}
                    animationBegin={index2 * 50}
                    dataKey={payload => payload[curveIndex ?? 0][`${line.key}${type}`]}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          )
        })()}
      </ChartContainer>
      <TableContainer>
        <table>
          <thead>
            <tr>
              {keys.map(key => (
                <th key={key.label}>{key.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {curveIndex &&
              data?.series.map(serie => {
                if (!serie[curveIndex]) return null
                return (
                  <tr key={serie.product}>
                    <td>
                      {(() => {
                        const productDate = utcDateFromDate(new Date(serie[curveIndex].product))
                        const productYear = productDate.getFullYear()
                        const productMonth = productDate.toLocaleDateString('pt-br', { month: 'long' })
                        return `${capitalize(productMonth)}/${productYear}`
                      })()}
                    </td>
                    {(() => {
                      if (type === 'Sell')
                        return (
                          <>
                            <td>{numberToBrl(serie[curveIndex].convencionalSell)}</td>
                            <td>{numberToBrl(serie[curveIndex].incentivated50Sell)}</td>
                            <td>{numberToBrl(serie[curveIndex].incentivated100Sell)}</td>
                            <td>{numberToBrl(serie[curveIndex].cq5Sell)}</td>
                            <td>{numberToBrl(serie[curveIndex].spreadSouth)}</td>
                            <td>{numberToBrl(serie[curveIndex].spreadNortheast)}</td>
                            <td>{numberToBrl(serie[curveIndex].spreadNorth)}</td>
                          </>
                        )
                      if (type === 'Buy')
                        return (
                          <>
                            <td>{numberToBrl(serie[curveIndex].convencionalBuy)}</td>
                            <td>{numberToBrl(serie[curveIndex].incentivated50Buy)}</td>
                            <td>{numberToBrl(serie[curveIndex].incentivated100Buy)}</td>
                            <td>{numberToBrl(serie[curveIndex].cq5)}</td>
                            <td>{numberToBrl(serie[curveIndex].spreadSouth)}</td>
                            <td>{numberToBrl(serie[curveIndex].spreadNortheast)}</td>
                            <td>{numberToBrl(serie[curveIndex].spreadNorth)}</td>
                          </>
                        )

                      return (
                        <>
                          <td>{numberToBrl(serie[curveIndex].convencional)}</td>
                          <td>{numberToBrl(serie[curveIndex].incentivated50)}</td>
                          <td>{numberToBrl(serie[curveIndex].incentivated100)}</td>
                          <td>{numberToBrl(serie[curveIndex].cq5)}</td>
                          <td>{numberToBrl(serie[curveIndex].spreadSouth)}</td>
                          <td>{numberToBrl(serie[curveIndex].spreadNortheast)}</td>
                          <td>{numberToBrl(serie[curveIndex].spreadNorth)}</td>
                        </>
                      )
                    })()}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  )
}
