import React from 'react'
import { TooltipProps } from 'recharts'
import { subsystems } from 'utils/globals'
import { numberToBrl, utcDateFromDate } from 'utils'
import {
  Container,
  TooltipGrid,
  TooltipInfoContainer,
  TooltipInfoValue,
  TooltipContainer,
  ToolTipDivider,
  TooltipTitleContainer,
} from 'components/CustomTooltip'

interface CustomTooltip {
  isDaily: boolean
}

const CustomTooltip: React.FC<TooltipProps & CustomTooltip> = ({ payload, active, isDaily }) => {
  if (payload && active) {
    return (
      <Container>
        <TooltipContainer>
          {isDaily ? (
            <TooltipTitleContainer>
              <span>Preços médios </span>
              <span>{utcDateFromDate(new Date(payload[0].payload.date)).toLocaleDateString('pt-br')}</span>
            </TooltipTitleContainer>
          ) : (
            <TooltipTitleContainer>
              <span>Preços diários </span>
              <span>{utcDateFromDate(new Date(payload[0].payload.time)).toLocaleTimeString('pt-br')}</span>
            </TooltipTitleContainer>
          )}
          <TooltipGrid>
            <TooltipInfoContainer>
              {subsystems.map(subsystem => (
                <TooltipInfoValue bold key={subsystem.name}>
                  {subsystem.name}
                </TooltipInfoValue>
              ))}
            </TooltipInfoContainer>
            <ToolTipDivider />
            <TooltipInfoContainer>
              {subsystems.map(subsystem => (
                <TooltipInfoValue key={subsystem.name}>{numberToBrl(payload[0].payload[subsystem.key])}</TooltipInfoValue>
              ))}
            </TooltipInfoContainer>
          </TooltipGrid>
        </TooltipContainer>
      </Container>
    )
  }
  return null
}

export default CustomTooltip
