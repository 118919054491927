import { transparentize } from 'polished'
import styled from 'styled-components'

export const Label = styled.span`
  font-size: 12px;
  color: #8c8c8c;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const Switcher = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 15px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => transparentize(0.5, props.theme.safiraColors.blue[5])};
  -webkit-transition: 200ms;
  transition: 200ms;
  border-radius: 0.5rem;
  :before {
    position: absolute;
    content: '';
    border-radius: 0.25rem;
    height: 7px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background: ${props => transparentize(0.5, props.theme.safiraColors.blue[5])};
    -webkit-transition: 200ms;
    transition: 200ms;
  }
`

export const Input = styled.input`
  :checked + ${Slider} {
    background-color: ${props => props.theme.safiraColors.green[2]};
  }

  :checked + ${Label} {
    filter: none;
  }

  :focus + ${Slider} {
    box-shadow: 0 0 1px #2196f3;
  }

  :checked + ${Slider}:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
    background-color: ${props => props.theme.safiraColors.green[4]};
  }
`
