import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 100px;
  padding: 100px 300px;
  transition: 200ms;
  @media (max-width: 1400px) {
    padding: 100px 10px;
  }
  @media (max-width: 1600px) {
    padding: 100px 100px;
  }
`

export const HomeSectionTitle = styled.h1`
  font-size: 1.5rem;
  color: ${props => props.theme.safiraColors.blue[2]};
`
