import styled from 'styled-components'
import { transparentize } from 'polished'

export const Container = styled.div``

export const ChartsContainer = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
`

export const SummaryContainer = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
`

export const SummaryFrame = styled.div`
  gap: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 5px;
  border-bottom: 5px solid ${props => props.theme.safiraColors.blue[4]};
  h1 {
    font-size: 20px;
    color: ${props => props.theme.safiraColors.blue[4]};
    text-align: center;
    position: relative;
    margin-bottom: 5px;
  }
  h2 {
    font-size: 14px;
    color: ${props => props.theme.safiraColors.blue[4]};
    text-align: center;
    position: relative;
    margin-bottom: 5px;
  }
`

export const RevisionContainer = styled.div<{ index: number }>`
  display: grid;
  gap: 10px;
  > h1 {
    font-size: 20px;
    color: ${props => props.theme.safiraColors.blue[4]};
    display: flex;
    align-items: center;
    border-bottom: 3px solid ${props => transparentize(0.9, props.theme.safiraColors.green[5])};
    padding-bottom: 5px;
  }
`

export const RevisionChartAndInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  gap: 15px;
  > div > h3 {
    font-size: 1rem;
    color: ${props => props.theme.safiraColors.blue[4]};
    padding-bottom: 10px;
  }
  > div:last-child {
    display: flex;
    align-items: center;
  }
`

export const SummaryTable = styled.table`
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
  thead tr th {
    padding: 10px;
    color: ${props => props.theme.safiraColors.blue[4]};
    text-align: center;
    :first-child {
      border-top-left-radius: 5px;
    }
    :last-child {
      border-top-right-radius: 5px;
    }
  }
  tbody {
    border-top: none;
    text-align: center;
    tr {
      :last-child td :first-child {
        border-bottom-left-radius: 5px;
      }
      :last-child td :last-child {
        border-bottom-right-radius: 5px;
      }
      td {
        padding: 5px;
      }
    }
  }
`
