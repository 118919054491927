import React from 'react'

import { Container, InputElement, Label } from './styles'

interface InputText {
  label?: string | React.ReactNode
  placeholder?: string
  value?: number
  disabled?: boolean
  max?: number
  min?: number
  customOnChange?: (value: number) => void
}

export function InputNumber({ label, placeholder, disabled, max, min, value, customOnChange }: InputText) {
  return (
    <Container>
      <Label>{label}</Label>
      <InputElement
        placeholder={placeholder}
        disabled={disabled}
        type='number'
        value={value}
        max={max}
        min={min}
        onChange={customOnChange && (event => customOnChange(Number(event.target.value)))}
      />
    </Container>
  )
}
