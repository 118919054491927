import React from 'react'
import { CustomProspectiveStudy } from '..'
import { Container } from './styles'
import { subsystems } from 'utils/globals'

export function DailyTable({
  data,
  studies,
  limitedPrices,
}: {
  data: CustomProspectiveStudy['data']['daily']
  studies: CustomProspectiveStudy['meta']['studies']
  selectedSubsystem: number
  limitedPrices: boolean
}) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th colSpan={2}>-</th>
            {data.map(entry => (
              <th key={entry.date}>{new Date(entry.date).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {studies.map(study => (
            <React.Fragment key={study.hash}>
              <tr>
                <td rowSpan={5}>{study.name}</td>
              </tr>
              {subsystems.map(subsystem => (
                <tr key={subsystem.key}>
                  <td style={{ color: subsystem.color, fontWeight: 'bold' }}>{subsystem.name}</td>
                  {data.map(entry => {
                    return (
                      <td key={`${entry.date}-${subsystem.key}`}>
                        {(() => {
                          const value = entry.studies?.[study.hash]?.[subsystem.key]?.[limitedPrices ? 'pld' : 'cmo']

                          if (value === 0) {
                            return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value)
                          }

                          if (!value) return '-'

                          return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value)
                        })()}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Container>
  )
}
