import React, { ChangeEventHandler } from 'react'

import { Container, InputElement, Label } from './styles'

interface InputText {
  label?: string | React.ReactNode
  placeholder?: string
  value?: string
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const InputText = React.forwardRef<HTMLDivElement, InputText>(({ label, placeholder, disabled, value, onChange }, ref) => {
  return (
    <Container ref={ref}>
      <Label>{label}</Label>
      <InputElement placeholder={placeholder} required disabled={disabled} type='text' value={value} onChange={onChange} />
    </Container>
  )
})

InputText.displayName = 'InputText'

export { InputText }
