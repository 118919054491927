import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 3rem;
`

export const ControllerContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const Title = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.safiraColors.blue[3]};
`
