import { DefaultTheme } from 'styled-components'

const light: DefaultTheme = {
  colors: {
    primary: '#031e4c',
    secondary: '#2b7af9',
    background: '#f2f1f6',
    // X--x--X
    blue100: '#bee6ff',
    blue400: '#0554d3',
    blue700: '#032677',
    // X--x--X
    red300: '#de3400',
    red400: '#aa0000',
    red500: '#820000',
    red700: '#600000',
    // X--x--X
    cyan: '#00ffff',
    green: '#a1e44d',
    yellow: '#ffd766',
    orange: '#ff9852',
    black100: '#f2f1f6',
    black200: '#d9d9d9',
    black300: '#cccccc',
    black500: '#8c8c8c',
    text: {
      highEmphasis: 'rgba(255, 255, 255, 0.87)',
      mediumEmphasis: 'rgba(255, 255, 255, 0.60)',
      disabled: 'rgba(255, 255, 255, 0.38)',
    },
  },
  safiraColors: {
    blue: ['#2b7af9', '#0554d3', '#043baa', '#032677', '#031e4c', '#021126'],
    green: ['#b8ed5d', '#a1e244', '#83d123', '#78be21', '#578b18', '#2c460c'],
    orange: ['#f6ad79', '#f28a40', '#e2762d', '#e56910', '#ac4e0c', '#873d0b'],
    yellow: ['#f9ea9f', '#f6de65', '#ffd939', '#f2ce1b', '#d4b30c', '#c1a30b'],
    red: ['#de3400', '#aa0000', '#820000', '#600000'],
  },
  subSystemColors: {
    southEast: '#032677',
    south: '#f2ce1b',
    northEast: '#e56910',
    north: '#78be21',
  },
}

export default light
