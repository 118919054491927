import React, { useState, useEffect, useRef, MouseEventHandler } from 'react'
import api from 'services/api'
import { format, startOfMonth, isSameDay } from 'date-fns'
import ptBr from 'date-fns/locale/pt-BR'
import { useTheme } from 'styled-components'
import { BiCloudDownload } from 'react-icons/bi'
import { ResponsiveContainer, LineChart, XAxis, YAxis, Legend, Tooltip, Line, CartesianGrid, Label } from 'recharts'
import { Section, SectionTitle, SectionText } from 'components/Section'
import { InputDate, InputNumber } from 'components/Input'
import InputRadio from 'components/InputRadio'
import { utcDateFromDate, numberToBrl } from 'utils'
import { ChartContainer, ControllerContainer, CustomLegend, LoadingContainer } from 'components/Chart'
import { Container, TableContainer, DownloadBar, DownloadButton, TableRow } from './styles'
import CustomTooltip from './CustomTooltip'
import { useDebounce } from 'usehooks-ts'

interface ProductDto {
  id: number
  control: number
  register: Date
  product: Date
  convencional: number
  convencionalBuy: number
  convencionalSell: number
  incentivated50: number
  incentivated50Buy: number
  incentivated50Sell: number
  incentivated100: number
  incentivated100Buy: number
  incentivated100Sell: number
  createdAt: Date
  updatedAt: Date
  cq5: number
  cq5Buy: number
  cq5Sell: number
  spreadSouth: number
  spreadNortheast: number
  spreadNorth: number
}

export const ForwardCurveByProduct = () => {
  const [date, setDate] = useState(startOfMonth(new Date()))
  const [type, setType] = useState('')
  const [downloadUrl, setDownloadUrl] = useState<string>()
  const [data, setData] = useState<ProductDto[]>([])
  const [loading, setLoading] = useState(true)
  const [yAxisMaxValue, setYAxisMaxValue] = useState<number>(0)
  const debouncedYAxisMaxValue = useDebounce(yAxisMaxValue, 1000)
  const downloadRef = useRef<HTMLAnchorElement>(document.createElement('a'))
  const theme = useTheme()

  useEffect(() => {
    if (downloadUrl) downloadRef.current.click()
  }, [downloadUrl])

  useEffect(() => {
    setLoading(true)
    api.get<ProductDto[]>('forward-curve/by-product', { params: { product: date.toISOString() } }).then(response => {
      setData(response.data)
      setTimeout(() => setLoading(false), 500)
    })
  }, [date])

  const keys = [
    { key: 'product', label: 'Produto' },
    { key: 'convencional', label: 'Convencional' },
    { key: 'incentivated50', label: 'Incentivada 50%' },
    { key: 'incentivated100', label: 'Incentivada 100%' },
    { key: 'cq5', label: 'CQ5' },
    { key: 'spreadSouth', label: 'Swap SE/S' },
    { key: 'spreadNortheast', label: 'Swap SE/NE' },
    { key: 'spreadNorth', label: 'Swap SE/N' },
  ]

  const chartLines = [
    { name: 'Convencional', key: 'convencional', color: theme.safiraColors.orange[3] },
    { name: 'CQ5', key: 'cq5', color: theme.safiraColors.blue[3] },
    { name: 'Incentivada 50%', key: 'incentivated50', color: theme.safiraColors.yellow[3] },
    { name: 'Incentivada 100%', key: 'incentivated100', color: theme.safiraColors.green[3] },
  ]

  const downloadData: MouseEventHandler<HTMLButtonElement> = ev => {
    ev.preventDefault()
    if (data.length === 0) return
    const csvTitle = [
      'Produto',
      'Registro',
      ['Convencional', 'Incentivada 50%', 'Incentivada 100%', 'CQ5'].flatMap(entry =>
        ['', 'Venda', 'Compra'].flatMap(entry2 => `${entry} ${entry2}`),
      ),
      'Swap SE/S',
      'Swap SE/NE',
      'Swap SE/N',
    ].join(',')
    const csvContent = [...data]
      .reverse()
      .map(entry =>
        [
          format(utcDateFromDate(new Date(entry.product)), 'yyyy-MM-dd') ?? '-',
          format(utcDateFromDate(new Date(entry.register)), 'yyyy-MM-dd') ?? '-',
          entry.convencional ?? '-',
          entry.convencionalSell ?? '-',
          entry.convencionalBuy ?? '-',

          entry.incentivated50 ?? '-',
          entry.incentivated50Sell ?? '-',
          entry.incentivated50Buy ?? '-',

          entry.incentivated100 ?? '-',
          entry.incentivated100Sell ?? '-',
          entry.incentivated100Buy ?? '-',

          entry.cq5 ?? '-',
          entry.cq5Sell ?? '-',
          entry.cq5Buy ?? '-',

          entry.spreadSouth ?? '-',
          entry.spreadNortheast ?? '-',
          entry.spreadNorth ?? '-',
        ].join(','),
      )
      .join('\n')
    const blob = new Blob([csvTitle.concat('\n', csvContent)])
    const fileDownloadUrl = URL.createObjectURL(blob)
    setDownloadUrl(fileDownloadUrl)
  }

  return (
    <Container>
      <Section>
        <SectionTitle title='Curva forward - Sudeste ' />
        <SectionText>
          Nesta aba estão expostos, por produto selecionado, os dados da curva forward disponibilizados e atualizados pelas mesas de negócio da Safira
          Energia, por meio de gráfico e tabela. Os produtos mensais disponíveis para consulta estão divididos em tipos de energia: Convencional,
          Incentivada 50% (i5), incentivada 100% (i1) e CQ5 (cogeração qualificada 50%). Também estão disponíveis informações de Swap SE/S, Swap SE/NE
          e Swap SE/N.
        </SectionText>
        <ControllerContainer>
          <InputDate
            type='month'
            label='Produto'
            value={format(date, 'yyyy-MM')}
            onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
          />
          <InputNumber max={5000} min={0} label='Preço máximo (R$/MWh)' value={yAxisMaxValue} customOnChange={value => setYAxisMaxValue(value)} />
          <InputRadio
            label='Tipo de preço'
            name='forward-curve-'
            onChange={ev => setType(ev.target.value)}
            options={[
              { label: 'Preço médio', value: '', checked: true },
              { label: 'Preço de venda', value: 'Sell', checked: false },
              { label: 'Preço de compra', value: 'Buy', checked: false },
            ]}
          />
        </ControllerContainer>
        <DownloadBar>
          <a
            style={{ display: 'none' }}
            ref={downloadRef}
            href={downloadUrl}
            download={`curva_forward_${format(date, 'dd_MMM_yyyy', { locale: ptBr })}.csv`}
          >
            &nbsp;
          </a>
          <DownloadButton title='Download em csv' type='button' description='Download dos dados em CSV' onClick={downloadData}>
            <div>
              <BiCloudDownload />
            </div>
            <div>
              <span>Download dos dados em csv </span>
            </div>
          </DownloadButton>
        </DownloadBar>
        <ChartContainer height='500px' style={{ paddingTop: '15px' }}>
          {(() => {
            if (loading) return <LoadingContainer />
            return (
              <ResponsiveContainer>
                <LineChart data={data} key={type}>
                  <Tooltip content={<CustomTooltip keys={keys} type={type} />} />
                  <CartesianGrid strokeDasharray='3' />
                  <Legend content={<CustomLegend legends={chartLines.map(line => ({ text: line.name, color: line.color }))} />} />
                  <XAxis
                    height={50}
                    dataKey='register'
                    tick={{ fontSize: '0.875rem', color: theme.colors.black500 }}
                    tickFormatter={tick => new Date(tick).toLocaleDateString()}
                  />
                  <YAxis
                    tick={{ fontSize: '0.875rem', color: theme.colors.black500 }}
                    tickCount={9}
                    width={90}
                    domain={[0, debouncedYAxisMaxValue || 'auto']}
                    tickFormatter={tick => Number(tick).toLocaleString('default', { style: 'currency', currency: 'BRL' })}
                  >
                    <Label
                      angle={-90}
                      value='Preço (R$/MWh)'
                      position='insideLeft'
                      style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                    />
                  </YAxis>
                  {chartLines.map((line, index2) => (
                    <Line
                      key={line.key}
                      strokeWidth={4}
                      stroke={line.color}
                      fill={line.color}
                      name={line.name}
                      animationBegin={index2 * 50}
                      dataKey={`${line.key}${type}`}
                      dot={false}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            )
          })()}
        </ChartContainer>
      </Section>
      <Section style={{ display: data.length === 0 || loading ? 'none' : '' }}>
        <TableContainer>
          <table>
            <thead>
              <tr>
                {keys.map(key => (
                  <th key={key.key}>{key.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[...data].reverse().map(entry => {
                const creationDate = utcDateFromDate(new Date(entry.register))
                const isCreationDateToday = isSameDay(creationDate, new Date())
                return (
                  <TableRow key={entry.id} isToday={isCreationDateToday}>
                    <td>
                      {creationDate.toLocaleDateString()}
                      &nbsp;
                      {new Date(entry.createdAt).toLocaleTimeString()}
                    </td>
                    {(() => {
                      if (type === 'Sell')
                        return (
                          <>
                            <td>{numberToBrl(entry.convencionalSell)}</td>
                            <td>{numberToBrl(entry.incentivated50Sell)}</td>
                            <td>{numberToBrl(entry.incentivated100Sell)}</td>
                            <td>{numberToBrl(entry.cq5Sell)}</td>
                            <td>{numberToBrl(entry.spreadSouth)}</td>
                            <td>{numberToBrl(entry.spreadNortheast)}</td>
                            <td>{numberToBrl(entry.spreadNorth)}</td>
                          </>
                        )
                      if (type === 'Buy')
                        return (
                          <>
                            <td>{numberToBrl(entry.convencionalBuy)}</td>
                            <td>{numberToBrl(entry.incentivated50Buy)}</td>
                            <td>{numberToBrl(entry.incentivated100Buy)}</td>
                            <td>{numberToBrl(entry.cq5)}</td>
                            <td>{numberToBrl(entry.spreadSouth)}</td>
                            <td>{numberToBrl(entry.spreadNortheast)}</td>
                            <td>{numberToBrl(entry.spreadNorth)}</td>
                          </>
                        )

                      return (
                        <>
                          <td>{numberToBrl(entry.convencional)}</td>
                          <td>{numberToBrl(entry.incentivated50)}</td>
                          <td>{numberToBrl(entry.incentivated100)}</td>
                          <td>{numberToBrl(entry.cq5)}</td>
                          <td>{numberToBrl(entry.spreadSouth)}</td>
                          <td>{numberToBrl(entry.spreadNortheast)}</td>
                          <td>{numberToBrl(entry.spreadNorth)}</td>
                        </>
                      )
                    })()}
                  </TableRow>
                )
              })}
            </tbody>
          </table>
        </TableContainer>
      </Section>
    </Container>
  )
}
