import { darken, transparentize } from 'polished'
import styled, { css, keyframes } from 'styled-components'

export const Container = styled.div`
  scroll-behavior: smooth;
`

export const ArticlesContainerAnimation = keyframes`
from {
  opacity: 0;
  top: -10px;
}
to {
  opacity: 1;
  top: 0;
}
`

export const ArticlesContainer = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(3, 1fr);
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    animation: ${ArticlesContainerAnimation} 500ms 1;
    position: relative;
    animation-delay: 250ms;
    animation-fill-mode: backwards;
    > div {
      :first-child {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        > div {
          display: flex;
          justify-content: space-between;
          padding: 0.5rem;
          border-radius: 0.5rem;
          border: 1px solid ${props => props.theme.colors.black100};
          background-color: ${props => transparentize(0.5, props.theme.colors.black100)};
          span {
            font-weight: bold;
            opacity: 0.5;
            font-size: 0.875rem;
            :first-child {
              text-transform: uppercase;
              letter-spacing: 2px;
            }
            :last-child {
              font-size: 0.75rem;
              opacity: 0.3;
            }
          }
          :has(button),
          :has(img) {
            background: none;
            padding: 0;
          }
        }
        h1 {
          font-size: 1.125rem;
          color: rgba(0, 0, 0, 0.87);
        }
        p {
          color: rgba(0, 0, 0, 0.6);
          line-height: 30px;
        }
      }
      :last-child {
        button {
          margin-top: auto;
          padding: 0.5rem;
          width: auto;
          border: 1px solid ${props => props.theme.colors.blue700};
          border-radius: 0.25rem;
          color: ${props => props.theme.colors.blue700};
          background: #fff;
          transition: 150ms;
          :hover {
            color: #fff;
            background: ${props => props.theme.colors.blue400};
            border: 1px solid ${props => props.theme.colors.blue400};
          }
        }
      }
    }
  }
`

export const ArticleBanner = styled.div<{ src: string; showNewLabel: boolean }>`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  height: 15rem;
  border-radius: 0.5rem;
  position: relative;
  ${props =>
    props.showNewLabel &&
    css`
      ::after {
        content: 'NOVO';
        position: absolute;
        width: 100px;
        padding: 0.25rem;
        left: -5px;
        top: 5%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        background: #fff;
        border-radius: 0.15rem;
        color: ${props => props.theme.colors.black500};
        font-size: 0.75rem;
        text-align: center;
        font-weight: bold;
      }
    `}
`

const ArticleTypeContainerAnimation = keyframes`
  from {
    opacity: 0;
    left: -10px;
  }
  to {
    opacity: 1;
    left: 0;
  }
`

export const ArticleTypeContainer = styled.div`
  display: flex;
  > div {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
    animation: ${ArticleTypeContainerAnimation} 500ms 1;
    animation-delay: 50ms;
    animation-fill-mode: backwards;
    position: relative;
    border: 2px solid;
    padding: 0.25rem;
    border-radius: 0.5rem;
    img {
      border-radius: 0.5rem;
      opacity: 0.8;
    }
    > div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      h3 {
        color: ${props => darken(0.2, props.theme.colors.black500)};
      }
      p {
        color: ${props => props.theme.colors.black500};
      }
    }
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem 0;
  button {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: ${props => props.theme.colors.cyan};
    border-radius: 0.5rem;
    color: ${props => props.theme.colors.blue400};
  }
`
