import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${props => props.theme.safiraColors.blue[3]};
  color: #fff;
  padding: 4rem 4rem 2rem 4rem;

  display: grid;
  grid-row-gap: 100px;
  grid-column-gap: 3rem;
  grid-template-columns: repeat(4, 1fr);

  div {
    img {
      height: 5rem;
      object-fit: contain;
    }
    p {
      line-height: 25px;
      font-size: 1.05rem;
      :first-child {
        color: ${props => props.theme.colors.cyan};
        font-weight: 500;
      }
    }
    :first-child {
      font-size: 2rem;
      /* display: flex;
      flex-direction: column;
      gap: 2rem; */
      > div {
        display: flex;
        gap: 1rem;
        justify-content: center;
      }
    }
    :last-child {
      grid-column: 1 / span 4;
      text-align: center;
      p {
        margin-top: 2rem;
      }
    }
  }
`
