import React from 'react'
import { TooltipProps } from 'recharts'
import { subsystems } from 'utils/globals'
import { numberToBrl } from 'utils'
import { Container, TooltipGrid, TooltipInfoContainer, TooltipInfoValue, TooltipContainer, ToolTipDivider } from 'components/CustomTooltip'

type CustomTooltip = {
  currentDate: Date
  currentDateString: string
  previousDate: Date
  previousDateString: string
  subsystemKey: string
}

const CustomTooltip: React.FC<TooltipProps & CustomTooltip> = ({
  payload,
  active,
  currentDate,
  currentDateString,
  previousDate,
  previousDateString,
  subsystemKey,
}) => {
  if (payload && active) {
    return (
      <Container>
        <TooltipContainer>
          <TooltipGrid columns={5}>
            <TooltipInfoContainer>
              <TooltipInfoValue>
                <span>{Number(payload[0].payload.time).toLocaleString('pt-br', { minimumIntegerDigits: 2 })}</span>
                <span>:00</span>
              </TooltipInfoValue>
              {subsystems
                .filter(entry => entry.key === subsystemKey)
                .map(subsystem => (
                  <TooltipInfoValue bold key={subsystem.name}>
                    {subsystem.name}
                  </TooltipInfoValue>
                ))}
            </TooltipInfoContainer>
            <ToolTipDivider />
            <TooltipInfoContainer>
              <TooltipInfoValue bold>{previousDate.toLocaleDateString()}</TooltipInfoValue>
              {subsystems
                .filter(entry => entry.key === subsystemKey)
                .map(subsystem => (
                  <TooltipInfoValue key={subsystem.name}>
                    {numberToBrl(payload[0].payload.prices[previousDateString]?.[subsystem.key])}
                  </TooltipInfoValue>
                ))}
            </TooltipInfoContainer>
            <ToolTipDivider />
            <TooltipInfoContainer>
              <TooltipInfoValue bold>{currentDate.toLocaleDateString()}</TooltipInfoValue>
              {subsystems
                .filter(entry => entry.key === subsystemKey)
                .map(subsystem => (
                  <TooltipInfoValue key={subsystem.name}>
                    {numberToBrl(payload[0].payload.prices[currentDateString]?.[subsystem.key])}
                  </TooltipInfoValue>
                ))}
            </TooltipInfoContainer>
          </TooltipGrid>
        </TooltipContainer>
      </Container>
    )
  }
  return null
}

export default CustomTooltip
