import { darken, transparentize } from 'polished'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 2rem;
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    thead tr {
      :first-child th {
        background: ${props => darken(0.1, props.theme.colors.blue700)};
        :first-child {
          border-top-left-radius: 0.5rem;
        }
        :last-child {
          border-top-right-radius: 0.5rem;
        }
      }
      :last-child th {
        background: ${props => transparentize(0.06, darken(0.1, props.theme.colors.blue700))};
      }
      th {
        padding: 1rem;
        color: #fff;
      }
    }
    tbody tr {
      td {
        padding: 0.3rem;
        font-size: 0.875rem;
      }
      :hover td {
        background: #eaeaea;
      }
    }
  }
`

type TableDataProps = {
  isLastFromCase?: boolean
}

export const TableData = styled.td<TableDataProps>`
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  ${props =>
    props.isLastFromCase &&
    css`
      border-bottom: 1px solid #eaeaea;
    `}
`

export const CaseTableData = styled.td<TableDataProps>`
  border: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
`
