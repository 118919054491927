import React, { ChangeEventHandler } from 'react'

import { Container, Label, InputElement } from './styles'

interface Select {
  label?: string
  onChange?: ChangeEventHandler<HTMLSelectElement>
  disabled?: boolean
  value?: string
  options?: {
    value: string
    label: string
  }[]
}

const PrismaSelect: React.FC<Select> = ({ options, label, disabled, value, onChange }) => {
  return (
    <Container>
      <Label>{label ?? 'Selecione uma opção'}</Label>
      <InputElement disabled={disabled} onChange={onChange} value={value}>
        {options?.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </InputElement>
    </Container>
  )
}

export default PrismaSelect
