const apiAdress = process.env.REACT_APP_STATIC_ADDRESS

export const subsystems = [
  {
    name: 'Sudeste/Centro-Oeste',
    initials: 'se/co',
    key: 'southeast',
    color: '#032677',
    keyPt: 'sudeste',
    mapUrl: `${apiAdress}/images/subsystems/fade_sudeste_centro_oeste_azul.png`,
  },
  {
    name: 'Sul',
    initials: 's',
    key: 'south',
    color: '#f2ce1b',
    keyPt: 'sul',
    mapUrl: `${apiAdress}/images/subsystems/fade_sul_amarelo.png`,
  },
  {
    name: 'Nordeste',
    initials: 'ne',
    key: 'northeast',
    color: '#e56910',
    keyPt: 'nordeste',
    mapUrl: `${apiAdress}/images/subsystems/fade_nordeste_laranja.png`,
  },
  {
    name: 'Norte',
    initials: 'n',
    key: 'north',
    color: '#78be21',
    keyPt: 'norte',
    mapUrl: `${apiAdress}/images/subsystems/fade_norte_verde.png`,
  },
] as const

export const subsystemsWithSin = [
  {
    name: 'Sudeste/Centro-Oeste',
    initials: 'se/co',
    key: 'southeast',
    keyPt: 'sudeste',
    color: '#032677',
    mapUrl: `${apiAdress}/images/subsystems/fade_sudeste_centro_oeste_azul.png`,
  },
  {
    name: 'Sul',
    initials: 's',
    key: 'south',
    color: '#f2ce1b',
    keyPt: 'sul',
    mapUrl: `${apiAdress}/images/subsystems/fade_sul_amarelo.png`,
  },
  {
    name: 'Nordeste',
    initials: 'ne',
    key: 'northeast',
    color: '#e56910',
    keyPt: 'nordeste',
    mapUrl: `${apiAdress}/images/subsystems/fade_nordeste_laranja.png`,
  },
  {
    name: 'Norte',
    initials: 'n',
    key: 'north',
    color: '#78be21',
    keyPt: 'norte',
    mapUrl: `${apiAdress}/images/subsystems/fade_norte_verde.png`,
  },
  {
    name: 'Sistema interligado nacional',
    initials: 'sin',
    key: 'sin',
    color: '#808080',
    keyPt: 'sin',
    mapUrl: `${apiAdress}/images/subsystems/mapa_brasil_colorido.png`,
  },
] as const

export const months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']
