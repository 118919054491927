import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Price } from './Price'
import { Demand } from './Demand'
import { EnergyBalance } from './EnergyBalance'
import { useQuery } from 'react-query'
import api from 'services/api'
import { utcDateFromDate } from 'utils'
import { LoadingWarning } from './styles'

export function DessemCCEE() {
  const { data: lastCceeDessemDate } = useQuery(
    'last-dessem-day',
    () => api.get<string>('dessem-ccee').then(({ data }) => utcDateFromDate(new Date(data))),
    { refetchOnWindowFocus: false },
  )

  if (!lastCceeDessemDate) return <LoadingWarning>Buscando último dia disponível</LoadingWarning>

  return (
    <Switch>
      <Route exact path='/dessem/ccee/price' component={() => <Price lastCceeDessemDate={lastCceeDessemDate} />} />
      <Route exact path='/dessem/ccee/demand' component={() => <Demand lastCceeDessemDate={lastCceeDessemDate} />} />
      <Route exact path='/dessem/ccee/energy-balance' component={() => <EnergyBalance lastCceeDessemDate={lastCceeDessemDate} />} />
      {/* <Route
        exact
        path='/dessem/ccee/exchange'
        component={() => <Exchange lastCceeDessemDate={lastCceeDessemDate} />}
      /> */}
    </Switch>
  )
}
