import React from 'react'
import { IoIosReturnLeft } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { BsExclamationLg } from 'react-icons/bs'
import { utcDateFromDate } from 'utils'
import { StudyMetaData } from '..'
import { Container, Banner, ReturnButton, UnpublishedWarning } from './styles'

export function Header({ meta }: { meta: StudyMetaData }) {
  return (
    <Container>
      <Link to='/estudos/medio-prazo'>
        <ReturnButton>
          <IoIosReturnLeft />
        </ReturnButton>
      </Link>
      {!meta.published && (
        <UnpublishedWarning>
          <BsExclamationLg />
          Este estudo não está publicado. Estudos não publicados são visíveis apenas para o Middle Office.
        </UnpublishedWarning>
      )}
      <Banner />
      <h1>{meta.title}</h1>
      <div>
        <p>{meta.author}</p>
        <p>
          {utcDateFromDate(new Date(meta.createdAt)).toLocaleString('pt-br', {
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </p>
      </div>
      <p>{meta.body}</p>
    </Container>
  )
}
