import React, { useEffect, useState } from 'react'
import { Container, ArticlesContainer, ArticleBanner, ArticleTypeContainer, PaginationContainer } from './styles'
import { client } from 'services/prismic'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { isSameWeek } from 'date-fns'
import { asText, asImageSrc } from '@prismicio/helpers'
import { useQuery } from 'react-query'
import { predicate } from '@prismicio/client'
import { Link } from 'react-router-dom'
import { LoadingBlocks } from 'components/LoadingBlocks'
import { usePrismicDocumentByID } from '@prismicio/react'

export function List({ typeId }: { typeId?: string }) {
  const [page, setPage] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  const { data } = useQuery(
    ['middle_post', typeId, page],
    () =>
      client
        .getByType('middle_post', {
          page,
          pageSize: 6,
          predicates: typeId && [predicate.at('my.middle_post.post_type_link', typeId)],
          orderings: { field: 'document.first_publication_date', direction: 'desc' },
          graphQuery: `{
          middle_post {
            title
            callout
            banner
            body
            author_link {
              name
              picture
            }
            post_type_link {
              title
              color
            }
          }
        }`,
        })
        .then(response => ({
          ...response,
          formatedResults: response.results.map(post => ({
            slug: post.uid,
            title: asText(post.data.title),
            callout: asText(post.data.callout),
            banner: asImageSrc(post.data.banner),
            author: {
              name: asText(post.data.author_link?.data.name),
              asImg: asImageSrc(post.data.author_link?.data.picture),
            },
            type: {
              title: asText(post.data.post_type_link?.data.title),
              color: post.data.post_type_link?.data.color,
            },
            rawCreatedAt: new Date(post.first_publication_date),
            createdAt: new Intl.DateTimeFormat('pt-br', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            }).format(new Date(post.first_publication_date)),
          })),
        })),
    {
      staleTime: 60 * 60 * 1000, // 1 minute
      keepPreviousData: true,
    },
  )

  const [type, { state }] = usePrismicDocumentByID(typeId ?? '')

  if (!data?.formatedResults || (typeId && state === 'loading'))
    return (
      <>
        <RectShape color='#ccc' style={{ borderRadius: '0.5rem', marginBottom: '2rem', height: '4rem' }} />
        <LoadingBlocks />
      </>
    )

  return (
    <Container>
      {(() => {
        if (!typeId) return null

        if (!type) return null

        return (
          <ArticleTypeContainer>
            <div style={{ borderColor: type.data.color ?? '' }}>
              <img src={asImageSrc(type.data.banner) ?? ''} alt='' />
              <div>
                <h3>{asText(type.data.title)}</h3>
                <p>{asText(type.data.body)}</p>
              </div>
            </div>
          </ArticleTypeContainer>
        )
      })()}
      <ArticlesContainer>
        {data?.formatedResults.map(article => (
          <div key={article.slug} style={{ animationDelay: typeId ? '' : '0ms' }}>
            <div>
              <ArticleBanner src={article.banner ?? ''} showNewLabel={isSameWeek(new Date(), article.rawCreatedAt)} />
              <div>
                <span>{article.type?.title ?? '-'}</span>
                <span>{article.createdAt}h</span>
              </div>
              <h1>{article.title}</h1>
              <p>{article.callout}</p>
            </div>
            <div>
              <Link to={`/meteorology/reports/${article.slug}`}>
                <button>Ler análise completa</button>
              </Link>
            </div>
          </div>
        ))}
      </ArticlesContainer>
      <PaginationContainer>
        <button type='button' onClick={() => page > 1 && setPage(state => state - 1)}>
          <FaChevronLeft />
        </button>
        <span>
          <span>Página</span>
          &nbsp;
          <span>{page}</span>
          &nbsp;
          <span>de</span>
          &nbsp;
          <span>{data?.total_pages}</span>
        </span>
        <button type='button' onClick={() => data?.total_pages && page < data.total_pages && setPage(state => state + 1)}>
          <FaChevronRight />
        </button>
      </PaginationContainer>
    </Container>
  )
}
