import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Banner from 'components/Banner'
import Navigation from 'components/Navigation'

import History from './History'
import TwoDayComparison from './TwoDayComparison'
import CurrentDay from './CurrentDay'
import { Container, Divider } from './styles'
import { DessemCCEE } from './DessemCCEE'
import { DessemCCEEMiddle } from './DessemCCEEMiddle'
import { useQuery } from 'react-query'
import api from 'services/api'

const Dessem: React.FC = () => {
  const { data: userCanSeeMiddleDessem } = useQuery(
    'user-can-see-middle-dessem',
    () => api.get<boolean>('middle-dessem-ccee').then(response => response.data),
    { refetchOnWindowFocus: false },
  )

  return (
    <>
      <Banner title='PLD horário' />
      <Container>
        <Navigation
          options={[
            { label: 'Diário', value: '/dessem/current-day', base: 'dessem/current-day' },
            { label: 'Comparar dois dias', value: '/dessem/compare-two-days', base: 'dessem/compare-two-days' },
            { label: 'Histórico', value: '/dessem/price-history', base: 'dessem/price-history' },
            {
              label: 'Modelo DESSEM',
              value: '/dessem/ccee/price',
              base: 'dessem/ccee/',
              suboptions: [
                { label: 'Balanço energético', value: '/dessem/ccee/energy-balance' },
                { label: 'Carga', value: '/dessem/ccee/demand' },
                { label: 'PLD', value: '/dessem/ccee/price' },
              ],
            },
            ...(userCanSeeMiddleDessem
              ? [
                  {
                    label: 'Modelo DESSEM - Middle',
                    value: '/dessem/ccee-middle/energy-balance',
                    base: 'dessem/ccee-middle/',
                    suboptions: [
                      { label: 'Balanço energético', value: '/dessem/ccee-middle/energy-balance' },
                      { label: 'Carga', value: '/dessem/ccee-middle/demand' },
                      { label: 'PLD', value: '/dessem/ccee-middle/price' },
                      { label: 'Intercâmbio', value: '/dessem/ccee-middle/exchange' },
                    ],
                  },
                ]
              : []),
          ]}
        />
        <Divider />
        <Switch>
          <Route exact path='/dessem/current-day' component={CurrentDay} />
          <Route exact path='/dessem/compare-two-days' component={TwoDayComparison} />
          <Route exact path='/dessem/price-history' component={History} />
          {userCanSeeMiddleDessem && <Route path='/dessem/ccee-middle' component={DessemCCEEMiddle} />}
          <Route path='/dessem/ccee' component={DessemCCEE} />
        </Switch>
      </Container>
    </>
  )
}

export default Dessem
