import React, { useState, useEffect } from 'react'
import { lighten, transparentize } from 'polished'
import { format, add, parse, isBefore } from 'date-fns'
import { capitalize } from 'lodash'
import { ResponsiveContainer, Label, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ReferenceLine } from 'recharts'
import { useDebounce } from 'usehooks-ts'
import { useTheme } from 'styled-components'
import api from 'services/api'
import { subsystems } from 'utils/globals'
import { utcDateFromDate } from 'utils'
import { SectionTitle, SectionText } from 'components/Section'
import { InputDate, PrismaSelect } from 'components/Input'
import { ChartContainer, CustomLegend } from 'components/Chart'
import {
  Container,
  ChartAndMenuContainer,
  SideMenuContainer,
  SideMenuOptions,
  SideMenuFooter,
  SideMenuTitle,
  ControllerContainer,
  SideMenuGroupContainer,
  SideMenuImageContainer,
  SummaryWrapper,
  SummaryContainer,
  SummaryValueContainer,
  SummaryTitleContainer,
} from './styles'
import { CustomTooltip } from './CustomTooltip'

type ApiResponse = {
  summary: {
    averages: {
      installed_power: number
      order_of_merit: number
      inflexibility: number
      energy_restriction: number
      energy_guarantee: number
      exportation: number
      unit_commitment: number
      verified: number
      others: number
      total: number
      total_without_exportation: number
    }
  }
  data: {
    date: string
    order_of_merit: number
    inflexibility: number
    energy_restriction: number
    energy_guarantee: number
    exportation: number
    unit_commitment: number
    verified: number
    installed_power: number
    others: number
    total: number
  }[]
}

const ThermalDispatch: React.FC = () => {
  const date = new Date()

  // * Theme and window debounce
  const theme = useTheme()
  const [key, setKey] = useState(1)
  const debouncedValue = useDebounce<number>(key, 1000)

  // * Controls
  const [loading, setLoading] = useState(true)
  const [dateInterval, setDateInterval] = useState({ start: add(date, { months: -1 }), end: date })
  const [subsystemId, setSubsystemId] = useState(5)
  const [installedPower, setInstalledPower] = useState(false)
  const [axises, setAxises] = useState([
    { dataKey: 'order_of_merit', name: 'Ordem de mérito', stackId: 2, hide: false },
    { dataKey: 'energy_restriction', name: 'Restrição elétrica', stackId: 2, hide: false },
    { dataKey: 'energy_guarantee', name: 'Garantia energética', stackId: 2, hide: false },
    { dataKey: 'exportation', name: 'Exportação', stackId: 2, hide: false },
    { dataKey: 'others', name: 'Outros', stackId: 2, hide: false },
    { dataKey: 'unit_commitment', name: 'Unit Commitment', stackId: 2, hide: false },
    { dataKey: 'inflexibility', name: 'Inflexibilidade', stackId: 2, hide: false },
  ])

  // * Data
  const [summary, setSummary] = useState<ApiResponse['summary']>()
  const [data, setData] = useState<ApiResponse['data']>([])

  useEffect(() => {
    api
      .get<ApiResponse>('thermal-dispatch', {
        params: {
          start: dateInterval.start,
          end: dateInterval.end,
          subsystemId: subsystemId === 5 ? undefined : subsystemId,
        },
      })
      .then(response => {
        setData(response.data.data)
        setSummary(response.data.summary)
        setLoading(false)
        const lastDataEntryDate = utcDateFromDate(new Date([...response.data.data].pop()?.date ?? new Date()))
        if (isBefore(lastDataEntryDate, dateInterval.end)) setDateInterval({ ...dateInterval, end: lastDataEntryDate })
      })
  }, [subsystemId, dateInterval])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setKey(key + 1)
    })
    return () => window.removeEventListener('resize', () => setKey(key + 1))
  }, [debouncedValue])

  return (
    <Container>
      <SectionTitle>Motivo do despacho térmico</SectionTitle>
      <SectionText>
        Motivo do despacho de usinas termelétricas classificadas nas modalidades Tipo I e Tipo II-A, enquadradas no despacho centralizado do Operador
        Nacional do Sistema Elétrico (ONS), disponibilizado no Boletim Diário da Operação (BDO), para o Sistema Interligado Nacional (SIN) e para os
        subsistemas.
      </SectionText>
      <ControllerContainer>
        <InputDate
          label='Início'
          value={format(utcDateFromDate(dateInterval.start), 'yyyy-MM-dd')}
          disabled={loading}
          onChange={e => dateInterval && setDateInterval({ ...dateInterval, start: parse(e.target.value, 'yyyy-MM-dd', new Date()) })}
        />
        <InputDate
          label='Fim'
          disabled={loading}
          value={dateInterval && format(utcDateFromDate(dateInterval.end), 'yyyy-MM-dd')}
          onChange={e => dateInterval && setDateInterval({ ...dateInterval, end: parse(e.target.value, 'yyyy-MM-dd', new Date()) })}
        />
        <PrismaSelect
          label='Submercado'
          disabled={loading}
          onChange={e => setSubsystemId(Number(e.target.value))}
          options={[
            { label: 'Sistema Interligado', value: '5' },
            ...subsystems.map((subsystem, index) => ({ label: subsystem.name, value: String(index + 1) })),
          ]}
        />
      </ControllerContainer>
      <SummaryWrapper>
        <SummaryContainer>
          <SummaryTitleContainer>Potência instalada</SummaryTitleContainer>
          <SummaryValueContainer>
            {(() => {
              if (!summary) return '- MW'
              // eslint-disable-next-line camelcase
              const { installed_power } = summary.averages
              return `${Math.round(installed_power).toLocaleString('pt-br')} MW`
            })()}
          </SummaryValueContainer>
        </SummaryContainer>
        <SummaryContainer>
          <SummaryTitleContainer>Média do despacho térmico total</SummaryTitleContainer>
          <SummaryValueContainer>
            {(() => {
              if (!summary) return '- MWmed'
              const { total } = summary.averages
              return `${Math.round(total).toLocaleString('pt-br')} MWmed`
            })()}
          </SummaryValueContainer>
        </SummaryContainer>
        <SummaryContainer>
          <SummaryTitleContainer>Média do despacho térmico total (sem exportação)</SummaryTitleContainer>
          <SummaryValueContainer>
            {(() => {
              if (!summary) return '- MWmed'
              const { total_without_exportation: totalWithoutExportation } = summary.averages
              return `${Math.round(totalWithoutExportation).toLocaleString('pt-br')} MWmed`
            })()}
          </SummaryValueContainer>
        </SummaryContainer>
        <SummaryContainer>
          <SummaryTitleContainer>Média do despacho térmico das fontes selecionadas</SummaryTitleContainer>
          <SummaryValueContainer>
            {(() => {
              if (!summary) return '- MWmed'
              if (axises.filter(axis => axis.hide).length === 0) return `${Math.round(summary.averages.total).toLocaleString('pt-br')} MWmed`
              const averagesCopy: { [index: string]: number } = { ...summary.averages }
              let { total } = summary.averages
              axises
                .filter(axis => axis.hide)
                .forEach(axis => {
                  const valueToSubtract = averagesCopy[axis.dataKey]
                  if (valueToSubtract) total -= valueToSubtract
                })
              return `${Math.round(total).toLocaleString('pt-br')} MWmed`
            })()}
          </SummaryValueContainer>
        </SummaryContainer>
        <SummaryContainer>
          <SummaryTitleContainer>Percentual do despacho das fontes selecionadas sobre o total</SummaryTitleContainer>
          <SummaryValueContainer>
            {(() => {
              if (!summary) return '- %'
              const averagesCopy: { [index: string]: number } = { ...summary.averages }
              let { total } = summary.averages
              axises
                .filter(axis => axis.hide)
                .forEach(axis => {
                  const valueToSubtract = averagesCopy[axis.dataKey]
                  if (valueToSubtract) total -= valueToSubtract
                })
              const totalPercentage = total / summary.averages.total
              return totalPercentage.toLocaleString('pt-br', { style: 'percent', maximumFractionDigits: 2 })
            })()}
          </SummaryValueContainer>
        </SummaryContainer>
      </SummaryWrapper>
      <ChartAndMenuContainer>
        <ChartContainer height='600px'>
          <ResponsiveContainer key={debouncedValue}>
            <BarChart data={data} margin={{ top: 25, bottom: 0 }}>
              <CartesianGrid strokeDasharray='3 3' vertical={false} />
              <Tooltip
                animationBegin={10}
                animationDuration={1000}
                cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
                content={<CustomTooltip axises={axises} />}
              />
              <XAxis
                tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                interval='preserveEnd'
                dataKey='date'
                height={20}
                tickFormatter={tick => {
                  const zonedTime = utcDateFromDate(new Date(tick))
                  const dateString = zonedTime.toLocaleDateString('pt-br')
                  const weekdayString = zonedTime.toLocaleDateString('pt-br', { weekday: 'short' })
                  return `${dateString} ${capitalize(weekdayString)}`
                }}
              />
              <YAxis
                stroke='#ccc'
                tickLine={false}
                tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                tickCount={11}
                width={70}
                domain={['auto', installedPower ? Math.max(...data.map(entry => entry.installed_power)) : 'auto']}
                tickFormatter={tick => new Intl.NumberFormat('pt-br').format(tick)}
              >
                <Label
                  angle={-90}
                  value='Geração térmica (MWmed)'
                  position='insideLeft'
                  style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                />
              </YAxis>
              {data.length > 0 && installedPower && (
                <ReferenceLine
                  stroke={subsystems[subsystemId - 1] ? transparentize(0.5, subsystems[subsystemId - 1].color) : undefined}
                  strokeWidth={3}
                  strokeDasharray='20 5'
                  y={data.map(entry => entry.installed_power).reduce((a, b) => a + b) / data.length}
                />
              )}
              {axises.map((axis, index, array) => (
                <Bar
                  key={axis.name}
                  style={{
                    stroke: lighten(index * 0.03, subsystems[subsystemId - 1]?.color ?? theme.safiraColors.blue[5]),
                    strokeWidth: 1,
                  }}
                  maxBarSize={50}
                  hide={axis.hide}
                  radius={index === array.length - 1 ? [5, 5, 0, 0] : undefined}
                  dataKey={axis.dataKey}
                  name={axis.name}
                  stackId={axis.stackId}
                  strokeWidth={0}
                  fill={lighten(index * 0.04, subsystems[subsystemId - 1]?.color ?? theme.safiraColors.blue[5])}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
        <SideMenuContainer>
          <SideMenuGroupContainer>
            <SideMenuOptions>
              <li>
                <input
                  checked={installedPower}
                  type='checkbox'
                  id='checkbox-installed-pottency'
                  onChange={ev => setInstalledPower(ev.target.checked)}
                />
                <label htmlFor='checkbox-installed-pottency'>Potência instalada</label>
              </li>
            </SideMenuOptions>
          </SideMenuGroupContainer>
          <SideMenuGroupContainer>
            <SideMenuTitle>Fontes</SideMenuTitle>
            <SideMenuOptions>
              {axises.map(axis => (
                <li key={axis.name}>
                  <input
                    checked={!axises.find(a => a.dataKey === axis.dataKey)?.hide}
                    type='checkbox'
                    id={`checkbox-${axis.dataKey}`}
                    value={axis.dataKey}
                    onChange={ev => {
                      const axisFromKey = axises.findIndex(a => a.dataKey === axis.dataKey)
                      if (axisFromKey === -1) return
                      const axisesCopy = [...axises]
                      axisesCopy[axisFromKey].hide = !ev.target.checked
                      setAxises(axisesCopy)
                    }}
                  />
                  <label htmlFor={`checkbox-${axis.dataKey}`} style={{ color: axis.hide ? '#ccc' : '' }}>
                    {axis.name}
                  </label>
                </li>
              ))}
            </SideMenuOptions>
            <SideMenuFooter>
              <div>
                <span>{axises.filter(axis => !axis.hide).length}</span>
                <span>
                  &nbsp; de &nbsp;
                  {axises.length}
                </span>
              </div>
              <div>
                <button type='button' onClick={() => setAxises(axises.map(axis => ({ ...axis, hide: false })))}>
                  Todos
                </button>
                <button type='button' onClick={() => setAxises(axises.map(axis => ({ ...axis, hide: true })))}>
                  Nenhum
                </button>
              </div>
            </SideMenuFooter>
          </SideMenuGroupContainer>
          <SideMenuImageContainer>
            <img
              src={subsystems[subsystemId - 1]?.mapUrl ?? `${process.env.REACT_APP_STATIC_ADDRESS}/images/subsystems/mapa_brasil_colorido.png`}
              alt='subsystem'
            />
          </SideMenuImageContainer>
        </SideMenuContainer>
        <CustomLegend
          legends={axises
            .filter(axis => !axis.hide)
            .map((axis, index) => ({
              text: axis.name,
              color: lighten(index * 0.03, subsystems[subsystemId - 1]?.color ?? theme.safiraColors.blue[5]),
            }))}
        />
      </ChartAndMenuContainer>
    </Container>
  )
}

export default ThermalDispatch
