import React, { useState, useEffect } from 'react'
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { format } from 'date-fns'
import { useTheme } from 'styled-components'
import api from 'services/api'
import { capitalize } from 'utils'
import { MeteorologyIndex } from 'types'
import { ChartContainer, LoadingContainer } from 'components/Chart'
import { Section } from 'components/Section'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import SectionSubTitle from 'components/SectionSubtitle'
import SectionSubText from 'components/SectionSubText'

import { Container, Grid, ONIImagesContainer } from './styles'
import CustomTooltip from './CustomTooltip'

const ENSO: React.FC = () => {
  const [oceanicNinoIndexData, setOceanicNinoIndexData] = useState<MeteorologyIndex[]>([])
  const [multivariateEnsoIndexData, setMultivariateEnsoIndexData] = useState<MeteorologyIndex[]>([])
  const [oceanicNinoIndexLoading, setOceanicNinoIndexLoading] = useState(true)
  const [multivariateEnsoIndexLoading, setMultivariateEnsoIndexLoading] = useState(true)
  const theme = useTheme()
  const date = new Date()
  const formattedDate = format(date, 'yyyyMMdd')

  useEffect(() => {
    api.get<MeteorologyIndex[]>('oceanic-nino-index').then(response => {
      setOceanicNinoIndexData(response.data)
      setOceanicNinoIndexLoading(false)
    })
  }, [])

  useEffect(() => {
    api.get<MeteorologyIndex[]>('multivariate-enso-index').then(response => {
      setMultivariateEnsoIndexData(response.data)
      setMultivariateEnsoIndexLoading(false)
    })
  }, [])

  const gradientOffset = (data: MeteorologyIndex[]) => {
    const dataMax = Math.max(...data.map(entry => entry.value))
    const dataMin = Math.min(...data.map(entry => entry.value))

    if (dataMax <= 0) return 0
    if (dataMin >= 0) return 1
    return dataMax / (dataMax - dataMin)
  }

  const xAxisFormatter = (tick: string): string => {
    const year = new Date(tick).toLocaleString('pt-BR', { year: 'numeric' })
    const month = new Date(tick).toLocaleString('pt-BR', { month: 'short' })
    return `${capitalize(month)}/${year}`
  }

  const tooltips = {
    tsm: (
      <div>
        <p>Temperatura da superfície do mar</p>
        <p>
          <span>Fonte: </span>
          <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
            NOAA
          </a>
        </p>
      </div>
    ),
    oni: (
      <div>
        <p>O ONI o monitoramento da TSM é realizado dividindo o oceano Pacífico Equatorial em 4 regiões: Niño 1+2, Niñ0 3, Niño 3.4 e Niño 4.</p>
        <p>
          <span>Dados fornecidos por: </span>
          <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
            NOAA
          </a>
        </p>
      </div>
    ),
    tsmAnom: (
      <div>
        <p>
          Diferença entre a temperatura observada/prevista e a média climatológica de temperatura: anomalia positiva (cores quentes) e anomalia
          negativa (cores frias) representam TSM acima e abaixo da média, respectivamente.
        </p>
        <p>
          <span>Fonte: </span>
          <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
            NOAA
          </a>
        </p>
      </div>
    ),
    elNino: (
      <div>
        <table>
          <thead>
            <tr>
              <th>El Niño</th>
              <th>La Niña</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                É a fase caracterizada pelo aquecimento do oceano Pacífico. Nesta fase, a precipitação no Brasil é favorecida e desfavorecida nas
                regiões Sul e Norte, respectivamente. O El Niño é a fase do ENSO em que o oceano Pacífico sofre aquecimento e os ventos alísios são
                enfraquecidos, intensificando a formação de nuvens no Pacífico equatorial central e oriental.
              </td>
              <td>
                A fase é marcada pelo resfriamento do oceano Pacífico. Nela, a precipitação no Sul é inibida, e favorecida nas regiões Norte e
                Nordeste. La Niña é a fase de esfriamento do oceano Pacífico oeste e intensificação dos ventos alísios, provocando precipitação
                intensificada no extremo oeste do Pacífico e inibindo a formação de nuvens de chuva no Pacífico equatorial central e oriental.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <span>Fonte: </span>
          <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
            NOAA
          </a>
        </p>
      </div>
    ),
    mei: (
      <div>
        <p>
          O ENSO pode ser acompanhado através das variações no oceano, pelo Oceanic Niño Index, ou por alterações atmosféricas, pelo Índice de
          Oscilação Sul{' '}
        </p>
        <p>
          O gráfico apresenta a série temporal entre 1980 e 2021, com os anos de El Niño em vermelho e La Niña em azul, sendo possível notar a
          intensidade e período de alternância entre os eventos.
        </p>
        <p>
          Os eventos de El Niño e La Niña podem ocorrer nos padrões canônico e Modoki. Nos eventos canônicos, a oscilação é interanual, e a anomalia
          na TSM ocorre principalmente no oceano Pacífico Equatorial Leste, com os impactos descritos anteriormente. Os eventos Modoki apresentam
          anomalia na TSM na região central do Pacífico Equatorial, com escala de tempo decadal, e a precipitação pode não sofrer alterações. Ambos os
          padrões podem alternar ao longo de um evento, ou ocorrer simultaneamente.
        </p>
        <p>
          <span>Dados fornecidos por: </span>
          <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
            NOAA
          </a>
        </p>
      </div>
    ),
    nino12: (
      <div>
        <p>
          A previsão é realizada com base na climatologia de um período de 30 anos. No gráfico são apresentadas a anomalia da TSM observadas nos meses
          anteriores (linha escura), a previsão de diferentes modelos (linhas coloridas) e a média das previsões (linha tracejada) para os próximos
          trimestres nas regiões Niño 1+2 (esquerda) e Niño 3.4 (direita).
        </p>
        <p>
          <span>Fonte: </span>
          <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
            NOAA
          </a>
        </p>
      </div>
    ),
    nino34: (
      <div>
        <p>
          A previsão é realizada com base na climatologia de um período de 30 anos. No gráfico são apresentadas a anomalia da TSM observadas nos meses
          anteriores (linha escura), a previsão de diferentes modelos (linhas coloridas) e a média das previsões (linha tracejada) para os próximos
          trimestres nas regiões Niño 1+2 (esquerda) e Niño 3.4 (direita).
        </p>
        <p>
          <span>Fonte: </span>
          <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
            NOAA
          </a>
        </p>
      </div>
    ),
  }

  return (
    <Container>
      <Section>
        <SectionTitle info={tooltips.elNino}>El Niño - Oscilação Sul (ENSO)</SectionTitle>
        <SectionText>
          É um fenômeno atmosférico-oceânico interanual que ocorre no oceano Pacífico Equatorial, e provoca alterações na temperatura e precipitação
          em diversas regiões do globo.
        </SectionText>
      </Section>
      <Section>
        <SectionSubTitle title='Índice Oceânico do Niño (ONI)' info={tooltips.oni} />
        <SectionSubText>
          O índice é baseado na anomalia na temperatura da superfície do mar (TSM), sendo a principal forma de monitoramento das condições de El
          Niño/La Niña.
        </SectionSubText>
        <ChartContainer height='400px'>
          {oceanicNinoIndexLoading ? (
            <LoadingContainer />
          ) : (
            <ResponsiveContainer>
              <AreaChart data={oceanicNinoIndexData}>
                <CartesianGrid strokeDasharray='3 3' />
                <Tooltip content={CustomTooltip} />
                <YAxis
                  dataKey='value'
                  type='number'
                  tick={{ fontSize: '12px' }}
                  width={10}
                  domain={[dataMin => Math.floor(dataMin / 1) * 1, dataMax => Math.ceil(dataMax / 1) * 1]}
                />
                <XAxis dataKey='date' type='category' tick={{ fontSize: '12px' }} tickFormatter={xAxisFormatter} />
                <defs>
                  <linearGradient id='oceanicNinoIndex' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset={gradientOffset(oceanicNinoIndexData)} stopColor={theme.safiraColors.red[1]} stopOpacity={1} />
                    <stop offset={gradientOffset(oceanicNinoIndexData)} stopColor={theme.safiraColors.blue[1]} stopOpacity={1} />
                  </linearGradient>
                </defs>
                <Area dataKey='value' fill='url(#oceanicNinoIndex)' stroke='url(#oceanicNinoIndex)' />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </ChartContainer>
      </Section>
      <Grid columns={2}>
        <Section>
          <SectionSubTitle title='TSM (°C)' info={tooltips.tsm} />
          <ONIImagesContainer>
            <div>
              <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/el-nino-la-nina/sstanim.gif`} alt='' />
            </div>
          </ONIImagesContainer>
        </Section>
        <Section>
          <SectionSubTitle title='Anomalia da TSM (°C)' info={tooltips.tsmAnom} />
          <ONIImagesContainer>
            <div>
              <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/el-nino-la-nina/sstaanim.gif`} alt='' />
            </div>
          </ONIImagesContainer>
        </Section>
        <Section>
          <SectionSubTitle title='Niño1+2' info={tooltips.nino12} />
          <ONIImagesContainer>
            <div>
              <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/el-nino-la-nina/nino12Mon.png`} alt='' />
            </div>
          </ONIImagesContainer>
        </Section>
        <Section>
          <SectionSubTitle title='Niño3.4' info={tooltips.nino34} />
          <ONIImagesContainer>
            <div>
              <img src={`${process.env.REACT_APP_STATIC_ADDRESS}/maps/${formattedDate}/el-nino-la-nina/nino34Mon.png`} alt='' />
            </div>
          </ONIImagesContainer>
        </Section>
      </Grid>
      <Section>
        <SectionSubTitle title='Índice Multivariado do ENSO V2 (MEI)' info={tooltips.mei} />
        <SectionSubText>
          A série temporal de ENSO é obtida a partir de termos oceânicos e atmosféricos, sendo o índice mais eficiente para representar a sazonalidade
          do fenômeno.
        </SectionSubText>
        <ChartContainer height='400px'>
          {multivariateEnsoIndexLoading ? (
            <LoadingContainer />
          ) : (
            <ResponsiveContainer>
              <AreaChart data={multivariateEnsoIndexData}>
                <CartesianGrid strokeDasharray='3 3' />
                <Tooltip content={CustomTooltip} />
                <YAxis
                  dataKey='value'
                  type='number'
                  tick={{ fontSize: '12px' }}
                  width={10}
                  domain={[dataMin => Math.floor(dataMin / 1) * 1, dataMax => Math.ceil(dataMax / 1) * 1]}
                />
                <XAxis dataKey='date' type='category' tick={{ fontSize: '12px' }} tickFormatter={xAxisFormatter} />
                <defs>
                  <linearGradient id='multivariateEnsoIndex' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset={gradientOffset(multivariateEnsoIndexData)} stopColor={theme.safiraColors.red[1]} stopOpacity={1} />
                    <stop offset={gradientOffset(multivariateEnsoIndexData)} stopColor={theme.safiraColors.blue[1]} stopOpacity={1} />
                  </linearGradient>
                </defs>
                <Area dataKey='value' fill='url(#multivariateEnsoIndex)' stroke='url(#multivariateEnsoIndex)' />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </ChartContainer>
      </Section>
    </Container>
  )
}

export default ENSO
