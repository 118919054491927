import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const Label = styled.label`
  color: #8c8c8c;
  font-size: 0.875rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  padding-top: 3px;
`

export const Button = styled.button<{ active: boolean }>`
  padding: 10px 15px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: ${props => props.theme.colors.blue700};
  display: flex;
  align-items: center;
  background: ${props => {
    if (props.active) return props.theme.colors.blue100
    return props.theme.colors.black100
  }};
  svg {
    font-size: 1.3rem;
    color: ${props => props.theme.colors.black500};
  }
`

export const InputElement = styled.input``
