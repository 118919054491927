import React from 'react'
import { TooltipProps } from 'recharts'

import { returnBRLFromNumber } from 'utils'
import { Container, TooltipGrid, TooltipInfoContainer, TooltipInfoValue } from 'components/CustomTooltip'

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
  if (active && payload && label) {
    if (payload[0]) {
      return (
        <Container>
          <TooltipGrid>
            <TooltipInfoContainer>
              <TooltipInfoValue style={{ fontWeight: 'bold' }}>Sudeste</TooltipInfoValue>
              <TooltipInfoValue style={{ fontWeight: 'bold' }}>Sul</TooltipInfoValue>
              <TooltipInfoValue style={{ fontWeight: 'bold' }}>Nordeste</TooltipInfoValue>
              <TooltipInfoValue style={{ fontWeight: 'bold' }}>Norte</TooltipInfoValue>
            </TooltipInfoContainer>
            <TooltipInfoContainer>
              <TooltipInfoValue>{`${returnBRLFromNumber(payload[0].payload.southeast)}`}</TooltipInfoValue>
              <TooltipInfoValue>{`${returnBRLFromNumber(payload[0].payload.south)}`}</TooltipInfoValue>
              <TooltipInfoValue>{`${returnBRLFromNumber(payload[0].payload.northeast)}`}</TooltipInfoValue>
              <TooltipInfoValue>{`${returnBRLFromNumber(payload[0].payload.north)}`}</TooltipInfoValue>
            </TooltipInfoContainer>
          </TooltipGrid>
        </Container>
      )
    }
  }
  return null
}

export default CustomTooltip
