import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Banner from 'components/Banner'
import Navigation from 'components/Navigation'
import { Container, Divider } from './styles'

import { ForwardCurveByDay } from './ForwardCurveByDay'
import { ForwardCurveByProduct } from './ForwardCurveByProduct'

const Market: React.FC = () => {
  return (
    <>
      <Banner title='Mercado' hasNavigation />
      <Container>
        <Navigation
          options={[
            {
              label: 'Curva forward diária',
              value: '/market/forward-curve/by-day',
              base: '/market/forward-curve/by-day',
            },
            {
              label: 'Curva forward por produto',
              value: '/market/forward-curve/by-product',
              base: '/market/forward-curve/by-product',
            },
          ]}
        />
        <Divider />
        <Switch>
          <Route path='/market/forward-curve/by-day' component={ForwardCurveByDay} />
          <Route path='/market/forward-curve/by-product' component={ForwardCurveByProduct} />
        </Switch>
      </Container>
    </>
  )
}

export default Market
