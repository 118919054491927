import styled from 'styled-components'
import { lighten, transparentize } from 'polished'

export const Container = styled.div``

export const TableContainer = styled.div`
  margin-top: 2rem;
  overflow-y: auto;
  max-height: 400px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid #ccc;
  border-radius: 5px;
  table {
    text-align: center;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    thead {
      tr {
        color: #fff;
        :first-child th {
          background: ${props => props.theme.safiraColors.blue[4]};
          z-index: 2;
          top: 0;
        }
        :not(:first-child) th {
          background: ${props => lighten(0.1, props.theme.safiraColors.blue[5])};
          z-index: 1;
          top: 38px;
        }
        th {
          position: sticky;
          padding: 15px;
          font-size: 14px;
          word-wrap:break-word :not(:last-child) {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
    tbody tr {
      :nth-child(even) {
        background: ${props => transparentize(0.95, props.theme.safiraColors.blue[4])};
      }
      td {
        font-size: 12px;
        padding: 10px;
      }
    }
  }
`

export const DownloadBar = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DownloadButton = styled.button<{ description?: string }>`
  border: none;
  border-bottom: 2px solid ${props => transparentize(0.7, props.theme.safiraColors.green[4])};
  padding: 5px;
  border-radius: 5px;
  font-size: 20px;
  background: ${props => transparentize(0.8, props.theme.safiraColors.green[4])};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 200ms;
  position: relative;
  width: auto;
  div {
    :first-child {
      /* background: red; */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :last-child {
      width: 0%;
      transition: 200ms;
      span {
        font-size: 10px;
        display: none;
      }
    }
  }
  :hover {
    background: ${props => props.theme.safiraColors.green[4]};
    border-bottom: 2px solid ${props => lighten(0.1, props.theme.safiraColors.green[4])};
    :hover div:last-child {
      width: 100%;
      span {
        display: block;
        white-space: nowrap;
      }
    }
  }
`
