import React, { useEffect, useRef } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import { usePrismicDocumentByUID, PrismicRichText, PrismicImage } from '@prismicio/react'
import { asImageSrc } from '@prismicio/helpers'
import { Container, BannerContainer, ReturnContainer, ArticleTitle, AuthorInfo, ArticleBody } from './styles'
import { IoMdArrowRoundBack } from 'react-icons/io'

function LoadingPlaceholder() {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', display: 'grid', gap: '30px', marginTop: '50px' }}>
      <RectShape color='#ccc' style={{ height: '300px' }} />
      <RectShape color='#ccc' style={{ height: '2rem' }} />
      <TextBlock rows={30} color='#ccc' />
    </div>
  )
}

export function Article() {
  const { slug } = useParams<{ slug: string; page: string }>()
  const query = new URLSearchParams(useLocation().search)

  const bannerRef = useRef<HTMLImageElement>(null)
  const [document, { state }] = usePrismicDocumentByUID('gim_post', slug, {
    graphQuery: `{
      gim_post {
        banner
        title
        body
        author_link {
          name
          description
          picture
        }
      }
    }`,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (state === 'loading') return <LoadingPlaceholder />

  return (
    <>
      <BannerContainer>
        <div />
        <PrismicImage ref={bannerRef} field={document?.data.banner} />
      </BannerContainer>
      <Container>
        <Link to={`/regulatorio?page=${query.get('page')}&search=${query.get('search')}`}>
          <ReturnContainer>
            <IoMdArrowRoundBack />
          </ReturnContainer>
        </Link>
        <ArticleTitle>
          <PrismicRichText field={document?.data.title} />
        </ArticleTitle>
        <AuthorInfo>
          {document?.data.author_link.data?.picture ? (
            <PrismicImage field={document?.data.author_link.data.picture} />
          ) : (
            <img
              style={{ width: '100px', height: '100px' }}
              src={`${process.env.REACT_APP_STATIC_ADDRESS}/images/client/grupo-safira.jfif`}
              alt='Prisma avatar'
            />
          )}
          <div>
            {document?.data.author_link.data?.name ? <PrismicRichText field={document?.data.author_link.data.name} /> : 'Regulatório Safira'}
            <span>
              {document?.last_publication_date &&
                new Date(document.last_publication_date).toLocaleDateString('pt-br', {
                  day: 'numeric',
                  month: 'long',
                  year: '2-digit',
                })}
            </span>
          </div>
        </AuthorInfo>
        <ArticleBody>
          <PrismicRichText
            field={document?.data.body}
            components={{
              image: children => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={asImageSrc(children.node)} />
                </div>
              ),
            }}
          />
        </ArticleBody>
      </Container>
    </>
  )
}
