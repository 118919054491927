import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  @media (max-width: 1920px) {
    grid-template-columns: repeat(10, 1fr);
  }
  @media (max-width: 1366px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(5, 1fr);
  }
`

type CustomLegendValueContainer = {
  color?: string
  isDashed?: boolean
  isLine?: boolean
  handlesMouseOver: boolean
}

export const CustomLegendValueContainer = styled.div<CustomLegendValueContainer>`
  padding: 0 15px;
  position: relative;
  font-size: 12px;
  color: ${props => (props.isDashed ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.5)')};
  cursor: ${props => (props.handlesMouseOver ? 'pointer' : 'normal')};
  font-weight: 600;
  text-decoration: ${props => (props.isDashed ? 'line-through' : '')};
  white-space: nowrap;
  transition: color 200ms;
  :hover {
    color: ${props => {
      if (!props.handlesMouseOver) return ''
      return props.isDashed ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.5)'
    }};
  }
  ::before {
    content: '';
    position: absolute;
    width: 13px;
    height: ${props => (props.isLine ? '3px' : '13px')};
    background-color: ${props => (props.color ? props.color : props.theme.safiraColors.blue[4])};
    top: 50%;
    left: -3px;
    transform: translateY(-50%);
    ${props =>
      !props.isLine &&
      css`
        border-radius: 50%;
      `}
  }
`
