import { createGlobalStyle, keyframes } from 'styled-components'

const textBlockAnimation = keyframes`
  0% {
    opacity: .6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .6;
  }
`

const InjectGlobal = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
    background: ${props => props.theme.colors.background};
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  .text-block {
    animation: ${textBlockAnimation} 1s infinite;
  }

  .rect-shape {
    animation: ${textBlockAnimation} 1s infinite;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #999999;
  }
  ::-webkit-scrollbar-thumb {
    background: #4d4d4d;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export default InjectGlobal
