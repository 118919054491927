import React from 'react'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders'

export function LoadingPlaceholder() {
  return (
    <div style={{ display: 'grid', gap: '2rem' }}>
      <RectShape color='#ccc' style={{ height: '60px', width: '40%' }} />
      <RectShape color='#ccc' style={{ height: '150px' }} />
      <TextBlock color='#ccc' rows={3} />
      <RectShape color='#ccc' style={{ height: '30px', width: '40%' }} />
    </div>
  )
}
