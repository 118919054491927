import React, { useState, useEffect, useRef } from 'react'
import { BsInfoCircleFill } from 'react-icons/bs'

import { Container, InfoContainer } from './styles'

interface InfoTooltip {
  component?: JSX.Element
}

const InfoTooltip: React.FC<InfoTooltip> = ({ component }) => {
  const [show, setShow] = useState(false)
  const tooltipRef = useRef(document.createElement('div'))

  useEffect(() => {
    function handleOutsideOfUserPoupClick(this: Document, ev: globalThis.MouseEvent) {
      if (tooltipRef.current && !tooltipRef.current.contains(ev.target as Node)) setShow(false)
    }

    document.addEventListener('mouseover', handleOutsideOfUserPoupClick)

    return () => document.removeEventListener('mouseover', handleOutsideOfUserPoupClick)
  }, [])

  return (
    <Container onMouseMove={() => setShow(true)}>
      <BsInfoCircleFill />
      <InfoContainer ref={tooltipRef}>
        {show ? (
          <>
            <div style={{ maxHeight: '400px', overflow: 'auto' }}>{component}</div>
          </>
        ) : null}
      </InfoContainer>
    </Container>
  )
}

export default InfoTooltip
