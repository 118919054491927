import React from 'react'
import { subsystems } from 'utils/globals'
import { Container, DataTableContainer, DataTableTd, DataTableMapTd, DataTableSubsystemTd, DataTableRow } from './styles'

interface Study {
  id: number
  date: string
  map: string
  member: string
  sensibility: string
  rainModel: string
  forecast: string
  model: string
  subsystems: {
    [index: string]: {
      [index: string]: number
    }
  }
}

const studyKeys: { key: string; formatter?: (value: number) => string }[] = [
  {
    key: 'earStart',
    formatter: (value: number) => (value / 100).toLocaleString('pt-br', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 }),
  },
  { key: 'week1', formatter: (value: number) => value.toLocaleString('pt-br', { maximumFractionDigits: 0 }) },
  { key: 'week2', formatter: (value: number) => value.toLocaleString('pt-br', { maximumFractionDigits: 0 }) },
  { key: 'week3', formatter: (value: number) => value.toLocaleString('pt-br', { maximumFractionDigits: 0 }) },
  { key: 'week4', formatter: (value: number) => value.toLocaleString('pt-br', { maximumFractionDigits: 0 }) },
  { key: 'week5', formatter: (value: number) => value.toLocaleString('pt-br', { maximumFractionDigits: 0 }) },
  { key: 'week6', formatter: (value: number) => value.toLocaleString('pt-br', { maximumFractionDigits: 0 }) },
  { key: 'monthlyEnaMwmed', formatter: (value: number) => value.toLocaleString('pt-br', { maximumFractionDigits: 0 }) },
  { key: 'mlt', formatter: (value: number) => value.toLocaleString('pt-br', { maximumFractionDigits: 0 }) },
  { key: 'monthlyEnaMlt', formatter: (value: number) => value.toLocaleString('pt-br', { style: 'percent' }) },
  {
    key: 'cmoAverage',
    formatter: (value: number) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
  },
  {
    key: 'pldAverage',
    formatter: (value: number) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
  },
]

const tableHeadersRows: { text: string; rowSpan?: number; colSpan?: number }[][] = [
  [
    { text: 'Modelo', rowSpan: 2 },
    { text: 'Submercado', rowSpan: 2 },
    { text: 'EAR Inicial (%)', rowSpan: 2 },
    { text: 'ENA Semanal (MWmed)', colSpan: 6 },
    { text: 'ENA Mensal', colSpan: 3 },
    { text: 'PLD Médio (R$/MWh)', colSpan: 2 },
  ],
  [
    { text: 'Sem. 1' },
    { text: 'Sem. 2' },
    { text: 'Sem. 3' },
    { text: 'Sem. 4' },
    { text: 'Sem. 5' },
    { text: 'Sem. 6' },
    { text: 'MWmed' },
    { text: 'MLT' },
    { text: '% MLT' },
    { text: 'Sem limites' },
    { text: 'Com limites' },
  ],
]

const DataTableAndChart: React.FC<{ studies: Study[]; index: number }> = ({ studies, index }) => (
  <Container animationDelay={index * 500}>
    <DataTableContainer>
      <table>
        <thead>
          {tableHeadersRows.map((tableHeaderRow, index) => (
            <tr key={index}>
              {tableHeaderRow.map(tableHeader => (
                <th key={tableHeader.text} colSpan={tableHeader.colSpan} rowSpan={tableHeader.rowSpan}>
                  {tableHeader.text}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {studies.map(study => (
            <>
              <tr>
                <DataTableMapTd rowSpan={5}>{study.map}</DataTableMapTd>
              </tr>
              {subsystems.map(subsystem => (
                <DataTableRow key={subsystem.name}>
                  <DataTableSubsystemTd subsystemColor={subsystem.color}>{subsystem.name}</DataTableSubsystemTd>
                  {studyKeys.map(key => {
                    const rawValue = study.subsystems[subsystem.key]?.[key.key]
                    if (Number.isNaN(rawValue)) return <DataTableTd>-</DataTableTd>
                    return (
                      <DataTableTd key={key.key}>
                        {key.formatter
                          ? key.formatter(study.subsystems[subsystem.key][key.key])
                          : study.subsystems[subsystem.key][key.key].toLocaleString('pt-br', {
                              maximumFractionDigits: 2,
                            })}
                      </DataTableTd>
                    )
                  })}
                </DataTableRow>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </DataTableContainer>
  </Container>
)

export default DataTableAndChart
