import React from 'react'

import { Container, ColorsContainer, Color, LabelsContainer } from './styles'

const Scale: React.FC = () => {
  return (
    <Container>
      <ColorsContainer>
        <Color style={{ background: '#ffffff' }} />
        <Color style={{ background: '#e7f6fc' }} />
        <Color style={{ background: '#81d2fa' }} />
        <Color style={{ background: '#1777ba' }} />
        <Color style={{ background: '#032677' }} />
        <Color style={{ background: '#78be21' }} />
        <Color style={{ background: '#3ca611' }} />
        <Color style={{ background: '#008e00' }} />
        <Color style={{ background: '#2c460c' }} />
        <Color style={{ background: '#f2c31b' }} />
        <Color style={{ background: '#ff7700' }} />
        <Color style={{ background: '#de3400' }} />
        <Color style={{ background: '#aa0000' }} />
        <Color style={{ background: '#600000' }} />
        <Color style={{ background: '#cd7ef5' }} />
        <Color style={{ background: '#9611d6' }} />
      </ColorsContainer>
      <LabelsContainer>
        <span>1</span>
        <span>5</span>
        <span>10</span>
        <span>15</span>
        <span>20</span>
        <span>25</span>
        <span>30</span>
        <span>40</span>
        <span>50</span>
        <span>70</span>
        <span>90</span>
        <span>120</span>
        <span>150</span>
        <span>200</span>
        <span>250</span>
      </LabelsContainer>
      <span>Precipitação (mm)</span>
    </Container>
  )
}

export default Scale
