import styled from 'styled-components'
import { range } from 'lodash'

export const Container = styled.div<{ count: number; columns: number }>`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(${props => props.count}, 1fr);
  > div {
    box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    > h2 {
      font-size: 1.1rem;
      text-align: center;
      font-weight: 700;
      position: relative;
      color: ${props => props.theme.safiraColors.blue[3]};
    }
    > div {
      margin-top: 1rem;
      display: grid;
      grid-row-gap: 0.5rem;
      border-radius: 0.25rem;
      > div {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: ${props =>
          range(props.columns)
            .map(entry => (entry % 2 === 0 ? 'auto' : '1fr'))
            .join(' ')};
        > span {
          font-size: 1rem;
          text-align: center;
          color: ${props => props.theme.safiraColors.blue[3]};
          :first-child {
            text-align: left;
          }
          &.dots {
            opacity: 0.5;
            overflow-x: hidden;
            display: block;
          }
          &.highlighted {
            font-weight: bold;
          }
        }
      }
    }
  }
`
