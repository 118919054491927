import React, { ChangeEventHandler, CSSProperties, useState } from 'react'

import { Container, InputGroup, Label, InputElement, InputLabel } from './styles'

interface Select {
  label?: string
  wrapperStyle?: CSSProperties
  disabled?: boolean
  full?: boolean
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  options?: {
    label?: string
    value?: string
    checked?: boolean
  }[]
}

const InputCheckbox: React.FC<Select> = ({ name, label, options, disabled, wrapperStyle, full, onChange }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Container style={wrapperStyle}>
      <Label>{label}</Label>
      <InputGroup>
        {options?.map((option, index) => (
          <div key={option.value}>
            <InputElement
              name={name}
              checked={index === selectedIndex}
              value={option.value}
              style={{ width: full ? '100%' : '' }}
              disabled={disabled}
              type='radio'
              id={`radio-button-${option.value}`}
              onChange={ev => {
                setSelectedIndex(index)
                if (onChange) onChange(ev)
              }}
            />
            <InputLabel htmlFor={`radio-button-${option.value}`}>{option.label}</InputLabel>
          </div>
        ))}
      </InputGroup>
    </Container>
  )
}

export default InputCheckbox
