import React from 'react'
import { TooltipProps } from 'recharts'
import { Container, TooltipInfoContainer, TooltipInfoValue, TooltipGrid, TooltipTitleContainer, ToolTipDivider } from 'components/CustomTooltip'
import { useTheme } from 'styled-components'
import { Station, Variable } from '..'

interface CustomTooltipProps extends TooltipProps {
  variable1: Variable
  variable2: Variable
  station1?: Station
  station2?: Station
}

export const CustomTooltip = ({ active, payload, variable1, variable2, station1, station2 }: CustomTooltipProps) => {
  if (!active || !payload || !payload[0] || !payload[0].payload) return null

  const theme = useTheme()

  return (
    <Container>
      <TooltipTitleContainer>{new Date(payload[0].payload.date).toLocaleDateString('pt-br', { timeZone: 'UTC' })}</TooltipTitleContainer>
      <TooltipGrid columns={3}>
        <TooltipInfoContainer>
          {station1 && (
            <TooltipInfoValue>
              <span style={{ color: theme.colors.blue700, fontWeight: 'bold' }}>{station1.usable}</span>
            </TooltipInfoValue>
          )}
          {station2 && (
            <TooltipInfoValue>
              <span style={{ color: theme.colors.red300, fontWeight: 'bold' }}>{station2.usable}</span>
            </TooltipInfoValue>
          )}
        </TooltipInfoContainer>
        <ToolTipDivider />
        <TooltipInfoContainer>
          {station1 && (
            <TooltipInfoValue>
              <span>{payload[0]?.payload?.[variable1.key] ?? ''}</span>
              &nbsp;
              <span>{variable1.unit}</span>
            </TooltipInfoValue>
          )}
          {station2 && (
            <TooltipInfoValue>
              <span>{payload[1]?.payload?.[variable2.key] ?? ''}</span>
              &nbsp;
              <span>{variable2.unit}</span>
            </TooltipInfoValue>
          )}
        </TooltipInfoContainer>
      </TooltipGrid>
    </Container>
  )
}
