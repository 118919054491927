import styled, { keyframes } from 'styled-components'
import { transparentize } from 'polished'
import loadingFastGif from 'assets/gifs/loading_fast.gif'
import CustomLegend from './CustomLegend'

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${loadingFastGif});
  background-repeat: no-repeat;
  background-position: center;
  animation: ${appear} 1s 1;
  animation-fill-mode: backwards;
`

interface ChartContainer {
  height?: string
}

const ChartContainer = styled.div<ChartContainer>`
  height: ${props => (props.height ? props.height : '700px')};
  position: relative;
`

const ControllerContainer = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  grid-column-gap: 15px;
`

const MapsController = styled.div`
  display: flex;
  align-items: flex-end;
  div {
    button {
      border: none;
      background: ${props => transparentize(0.5, props.theme.safiraColors.green[4])};
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      cursor: pointer;
      opacity: 0.3;
      transition: 200ms;
      svg {
        color: ${props => transparentize(0.3, props.theme.safiraColors.green[4])};
      }
      :hover {
        opacity: 1;
      }
    }
  }
`

export { CustomLegend, LoadingContainer, ChartContainer, ControllerContainer, MapsController }
