import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Filter } from './Filter'
import { List } from './List'
import { Article } from './Article'
import { Container } from './styles'

export function Report() {
  const [typeId, setTypeId] = useState<string>()

  const updateTypeId = (newTypeId?: string) => setTypeId(newTypeId)

  return (
    <Container>
      <Switch>
        <Route
          path='/meteorology/reports'
          exact
          component={() => (
            <>
              <Filter typeId={typeId} updateTypeId={updateTypeId} />
              <List typeId={typeId} />
            </>
          )}
        />
        <Route exact path='/meteorology/reports/:slug' component={Article} />
      </Switch>
    </Container>
  )
}
