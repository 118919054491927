import React from 'react'
import { Container, Label, Switcher, Input, Slider } from './styles'

type SwitchProps = {
  text?: string
  value?: boolean
  onChange?: (value: boolean) => void
}

export function Switch({ onChange, text, value: defaultValue }: SwitchProps) {
  return (
    <Container>
      <Label>{text ?? 'Ligado/Desligado'}</Label>
      <Switcher>
        <Input type='checkbox' checked={defaultValue} onChange={({ target }) => onChange && onChange(target.checked)} />
        <Slider />
      </Switcher>
    </Container>
  )
}
