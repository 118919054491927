import React, { useState, useEffect } from 'react'
import InfoTooltip from 'components/InfoTooltip'
import { BiChevronLeft, BiChevronRight, BiLinkExternal } from 'react-icons/bi'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Scale from './Scale'
import ScaleGoes from './ScaleGoes'
import ScaleClimate from './ScaleClimate'
import Image from './Image'
import {
  Container,
  ContentContainer,
  ImagesContainer,
  ImageContainer,
  ControllerContainer,
  ImageTitle,
  RangeContainer,
  LabelContainer,
  ImageGroupTitle,
  ImageDownloadButton,
} from './styles'

interface MapGaleryData {
  models?: string[]
  scale: 'forecast' | 'goes' | 'climate'
  startFromEnd?: boolean
  imagesPerLine?: number
  images: {
    [index: string]: string
  }[]
  descriptions?: {
    [index: string]: JSX.Element
  }
  aliases?: {
    [index: string]: string
  }
}

const MapGallery: React.FC<MapGaleryData> = ({ models, images, descriptions, aliases, scale, startFromEnd, imagesPerLine }) => {
  const [imageIndex, setImageIndex] = useState(startFromEnd ? images.length - 1 : 0)
  const numberOfImages = images.length - 1

  const handleIndexChange = (newIndex: number) => {
    if (newIndex < 0) {
      setImageIndex(numberOfImages)
      return
    }
    if (newIndex > numberOfImages) {
      setImageIndex(0)
      return
    }
    setImageIndex(newIndex)
  }

  const handleKeyboardKeydown = (ev: KeyboardEvent) => {
    const keys: { [index: string]: () => void } = {
      ArrowLeft: () => handleIndexChange(imageIndex - 1),
      ArrowRight: () => handleIndexChange(imageIndex + 1),
    }
    const { key } = ev
    if (keys[key]) keys[key]()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardKeydown)
    return () => document.removeEventListener('keydown', handleKeyboardKeydown)
  })

  const imagesBlock = () => {
    if (models) {
      return models.map(model => (
        <ImageContainer key={model}>
          <ImageTitle hasTooltip style={{ background: images[imageIndex].color }}>
            <InfoTooltip component={descriptions?.[model]} />
            <span>{aliases?.[model] || model.toUpperCase()}</span>
            <a target='_blank' href={images[imageIndex][model]} rel='noreferrer'>
              <ImageDownloadButton>
                <BiLinkExternal />
              </ImageDownloadButton>
            </a>
          </ImageTitle>
          <TransformWrapper>
            <TransformComponent>
              <Image src={images[imageIndex][model]} />
            </TransformComponent>
          </TransformWrapper>
        </ImageContainer>
      ))
    }

    return images.map(image => (
      <ImageContainer key={image.title}>
        <ImageTitle hasTooltip={false} style={{ background: images[imageIndex].color }}>
          <div></div>
          <span>{image.title}</span>
          <a target='_blank' href={image.defaultURL} rel='noreferrer'>
            <ImageDownloadButton>
              <BiLinkExternal />
            </ImageDownloadButton>
          </a>
        </ImageTitle>
        <TransformWrapper>
          <TransformComponent>
            <Image src={image.defaultURL} />
          </TransformComponent>
        </TransformWrapper>
      </ImageContainer>
    ))
  }

  return (
    <Container>
      {models ? (
        <ControllerContainer>
          <button type='button' onClick={() => handleIndexChange(imageIndex - 1)}>
            <BiChevronLeft size={40} />
          </button>
          <RangeContainer>
            {images.find(image => image.color) ? (
              <LabelContainer numberOfLabels={images.length}>
                {images.map(image => (image.color ? <div style={{ background: image.color }} /> : null))}
              </LabelContainer>
            ) : null}
            <input type='range' max={numberOfImages} step={1} value={imageIndex} onChange={ev => setImageIndex(Number(ev.target.value))} />
          </RangeContainer>
          <button type='button' onClick={() => handleIndexChange(imageIndex + 1)}>
            <BiChevronRight size={40} />
          </button>
        </ControllerContainer>
      ) : null}
      <ContentContainer>
        {models ? (
          <ImageGroupTitle>
            <span>{images[imageIndex].title}</span>
          </ImageGroupTitle>
        ) : null}
        <ImagesContainer columnsNumber={imagesPerLine ?? (models ? models.length : images.length)}>{imagesBlock()}</ImagesContainer>
      </ContentContainer>
      {scale === 'forecast' ? <Scale /> : null}
      {scale === 'goes' ? <ScaleGoes /> : null}
      {scale === 'climate' ? <ScaleClimate /> : null}
    </Container>
  )
}

export default MapGallery
