import React from 'react'
import { TooltipProps } from 'recharts'
import { Container } from 'components/CustomTooltip'
import { TableContainer } from './styles'

type CustomTooltipProps = {
  index?: number
  arePricesLimited: boolean
}

export const CustomTooltip = ({ payload, active, index, arePricesLimited }: TooltipProps & CustomTooltipProps) => {
  if (!(payload && active)) return null

  return (
    <Container>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th colSpan={2}>{`Cenário: ${index}`}</th>
            </tr>
            <tr>
              <th>ENA (% MLT)</th>
              <th>PLD (R$/MWh)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Intl.NumberFormat('pt-br', { style: 'percent' }).format(payload[0].payload.monthly_ena_mlt)}</td>
              <td>
                {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(
                  payload[0].payload[arePricesLimited ? 'pld_average' : 'cmo_average'],
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
    </Container>
  )
}
