import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ResponsiveContainer, ComposedChart, Bar, Line, Area, CartesianGrid, YAxis, XAxis, Tooltip, Legend, Label } from 'recharts'
import { PrismaSelect, InputDate } from 'components/Input'
import { Container, ControllerContainer } from './styles'
import { subsystemsWithSin } from 'utils/globals'
import { SectionTitle } from 'components/Section'
import { useTheme } from 'styled-components'
import { format, parse, eachDayOfInterval, isAfter, isBefore, add } from 'date-fns'
import { toast } from 'react-toastify'
import { utcDateFromDate, formatQueryDate } from 'utils'
import { InputSubmit } from 'components/Input/InputSubmit'
import { ChartContainer } from '..'

interface IData {
  idSubsistena: string
  nomSubsistema: string
  dinInstante: string
  valGerhidraulica: number
  valGertermica: number
  valGereolica: number
  valGersolar: number
  valCarga: number
  valIntercambio: number
}

interface IResponse {
  sin: IData[]
  southeast: IData[]
  south: IData[]
  northeast: IData[]
  north: IData[]
}

const yTickFormatter = (tick: string) => Intl.NumberFormat('pt-br').format(Number(tick))

const xTickFormatter = (tick: string) => {
  return Intl.DateTimeFormat('pt-br', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  }).format(new Date(tick))
}

export function BalancoDeEnergiaNosSubsistemas() {
  const [end, setEnd] = useState(new Date())
  const [start, setStart] = useState(add(end, { days: -10 }))
  const [subsystemIndex, setSubsystemIndex] = useState(0)
  const [counter, setCounter] = useState(0)

  const daysInInterval = useMemo(() => eachDayOfInterval({ start, end }).length, [start, end])
  const theme = useTheme()

  const datasets = [
    { key: 'valGerhidraulica', name: 'Geração hidráulica', color: theme.colors.blue400, component: 'bar' },
    { key: 'valGertermica', name: 'Geração térmica', color: theme.colors.orange, component: 'bar' },
    { key: 'valGereolica', name: 'Geração eólica', color: theme.colors.green, component: 'bar' },
    { key: 'valGersolar', name: 'Geração solar', color: theme.colors.yellow, component: 'bar' },
    { key: 'valCarga', name: 'Carga', color: theme.colors.black500, component: 'line' },
  ]

  const { data, isLoading, isFetching } = useQuery(
    ['balanco-de-energia-nos-subsistemas', counter],
    () => {
      return api.get<IResponse>('dados-abertos-ons/balanco-de-energia-nos-subsistemas', {
        params: { start: formatQueryDate(start), end: formatQueryDate(end) },
      })
    },
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  return (
    <Container>
      <SectionTitle>Balanço de energia nos submercados</SectionTitle>
      <ControllerContainer>
        <InputDate
          label='Início'
          value={format(utcDateFromDate(start), 'yyyy-MM-dd')}
          onChange={e => {
            const newStart = parse(e.target.value, 'yyyy-MM-dd', new Date())
            if (isBefore(newStart, end)) return setStart(newStart)
            toast.error('Data inicial deve ser anterior a final.', { position: 'top-right' })
          }}
        />
        <InputDate
          label='Fim'
          value={format(utcDateFromDate(end), 'yyyy-MM-dd')}
          onChange={e => {
            const newEnd = parse(e.target.value, 'yyyy-MM-dd', new Date())
            if (isAfter(newEnd, start)) return setEnd(newEnd)
            toast.error('Data final deve ser posterior a inicial.', { position: 'top-right' })
          }}
        />
        <PrismaSelect
          label='Submercado'
          onChange={event => setSubsystemIndex(Number(event.target.value))}
          options={subsystemsWithSin.map((subsystem, index) => ({ label: subsystem.name, value: String(index) }))}
        />
        <InputSubmit onClick={() => setCounter(state => state + 1)} />
      </ControllerContainer>
      <ChartContainer isLoading={isLoading} isFetching={isFetching}>
        <ResponsiveContainer>
          <ComposedChart data={data?.data[subsystemsWithSin[subsystemIndex].key]}>
            <CartesianGrid strokeDasharray='3 5' />
            <XAxis scale={daysInInterval <= 3 ? 'auto' : 'point'} dataKey='dinInstante' tick={{ fontSize: '14px' }} tickFormatter={xTickFormatter} />
            <YAxis width={70} tick={{ fontSize: '14px' }} tickCount={8} tickFormatter={yTickFormatter}>
              <Label
                angle={-90}
                value={'MW'}
                position='insideLeft'
                style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
              />
            </YAxis>
            <Legend iconType='plainline' wrapperStyle={{ fontSize: '14px' }} />
            <Tooltip labelFormatter={label => xTickFormatter(String(label))} formatter={value => `${Number(value).toLocaleString('pt-br')} MW`} />
            {datasets.map(dataset => {
              const { component } = dataset

              if (component === 'line')
                return (
                  <Line
                    key={dataset.key}
                    dataKey={dataset.key}
                    stroke={dataset.color}
                    strokeWidth={3}
                    name={dataset.name}
                    fill='transparent'
                    dot={daysInInterval <= 3}
                  />
                )

              if (daysInInterval <= 3) return <Bar key={dataset.key} dataKey={dataset.key} stackId={1} fill={dataset.color} name={dataset.name} />

              return (
                <Area
                  key={dataset.key}
                  dataKey={dataset.key}
                  stackId={1}
                  fill={dataset.color}
                  stroke={dataset.color}
                  strokeWidth={0}
                  name={dataset.name}
                />
              )
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}
