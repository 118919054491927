import styled from 'styled-components'

export const Container = styled.div``

export const ControllerContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 2rem 0;
`

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  height: 1000px;
`

export const ChartContainer = styled.div`
  :nth-child(5) {
    grid-column: 1 / span 2;
  }
  overflow: hidden;
`

export const SummaryWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'sin sin sin southeast southeast southeast'
    'south south northeast northeast north north';
  gap: 30px;
  padding: 30px 0;
  justify-content: center;
`

export const SummaryContainer = styled.div`
  border-radius: 8px;
  transition: 200ms;
  position: relative;
  background: ${props => props.color};
`

export const SummaryValuesContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  display: grid;
  gap: 10px;
  border: 2px solid #eaeaea;
  padding: 1rem;
  border-radius: 0.25rem;
`

export const SummaryTitleContainer = styled.div<{ color: string }>`
  font-size: 16px;
  color: #999;
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  color: ${props => props.theme.safiraColors.blue[3]};
  > div {
    width: 1.2rem;
    height: 1.2rem;
    background: ${props => props.color};
    border-radius: 0.25rem;
  }
`

export const SummaryInfoContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 10px;
  span {
    :first-child {
      color: ${props => props.theme.safiraColors.blue[3]};
      font-size: 14px;
      display: flex;
      align-items: center;
    }
    :last-child {
      color: ${props => props.theme.safiraColors.blue[3]};
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1px;
      font-weight: bold;
    }
  }
`
