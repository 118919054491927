import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 30px;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`
