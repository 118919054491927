import React from 'react'
import { Container, CustomLegendValueContainer } from './styles'

type Legend = {
  text?: string
  color?: string
  isDashed?: boolean
  isLine?: boolean
  meta?: Record<string, string>
}

type CustomLegend = {
  onLegendMouseOver?: (legend: Legend) => void
  onLegendMouseLeave?: () => void
  onLegendClick?: (legend: Legend) => void
  wrapperStyle?: React.CSSProperties
  legends: Legend[]
}

const CustomLegend: React.FC<CustomLegend> = ({ legends, wrapperStyle, onLegendMouseOver, onLegendMouseLeave, onLegendClick }) => {
  return (
    <Container style={wrapperStyle}>
      {legends.map(legend => (
        <CustomLegendValueContainer
          key={legend.text}
          isDashed={legend.isDashed}
          isLine={legend.isLine}
          color={legend.color}
          handlesMouseOver={!!onLegendMouseOver}
          onMouseOver={onLegendMouseOver && (() => onLegendMouseOver(legend))}
          onMouseLeave={onLegendMouseLeave && (() => onLegendMouseLeave())}
          onClick={onLegendClick && (() => onLegendClick(legend))}
        >
          {legend.text}
        </CustomLegendValueContainer>
      ))}
    </Container>
  )
}

export default CustomLegend
