import React, { useState, useEffect } from 'react'
import { ResponsiveContainer, ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import api from 'services/api'
import { MeteorologyAMOIndex } from 'types'
import { Section } from 'components/Section'
import { ChartContainer, LoadingContainer } from 'components/Chart'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import SectionSubTitle from 'components/SectionSubtitle'
import { useTheme } from 'styled-components'
import { capitalize, PrismaChartUtils } from 'utils'
import { Container } from './styles'
import CustomTooltip from './CustomTooltip'

const AMO: React.FC = () => {
  const [data, setData] = useState<MeteorologyAMOIndex[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const theme = useTheme()

  useEffect(() => {
    api.get<MeteorologyAMOIndex[]>('atlantic-multidecadal-oscillation').then(response => {
      setData(response.data)
      setIsLoading(false)
    })
  }, [])

  const xAxisFormatter = (tick: string): string => {
    const year = new Date(tick).toLocaleString('pt-BR', { year: 'numeric' })
    const month = new Date(tick).toLocaleString('pt-BR', { month: 'short' })
    return `${capitalize(month)}/${year}`
  }

  const amoInfo = (
    <div>
      <p>A variação da TSM tem amplitude de 0,4ºC.</p>
      <p>
        A fase positiva é definida pelo Atlântico Norte aquecido e o Atlântico Equatorial esfriado. Nessa fase, a ZCIT (Zona de Convergência
        Intertropical) é deslocada para norte, reduzindo a precipitação na região Nordeste.
      </p>
      <p>
        Na fase negativa o Atlântico Norte está mais frio e o Atlântico Equatorial mais quente. Nela, a ZCAS (Zona de Convergência do Atlântico Sul) é
        intensificada e deslocada para norte durante a primavera e o verão, e a atividade anticiclônica subtropical é minimizada, contribuindo para a
        anomalia negativa na precipitação na primavera.
      </p>
      <p>
        <span>Dados fornecidos por: </span>
        <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
          NOAA
        </a>
      </p>
    </div>
  )

  return (
    <Container>
      <Section>
        <SectionTitle title='Oscilação Multidecadal do Atlântico (AMO)' />
        <SectionText>
          É um fenômeno de baixa frequência que ocorre no oceano Atlântico Norte, definido pela oscilação da temperatura da superfície do mar (TSM),
          com duração entre 60 e 90 anos, que afeta a temperatura e precipitação no Brasil. O índice para o monitoramento da AMO é obtido através da
          anomalia média da TSM no Atlântico Norte (0º-70ºN).
        </SectionText>
      </Section>
      <Section>
        <SectionSubTitle title='Histórico' info={amoInfo} />
        <ChartContainer height='400px' style={{ paddingTop: '10px' }}>
          <ResponsiveContainer>
            {(() => {
              if (isLoading) return <LoadingContainer />
              return (
                <ComposedChart data={data}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip content={CustomTooltip} />
                  <YAxis
                    dataKey='value'
                    type='number'
                    tick={{ fontSize: '12px' }}
                    width={20}
                    domain={[dataMin => Math.floor(dataMin / 1) * 1, dataMax => Math.ceil(dataMax / 1) * 1]}
                  />
                  <XAxis dataKey='date' type='category' tick={{ fontSize: '12px' }} tickFormatter={xAxisFormatter} />
                  <defs>
                    <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
                      <stop
                        offset={PrismaChartUtils.getChartGradientOffset({ data: data.map(entry => entry.value) })}
                        stopColor={theme.safiraColors.red[1]}
                        stopOpacity={1}
                      />
                      <stop
                        offset={PrismaChartUtils.getChartGradientOffset({ data: data.map(entry => entry.value) })}
                        stopColor={theme.safiraColors.blue[1]}
                        stopOpacity={1}
                      />
                    </linearGradient>
                  </defs>
                  <Area dataKey='value' fill='url(#splitColor)' stroke='url(#splitColor)' />
                  <Line dataKey='average' dot={false} strokeWidth={2} stroke={theme.safiraColors.blue[5]} />
                </ComposedChart>
              )
            })()}
          </ResponsiveContainer>
        </ChartContainer>
      </Section>
    </Container>
  )
}

export default AMO
