import React from 'react'
import { AiOutlineFacebook, AiOutlineInstagram, AiOutlineLinkedin, AiOutlineYoutube } from 'react-icons/ai'

import { Container } from './styles'

const Footer: React.FC = () => {
  const socials = [
    {
      icon: AiOutlineFacebook,
      link: 'https://www.facebook.com/SafiraEnergiaCom',
    },
    {
      icon: AiOutlineInstagram,
      link: 'https://www.instagram.com/safira.energia',
    },
    {
      icon: AiOutlineLinkedin,
      link: 'https://www.linkedin.com/company/safiraenergia',
    },
    {
      icon: AiOutlineYoutube,
      link: 'https://www.youtube.com/channel/UCQvNoQXMfUoVUuVVobRkT5g',
    },
  ]

  return (
    <Container>
      <div>
        {/* <img
          alt='Grupo Safira Logo'
          src={`${process.env.REACT_APP_STATIC_ADDRESS}/images/client/logo_grupo_safira_branco.png`}
        /> */}
        <div>
          <p>Siga nossas redes sociais</p>
        </div>
        <div>
          {socials.map(social => (
            <a target='_blank' rel='noreferrer' href={social.link} key={social.link}>
              <social.icon color='#fff' />
            </a>
          ))}
        </div>
      </div>
      <div>
        <p>Canais de Atendimento</p>
        <p>(11) 4191-3752</p>
        <p>contato@gpsafira.com.br</p>
      </div>
      <div>
        <p>Endereço</p>
        <p>Alameda Rio Negro, 500 Alphaville Industrial, Barueri - SP</p>
      </div>
      <div>
        <a target='_blank' rel='noreferrer' href={`${process.env.REACT_APP_STATIC_ADDRESS}/files/Politica de Privacidade - Safira Energia.pdf`}>
          <p>Política de privacidade</p>
        </a>
      </div>
      <div>
        <img alt='Grupo Safira Logo' src={`${process.env.REACT_APP_STATIC_ADDRESS}/images/client/logo_grupo_safira_branco.png`} />
        <p>Todos os direitos reservados ao Grupo Safira</p>
      </div>
    </Container>
  )
}

export default Footer
