import React from 'react'
import { ResponsiveContainer, ComposedChart, CartesianGrid, YAxis, XAxis, Tooltip, Legend, Label, Line } from 'recharts'
import { useTheme } from 'styled-components'
import { darken } from 'polished'
import { ChartContainer } from '../..'

const yTickFormatter = (tick: string) => Intl.NumberFormat('pt-br').format(Number(tick))

const xTickFormatter = (tick: string) => {
  return Intl.DateTimeFormat('pt-br', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    timeZone: 'UTC',
  }).format(new Date(tick))
}

type ChartProps = {
  isLoading?: boolean
  isFetching?: boolean
  cargaProgramada: Record<string, unknown>[] | undefined
  cargaVerificada: Record<string, unknown>[] | undefined
}

export function Chart({ cargaProgramada, cargaVerificada, isLoading, isFetching }: ChartProps) {
  const theme = useTheme()

  const datasets = [
    {
      key: 'val_cargaglobal',
      name: 'Carga global',
      color: theme.colors.blue100,
    },
    {
      key: 'val_cargaglobalcons',
      name: 'Carga global cons',
      color: theme.colors.blue400,
    },
    {
      key: 'val_cargaglobalsmmgd',
      name: 'Carga global smmgd',
      color: theme.colors.blue700,
    },
    {
      key: 'val_cargasupervisionada',
      name: 'Carga supervisionada',
      color: theme.colors.green,
    },
    {
      key: 'val_carganaosupervisionada',
      name: 'Carga não supervisionada',
      color: theme.colors.orange,
    },
    {
      key: 'val_cargammgd',
      name: 'Carga mmgd',
      color: theme.colors.red300,
    },
    {
      key: 'val_consistencia',
      name: 'Consistência',
      color: darken(0.2, theme.colors.cyan),
    },
  ]

  return (
    <ChartContainer isLoading={isLoading} isFetching={isFetching}>
      <ResponsiveContainer>
        <ComposedChart>
          <CartesianGrid strokeDasharray='3 5' />
          <XAxis
            dataKey='din_referenciautc'
            tick={{ fontSize: '14px' }}
            tickFormatter={xTickFormatter}
            type='category'
            allowDuplicatedCategory={false}
          />
          <YAxis width={70} tick={{ fontSize: '14px' }} tickFormatter={yTickFormatter}>
            <Label
              angle={-90}
              value={'MW'}
              position='insideLeft'
              style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
            />
          </YAxis>
          <Legend iconType='square' wrapperStyle={{ fontSize: '14px' }} />
          <Tooltip
            labelFormatter={label => new Date(label).toLocaleString('pt-br', { hour12: false })}
            formatter={value => `${Number(value).toLocaleString('pt-br')} MW`}
          />
          <Line data={cargaProgramada} dot={false} dataKey='val_cargaglobalprogramada' stroke='gray' name='Carga global programada' strokeWidth={3} />
          {datasets.map(dataset => (
            <Line
              data={cargaVerificada}
              dot={false}
              key={dataset.key}
              dataKey={dataset.key}
              stroke={dataset.color}
              fill='transparent'
              name={dataset.name}
              strokeWidth={3}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}
