import styled from 'styled-components'
import { lighten, transparentize } from 'polished'

export const Container = styled.div``

export const SummaryContainer = styled.menu`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5rem;
  margin-top: 1rem;
  > div {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    span {
      color: ${props => props.theme.safiraColors.blue[3]};
      text-align: center;
      &.dots {
        opacity: 0.5;
        overflow-x: hidden;
        display: block;
      }
    }
  }
`

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding-top: 30px;
`

export const TableContainer = styled.div`
  margin-top: 2rem;
  overflow-y: auto;
  max-height: 400px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid #ccc;
  border-radius: 5px;
  table {
    text-align: center;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    thead {
      tr {
        color: #fff;
        :first-child th {
          background: ${props => props.theme.safiraColors.blue[4]};
          z-index: 2;
          top: 0;
        }
        :not(:first-child) th {
          background: ${props => lighten(0.1, props.theme.safiraColors.blue[5])};
          z-index: 1;
          top: 38px;
        }
        th {
          position: sticky;
          padding: 15px;
          font-size: 14px;
          word-wrap:break-word :not(:last-child) {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
    tbody tr {
      :nth-child(even) {
        background: ${props => transparentize(0.95, props.theme.safiraColors.blue[4])};
      }
      td {
        font-size: 12px;
        padding: 10px;
      }
    }
  }
`
