import React, { useState } from 'react'
import { PrismaSelect, InputDate } from 'components/Input'
import { utcDateFromDate } from 'utils'
import { toast } from 'react-toastify'
import { format, isAfter, isBefore, parse } from 'date-fns'
import { InputSubmit } from 'components/Input/InputSubmit'
import { Container } from './styles'

type Code = { code: string; label: string }

const codes: Code[] = [
  { code: 'SECO', label: 'Subsistema Sudeste/Centro-Oeste' },
  { code: 'S', label: 'Subsistema Sul' },
  { code: 'NE', label: 'Subsistema Nordeste' },
  { code: 'N', label: 'Subsistema Norte' },
  { code: 'RJ', label: 'Rio de Janeiro' },
  { code: 'SP', label: 'São Paulo' },
  { code: 'MG', label: 'Minas Gerais' },
  { code: 'ES', label: 'Espírito Santo' },
  { code: 'MT', label: 'Mato Grosso' },
  { code: 'MS', label: 'Mato Grosso do Sul' },
  { code: 'DF', label: 'Distrito Federal' },
  { code: 'GO', label: 'Goiás' },
  { code: 'AC', label: 'Acre' },
  { code: 'RO', label: 'Rondônia' },
  { code: 'PR', label: 'Paraná' },
  { code: 'SC', label: 'Santa Catarina' },
  { code: 'RS', label: 'Rio Grande do Sul' },
  { code: 'BASE', label: 'Bahia/Sergipe' },
  { code: 'BAOE', label: 'Bahia Oeste' },
  { code: 'ALPE', label: 'Alagoas/Pernambuco' },
  { code: 'PBRN', label: 'Paraíba/Rio Grande do Norte' },
  { code: 'CE', label: 'Ceará' },
  { code: 'PI', label: 'Piauí' },
  { code: 'TON', label: 'Tocantins' },
  { code: 'PA', label: 'Pará' },
  { code: 'MA', label: 'Maranhão' },
  { code: 'AP', label: 'Amapá' },
  { code: 'AM', label: 'Amazonas' },
  { code: 'RR', label: 'Roraima' },
  { code: 'PESE', label: 'Perdas Subsistema Sudeste/Centro-Oeste' },
  { code: 'PES', label: 'Perdas Subsistema Sul' },
  { code: 'PENE', label: 'Perdas Subsistema Nordeste' },
  { code: 'PEN', label: 'Perdas Subsistema Norte' },
]

type ControllerProps = {
  setParams: React.Dispatch<React.SetStateAction<{ start: Date; end: Date; code: string }>>
  start: Date
  end: Date
}

export function Controller({ setParams, start, end }: ControllerProps) {
  const [controllerStart, setControllerStart] = useState(start)
  const [controllerEnd, setControllerEnd] = useState(end)
  const [controllerCode, setControllerCode] = useState(codes[0])

  function updateValuesFromFater() {
    setParams({ start: controllerStart, end: controllerEnd, code: controllerCode.code })
  }

  return (
    <Container>
      <InputDate
        label='Início'
        value={format(utcDateFromDate(controllerStart), 'yyyy-MM-dd')}
        onChange={e => {
          const newStart = parse(e.target.value, 'yyyy-MM-dd', new Date())
          if (isBefore(newStart, controllerEnd)) return setControllerStart(newStart)
          toast.error('Data inicial deve ser anterior a final.', { position: 'top-right' })
        }}
      />
      <InputDate
        label='Fim'
        value={format(utcDateFromDate(controllerEnd), 'yyyy-MM-dd')}
        onChange={e => {
          const newEnd = parse(e.target.value, 'yyyy-MM-dd', new Date())
          if (isAfter(newEnd, controllerStart)) return setControllerEnd(newEnd)
          toast.error('Data final deve ser posterior a inicial.', { position: 'top-right' })
        }}
      />
      <PrismaSelect
        label='Bacia'
        onChange={event => setControllerCode(codes.find(code => code.code === event.target.value) as Code)}
        options={codes.map(code => ({ label: code.label, value: code.code }))}
      />
      <InputSubmit onClick={updateValuesFromFater} />
    </Container>
  )
}
