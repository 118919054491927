import styled from 'styled-components'

export const Container = styled.div`
  max-width: 800px;
  box-sizing: content-box;
  margin: 5rem auto;
  background: #fff;
  border-radius: 0.5rem;
  h1 {
    font-size: 2.2rem;
    line-height: 40px;
    color: ${props => props.theme.safiraColors.blue[3]};
  }
`

export const BannerContainer = styled.div`
  :has(img) {
    margin-top: 3rem;
  }
  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 1rem;
  }
  figcaption {
    max-width: 800px;
    word-break: break-all;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
  }
`

export const ReturnContainer = styled.button`
  background: ${props => props.theme.colors.cyan};

  border: none;
  border-radius: 50%;
  opacity: 0.3;
  transition: opacity 200ms;

  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 1.1rem;
  }
  :hover {
    opacity: 0.8;
  }
`

export const ArticleTitle = styled.div`
  margin-top: 2rem;
  h1 {
    font-size: 2.5em;
    line-height: 60px;
  }
`

export const AuthorInfo = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  h6 {
    font-size: 1rem;
    font-weight: normal;
  }
  span {
    color: rgba(0, 0, 0, 0.36);
    font-size: 0.875rem;
  }
  img {
    border-radius: 50%;
    padding: 2px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    width: 3rem;
    height: 3rem;
  }
`

export const ArticleBody = styled.div`
  display: grid;
  gap: 2rem;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 2rem;
  img {
    max-width: 100%;
    border-radius: 0.25rem;
    margin: 0 auto;
  }
  p {
    line-height: 40px;
    font-size: 1.1rem;
    a {
      color: ${props => props.theme.safiraColors.blue[1]};
      :hover {
        text-decoration: underline;
      }
    }
    :first-child {
      margin-top: 2rem;
    }
  }
  ol,
  ul {
    list-style-position: inside;
    display: grid;
    gap: 1rem;
    line-height: 40px;
    font-size: 1.1rem;
  }
  pre {
    padding: 1rem;
    background: ${props => props.theme.safiraColors.blue[5]};
    border-radius: 0.5rem;
    color: #fff;
    font-size: 0.8rem;
  }
  div[data-oembed-type='video'] iframe {
    width: 100%;
    min-height: 400px;
    border-radius: 0.5rem;
  }
`

export const RichTextImgContainer = styled.div`
  display: grid;
  gap: 3px;
  figcaption {
    background: rgba(0, 0, 0, 0.05);
    padding: 0.25rem 0 0.25rem 0.5rem;
    border-radius: 0.25rem;
    max-width: 800px;
    word-break: break-all;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
  }
`
