import React from 'react'
import { ThemeProvider } from 'styled-components'
import { toast, ToastContainer } from 'react-toastify'
import { QueryClientProvider } from 'react-query'
import { PrismicProvider } from '@prismicio/react'
import { client } from 'services/prismic'
import { queryClient } from 'services/queryClient'
import Routes from 'routes'
import light from 'styles/themes/light'
import InjectGlobal from 'styles/InjectGlobal'
import { AuthProvider, AuthProviderProps, User } from 'oidc-react'
import { Prefetcher } from 'components'
import { Analytics } from 'Analytics'
import 'react-placeholder/lib/reactPlaceholder.css'
import 'react-toastify/dist/ReactToastify.css'
import { CompanyProvider } from 'CompanyProvider'
import { SaveModule } from 'SaveModule'
import { CookieConsent } from 'components/CookieConsent'

const urlUser = new URLSearchParams(window.location.search).get('user')

const oidcConfig: AuthProviderProps = {
  async onSignIn(response: User | null) {
    toast.dismiss()
    toast.success(`👋🏼 Olá ${response?.profile.name}`, {
      position: 'top-right',
      icon: false,
      hideProgressBar: true,
      style: { marginTop: '80px' },
      bodyStyle: { fontSize: '1.015rem' },
    })
    const nextURL = '/'
    const nextTitle = 'Boas vindas ao Prisma!'
    const nextState = { additionalInformation: 'Boas vindas ao Safira On - Prisma.' }
    window.history.pushState(nextState, nextTitle, nextURL)
  },
  extraQueryParams: urlUser ? { login_hint: urlUser } : undefined,
  autoSignIn: true,
  authority: process.env.REACT_APP_ZITADEL_AUTHORITY,
  clientId: process.env.REACT_APP_ZITADEL_CLIENT_ID,
  responseType: 'code',
  redirectUri: process.env.REACT_APP_ZITADEL_REDIRECT_URI,
  scope: 'openid profile email',
}

export const App = () => (
  <ThemeProvider theme={light}>
    <InjectGlobal />
    <Analytics />
    <QueryClientProvider client={queryClient}>
      <PrismicProvider client={client}>
        <AuthProvider {...oidcConfig}>
          <Prefetcher />
          <SaveModule />
          <CompanyProvider>
            <Routes />
          </CompanyProvider>
        </AuthProvider>
      </PrismicProvider>
    </QueryClientProvider>
    <CookieConsent />
    <ToastContainer position='top-center' autoClose={5000} style={{ fontSize: '12px', fontWeight: 'bold' }} />
  </ThemeProvider>
)
