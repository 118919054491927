import React, { useEffect, useRef, useState } from 'react'
import { asText } from '@prismicio/helpers'
import { client } from 'services/prismic'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { Container, ControllerContainer, PostContainer, PostsContainer, PaginatorContainer } from './styles'
import { useQuery } from 'react-query'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { predicate } from '@prismicio/client'
import { useDebounce } from 'usehooks-ts'
import { InputText } from 'components/Input'
import NoDataWarning from 'components/NoDataWarning'

function LoadingPlaceholder() {
  return (
    <div style={{ maxWidth: '900px', margin: '0 auto', display: 'grid', gap: '30px', marginTop: '50px' }}>
      {Array(4).fill(
        <>
          <RectShape color='#ccc' style={{ height: '1rem', width: '15%' }} />
          <RectShape color='#ccc' style={{ height: '2.5rem' }} />
          <TextBlock color='#ccc' rows={4} />
        </>,
      )}
    </div>
  )
}

export function RegulatorioList() {
  const query = new URLSearchParams(useLocation().search)
  const history = useHistory()
  const [page, setPage] = useState(Number(query.get('page') ?? 1))
  const [key, setKey] = useState(0)
  const [searchString, setSearchString] = useState(query.get('search') ?? '')
  const postsRef = useRef(document.createElement('input'))
  const debouncedSearchString = useDebounce(searchString, 1000)
  const { data, isLoading, isFetching } = useQuery(
    ['regulatory-posts-list', page, debouncedSearchString],
    () =>
      client
        .getByType('gim_post', {
          page,
          pageSize: 4,
          predicates: [predicate.fulltext('my.gim_post.title', debouncedSearchString)],
          orderings: {
            field: 'document.first_publication_date',
            direction: 'desc',
          },
          graphQuery: `
          {
            gim_post {
              title
              body
            }
          }`,
        })
        .then(async response => {
          await new Promise(resolve => setTimeout(() => resolve(true), 500))

          return {
            ...response,
            formatedResults: response.results.map(post => ({
              slug: post.uid,
              title: asText(post.data.title),
              excerpt: post.data.body.find((content: { type: string }) => content.type === 'paragraph')?.text.slice(0, 300) ?? '',
              banner: post.data.banner,
              createdAt: new Intl.DateTimeFormat('pt-br', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(post.last_publication_date)),
            })),
          }
        }),
    {
      staleTime: 60 * 60 * 1000, // 1 minute
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    query.delete('page')
    query.delete('search')

    history.replace({ search: query.toString() })
  }, [])

  useEffect(() => {
    if (key === 0) {
      setKey(state => state + 1)
      return
    }

    setPage(1)
  }, [debouncedSearchString])

  function incrementPage() {
    if (!data || page === data.total_pages) return
    setPage(state => state + 1)
    setKey(state => state + 1)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function decrementPage() {
    if (page === 1) return
    setPage(state => state - 1)
    setKey(state => state + 1)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <Container>
      <ControllerContainer>
        <InputText
          ref={postsRef}
          label='Pesquise pelo título dos artigos'
          placeholder='Insira um termo de pesquisa ou um assunto'
          value={searchString}
          onChange={ev => setSearchString(ev.target.value)}
        />
        {(() => {
          if (!searchString || !data || searchString !== debouncedSearchString || isFetching) return ''

          const { total_results_size: totalResultsSize } = data

          const sufix = totalResultsSize > 1 ? 'artigos encontrados' : 'artigo encontrado'

          return (
            <div>
              <span>{totalResultsSize}</span>
              &nbsp;
              <span>{sufix}</span>
            </div>
          )
        })()}
      </ControllerContainer>
      {(() => {
        if (isLoading) return <LoadingPlaceholder />
        return (
          <>
            <PostsContainer isFetching={isFetching}>
              {data?.formatedResults.map((post, index) => (
                <PostContainer key={post.slug} index={index}>
                  <Link to={`/regulatorio/${post.slug}?page=${page}&search=${searchString}`}>
                    <time>{post.createdAt}</time>
                    <strong>{post.title}</strong>
                    <p>{post.excerpt}</p>
                  </Link>
                </PostContainer>
              ))}
            </PostsContainer>
            {data && data.results.length !== 0 ? (
              <PaginatorContainer>
                <button type='button' onClick={decrementPage} disabled={page === 1}>
                  <BsArrowLeft />
                </button>
                <span>{`Página ${page} de ${data?.total_pages}`}</span>
                <button type='button' onClick={incrementPage} disabled={!data || page === data.total_pages}>
                  <BsArrowRight />
                </button>
              </PaginatorContainer>
            ) : (
              <NoDataWarning text='Nenhum artigo encontrado' />
            )}
          </>
        )
      })()}
    </Container>
  )
}
