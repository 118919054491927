import React from 'react'
import { utcDateFromDate } from 'utils'
import { TooltipProps } from 'recharts'
import {
  Container,
  TooltipGrid,
  TooltipTitleContainer,
  TooltipInfoContainer,
  TooltipInfoValue,
  TooltipContainer,
  ToolTipDivider,
} from 'components/CustomTooltip'

export const CustomTooltip: React.FC<TooltipProps & { axises: { dataKey: string; name: string; stackId: number }[] }> = ({
  payload,
  active,
  axises,
}) => {
  if (!(payload && active)) return null
  if (!payload[0]) return null
  return (
    <Container>
      <TooltipTitleContainer>{utcDateFromDate(new Date(payload[0].payload.date)).toLocaleDateString('pt-br')}</TooltipTitleContainer>
      <TooltipContainer>
        <TooltipGrid columns={3}>
          <TooltipInfoContainer>
            {axises.map(axis => {
              const value = Number(payload[0].payload[axis.dataKey])
              if (!value) return null
              return <TooltipInfoValue key={axis.name}>{axis.name}</TooltipInfoValue>
            })}
            <TooltipInfoValue>Total</TooltipInfoValue>
          </TooltipInfoContainer>
          <ToolTipDivider />
          <TooltipInfoContainer>
            {axises.map(axis => {
              const value = Number(payload[0].payload[axis.dataKey])
              if (!value) return null
              return <TooltipInfoValue key={axis.name}>{`${value.toLocaleString('pt-br')} MWmed`}</TooltipInfoValue>
            })}
            <TooltipInfoValue>{`${payload[0].payload.total.toLocaleString('pt-br')} MWmed`}</TooltipInfoValue>
          </TooltipInfoContainer>
        </TooltipGrid>
      </TooltipContainer>
    </Container>
  )
}
