import styled, { keyframes } from 'styled-components'
import { transparentize } from 'polished'

export const Container = styled.div``

export const SubtitleContainer = styled.h2`
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  gap: 10px;
`

export const TableContainer = styled.div`
  table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
    border-spacing: 0.5rem 0.5rem;
    thead tr {
      th {
        position: relative;
        padding: 0.5rem;
        border-radius: 0.25rem;
        border: 2px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.5);
        transition: 200ms;
        :first-child {
          opacity: 0;
        }
      }
      :not(:last-child) {
        background: #ccc;
      }
    }
    tbody tr td {
      padding: 0.5rem;
      border-radius: 0.25rem;
      color: rgba(0, 0, 0, 0.87);
      transition: 200ms;
      :first-child {
        font-weight: 700;
      }
    }
  }
`

export const TableData = styled.td<{ color: string }>`
  border: 2px solid rgba(0, 0, 0, 0.05);
  :first-child {
    border: 2px solid ${props => transparentize(0.3, props.color)};
  }
  :not(:first-child):hover {
    color: ${props => props.theme.safiraColors.blue[4]};
  }
`

export const TableHeaderContent = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`

const InfoContainerAnimation = keyframes`
  from {
    right: -40px;
    opacity: 0;
  }
  to {
    right: -30px;
    opacity: 1;
  }
`

export const InfoContainer = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
  :hover div {
    display: flex;
  }
  div {
    gap: 1rem;
    align-items: center;
    animation: ${InfoContainerAnimation} 500ms 1;
    background: ${props => props.theme.safiraColors.blue[3]};
    position: absolute;
    top: 18px;
    right: -30px;
    padding: 1rem;
    border-radius: 1rem;
    min-width: 400px;
    display: none;
    svg {
      font-size: 6rem;
      max-height: 50px;
      color: ${props => props.theme.safiraColors.green[0]};
    }
    span {
      border-left: 1px solid #fff;
      color: #fff;
      line-height: 30px;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }
`
