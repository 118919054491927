import { lighten, transparentize } from 'polished'
import styled, { css } from 'styled-components'

export const Container = styled.div``

export const TableContainer = styled.div`
  margin-top: 2rem;
  overflow: auto;
  max-width: 100%;
  max-height: 700px;
  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${props => transparentize(0.3, props.theme.colors.blue100)};
  }
  ::-webkit-scrollbar-track {
    background-color: ${props => transparentize(0.3, props.theme.colors.black100)};
  }
  table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
    thead tr {
      th {
        padding: 1rem;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        color: ${props => props.theme.colors.blue700};
        background: ${props => props.theme.safiraColors.blue[4]};
      }
      :first-child {
        th {
          color: #fff;
          :first-child {
            border-top-left-radius: 1rem;
            border-left: none;
          }
          :last-child {
            border-top-right-radius: 1rem;
            border-right: none;
          }
        }
      }
      :last-child th {
        color: #fff;
        background: ${props => lighten(0.1, props.theme.safiraColors.blue[5])};
        :last-child {
          border-right: none;
        }
      }
    }
    tbody tr {
      td {
        padding: 0.5rem;
        font-size: 0.875rem;
      }
    }
  }
`

export const DateRevisionTd = styled.td`
  font-weight: bold;
  box-shadow: inset 0 1px 0 #eaeaea, inset 0 -1px 0 #eaeaea, inset 1px 0 0 #eaeaea, inset -1px 0 0 #eaeaea;
  color: ${props => props.theme.colors.blue700};
`

export const StudyTd = styled.td`
  font-weight: bold;
  box-shadow: inset 0 1px 0 #eaeaea, inset 0 -1px 0 #eaeaea, inset 1px 0 0 #eaeaea, inset -1px 0 0 #eaeaea;
  color: ${props => props.theme.colors.blue700};
`

type ValueTdProps = {
  color: string
  isLastFromStudy: boolean
}

export const ValueTd = styled.td<ValueTdProps>`
  :first-child {
    color: ${props => transparentize(0.3, props.color)};
    font-weight: bold;
    border: none;
    box-shadow: inset 0 1px 0 #eaeaea, inset 0 -1px 0 #eaeaea, inset 1px 0 0 #eaeaea, inset -1px 0 0 #eaeaea;
  }
  border-right: 1px solid #ccc;
  ${props =>
    props.isLastFromStudy &&
    css`
      border-bottom: 1px solid #ccc;
    `}
`
