import React, { Children } from 'react'
import { Container } from './styles'

type SummaryProps = {
  columns: number
  children: React.ReactNode
}

export function Summary({ children, columns }: SummaryProps) {
  const count = Children.count(children)

  return (
    <Container count={count} columns={columns}>
      {children}
    </Container>
  )
}

function SummaryDots(props: { style?: React.CSSProperties; count?: number }) {
  return (
    <span className='dots' style={props.style}>
      {Array(props.count ?? 100).fill('.')}
    </span>
  )
}

Summary.Dots = SummaryDots
