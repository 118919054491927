import React, { useState } from 'react'
import { add, format, parse, startOfYear } from 'date-fns'
import { useQuery } from 'react-query'
import { SectionTitle, SectionText } from 'components/Section'
import { InputDate, PrismaSelect } from 'components/Input'
import api from 'services/api'
import { subsystems } from 'utils/globals'
import { Charts } from './Charts'
import { DamIcon, WaterIcon } from 'components/Icons'
import { Container, ControllerContainer, TitlesContainer, ControllerAndMapContainer } from './styles'
import { getImageUrl } from 'utils'
import { InputChartType } from 'components/Input/InputChartType'

export type EnaEarDTO = {
  date: string
  enaMwmed: number
  enaPercent: number
  earDayMwMonth: number
  earDayPercent: number
  earCapacityMwMonth: number
  mlt: number
}

export const EnaEar: React.FC = () => {
  const [interval, setInterval] = useState({ start: startOfYear(add(new Date(), { years: -3 })), end: new Date() })
  const [subsystem, setSubsystem] = useState('')
  const [chartType, setChartType] = useState('area')

  const { data, isLoading } = useQuery(
    ['operation-ena-ear', interval, subsystem],
    () => api.get<EnaEarDTO[]>('ena-ear', { params: { ...interval, subsystem } }).then(({ data }) => data),
    {
      staleTime: 1000 * 60, // 1 minute
    },
  )

  return (
    <Container>
      <SectionTitle>BDO - Boletim Diário da Operação</SectionTitle>
      <SectionText>
        Histórico recente de energia natural afluente (ENA) e energia armazenada (EAR) de acordo com os dados do Boletim Diário da Operação (BDO) do
        Operador Nacional do Sistema Elétrico (ONS), para o Sistema Interligado Nacional (SIN) e para os subsistemas.
      </SectionText>
      <ControllerAndMapContainer>
        <ControllerContainer>
          <InputDate
            label='Início'
            value={format(interval.start, 'yyyy-MM-dd')}
            onChange={({ target }) => setInterval(state => ({ ...state, start: parse(target.value, 'yyyy-MM-dd', new Date()) }))}
          />
          <InputDate
            label='Fim'
            value={format(interval.end, 'yyyy-MM-dd')}
            onChange={({ target }) => {
              setInterval(state => ({ ...state, end: parse(target.value, 'yyyy-MM-dd', new Date()) }))
            }}
          />
          <PrismaSelect
            onChange={({ target }) => setSubsystem(target.value)}
            options={[{ label: 'Sistema interligado', value: 'sin' }].concat(
              subsystems.map(subsystem => ({ label: subsystem.name, value: subsystem.key })),
            )}
          />
          <InputChartType defaultType='area' onChange={newCharType => setChartType(newCharType)} />
        </ControllerContainer>
        <div className='image-container'>
          <img
            src={subsystems.find(e => e.key === subsystem)?.mapUrl ?? getImageUrl('images/subsystems/mapa_brasil_colorido.png')}
            alt='Imagem do submercado'
          />
        </div>
      </ControllerAndMapContainer>
      <TitlesContainer>
        <div>
          <WaterIcon />
          <span>Energia natural afluente (ENA)</span>
        </div>
        <div>
          <DamIcon />
          <span>Energia armazenada (EAR)</span>
        </div>
      </TitlesContainer>
      <Charts isLoading={isLoading} data={data} color={subsystems.find(e => e.key === subsystem)?.color ?? '#333'} chartType={chartType} />
    </Container>
  )
}
