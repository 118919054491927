import React, { useState, useEffect } from 'react'
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import api from 'services/api'
import { ChartContainer, LoadingContainer } from 'components/Chart'
import { MeteorologyIndex } from 'types'
import { Section } from 'components/Section'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { useTheme } from 'styled-components'
import { capitalize, PrismaChartUtils } from 'utils'
import SectionSubTitle from 'components/SectionSubtitle'
import { Container } from './styles'
import CustomTooltip from './CustomTooltip'

const IOD: React.FC = () => {
  const [data, setData] = useState<MeteorologyIndex[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const theme = useTheme()

  useEffect(() => {
    api.get<MeteorologyIndex[]>('dipole-mode-index').then(response => {
      setData(response.data)
      setIsLoading(false)
    })
  }, [])

  const xAxisFormatter = (tick: string): string => {
    const year = new Date(tick).toLocaleString('pt-BR', { year: 'numeric' })
    const month = new Date(tick).toLocaleString('pt-BR', { month: 'short' })
    return `${capitalize(month)}/${year}`
  }

  const tooltip = (
    <div>
      <p>
        A diferença na TSM entre os polos ocidental (oeste do oceano Índico) e oriental (leste do oceano Índico e ao sul da Indonésia) gera alterações
        nos padrões de vento, temperatura e precipitação, afetando o clima local e global.
      </p>
      <p>
        Na fase positiva, o polo ocidental possui águas mais quentes, e o polo oriental mais frias, com ventos de oeste enfraquecidos. Na fase
        negativa, o polo ocidental está mais frio e o oriental mais quente, com os ventos de oeste intensificados ao longo do equador.
      </p>
      <p>
        No Brasil, a precipitação é influenciada pela alteração provocada na circulação atmosférica, que em sua fase positiva, passa a ter condições
        desfavoráveis para a formação de precipitação no centro e leste do Brasil, reduzindo os níveis de precipitação no Sudeste.
      </p>
      <p>Pelo índice IOD, percebe-se que a oscilação ocorre entre as fases positiva, negativa e neutra.</p>
      <p>
        <span>Dados fornecidos por: </span>
        <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
          NOAA
        </a>
      </p>
    </div>
  )

  return (
    <Container>
      <Section>
        <SectionTitle title='Dipolo do Oceano Índico (IOD)' />
        <SectionText>
          É um fenômeno atmosférico-oceânico caracterizado pela diferença na temperatura na superfície do mar (TSM) entre as porções ocidental e
          oriental do oceano Índico Equatorial. Sua fase negativa está associada à condições desfavoráveis para a formação de chuva na região Sudeste
          do Brasil. O monitoramento da oscilação e sua intensidade é obtida pelo Índice IOD: fase negativa (azul) e fase positiva (vermelho).
        </SectionText>
      </Section>
      <Section>
        <SectionSubTitle title='Histórico' info={tooltip} />
        <ChartContainer height='400px' style={{ paddingTop: '10px' }}>
          <ResponsiveContainer>
            {(() => {
              if (isLoading) return <LoadingContainer />
              return (
                <AreaChart data={data}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip content={CustomTooltip} />
                  <YAxis
                    dataKey='value'
                    type='number'
                    tick={{ fontSize: '12px' }}
                    width={10}
                    domain={[dataMin => Math.floor(dataMin / 1) * 1, dataMax => Math.ceil(dataMax / 1) * 1]}
                  />
                  <XAxis dataKey='date' type='category' tick={{ fontSize: '12px' }} tickFormatter={xAxisFormatter} />
                  <defs>
                    <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
                      <stop
                        offset={PrismaChartUtils.getChartGradientOffset({ data: data.map(entry => entry.value) })}
                        stopColor={theme.safiraColors.red[1]}
                        stopOpacity={1}
                      />
                      <stop
                        offset={PrismaChartUtils.getChartGradientOffset({ data: data.map(entry => entry.value) })}
                        stopColor={theme.safiraColors.blue[1]}
                        stopOpacity={1}
                      />
                    </linearGradient>
                  </defs>
                  <Area dataKey='value' fill='url(#splitColor)' stroke='url(#splitColor)' />
                </AreaChart>
              )
            })()}
          </ResponsiveContainer>
        </ChartContainer>
      </Section>
    </Container>
  )
}

export default IOD
