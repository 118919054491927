import React from 'react'
import { ResponsiveContainer, BarChart, YAxis, XAxis, Bar, Tooltip, CartesianGrid, Legend } from 'recharts'
import { fixedColors, CustomProspectiveStudy } from '..'
import { ChartContainer } from './styles'
import { utcDateFromDate } from 'utils'
import { CustomLegend } from 'components/Chart'
import { subsystems } from 'utils/globals'

export function DailyChart({
  data,
  studies,
  selectedSubsystem,
  limitedPrices,
}: {
  data: CustomProspectiveStudy['data']['daily']
  studies: CustomProspectiveStudy['meta']['studies']
  selectedSubsystem: number
  limitedPrices: boolean
}) {
  return (
    <ChartContainer>
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray='3 3' vertical={false} />
          <XAxis
            dataKey='date'
            tickLine={false}
            interval={0}
            tick={{ fontSize: '14px' }}
            tickFormatter={tick => {
              const tickDate = utcDateFromDate(new Date(tick))
              return tickDate.toLocaleDateString()
            }}
          />
          <YAxis
            tick={{ fontSize: '14px' }}
            width={80}
            tickFormatter={tick => {
              return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(tick))
            }}
          />
          <Tooltip
            labelFormatter={label => utcDateFromDate(new Date(label)).toLocaleString()}
            formatter={tooltip => {
              return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(tooltip))
            }}
          />
          {studies.map((entry, index) => (
            <Bar
              key={entry.hash}
              dataKey={`studies.${entry.hash}.${subsystems[selectedSubsystem].key}.${limitedPrices ? 'pld' : 'cmo'}`}
              fill={fixedColors[index] ?? subsystems[selectedSubsystem].color ?? 'blue'}
              name={entry.name}
            />
          ))}
          <Legend
            content={
              <CustomLegend
                legends={studies.map((entry, index) => ({
                  text: entry.name,
                  color: fixedColors[index],
                }))}
              />
            }
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}
