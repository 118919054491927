import React, { useState } from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import { ResponsiveContainer, ComposedChart, CartesianGrid, YAxis, XAxis, Tooltip, Legend, Label, Line } from 'recharts'
import { PrismaSelect, InputDate } from 'components/Input'
import { Container, ControllerContainer } from './styles'
import { SectionTitle } from 'components/Section'
import { useTheme } from 'styled-components'
import { add, format, isAfter, isBefore, parse } from 'date-fns'
import { formatQueryDate, utcDateFromDate } from 'utils'
import { toast } from 'react-toastify'
import randomColor from 'randomcolor'
import { ChartContainer } from '..'

const yTickFormatter = (tick: string) => Intl.NumberFormat('pt-br').format(Number(tick))

const y2TickFormatter = (tick: string) => Intl.NumberFormat('pt-br', { style: 'percent' }).format(Number(tick) / 100)

const xTickFormatter = (tick: string) => {
  return Intl.DateTimeFormat('pt-br', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  }).format(new Date(tick))
}

export function GeracaoTermicaPorMotivoDeDespacho() {
  const [end, setEnd] = useState(new Date())
  const [start, setStart] = useState(add(end, { days: -10 }))
  const [usina, setUsina] = useState(0)

  const theme = useTheme()

  const datasets = [
    'valProgordemmerito',
    'valProgordemdemeritoref',
    'valProginflexibilidade',
    'valPrograzaoeletrica',
    'valProggarantiaenergetica',
    'valProggfom',
    'valProgreposicaoperdas',
    'valProgexportacao',
    'valProgreservapotencia',
    'valProggsub',
    'valProgunitcommitment',
    'valProgconstrainedoff',
    'valVerifgeracao',
    'valVerifordemmerito',
    'valVerifinflexibilidade',
    'valVerifrazaoeletrica',
    'valVerifgarantiaenergetica',
    'valVerifgfom',
    'valVerifreposicaoperdas',
    'valVerifexportacao',
    'valVerifreservapotencia',
    'valAtendsatisfatoriorpo',
    'valVerifgsub',
    'valVerifunitcommitment',
    'valVerifconstrainedoff',
  ].map(entry => ({ key: entry, name: entry, color: randomColor(), component: 'line', yAxisId: 1 }))

  const {
    data: usinas,
    isLoading: isLoading1,
    isFetching: isFetching1,
  } = useQuery(
    ['geracao-termica-por-motivo-de-despacho-usinas', start, end],
    async () => {
      const response = await api.get<string[]>('dados-abertos-ons/geracao-termica-por-motivo-de-despacho', {
        params: { start: formatQueryDate(start), end: formatQueryDate(end) },
      })

      return response.data
    },
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  const {
    data,
    isLoading: isLoading2,
    isFetching: isFetching2,
  } = useQuery(
    ['geracao-termica-por-motivo-de-despacho-data', start, end, usina],
    async () => {
      const response = await api.get<Record<string, unknown>[]>(`dados-abertos-ons/geracao-termica-por-motivo-de-despacho/${usinas?.[usina]}`, {
        params: { start: formatQueryDate(start), end: formatQueryDate(end) },
      })

      return response.data
    },
    { keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!usinas },
  )

  return (
    <Container>
      <SectionTitle>Geracao térmica por motivo de despacho</SectionTitle>
      <ControllerContainer>
        <InputDate
          label='Início'
          value={format(utcDateFromDate(start), 'yyyy-MM-dd')}
          onChange={e => {
            const newStart = parse(e.target.value, 'yyyy-MM-dd', new Date())
            if (isBefore(newStart, end)) return setStart(newStart)
            toast.error('Data inicial deve ser anterior a final.', { position: 'top-right' })
          }}
        />
        <InputDate
          label='Fim'
          value={format(utcDateFromDate(end), 'yyyy-MM-dd')}
          onChange={e => {
            const newEnd = parse(e.target.value, 'yyyy-MM-dd', new Date())
            if (isAfter(newEnd, start)) return setEnd(newEnd)
            toast.error('Data final deve ser posterior a inicial.', { position: 'top-right' })
          }}
        />
        <PrismaSelect
          label='Usina'
          onChange={event => setUsina(Number(event.target.value))}
          options={usinas?.map((basin, index) => ({ label: basin, value: String(index) }))}
        />
      </ControllerContainer>
      <ChartContainer isFetching={isFetching1 || isFetching2} isLoading={isLoading1 || isLoading2}>
        <ResponsiveContainer>
          <ComposedChart data={data}>
            <CartesianGrid strokeDasharray='3 5' />
            <XAxis dataKey='dinInstante' tick={{ fontSize: '14px' }} tickFormatter={xTickFormatter} />
            <YAxis tick={{ fontSize: '14px' }} tickCount={8} yAxisId={1} tickFormatter={yTickFormatter}>
              <Label
                angle={-90}
                value={'MWmês'}
                position='insideLeft'
                style={{ fontSize: '0.875rem', fill: theme.colors.black300, textAnchor: 'middle' }}
              />
            </YAxis>
            <YAxis tick={{ fontSize: '14px' }} tickCount={12} yAxisId={2} orientation='right' domain={[0, 100]} tickFormatter={y2TickFormatter} />
            <Legend iconType='square' wrapperStyle={{ fontSize: '14px' }} />
            <Tooltip labelFormatter={label => xTickFormatter(String(label))} />
            {datasets.map(dataset => (
              <Line
                dot={false}
                key={dataset.key}
                dataKey={dataset.key}
                stroke={dataset.color}
                name={dataset.name}
                yAxisId={dataset.yAxisId}
                strokeWidth={3}
              />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  )
}
