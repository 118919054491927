import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
`

export const InfoContainer = styled.div<{ highlighted?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1.5rem;
`

export const InfoTitle = styled.div`
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  position: relative;
  color: ${props => props.theme.safiraColors.blue[3]};
`

export const InfoValueContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 5px;
  div:nth-child(2) {
    overflow-x: hidden;
    opacity: 0.5;
  }
`

export const InfoIndex = styled.div`
  font-size: 1rem;
  color: ${props => props.theme.safiraColors.blue[3]};
  font-weight: 500;
`

export const InfoValue = styled.div`
  font-size: 1rem;
  text-align: right;
  color: ${props => props.theme.safiraColors.blue[3]};
  font-weight: 700;
`
