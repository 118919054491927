import React, { useState, useEffect } from 'react'
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import api from 'services/api'
import { ChartContainer, LoadingContainer } from 'components/Chart'
import { MeteorologyIndex } from 'types'
import { Section } from 'components/Section'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { useTheme } from 'styled-components'
import { capitalize } from 'utils'
import SectionSubTitle from 'components/SectionSubtitle'
import { Container } from './styles'
import CustomTooltip from './CustomTooltip'

const PDO: React.FC = () => {
  const [data, setData] = useState<MeteorologyIndex[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const theme = useTheme()

  useEffect(() => {
    api.get<MeteorologyIndex[]>('pacific-decadal-oscillation').then(response => {
      setData(response.data)
      setIsLoading(false)
    })
  }, [])

  const gradientOffset = () => {
    const dataMax = Math.max(...data.map(i => i.value))
    const dataMin = Math.min(...data.map(i => i.value))

    if (dataMax <= 0) {
      return 0
    }
    if (dataMin >= 0) {
      return 1
    }

    return dataMax / (dataMax - dataMin)
  }

  const off = gradientOffset()

  const xAxisFormatter = (tick: string): string => {
    const year = new Date(tick).toLocaleString('pt-BR', { year: 'numeric' })
    const month = new Date(tick).toLocaleString('pt-BR', { month: 'short' })
    return `${capitalize(month)}/${year}`
  }

  const tooltip = (
    <div>
      <p>
        A fase positiva é caracterizada pela anomalia positiva da TSM no trópico e negativa na região nordeste do Pacífico, enquanto na fase negativa,
        a anomalia da TSM é negativa nos trópicos é negativa, e positiva no nordeste do oceano Pacífico Tropical.
      </p>
      <p>
        Eventos de El Niño (La Niña) são intensificados (desintensificados) pela ODP positiva e desintensificados (intensificados) na fase negativa,
        amplificando, ou reduzindo, os efeitos destes fenômenos.
      </p>
      <p>
        <span>Dados fornecidos por: </span>
        <a href='https://www.noaa.gov' target='_blank' rel='noreferrer'>
          NOAA
        </a>
      </p>
    </div>
  )

  return (
    <Container>
      <Section>
        <SectionTitle title='Oscilação Decadal do Pacífico (PDO)' />
        <SectionText>
          É uma oscilação de baixa frequência na temperatura da superfície do mar (TSM) no oceano Pacífico Tropical e Nordeste, com duração entre 20 e
          30 anos, que afeta a temperatura e a precipitação. Os efeitos da PDO na precipitação no Brasil são similares aos do ENSO, porém menos
          intensos. O índice para monitoramento é feito a partir das anomalias mensais de TSM ao norte de 20ºN no oceano Pacífico.
        </SectionText>
      </Section>
      <Section>
        <SectionSubTitle title='Histórico' info={tooltip} />
        <ChartContainer height='400px' style={{ paddingTop: '10px' }}>
          <ResponsiveContainer>
            {isLoading ? (
              <LoadingContainer />
            ) : (
              <AreaChart data={data}>
                <CartesianGrid strokeDasharray='3 3' />
                <Tooltip content={CustomTooltip} />
                <YAxis
                  dataKey='value'
                  type='number'
                  tick={{ fontSize: '12px' }}
                  width={10}
                  domain={[dataMin => Math.floor(dataMin / 2) * 2, dataMax => Math.ceil(dataMax / 2) * 2]}
                />
                <XAxis dataKey='date' type='category' tick={{ fontSize: '12px' }} tickFormatter={xAxisFormatter} />
                <defs>
                  <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset={off} stopColor={theme.safiraColors.red[1]} stopOpacity={1} />
                    <stop offset={off} stopColor={theme.safiraColors.blue[1]} stopOpacity={1} />
                  </linearGradient>
                </defs>
                <Area dataKey='value' fill='url(#splitColor)' stroke='url(#splitColor)' />
              </AreaChart>
            )}
          </ResponsiveContainer>
        </ChartContainer>
      </Section>
    </Container>
  )
}

export default PDO
