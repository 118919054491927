import styled from 'styled-components'

export const Container = styled.div``

export const ControllerContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 2rem 0;
`

export const ControllerDivider = styled.div`
  width: 3px;
  border-radius: 0.25rem;
  margin: 0 1rem;
  background: ${props => props.theme.colors.blue100};
`

export const ScreenshotButton = styled.button`
  padding: 10px 15px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: ${props => props.theme.colors.blue700};
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.black100};
  transition: background 150ms;
  :hover {
    background: ${props => props.theme.colors.black200};
  }
  svg {
    font-size: 1.3rem;
    color: ${props => props.theme.colors.black500};
  }
`
