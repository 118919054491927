import styled from 'styled-components'
import { transparentize } from 'polished'

export const Container = styled.div``

export const ControllerContainer = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 15px;
  button {
    height: 30px;
    width: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    position: relative;
    transition: 200ms;
    background: ${props => transparentize(0.9, props.theme.safiraColors.blue[4])};
    opacity: 0.3;
    :focus {
      outline: none;
    }
    :hover {
      background: ${props => transparentize(0.85, props.theme.safiraColors.blue[4])};
      opacity: 1;
    }
    svg {
      color: ${props => transparentize(0.6, props.theme.safiraColors.blue[4])};
    }
  }
`

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  input[type='range'] {
    width: 100%;
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: ${props => transparentize(0.8, props.theme.safiraColors.blue[0])};
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    :hover {
      opacity: 1;
    }
    ::-webkit-slider-thumb {
      appearance: none;
      width: 15px;
      height: 15px;
      background: ${props => props.theme.safiraColors.blue[4]};
      border-radius: 50%;
      cursor: grab;
      :active {
        cursor: grabbing;
      }
    }
  }
`

export const LabelContainer = styled.div<{ numberOfLabels: number }>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.numberOfLabels}, 1fr)`};
  width: 100%;
  div {
    padding: 5px;
    filter: opacity(0.7);
    :first-child {
      border-radius: 5px 0 0 5px;
    }
    :last-child {
      border-radius: 0 5px 5px 0;
    }
  }
`

export const ContentContainer = styled.div``

export const ImagesContainer = styled.div<{ columnsNumber: number }>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columnsNumber}, 1fr)`};
  gap: 10px;
  img {
    max-width: 700px;
  }
`

export const ImageContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid ${props => transparentize(0.6, props.theme.safiraColors.blue[4])};
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  :hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  img {
    width: 100%;
  }
`

export const ImageTitle = styled.div<{ hasTooltip: boolean }>`
  width: 100%;
  padding: 0.25rem 1rem;
  background-color: ${props => props.theme.safiraColors.blue[4]};
  color: #fff;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  border-radius: 5px 5px 0 0;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
`

export const ImageDownloadButton = styled.button`
  background: none;
  color: #fff;
  border: none;
`

export const ImageGroupTitle = styled.div`
  padding: 5px 0 20px 0;
  grid-column: 1 / span 2;
  text-align: center;
  span {
    font-size: 18px;
    font-weight: bold;
    color: ${props => transparentize(0.5, props.theme.safiraColors.blue[5])};
  }
`
