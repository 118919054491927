import React, { useState } from 'react'
import { Container, ButtonsContainer, Button, Label } from './styles'
import { BsFillBarChartFill } from 'react-icons/bs'
import { MdOutlineAreaChart } from 'react-icons/md'
import { BiLineChart } from 'react-icons/bi'
import { IconType } from 'react-icons'

type ChartTypes = 'bar' | 'area' | 'line'

type Type = {
  name: string
  key: ChartTypes
  Icon: IconType
}

type InputChartTypeProps = {
  defaultType: ChartTypes
  onChange: (chartType: ChartTypes) => void
}

export function InputChartType({ defaultType, onChange }: InputChartTypeProps) {
  const [selectedType, setType] = useState<ChartTypes>(defaultType)

  const types: Type[] = [
    {
      name: 'Gráfico de área',
      key: 'area',
      Icon: MdOutlineAreaChart,
    },
    {
      name: 'Gráfico de linha',
      key: 'line',
      Icon: BiLineChart,
    },
    {
      name: 'Gráfico de barra',
      key: 'bar',
      Icon: BsFillBarChartFill,
    },
  ]

  function handleChartTypeChange(newChartType: ChartTypes) {
    onChange(newChartType)
  }

  return (
    <Container>
      <Label>Tipo de gráfico</Label>
      <ButtonsContainer>
        {types.map(type => (
          <Button
            key={type.key}
            active={type.key === selectedType}
            title={type.name}
            onClick={() => {
              setType(type.key)
              handleChartTypeChange(type.key)
            }}
          >
            <type.Icon />
          </Button>
        ))}
      </ButtonsContainer>
    </Container>
  )
}
