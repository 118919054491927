import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { ResponsiveContainer, Legend, LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, Label } from 'recharts'
import api from 'services/api'
import { PrismaChartUtils, utcDateFromDate, numberToBrl } from 'utils'
import { subsystems } from 'utils/globals'
import { SectionTitle, SectionText } from 'components/Section'
import { InputDate } from 'components/Input'
import { ControllerContainer } from 'components/Layout'
import { CustomLegend, LoadingContainer } from 'components/Chart'
import { Container, ChartContainer } from './styles'
import CustomTooltip from './CustomTooltip'
import { Summary } from 'components/Summary'
import { useTheme } from 'styled-components'

type DceeDessemPricesResponse = {
  summary: {
    max: { southeast: number; south: number; northeast: number; north: number }
    min: { southeast: number; south: number; northeast: number; north: number }
    average: { southeast: number; south: number; northeast: number; north: number }
  }
  prices: { date: string; time: string; southeast: number; south: number; northeast: number; north: number }[]
}

const CurrentDay: React.FC = () => {
  const [date, setDate] = useState<Date>()
  const [dates, setDates] = useState<Date[]>([])
  const [dateLimits, setDateLimits] = useState({ max: new Date(), min: new Date() })
  const [summaryData, setSummaryData] = useState<DceeDessemPricesResponse['summary']>()
  const [data, setData] = useState<DceeDessemPricesResponse['prices']>([])
  const [loading, setLoading] = useState(true)

  const theme = useTheme()

  useEffect(() => {
    api.get<{ date: string }[]>('ccee-dessem-prices/list-dates').then(response => {
      setDates(response.data.map(entry => new Date(entry.date)))
      const max = [...response.data].pop()
      const min = [...response.data].shift()
      if (max && min) {
        setDate(utcDateFromDate(new Date(max.date)))
        setDateLimits({ max: utcDateFromDate(new Date(max.date)), min: utcDateFromDate(new Date(min.date)) })
      }
    })
  }, [])

  useEffect(() => {
    if (!date) return
    api
      .get<DceeDessemPricesResponse>('ccee-dessem-prices', { params: { start: date } })
      .then(response => {
        setSummaryData(response.data.summary)
        setData(response.data.prices)
      })
      .then(() => setLoading(false))
  }, [date, dates])

  const summaries: { title: string; key: 'min' | 'max' | 'average' }[] = [
    { title: 'Preços mínimos', key: 'min' },
    { title: 'Preços médios', key: 'average' },
    { title: 'Preços máximos', key: 'max' },
  ]

  return (
    <Container>
      <SectionTitle
        title='Diário'
        info='A partir de 1° de janeiro de 2021, o Preço de Liquidação das Diferenças - PLD passou a ser calculado oficialmente para cada submercado em base horária, conforme proposto pela Comissão Permanente para Análise de Metodologias e programas Computacionais do Setor Elétrico – CPAMP, com cronograma de implantação definido pela Portaria MME 301/2019. No período de abril de 2018 até a efetiva implementação do preço horário, paralelamente ao cálculo oficial do PLD em base semanal, a CCEE também realizou o cálculo e divulgação do Preço horário “Sombra”, também disponível para consulta na ferramenta abaixo. Os valores divulgados até 31 de dezembro de 2020, consistem na operação sombra e não estão vigentes comercialmente.'
      />
      <SectionText text='Exposição do Preço de Liquidação das Diferenças (PLD) horário para cada submercado, disponibilizado pela Câmara de Comercialização de Energia Elétrica (CCEE).' />
      <ControllerContainer>
        <InputDate
          label='Data'
          disabled={loading}
          value={date && format(date, 'yyyy-MM-dd')}
          min={format(dateLimits.min, 'yyyy-MM-dd')}
          max={format(dateLimits.max, 'yyyy-MM-dd')}
          onChange={ev => setDate(utcDateFromDate(new Date(ev.target.value)))}
        />
      </ControllerContainer>
      <Summary columns={3}>
        {summaries.map(summary => (
          <div key={summary.key}>
            <h2>{summary.title}</h2>
            <div>
              {subsystems.map(subsystem => (
                <div key={subsystem.key}>
                  <span>{subsystem.name}</span>
                  <Summary.Dots />
                  <span key={subsystem.name} className='highlighted'>
                    {(() => {
                      const value = summaryData?.[summary.key]?.[subsystem.key]
                      if (!value || Number.isNaN(value)) return '-'
                      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    })()}
                  </span>
                  <span></span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Summary>
      <ChartContainer>
        {(() => {
          if (loading) return <LoadingContainer />
          return (
            <ResponsiveContainer>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray='3' strokeOpacity={0.5} />
                <Legend content={<CustomLegend legends={subsystems.map(entry => ({ text: entry.name, color: entry.color }))} />} />
                <Tooltip content={CustomTooltip} />
                <YAxis
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                  interval={0}
                  ticks={PrismaChartUtils.getChartTicks({
                    payload: data.flatMap(entry => [entry.southeast, entry.south, entry.northeast, entry.north]),
                    numberOfTicks: 8,
                    rounder: 1,
                  })}
                  width={90}
                  tickFormatter={tick => numberToBrl(Number(tick))}
                  domain={['dataMin', 'dataMax']}
                >
                  <Label
                    angle={-90}
                    value='PLD (R$/MWh)'
                    position='insideLeft'
                    style={{ fontSize: '0.875rem', fill: theme.colors.black500, textAnchor: 'middle' }}
                  />
                </YAxis>
                <XAxis
                  tick={{ fontSize: '0.875rem', fill: theme.colors.black500 }}
                  dataKey='time'
                  interval={0}
                  tickFormatter={tick => {
                    const formattedTick = utcDateFromDate(new Date(tick)).toLocaleString('default', {
                      hour: '2-digit',
                    })
                    return `${formattedTick}h`
                  }}
                />
                {[...subsystems].reverse().map(subsystem => (
                  <Line
                    key={subsystem.name}
                    name={subsystem.name}
                    dataKey={subsystem.key}
                    strokeWidth={5}
                    stroke={subsystem.color}
                    fill={subsystem.color}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          )
        })()}
      </ChartContainer>
    </Container>
  )
}

export default CurrentDay
