import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const Grid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
  gap: 30px;
`

export const ONIImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  div img {
    width: 100%;
  }
`
