import axios from 'axios'
import { getZitadelToken } from './zitadel'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ADDRESS,
})

api.interceptors.request.use(async config => {
  const token = getZitadelToken()
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default api
